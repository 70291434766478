<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox offerManagementModal-container change-password-modal">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: getTranslationText('72')">{{getTranslationText('72')}}</span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <div class="modal-label-input-container hide-element">
                    <div class="modal-label"></div>
                    <div>
                        <input class="form-control summary-modal-input" 
                            id="login-email-input" 
                            maxlength="100" 
                            type="email">
                    </div>
                </div>
                <div class="modal-label-input-container">
                    <div class="modal-label">
                        <span data-bind="text: getTranslationText('272')">{{getTranslationText('272')}}</span>
                    </div>
                    <div>
                        <input class="form-control summary-modal-input" 
                            id="oldPassword" 
                            maxlength="100" 
                            type="password" 
                            autocomplete="new-password" 
                            readonly 
                            data-toggle="tooltip" 
                            data-placement="right" 
                            data-html="true"
                            v-model="oldPassword"
                            :data-title="oldPasswordTooltipMessage"
                            data-bind="value: oldPassword, attr: { 'data-title': oldPasswordTooltipMessage }">
                    </div>
                    
                </div>
                <div class="change-passowrd-forgot-password-display-right">
                    <a @click="openForgotPasswordModal"
                        class="a-change-password knockout-clickable-item"
                    >
                        <span> {{ getTranslationText('2357') }} </span>
                    </a>
                </div>
                <div class="modal-label-input-container">
                    <div class="modal-label">
                        <span data-bind="text: getTranslationText('260')">{{getTranslationText('260')}}</span>
                    </div>
                    <div>
                        <input class="form-control summary-modal-input" 
                            id="newPassword" 
                            maxlength="100" 
                            type="password" 
                            autocomplete="new-password" 
                            readonly 
                            data-toggle="tooltip" 
                            data-placement="right" 
                            data-html="true"
                            v-model="newPassword"
                            :data-title="newPasswordTooltipMessage"
                            data-bind="value: newPassword, attr: { 'data-title': newPasswordTooltipMessage }">
                    </div>
                </div>
                <div class="modal-label-input-container">
                    <div class="modal-label">
                        <span data-bind="text: getTranslationText('88')">{{getTranslationText('88')}}</span>
                    </div>
                    <div>
                        <input class="form-control summary-modal-input"
                            id="confirmPassword" 
                            maxlength="100" 
                            type="password" 
                            autocomplete="new-password" 
                            readonly 
                            data-toggle="tooltip" 
                            data-placement="right" 
                            data-html="true"
                            v-model="confirmPassword"
                            :data-title="confirmPasswordTooltipMessage"
                            data-bind="value: confirmPassword, attr: { 'data-title': confirmPasswordTooltipMessage }">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience btn-primary"
                    @click="ok"
                    data-bind="click: ok, touchstart: ok">
                    <span data-bind="text: getTranslationText('2543')">{{getTranslationText('2543')}}</span>
                </button>
                <button class="btn btn-default btn-truckscience"
                    @click="close"
                    data-bind="click: close, touchstart: close">
                    <span data-bind="text: getTranslationText('2544')">{{getTranslationText('2544')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import { ref, toRef, shallowRef, onMounted, nextTick } from 'vue';

import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';

import PopupModal from './PopupModal.vue';
import CustomMessageBox from '@/services/CustomMessageBox';
import bus from '@/main';

const $ = window.$;

export default {
    name: 'ChangePasswordModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        const oldPassword = shallowRef('');
        const newPassword = shallowRef('');
        const confirmPassword = shallowRef('');
        const changingPassword = shallowRef(false);

        const newPasswordTooltipMessage = shallowRef(config.getTooltipText(config.OTHER_TOOLTIP.PASSWORD_CRITERIA, globals.user.getCultureCode()));
        const confirmPasswordTooltipMessage = shallowRef(config.getTooltipText(config.OTHER_TOOLTIP.PASSWORD_MISMATCH, globals.user.getCultureCode()));
        const oldPasswordTooltipMessage = shallowRef(config.getTooltipText(config.OTHER_TOOLTIP.OLD_PASSWORD_INCORRECT, globals.user.getCultureCode()));
    
        let resolvePromise;

        function compositionComplete() {
            $('#oldPassword').on('blur', function () {
                // if (oldPassword.value === globals.user.getPassword()) {
                if (oldPassword.value.length > 0) {
                    $('#oldPassword').removeClass('error');
                    $('#oldPassword').tooltip('destroy');
                } else {
                    $('#oldPassword').addClass('error');
                    $('#oldPassword').focus();
                }

            });

            $('#oldPassword').on('focus', function () {
                $('#oldPassword').attr('readonly', false).tooltip({ trigger: 'manual' }).tooltip('show');
            });

            $('#newPassword').on('blur', function () {
                if (newPassword.value === '' || globals.isValidPassword(newPassword.value)) {
                    $('#newPassword').removeClass('error');
                    $('#newPassword').tooltip('destroy');
                } else {
                    $('#newPassword').addClass('error');
                    $('#newPassword').focus();
                }

            });

            $('#newPassword').on('focus', function () {
                $('#newPassword').attr('readonly', false).tooltip({ trigger: 'manual', html: true }).tooltip('show');
            });

            $('#confirmPassword').on('blur', function () {
                if (confirmPassword.value === '' || newPassword.value === confirmPassword.value) {
                    $('#confirmPassword').removeClass('error');
                    $('#confirmPassword').tooltip('destroy');
                } else {
                    $('#confirmPassword').addClass('error');
                    $('#confirmPassword').focus();
                }
            });

            $('#confirmPassword').on('focus', function () {
                $('#confirmPassword').attr('readonly', false);
                if ($('#confirmPassword').hasClass('error')) {
                    $('#confirmPassword').tooltip({ trigger: 'manual', html: true }).tooltip('show');
                }
            });

            return true;
        }

        onMounted(() => {
            popup.value.open();
            nextTick(compositionComplete);
        });

        function show(opts = {}) {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function close() {
            popup.value.close();
            resolvePromise();
        }

        function ok() {
            // check if passwords are valid
            var passedValidation = true;
            var checkNewPassword = globals.isValidPassword(newPassword.value),
                checkConfirmPassword = globals.isValidPassword(confirmPassword.value),
                focusErrorId = undefined;
            $('.error').removeClass('error');
            
            // if (globals.user.getPassword() !== oldPassword.value) {
            //     $('#oldPassword-container').addClass('error');
            //     setFocusErrorId('#oldPassword');
            //     passedValidation = false;
            // }

            if (newPassword.value !== confirmPassword.value) {
                $('#confirmPassword-container').addClass('error');
                setFocusErrorId('#confirmPassword');
                passedValidation = false;
            }

            if (checkConfirmPassword === false) {
                $('#confirmPassword-container').addClass('error');
                setFocusErrorId('#confirmPassword');
                passedValidation = false;
            }

            if (checkNewPassword === false) {
                $('#confirmPassword-container').addClass('error');
                setFocusErrorId('#newPassword');
                passedValidation = false;
            }
                
            if (passedValidation === true) {
                changePassword();
            } else {
                $(focusErrorId).focus();
            }

            function setFocusErrorId(value) {
                focusErrorId = focusErrorId === undefined ? value : focusErrorId;
            }
        }

        function changePassword() {
            changingPassword.value = true;

            //make sure the token is still valid and extend if necessary
            if (!dataManager.securityTokenIsValid()) {
                bus.emit('logout');
                return;
            }
            globals.showSpinner();
            $.when(dataManager.changePassword(oldPassword.value, newPassword.value)).then(
                function (success) {
                    globals.hideSpinner();
                    var returnCode = success.ReturnCode;
                    if (returnCode > 0) {
                        changingPassword.value = false;
                        oldPassword.value = '';
                        newPassword.value = '';
                        confirmPassword.value = '';

                        // update Intercom
                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CHANGED_PASSWORD));
                        // close modal
                        close();
                    } else if (returnCode === -4 || returnCode === -1){
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4808', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4808')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))    
                    } else if(returnCode === -2) {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4807', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4807')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))    
                            .addCallback(() => {
                                $('#newPassword').on('focus', function () {
                                    $('#newPassword').attr('readonly', false).tooltip({ trigger: 'manual', html: true }).tooltip('show');
                                });
                                $('#oldPassword').addClass('error');
                                $('#oldPassword').focus();
                            });
                    }
                },

                function (error) {
                    globals.hideSpinner();
                    dataManager.log("changePassword, security.changePassword, error, 1573", error, 'changePasswordModal', config.LOG_MESSAGE_TYPE.ERROR);
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                }
            );

        }

        function openForgotPasswordModal() {
            globals.showDialog({
                type: 'ForgotPasswordModal',
                displayMode: config.MODAL_DISPLAY_MODE.OVERLAY
            });
        }

        return {
            popup,
            show,
            ok,
            close,
            // Values
            oldPassword,
            newPassword,
            confirmPassword,
            changingPassword,
            // Text
            newPasswordTooltipMessage,
            confirmPasswordTooltipMessage,
            oldPasswordTooltipMessage,
            getTranslationText: config.getTranslationText,
            openForgotPasswordModal,
        }
    }
}
</script>

<style lang="scss" scoped>

</style>