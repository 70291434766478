<template>
    <popup-modal ref="popup">
        <div id="modal" 
            class="messageBox costing-detail-modal" 
            :class="modalCssClasses">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: title">{{title}}</span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <div v-if="bodyType === COSTING_DETAIL_MODAL_BODY_TYPES.MENU"
                    class="configuration-wizard-input-container-div">
                    <MenuContainer :menuFromParent="activeMenu"></MenuContainer>
                </div>
                <div v-if="totalRow !== null"
                    class="costing-detail-total-div">
                    <div class="total-column total-text">
                        <span data-bind="text: totalRow">{{totalRow}}</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience btn-primary"
                    @click="ok"
                    data-bind="click: ok, touchstart: ok">
                    <span data-bind="text: okButtonText">{{okButtonText}}</span>
                </button>
                <button class="btn btn-default btn-truckscience"
                    @click="close()"
                    data-bind="click: close, touchstart: close">
                    <span data-bind="text: closeButtonText">{{closeButtonText}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
    
    <!-- <div id="modal" class="messageBox costing-detail-modal" data-bind="css: modalCssClasses">
        <div class="modal-header modal-header-text">
            <h4 class="modal-title"><span data-bind="text: title"></span></h4>
        </div>
        <div class="modal-body modal-body-text">
            ko if: bodyType === COSTING_DETAIL_MODAL_BODY_TYPES.MENU
            <div class="configuration-wizard-input-container-div">
                <div data-bind="template: {name: 'template-menu', data: activeMenu }"></div>
            </div>
            /ko
            ko if: totalRow !== null
            <div class="costing-detail-total-div">
                <div class="total-column total-text">
                    <span data-bind="text: totalRow"></span>
                </div>
            </div>
            /ko
        </div>
        <div class="modal-footer">
            <button class="btn btn-default btn-truckscience btn-primary" data-bind="click: ok, touchstart: ok"><span data-bind="text: okButtonText"></span></button>
            <button class="btn btn-default btn-truckscience" data-bind="click: close, touchstart: close"><span data-bind="text: closeButtonText"></span></button>
        </div>
    </div> -->
</template>

<script>
import { shallowRef, computed, toRef, ref, onMounted, onUnmounted, onUpdated, isRef, watch } from 'vue';
import config from '@/services/config';
import globals from '@/services/globals';
import offerManager from '@/services/offerManager';
import Menu from '@/services/menu/menu';
import MenuItemBuilder from '@/services/menu/menuItemBuilder';
import MenuPartBuilder from '@/services/menu/menuPartBuilder';

import PopupModal from '../PopupModal.vue';

import MenuContainer from '@/components/menu/MenuContainer.vue';

const $ = window.$;
const TScMessenger = window.TScMessenger;

const title = shallowRef('Title Not Setup');
const bodyType = shallowRef('');
const modalCssClasses = shallowRef('');
const activeMenu = shallowRef(null);

// let totalRow = computed(function () {
//     offerManager.refreshData().value;
//     var result = 0,
//         resultObjects = getResultObjects();
//     // MIGRATION: Uncomment and fix
//     resultObjects.vehicleRowObjects.forEach(function (ro) {
//         result += ro.getNetPrice();
//     });
//     resultObjects.trailer1RowObjects.forEach(function (ro) {
//         result += ro.getNetPrice();
//     });
//     resultObjects.trailer2RowObjects.forEach(function (ro) {
//         result += ro.getNetPrice();
//     });
//     return offerManager.getUIFormattedHighCurrencyValue(result);
// });

let path,
    costingModalType,
    startingValues,
    modalPathForAttrObvGroup,
    activeOffer,
    totalRow,
    vehicleObject = null,
    trailer1Object = null,
    trailer2Object = null,
    vehicleRowObjects = null,
    trailer1RowObjects = null,
    trailer2RowObjects = null;

const FUEL_AND_ADBLUE_ROW_TOTAL_TYPE = {
    VEHICLE_FUEL: 'vehicleFuel',
    VEHICLE_ADBLUE: 'vehicleAdBlue',
    EQUIPMENT: 'equipment',
};

// #region Finance Detail Modal Setup
function createFinanceModal(costingModalType) {
    title.value = config.getTranslationText('1499');
    bodyType.value = config.COSTING_DETAIL_MODAL_BODY_TYPES.MENU;
    modalCssClasses.value = 'finance-detail-modal';
    var objectToUse;

    vehicleRowObjects = [];
    trailer1RowObjects = [];
    trailer2RowObjects = [];

    // Create attribute observable groups
    var vehicleFinanceDetailRowObject = new FinanceDetailModalRowObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.VEHICLE, activeOffer.getVehicleName(), config.CHASSIS_OBJECT_TYPES.VEHICLE, activeOffer.getCosting().getVehiclePrice(), activeOffer.getCosting().getVehiclePriceOverride(), activeOffer.getCosting().getVehicleDiscount(), activeOffer.getCosting().getVehiclePeriod(), activeOffer.getCosting().getVehicleInterestRate(), activeOffer.getCosting().getVehicleResidualRate(), activeOffer.getCosting().getVehicleResidualRateType(), activeOffer.getCosting().getVehiclePriceEffectiveDate(), activeOffer.getCosting().getVehiclePriceOnDatabase());
    createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleFinanceDetailRowObject, costingModalType, true);
    vehicleRowObjects.push(vehicleFinanceDetailRowObject);

    if (activeOffer.getConfiguration().getBody() !== null) {
        objectToUse = activeOffer.getConfiguration().getBody();
        var vehicleBodyFinanceDetailRowObject = new FinanceDetailModalRowObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY, config.CHASSIS_OBJECT_TYPES.VEHICLE, objectToUse.getDescription(), objectToUse.getAccessoryType(), objectToUse.getCostingListPrice(), objectToUse.getCostingListPriceOverride(), objectToUse.getCostingDiscount(), objectToUse.getCostingPeriod(), objectToUse.getCostingInterestRate(), objectToUse.getCostingResidualRate(), objectToUse.getCostingResidualRateType());
        createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY, vehicleBodyFinanceDetailRowObject, costingModalType, true);
        vehicleRowObjects.push(vehicleBodyFinanceDetailRowObject);
    }

    if (activeOffer.getConfiguration().getAccessoryHolder().getAccessories().length > 0) {
        activeOffer.getConfiguration().getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                var accessoryPathToUse = accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER ? accessory.getAccessoryType() + '.' + accessory.getId() : accessory.getAccessoryType();
                var accessoryFinanceDetailRowObject = new FinanceDetailModalRowObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + accessoryPathToUse, config.CHASSIS_OBJECT_TYPES.VEHICLE, accessory.getDescription(), accessory.getAccessoryType(), accessory.getCostingListPrice(), accessory.getCostingListPriceOverride(), accessory.getCostingDiscount(), accessory.getCostingPeriod(), accessory.getCostingInterestRate(), accessory.getCostingResidualRate(), accessory.getCostingResidualRateType(), null, null, accessory.getId());
                createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + accessoryPathToUse, accessoryFinanceDetailRowObject, costingModalType, true);
                vehicleRowObjects.push(accessoryFinanceDetailRowObject);
            }
        });
    }

    if (activeOffer.getTrailer1() !== null) {
        var trailer1FinanceDetailObject = new FinanceDetailModalRowObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER1, activeOffer.getTrailer1().getDescription(), activeOffer.getTrailer1().getAccessoryType(), activeOffer.getTrailer1().getCostingListPrice(), activeOffer.getTrailer1().getCostingListPriceOverride(), activeOffer.getTrailer1().getCostingDiscount(), activeOffer.getTrailer1().getCostingPeriod(), activeOffer.getTrailer1().getCostingInterestRate(), activeOffer.getTrailer1().getCostingResidualRate(), activeOffer.getTrailer1().getCostingResidualRateType());
        createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1, trailer1FinanceDetailObject, costingModalType);
        trailer1RowObjects.push(trailer1FinanceDetailObject);

        if (activeOffer.getTrailer1().getAccessoryHolder().getBody() !== null) {
            objectToUse = activeOffer.getTrailer1().getAccessoryHolder().getBody();
            var trailer1BodyFinanceDetailRowObject = new FinanceDetailModalRowObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.BODY, config.CHASSIS_OBJECT_TYPES.TRAILER1, objectToUse.getDescription(), objectToUse.getAccessoryType(), objectToUse.getCostingListPrice(), objectToUse.getCostingListPriceOverride(), objectToUse.getCostingDiscount(), objectToUse.getCostingPeriod(), objectToUse.getCostingInterestRate(), objectToUse.getCostingResidualRate(), objectToUse.getCostingResidualRateType());
            createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.BODY, trailer1BodyFinanceDetailRowObject, costingModalType, true);
            trailer1RowObjects.push(trailer1BodyFinanceDetailRowObject);
        }

        if (activeOffer.getTrailer1().getAccessoryHolder().getAccessories().length > 0) {
            activeOffer.getTrailer1().getAccessoryHolder().getAccessories().forEach(function (accessory) {
                if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                    var accessoryPathToUse = accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER ? accessory.getAccessoryType() + '.' + accessory.getId() : accessory.getAccessoryType();
                    var accessoryFinanceDetailRowObject = new FinanceDetailModalRowObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + accessoryPathToUse, config.CHASSIS_OBJECT_TYPES.TRAILER1, accessory.getDescription(), accessory.getAccessoryType(), accessory.getCostingListPrice(), accessory.getCostingListPriceOverride(), accessory.getCostingDiscount(), accessory.getCostingPeriod(), accessory.getCostingInterestRate(), accessory.getCostingResidualRate(), accessory.getCostingResidualRateType(), null, null, accessory.getId());
                    createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + accessoryPathToUse, accessoryFinanceDetailRowObject, costingModalType, true);
                    trailer1RowObjects.push(accessoryFinanceDetailRowObject);
                }
            });
        }
    }
    if (activeOffer.getTrailer2() !== null) {
        var trailer2FinanceDetailObject = new FinanceDetailModalRowObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.TRAILER2, activeOffer.getTrailer2().getDescription(), activeOffer.getTrailer1().getAccessoryType(), activeOffer.getTrailer2().getCostingListPrice(), activeOffer.getTrailer2().getCostingListPriceOverride(), activeOffer.getTrailer2().getCostingDiscount(), activeOffer.getTrailer2().getCostingPeriod(), activeOffer.getTrailer2().getCostingInterestRate(), activeOffer.getTrailer2().getCostingResidualRate(), activeOffer.getTrailer2().getCostingResidualRateType());
        createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2, trailer2FinanceDetailObject, costingModalType);
        trailer2RowObjects.push(trailer2FinanceDetailObject);

        if (activeOffer.getTrailer2().getAccessoryHolder().getBody() !== null) {
            objectToUse = activeOffer.getTrailer2().getAccessoryHolder().getBody();
            var trailer2BodyFinanceDetailRowObject = new FinanceDetailModalRowObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.ACCESSORY_TYPES.BODY, config.CHASSIS_OBJECT_TYPES.TRAILER2, objectToUse.getDescription(), objectToUse.getAccessoryType(), objectToUse.getCostingListPrice(), objectToUse.getCostingListPriceOverride(), objectToUse.getCostingDiscount(), objectToUse.getCostingPeriod(), objectToUse.getCostingInterestRate(), objectToUse.getCostingResidualRate(), objectToUse.getCostingResidualRateType());
            createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.ACCESSORY_TYPES.BODY, trailer2BodyFinanceDetailRowObject, costingModalType, true);
            trailer2RowObjects.push(trailer2BodyFinanceDetailRowObject);
        }

        if (activeOffer.getTrailer2().getAccessoryHolder().getAccessories().length > 0) {
            activeOffer.getTrailer2().getAccessoryHolder().getAccessories().forEach(function (accessory) {
                if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                    var accessoryPathToUse = accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER ? accessory.getAccessoryType() + '.' + accessory.getId() : accessory.getAccessoryType();
                    var accessoryFinanceDetailRowObject = new FinanceDetailModalRowObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + accessoryPathToUse, config.CHASSIS_OBJECT_TYPES.TRAILER2, accessory.getDescription(), accessory.getAccessoryType(), accessory.getCostingListPrice(), accessory.getCostingListPriceOverride(), accessory.getCostingDiscount(), accessory.getCostingPeriod(), accessory.getCostingInterestRate(), accessory.getCostingResidualRate(), accessory.getCostingResidualRateType(), null, null, accessory.getId());
                    createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + accessoryPathToUse, accessoryFinanceDetailRowObject, costingModalType, true);
                    trailer2RowObjects.push(accessoryFinanceDetailRowObject);
                }
            });
        }
    }

    startingValues = cloneResultsObject(getResultObjects());

    // Create modal body
    activeMenu.value = createFinanceDetailModalBody(vehicleRowObjects, trailer1RowObjects, trailer2RowObjects);
    totalRow = computed(function () {
        offerManager.refreshData().value;

        var result = 0,
            resultObjects = getResultObjects();
        resultObjects.vehicleRowObjects.forEach(function (ro) {
            ro.netPriceComputed.value;
            result += ro.getNetPrice();
        });
        resultObjects.trailer1RowObjects.forEach(function (ro) {
            result += ro.getNetPrice();
        });
        resultObjects.trailer2RowObjects.forEach(function (ro) {
            result += ro.getNetPrice();
        });
        return offerManager.getUIFormattedHighCurrencyValue(result);
    });
}

function FinanceDetailModalRowObject(path, chassisObjectType, description, type, listPrice, listPriceOverride, discount, period, interestRate, residualRate, residualRateType, effectiveDate, originalListPrice, id) {
    ModalObject.call(this, path, chassisObjectType);
    
    var ro = this;
    this._netPrice;
    this._pricePerMonth;
    this._description = typeof description === 'string' ? description : config.getTranslationText('110') + '-Test';
    this._type = type;
    this._listPrice = listPrice;
    this._listPriceOverride = listPriceOverride;
    this._discount = discount;
    this._period = period;
    this._interestRate = interestRate;
    this._residualRate = residualRate;
    this._residualRateType = residualRateType || config.RESIDUAL_RATE_TYPES.NET_PRICE;
    this._effectiveDate = typeof effectiveDate === 'string' ? effectiveDate : null;
    this._effectiveDateTooltipText = this._effectiveDate !== null ? config.getTranslationText('1546', [globals.formatDateUsingCultureCode(this._effectiveDate, globals.user.getCultureCode())]) : config.getTranslationText('2560');
    this._originalListPrice = typeof originalListPrice === 'number' ? originalListPrice : null;
    this._id = id || null;

    ro.getDescription = function () { return this._description; };
    ro.getType = function () { return this._type; };
    ro.getListPrice = function () { return this._listPrice; };
    ro.setListPrice = function (newValue) { this._listPrice = newValue; };
    ro.getListPriceOverride = function () { return this._listPriceOverride; };
    ro.setListPriceOverride = function (newValue) { this._listPriceOverride = newValue; };
    ro.getNetPrice = function () { return this._netPrice; };
    ro.setNetPrice = function (newValue) { this._netPrice = newValue; };
    ro.getPricePerMonth = function () { return this._pricePerMonth; };
    ro.setPricePerMonth = function (newValue) { this._pricePerMonth = newValue; };
    ro.getDiscount = function () { return this._discount; };
    ro.setDiscount = function (newValue) { this._discount = newValue; };
    ro.getPeriod = function () { return this._period; };
    ro.setPeriod = function (newValue) { this._period = newValue; };
    ro.getInterestRate = function () { return this._interestRate; };
    ro.setInterestRate = function (newValue) { this._interestRate = newValue; };
    ro.getResidualRate = function () { return this._residualRate; };
    ro.setResidualRate = function (newValue) { this._residualRate = newValue; };
    ro.getResidualRateType = function () { return this._residualRateType; };
    ro.setResidualRateType = function (newValue) { this._residualRateType = newValue; };
    ro.getEffectiveDate = function () { return this._effectiveDate; };
    ro.getEffectiveDateTooltipText = function () { return this._effectiveDateTooltipText; };
    ro.getOriginalListPrice = function () { return this._originalListPrice; };
    ro.getId = function () { return this._id; };

    ro.netPriceComputed = computed(function () {
        offerManager.refreshData().value;
        var discountValue = 0;
        if (ro.getDiscount() > 0) {
            discountValue = (ro.getListPrice() / 100) * ro.getDiscount();
        }
        ro.setNetPrice(ro.getListPrice() - discountValue);
        ro.setPricePerMonth(globals.getPricePerMonth(ro.getListPrice(), ro.getNetPrice(), ro.getPeriod(), ro.getInterestRate(), ro.getResidualRate(), ro.getResidualRateType()));

        return offerManager.getUIFormattedHighCurrencyValue(ro.getNetPrice());
    });

    function updateNetPrice() {
        var discountValue = 0;
        if (ro.getDiscount() > 0) {
            discountValue = (ro.getListPrice() / 100) * ro.getDiscount();
        }
        ro.setNetPrice(ro.getListPrice() - discountValue);
        ro.setPricePerMonth(globals.getPricePerMonth(ro.getListPrice(), ro.getNetPrice(), ro.getPeriod(), ro.getInterestRate(), ro.getResidualRate(), ro.getResidualRateType()));
    }

    ro.clone = function () {
        return new FinanceDetailModalRowObject(
            this.getPath(),
            this.getChassisObjectType(),
            this.getDescription(),
            this.getType(),
            this.getListPrice(),
            this.getListPriceOverride(),
            this.getDiscount(),
            this.getPeriod(),
            this.getInterestRate(),
            this.getResidualRate(),
            this.getResidualRateType(),
            this.getEffectiveDate(),
            this.getOriginalListPrice(),
            this.getId()
        );
    };

    updateNetPrice();
}

function createFinanceDetailModalBody(vehicleRowObjects, trailer1RowObjects, trailer2RowObjects) {
    var financeDetailMenu = new Menu('finance-detail-menu');
    financeDetailMenu.createMenu(createFinanceDetailMenuItems(vehicleRowObjects, trailer1RowObjects, trailer2RowObjects));
    return financeDetailMenu;
}

function createFinanceDetailMenuItems(vehicleRowObjects, trailer1RowObjects, trailer2RowObjects) {
    var tabs = [],
        financeItems = [];

    var vehicleTotal = computed(function () {
        offerManager.refreshData().value;
        var result = 0,
            resultObjects = getResultObjects();
        resultObjects.vehicleRowObjects.forEach(function (ro) {
            result += ro.getNetPrice();
        });
        return offerManager.getUIFormattedHighCurrencyValue(result);
    });

    tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newTab(config.getTranslationText('4215'))
        .displayMenuTabWithTotal(vehicleTotal)
        .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleRowObjects))
        .build());

    if (trailer1RowObjects.length > 0) {
        var trailer1Total = computed(function () {
            offerManager.refreshData().value;
            var result = 0,
                resultObjects = getResultObjects();
            resultObjects.trailer1RowObjects.forEach(function (ro) {
                result += ro.getNetPrice();
            });
            return offerManager.getUIFormattedHighCurrencyValue(result);
        });

        tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1)
            .newTab(config.getTranslationText('2817'))
            .displayMenuTabWithTotal(trailer1Total)
            .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1, trailer1RowObjects))
            .build());
    }

    if (trailer2RowObjects.length > 0) {
        var trailer2Total = computed(function () {
            offerManager.refreshData().value;
            var result = 0,
                resultObjects = getResultObjects();
            resultObjects.trailer2RowObjects.forEach(function (ro) {
                result += ro.getNetPrice();
            });
            return offerManager.getUIFormattedHighCurrencyValue(result);
        });

        tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2)
            .newTab(config.getTranslationText('2818'))
            .displayMenuTabWithTotal(trailer2Total)
            .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2, trailer2RowObjects))
            .build());
    }

    function createMenuTable(path, rowObjectArray) {
        var items = [],
            tableRows = [],
            headerRow;

        headerRow = createMenuTableHeaderRow(path);
        rowObjectArray.forEach(function (rowObject) {
            tableRows.push(createMenuTableRow(rowObject.getPath(), rowObject));
        });

        items.push(new MenuPartBuilder(path).newMenuTable(tableRows, headerRow).build());

        return items;
    }

    function createMenuTableHeaderRow(path) {
        var tableCells = [];

        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('2561') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('1500') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('115') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('1501') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('1502') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('1507') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('393') }));
        return new MenuPartBuilder(path).newMenuTableRow(tableCells).build();
    }

    function createMenuTableRow(path, rowObject) {
        var tableCells = [],
            listPrice = new MenuItemBuilder(path)
                .newStandardUpDown(config.VALUE_TYPE.COSTINGFINANCEDETAILLISTPRICE)
                .addOverrideTooltipMessage(rowObject.getEffectiveDateTooltipText())
                .customCssClasses('finance-detail-input-list-price')
                .build(),
            discount = new MenuItemBuilder(path)
                .newStandardUpDown(config.VALUE_TYPE.COSTINGFINANCEDETAILDISCOUNT)
                .customCssClasses('finance-detail-input-percentage')
                .build(),
            period = new MenuItemBuilder(path)
                .newStandardUpDown(config.VALUE_TYPE.COSTINGFINANCEDETAILPERIOD)
                .customCssClasses('finance-detail-input-period')
                .build(),
            interestRate = new MenuItemBuilder(path)
                .newStandardUpDown(config.VALUE_TYPE.COSTINGFINANCEDETAILINTERESTRATE)
                .customCssClasses('finance-detail-input-percentage')
                .build(),
            residualValue = new MenuItemBuilder(path)
                .newStandardUpDown(config.VALUE_TYPE.COSTINGFINANCEDETAILRESIDUAL)
                .build(),
            residualOptions = new MenuItemBuilder(path)
                .newSelect(config.VALUE_TYPE.COSTINGFINANCEDETAILRESIDUALTYPE)
                .array(offerManager.getResidualValueOptions())
                .update()
                .width(115)
                .build(),
            residual = new MenuItemBuilder(path)
                .newInputGroupUpDownBoxAndSelect(residualValue, residualOptions)
                .customCssClasses('finance-detail-input-residual')
                .build();

        tableCells.push(createMenuTableCell(path, { value: rowObject.getDescription() }, 'finance-detail-td-description'));
        tableCells.push(createMenuTableMenuInputCell(path, listPrice, 'finance-detail-td-list-price'));
    
        tableCells.push(createMenuTableMenuInputCell(path, discount, 'finance-detail-td-percent'));
        tableCells.push(createMenuTableCell(path, { value: rowObject.netPriceComputed }, 'finance-detail-td-net-price'));
        tableCells.push(createMenuTableMenuInputCell(path, period, 'finance-detail-td-period'));
        tableCells.push(createMenuTableMenuInputCell(path, interestRate, 'finance-detail-td-percent'));
        tableCells.push(createMenuTableMenuInputCell(path, residual, 'finance-detail-td-residual'));

        return new MenuPartBuilder(path).newMenuTableRow(tableCells).build();
    }

    function createMenuTableCell(path, cellObject, cssClasses) {
        var cssClassesToUse = typeof cssClasses === 'string' ? cssClasses : '';

        return new MenuPartBuilder(path).newMenuTableTextCell(cellObject).addCustomCssClassesForTdElement(cssClassesToUse).build();
    }

    function createMenuTableMenuInputCell(path, cellObject, cssClasses) {
        var cssClassesToUse = typeof cssClasses === 'string' ? cssClasses : '';
        return new MenuPartBuilder(path).newMenuTableInputCell(cellObject).addCustomCssClassesForTdElement(cssClassesToUse).build();
    }

    var menuTabGroup = new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newMenuItem(config.ITEM_TYPE.MENUTABGROUP)
        .asAccordion()
        .displayMenuTabsWithTotal()
        .tabs(tabs)
        .build();
    financeItems.push(menuTabGroup);

    return new MenuPartBuilder(modalPathForAttrObvGroup)
        .newMenuStep('Home', 0)
        .items(financeItems)
        .build();
}
// #endregion Finance Detail Modal Setup

// #region Licence Category Detail Modal Setup
function createLicenceFeesModal(costingModalType) {
    title.value = config.getTranslationText('1487');
    bodyType.value = config.COSTING_DETAIL_MODAL_BODY_TYPES.MENU;
    modalCssClasses.value = 'licence-fees-detail-modal';
    
    // create offerManager objects for vehicle
    vehicleObject = new LicenceFeesObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.VEHICLE, getActiveVehicleLicenceCategory(offerManager.getActiveOffer().getRig().getVehicle().getUnladenTotal()), offerManager.getActiveOffer().getCosting().getVehicleLicenceFees(), offerManager.getActiveOffer().getCosting().getVehicleLicenceFeesOverride(), 0, offerManager.getActiveOffer().getRig().getVehicle().getUnladenTotal());
    createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleObject, costingModalType);
    if (activeOffer.getTrailer1() !== null) {
        trailer1Object = new LicenceFeesObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1, config.CHASSIS_OBJECT_TYPES.TRAILER1, getActiveTrailer1LicenceCategory(offerManager.getActiveOffer().getRig().getTrailer1().getUnladenTotal()), offerManager.getActiveOffer().getRig().getTrailer1().getLicenceFees(), offerManager.getActiveOffer().getRig().getTrailer1().getLicenceFeesOverride(), 1, offerManager.getActiveOffer().getRig().getTrailer1().getUnladenTotal());
        createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1, trailer1Object, costingModalType);
    }
    if (activeOffer.getTrailer2() !== null) {
        trailer2Object = new LicenceFeesObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2, config.CHASSIS_OBJECT_TYPES.TRAILER2, getActiveTrailer2LicenceCategory(offerManager.getActiveOffer().getRig().getTrailer2().getUnladenTotal()), offerManager.getActiveOffer().getRig().getTrailer2().getLicenceFees(), offerManager.getActiveOffer().getRig().getTrailer2().getLicenceFeesOverride(), 2, offerManager.getActiveOffer().getRig().getTrailer2().getUnladenTotal());
        createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2, trailer2Object, costingModalType);
    }

    startingValues = cloneResultsObject(getResultObjects());

    activeMenu.value = createLicenceFeesModalBody();
    totalRow = computed(function () {
        offerManager.refreshData().value;
        var total = vehicleObject.getLicenceFees();
        if (trailer1Object !== null) {
            total += trailer1Object.getLicenceFees();
        }
        if (trailer2Object !== null) {
            total += trailer2Object.getLicenceFees();
        }
        return offerManager.getUIFormattedCurrencyPerYearValue(total);
    });
}

function LicenceFeesObject(path, chassisObjectType, activeLicenceCategory, licenceFees, licenceFeesOverride, tabIndex, totalUnladenMass) {
    var lfo = this;
    var _path = path,
        _chassisObjectType = chassisObjectType,
        _activeLicenceCategory = activeLicenceCategory,
        _licenceFees = licenceFees,
        _licenceFeesOverride = licenceFeesOverride,
        _regionId = activeLicenceCategory.RegionId,
        _regionName = activeLicenceCategory.RegionName,
        //_regionPrice = activeLicenceCategory.Price,
        _regionPrice = activeLicenceCategory.TotalPrice,
        _regionMass = activeLicenceCategory.MassHigh,
        _effectiveDate = chassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE ? activeLicenceCategory.VehicleLicenceDate : activeLicenceCategory.TrailerLicenceDate,
        _tabIndex = tabIndex,
        _totalUnladenMass = totalUnladenMass,
        _selectMenuId;

    lfo.getPath = function () { return _path; };
    lfo.getChassisObjectType = function () { return _chassisObjectType; };
    lfo.setChassisObjectType = function (newValue) { _chassisObjectType = newValue; };
    lfo.getLicenceFees = function () { return _licenceFees; };
    lfo.setLicenceFees = function (newValue) { _licenceFees = newValue; };
    lfo.getLicenceFeesOverride = function () { return _licenceFeesOverride; };
    lfo.setLicenceFeesOverride = function (newValue) { _licenceFeesOverride = newValue; };
    lfo.getRegionId = function () { return _regionId; };
    lfo.setRegionId = function (newValue) { _regionId = newValue; };
    lfo.getRegionName = function () { return _regionName; };
    lfo.setRegionName = function (newValue) { _regionName = newValue; };
    lfo.getRegionPrice = function () { return _regionPrice; };
    lfo.getRegionPriceFormatted = function () { return offerManager.getUIFormattedCurrencyPerYearValue(_regionPrice); };
    lfo.setRegionPrice = function (newValue) { _regionPrice = newValue; };
    lfo.getRegionMass = function () { return offerManager.getUIFormattedMassValue(_regionMass); };
    lfo.setRegionMass = function (newValue) { _regionMass = newValue; };
    lfo.getEffectiveDate = function () { return globals.formatDateUsingCultureCode(_effectiveDate, globals.user.getCultureCode(), true); };
    lfo.setEffectiveDate = function (newValue) { _effectiveDate = newValue; };
    lfo.getTabIndex = function () { return _tabIndex; };
    lfo.setTabIndex = function (newValue) { _tabIndex = newValue; };
    lfo.getSelectMenuId = function () { return _selectMenuId; };
    lfo.setSelectMenuId = function (newValue) { _selectMenuId = newValue; };
    lfo.getTotalUnladenMass = function () { return _totalUnladenMass; };
    lfo.getRegionForSelect = function () {
        return {
            id: this.getRegionId(),
            description: this.getRegionName()
        };
    };

    lfo.disableSelectMenu = function () {
        changeSelectMenuDisabledOption('#' + this.getSelectMenuId(), true);
    };

    lfo.enableSelectMenu = function () {
        changeSelectMenuDisabledOption('#' + this.getSelectMenuId(), false);
    };

    lfo.closeTab = function () {

    };

    function changeSelectMenuDisabledOption(id, newOption) {
        $(id).selectmenu("option", "disabled", newOption);
    }

    lfo.regionPriceObv = shallowRef(lfo.getRegionPriceFormatted());
    lfo.regionMassObv = shallowRef(offerManager.getUIFormattedMassValue(lfo.getTotalUnladenMass()));
    lfo.effectiveDateObv = shallowRef(lfo.getEffectiveDate());

    lfo.clone = function () {
        return new LicenceFeesObject(_path, _chassisObjectType, _activeLicenceCategory, _licenceFees, _licenceFeesOverride, _tabIndex, _totalUnladenMass);
    };

    lfo.refreshData = function (newRegionId) {
        TScMessenger.writeDebugMessage('');
        if (newRegionId !== this.getRegionId()) {
            // Get new licence category using id
            //var newLicenceCategory = getLicenceCategoryOptions(this.getPath()).find(function (lc) {
            //    return lc.RegionId === newRegionId || lc.id === newRegionId;
            //});
            var newLicenceCategory = getLicenceCategoryOptionToUse(this.getPath(), newRegionId);
            if (newLicenceCategory !== undefined) {
                // Update values
                this.setRegionId(newLicenceCategory.RegionId);
                this.setRegionName(newLicenceCategory.RegionName);
                this.setRegionPrice(newLicenceCategory.TotalPrice);
                this.setRegionMass(newLicenceCategory.MassHigh);
                this.setEffectiveDate(chassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE ? newLicenceCategory.VehicleLicenceDate : newLicenceCategory.TrailerLicenceDate);

                // Update the observables
                this.regionPriceObv.value = lfo.getRegionPriceFormatted();
                this.effectiveDateObv.value = lfo.getEffectiveDate();

                // Update header value
                this.setLicenceFees(this.getRegionPrice());
                var attrObvGroup = offerManager.getAttributeGroup(this.getPath() + '.' + config.VALUE_TYPE.COSTINGLICENCEFEES);
                attrObvGroup.value.value = this.getLicenceFees();
            }
        }
    };

    lfo.cleanUp = function () { };
}

function createLicenceFeesModalBody() {
    var licenceFeesMenu = new Menu('licence-fees-menu');
    licenceFeesMenu.createMenu(createLicenceFeesMenuItems());
    return licenceFeesMenu;
}

function createLicenceFeesMenuItems() {
    var tabs = [],
        licenceFeeItems = [];

    var vehicleCostingLicenceFees = new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newStandardUpDown(config.VALUE_TYPE.COSTINGLICENCEFEES)
        .build();

    tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newTab(config.getTranslationText('4216'))
        .displayMenuTabWithInput(vehicleCostingLicenceFees)
        .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE))
        .build());

    if (activeOffer.getTrailer1() !== null) {
        tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1)
            .newTab(config.getTranslationText('2819'))
            .displayMenuTabWithInput(new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1)
                .newStandardUpDown(config.VALUE_TYPE.COSTINGLICENCEFEES)
                .build())
            .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1))
            .build());
    }

    if (activeOffer.getTrailer2() !== null) {
        tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2)
            .newTab(config.getTranslationText('2820'))
            .displayMenuTabWithInput(new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2)
                .newStandardUpDown(config.VALUE_TYPE.COSTINGLICENCEFEES)
                .build())
            .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2))
            .build());
    }

    function createMenuTable(path) {
        var items = [],
            tableRows = [],
            headerRow;

        tableRows.push(createMenuTableRow(path));
        headerRow = createMenuTableHeaderRow(path);

        items.push(new MenuPartBuilder(path).newMenuTable(tableRows, headerRow).build());

        return items;
    }

    function createMenuTableRow(path) {
        var tableCells = [],
            selectMenu = new MenuItemBuilder(path).newSelect(config.VALUE_TYPE.COSTINGLICENCEFEESREGION)
                .array(getLicenceCategoryOptionsForSelect(path))
                .update()
                .build();

        var licenceObjectToUse;
        if (path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
            licenceObjectToUse = vehicleObject;
        } else if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            licenceObjectToUse = trailer1Object;
        } else if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
            licenceObjectToUse = trailer2Object;
        }
        licenceObjectToUse.setSelectMenuId(selectMenu.id);
        tableCells.push(createMenuTableCell(path, {
            value: licenceObjectToUse.regionMassObv
        }));
        tableCells.push(createMenuTableSelectMenuCell(path, selectMenu));
        tableCells.push(createMenuTableCell(path, {
            value: licenceObjectToUse.effectiveDateObv
        }));
        tableCells.push(createMenuTableCell(path, {
            value: licenceObjectToUse.regionPriceObv
        }));

        return new MenuPartBuilder(path).newMenuTableRow(tableCells).build();
    }

    function createMenuTableHeaderRow(path) {
        var tableCells = [];
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('1488') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('1489') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('1490') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('1491') }));
        return new MenuPartBuilder(path).newMenuTableRow(tableCells).build();
    }

    function createMenuTableCell(path, cellObject) {
        return new MenuPartBuilder(path).newMenuTableTextCell(cellObject).build();
    }

    function createMenuTableSelectMenuCell(path, cellObject) {
        return new MenuPartBuilder(path).newMenuTableInputCell(cellObject).build();
    }

    var menuTabGroup = new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newMenuItem(config.ITEM_TYPE.MENUTABGROUP)
        .asAccordion()
        .displayMenuTabsWithInput()
        .tabs(tabs)
        .build();
    licenceFeeItems.push(menuTabGroup);

    return new MenuPartBuilder(modalPathForAttrObvGroup)
        .newMenuStep('Home', 0)
        .items(licenceFeeItems)
        .build();
}

function getLicenceCategoryOptionsForSelect(path) {
    var chassisTypeToUse,
        returnArray = [],
        massToUse,
        options = offerManager.getActiveOffer().getCosting().getLicenceCategories();
    if (path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        chassisTypeToUse = 'VEHICLE';
        massToUse = vehicleObject.getTotalUnladenMass();
    } else {
        chassisTypeToUse = 'TRAILER';
        if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            massToUse = trailer1Object.getTotalUnladenMass();
        } else {
            massToUse = trailer2Object.getTotalUnladenMass();
        }
    }
    var filteredOptions = globals.getFilteredLicenceCategoriesArray(options, chassisTypeToUse, massToUse);
    filteredOptions.forEach(function (option) {
        returnArray.push({
            id: option.RegionId,
            description: option.RegionName
        });
    });

    return returnArray;
}

function getLicenceCategoryOptionToUse(path, regionId) {
    var chassisTypeToUse,
        returnArray = [],
        massToUse,
        options = offerManager.getActiveOffer().getCosting().getLicenceCategories();
    if (path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
        chassisTypeToUse = 'VEHICLE';
        massToUse = vehicleObject.getTotalUnladenMass();
    } else {
        chassisTypeToUse = 'TRAILER';
        if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            massToUse = trailer1Object.getTotalUnladenMass();
        } else {
            massToUse = trailer2Object.getTotalUnladenMass();
        }
    }

    return globals.getLicenceCategoryObjectToUse(options, chassisTypeToUse, massToUse, regionId);
}
// #endregion Licence Category Detail Modal Setup

// #region Toll Fees Detail Modal Setup
function createTollFeesModal(costingModalType) {
    title.value = config.getTranslationText('4083');
    bodyType.value = config.COSTING_DETAIL_MODAL_BODY_TYPES.MENU;
    modalCssClasses.value = 'toll-fees-detail-modal';
    
    var vehicleClassIdToUse = offerManager.getVehicleClassId();

    if(offerManager.getActiveOffer().getCosting().getVehicleClassIdOverride() === true) {
        vehicleClassIdToUse = offerManager.getActiveOffer().getCosting().getVehicleClassId()
    }
    var tollObject = {
        vehicleClassId: vehicleClassIdToUse,
        vehicleClassIdOverride: offerManager.getActiveOffer().getCosting().getVehicleClassIdOverride(),
        vehicleClassIdDescription: offerManager.getVehicleClassId(),
        tollFeesPerTrip: offerManager.getActiveOffer().getCosting().getTollFeesPerTrip(),
        tollFeesPerTripOverride: offerManager.getActiveOffer().getCosting().getTollFeesPerTripOverride(),
        permitCostPerTrip: offerManager.getActiveOffer().getCosting().getPermitCostPerTrip(),
        trips: offerManager.getActiveOffer().getCosting().getMonthlyTrips()
    };
    
    vehicleObject = new TollFeesObject(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, tollObject)
    createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleObject, costingModalType);

    startingValues = cloneResultsObject(getResultObjects());

    activeMenu.value = createTollFeesModalBody();
    totalRow = computed(function () {
        offerManager.refreshData().value;
        var total = (vehicleObject.getTollFeesPerTrip() + vehicleObject.getPermitCostPerTrip()) * vehicleObject.getTrips();
        return offerManager.getUIFormattedCurrencyPerMonthValue(total, 2);
    });
}

function TollFeesObject(path, initTfo) {
    var tfo = this;
    var _path = path,
        _vehicleClassId = initTfo.vehicleClassId,
        _vehicleClassIdOverride = initTfo.vehicleClassIdOverride,
        _vehicleClassIdDescription = initTfo.vehicleClassIdDescription,
        _tollFeesPerTrip = initTfo.tollFeesPerTrip || 0,
        _tollFeesPerTripOverride = initTfo.tollFeesPerTripOverride || false,
        _permitCostPerTrip = initTfo.permitCostPerTrip || 0,
        _trips = initTfo.trips || 0;

    tfo.getPath = function () { return _path; };
    tfo.getVehicleClassId = function () { return _vehicleClassId; };
    tfo.setVehicleClassId = function (newValue) { _vehicleClassId = newValue; };
    tfo.getVehicleClassIdDescription = function () { return _vehicleClassIdDescription; };
    tfo.setVehicleClassIdDescription = function (newValue) { _vehicleClassIdDescription = newValue; };
    tfo.getVehicleClassIdOverride = function () { return _vehicleClassIdOverride; };
    tfo.setVehicleClassIdOverride = function (newValue) { _vehicleClassIdOverride = newValue; };
    tfo.getTollFeesPerTrip = function () { return _tollFeesPerTrip; };
    tfo.setTollFeesPerTrip = function (newValue) { _tollFeesPerTrip = newValue; };
    tfo.getTollFeesPerTripOverride = function () { return _tollFeesPerTripOverride; };
    tfo.setTollFeesPerTripOverride = function (newValue) {
        _tollFeesPerTripOverride = newValue;
    };
    tfo.getPermitCostPerTrip = function () { return _permitCostPerTrip; };
    tfo.setPermitCostPerTrip = function (newValue) { _permitCostPerTrip = newValue; };
    tfo.getTrips = function () { return _trips; };

    tfo.getVehicleClassForSelect = function () {
        return {
            id: this.getVehicleClassId(),
            description: this.getVehicleClassIdDescription()
        };
    };

    tfo.clone = function () {
        var objToClone = {
            vehicleClassId: _vehicleClassId,
            vehicleClassIdOverride: _vehicleClassIdOverride,
            vehicleClassIdDescription: _vehicleClassIdDescription,
            tollFeesPerTrip: _tollFeesPerTrip,
            tollFeesPerTripOverride: _tollFeesPerTripOverride,
            permitCostPerTrip: _permitCostPerTrip,
            trips: _trips
        };
        return new TollFeesObject(_path, objToClone);
    };

    tfo.refreshData = function (newVehicleClassId) {
        var tfoSelf = this;
        
        TScMessenger.writeDebugMessage('');
        if (newVehicleClassId !== tfoSelf.getVehicleClassId()) {                            
            tfoSelf.setVehicleClassId(newVehicleClassId);
            if (this.getTollFeesPerTripOverride() === false) {
                var newTotal = 0;
                offerManager.getActiveOffer().getPerformance().getTolls().forEach(function (toll, index) {
                    toll.updateActiveTollFee(tfoSelf.getVehicleClassId());
                    
                    // MIGRATION: Uncomment and fix
                    // var menuTableRows = ko.dataFor($('.toll-fees-detail-modal .menu-li.menu-li-value.menu-tr-table tbody')[0]).rows;
                    // menuTableRows[index].cells[3].cellObject.value(toll.getActiveTollFeeFormatted(offerManager.currencySymbolMajor()));
                    
                    // // Update the toll fees per trip
                    // newTotal += toll.getActiveTollFee();
                });
                this.setTollFeesPerTrip(newTotal);
            }
        }
    };
}
function createTollFeesModalBody() {
    var tollFeesMenu = new Menu('toll-fees-menu');
    tollFeesMenu.createMenu(createTollFeesMenuItems());
    return tollFeesMenu;
}

function createTollFeesMenuItems() {
    var tabs = [],
        licenceFeeItems = [];

    var vehicleCostingTollFeesPerTrip = new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newStandardUpDown(config.VALUE_TYPE.COSTINGTOLLFEESPERTRIP)
        .build();

    tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newTab('Tolls')
        .displayMenuTabWithInput(vehicleCostingTollFeesPerTrip)
        .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE))
        .build());



    function createMenuTable(path) {
        var items = [],
            tableRows = [],
            headerRow;

        offerManager.getActiveOffer().getPerformance().getTolls().forEach(function (toll) {
            tableRows.push(createMenuTableRow(path, toll));
        });
        headerRow = createMenuTableHeaderRow(path);

        items.push(new MenuPartBuilder(path).newMenuTable(tableRows, headerRow).build());

        return items;
    }

    function createMenuTableRow(path, tollObject) {
        var tableCells = [];
        tableCells.push(createMenuTableCell(path, { value: tollObject.getRoute() }));
        tableCells.push(createMenuTableCell(path, { value: tollObject.getPlaza() }));
        tableCells.push(createMenuTableCell(path, { value: tollObject.getGate() }));
        tableCells.push(createMenuTableCell(path, { value: shallowRef(tollObject.getActiveTollFeeFormatted(offerManager.currencySymbolMajor.value)) }, 'toll-fee-td'));
        return new MenuPartBuilder(path).newMenuTableRow(tableCells).build();
    }

    function createMenuTableHeaderRow(path) {
        var tableCells = [];
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('4088') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('4089') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('4090') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('4091') }));
        return new MenuPartBuilder(path).newMenuTableRow(tableCells).build();
    }

    function createMenuTableCell(path, cellObject, customCssForTd) {
        if (typeof customCssForTd === 'string') {
            return new MenuPartBuilder(path).newMenuTableTextCell(cellObject).addCustomCssClassesForTdElement(customCssForTd).build();
        } else {
            return new MenuPartBuilder(path).newMenuTableTextCell(cellObject).build();
        }
    }

    function createMenuTableSelectMenuCell(path, cellObject) {
        return new MenuPartBuilder(path).newMenuTableInputCell(cellObject).build();
    }

    function getVehicleClassesFromTolls() {
        var returnArray = [];

        if (offerManager.getActiveOffer().getPerformance().getTolls().length > 0) {
            offerManager.getActiveOffer().getPerformance().getTolls().forEach(function (toll) {
                toll.getTollFees().forEach(function (tollFee) {
                    var raObject = returnArray.find(function (item) {
                        return item.id === tollFee.getVehicleClassId();
                    });
                    if (raObject === undefined) {
                        returnArray.push({
                            id: tollFee.getVehicleClassId(),
                            description: tollFee.getVehicleClassDescription()
                        });
                    }
                });
            });
        } else {
            globals.user.getTolls().forEach(function (toll) {
                toll.Fees.forEach(function (tollFee) {
                    var raObject = returnArray.find(function (item) {
                        return item.id === tollFee.TollClassId;
                    });
                    if (raObject === undefined) {
                        returnArray.push({
                            id: tollFee.TollClassId,
                            description: tollFee.TollClassDescription
                        });
                    }
                });
            });
        }

        return returnArray;
    }

    var vehicleCostingVehicleClass = new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newSelect(config.VALUE_TYPE.COSTINGVEHICLECLASS)
        .label(config.getTranslationText('4092'))
        .array(getVehicleClassesFromTolls())
        .update()
        .customCssClasses('vehicle-class-select')
        .width(260)
        .build();
    licenceFeeItems.push(vehicleCostingVehicleClass);

    var menuTabGroup = new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newMenuItem(config.ITEM_TYPE.MENUTABGROUP)
        .asAccordion()
        .displayMenuTabsWithInput()
        .tabs(tabs)
        .build();
    licenceFeeItems.push(menuTabGroup);

    var vehicleCostingPermits = new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newStandardUpDown(config.VALUE_TYPE.COSTINGPERMITCOSTPERTRIP)
        .label(config.getTranslationText('4093'))
        //.customCssClasses('updownbox-as-total total-updownbox-row')
        .customCssClasses('updownbox-as-total permitcostpertrip-total-row')
        .build();
    licenceFeeItems.push(vehicleCostingPermits);

    var vehicleCostingTotalPerTrip = new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newStandardUpDown(config.VALUE_TYPE.COSTINGOVERALLCOSTPERTRIP)
        .label('')
        .customCssClasses('updownbox-as-total total-updownbox-row costpertrip-total-row')
        .build();
    licenceFeeItems.push(vehicleCostingTotalPerTrip);

    var vehicleCostingTrips = new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newStandardUpDown(config.VALUE_TYPE.COSTINGTRIPS)
        .label('')
        .customCssClasses('updownbox-as-total total-updownbox-row')
        .build();
    licenceFeeItems.push(vehicleCostingTrips);

    return new MenuPartBuilder(modalPathForAttrObvGroup)
        .newMenuStep('Home', 0)
        .items(licenceFeeItems)
        .build();
}
// #endregion Toll Fees Detail Modal Setup

// #region Fuel and Adblue Detail Modal Setup
function createFuelAndAdblueModal(costingModalType) {
    title.value = config.getTranslationText('4580');
    bodyType.value = config.COSTING_DETAIL_MODAL_BODY_TYPES.MENU;
    modalCssClasses.value = 'fuel-and-adblue-detail-modal';

    vehicleRowObjects = [];
    trailer1RowObjects = [];
    trailer2RowObjects = [];

    // Create attribute observable groups
    var vehicleFuelAndAdblueFuelDetailRowObject = new FuelAndAdblueDetailModalRowObject(
        modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.COSTING_FUEL_AND_ADBLUE_ROW_TYPE.VEHICLE_FUEL, 
        config.CHASSIS_OBJECT_TYPES.VEHICLE, 
        config.getTranslationText('4581'), 
        getConvertedConsumptionValue(activeOffer.getCosting().getVehicleFuelAndAdblueFuelConsumption(), offerManager.getActiveOffer().getMeasurementSystem()), // Fuel Consumption
        activeOffer.getCosting().getVehicleFuelAndAdblueFuelConsumptionOverride(), // Fuel Consumption Override
        getConvertedFuelPriceValue(activeOffer.getCosting().getVehicleFuelAndAdblueFuelCostPerVolume()), // Fuel Cost Per Volume
        activeOffer.getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeOverride(), // Fuel Cost Per Volume Override
        activeOffer.getCosting().getMonthlyDistance(), // Utilisation
        false, // Allow Utilisation Change
        FUEL_AND_ADBLUE_ROW_TOTAL_TYPE.VEHICLE_FUEL, // Total Type,
        getConvertedConsumptionValue(activeOffer.getFuelAndAdblueFuelConsumptionForOverride(), offerManager.getActiveOffer().getMeasurementSystem()),
        getConvertedFuelPriceValue(activeOffer.getCosting().getVehicleFuelAndAdblueFuelCostPerVolumeOnDatabase())
        );
    vehicleRowObjects.push(vehicleFuelAndAdblueFuelDetailRowObject);

    var vehicleFuelAndAdblueAdblueDetailRowObject = new FuelAndAdblueDetailModalRowObject(
        modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.COSTING_FUEL_AND_ADBLUE_ROW_TYPE.VEHICLE_ADBLUE, 
        config.CHASSIS_OBJECT_TYPES.VEHICLE, 
        config.getTranslationText('4582'), 
        activeOffer.getCosting().getVehicleFuelAndAdblueAdblueConsumption(), // AdBlue Consumption
        activeOffer.getCosting().getVehicleFuelAndAdblueAdblueConsumptionOverride(), // AdBlue Consumption Override
        getConvertedFuelPriceValue(activeOffer.getCosting().getVehicleFuelAndAdblueAdblueCostPerVolume()), // AdBlue Cost Per Volume
        activeOffer.getCosting().getVehicleFuelAndAdblueAdblueCostPerVolumeOverride(), // AdBlue Cost Per Volume Override
        activeOffer.getCosting().getMonthlyDistance(), // Utilisation
        false, // Allow Utilisation Change,
        FUEL_AND_ADBLUE_ROW_TOTAL_TYPE.VEHICLE_ADBLUE, // Total Type
        activeOffer.getCosting().getVehicleFuelAndAdblueAdblueConsumptionOnDatabase(),
        getConvertedFuelPriceValue(activeOffer.getCosting().getVehicleFuelAndAdblueAdblueCostPerVolumeOnDatabase())
        );
    vehicleRowObjects.push(vehicleFuelAndAdblueAdblueDetailRowObject);

    var vehicleFuelAndAdblueEquipmentDetailRowObject = new FuelAndAdblueDetailModalRowObject(
        modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.COSTING_FUEL_AND_ADBLUE_ROW_TYPE.VEHICLE_EQUIPMENT_FUEL, 
        config.CHASSIS_OBJECT_TYPES.VEHICLE, 
        config.getTranslationText('4583'), 
        getConvertedFuelPricePerHourValue(activeOffer.getCosting().getVehicleFuelAndAdblueEquipmentFuelConsumption()), // Fuel Consumption
        activeOffer.getCosting().getVehicleFuelAndAdblueEquipmentFuelConsumptionOverride(), // Fuel Consumption Override
        getConvertedFuelPriceValue(activeOffer.getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolume()), // Fuel Cost Per Volume
        activeOffer.getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOverride(), // Fuel Cost Per Volume Override
        activeOffer.getCosting().getVehicleFuelAndAdblueEquipmentUtilisation(), // Utilisation
        true, // Allow Utilisation Change
        FUEL_AND_ADBLUE_ROW_TOTAL_TYPE.EQUIPMENT, // Total Type
        0,
        getConvertedFuelPriceValue(activeOffer.getCosting().getVehicleFuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase())
        );
    vehicleRowObjects.push(vehicleFuelAndAdblueEquipmentDetailRowObject);
    
    vehicleObject = new FuelAndAdblueDetailModalObject(
        modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, 
        config.CHASSIS_OBJECT_TYPES.VEHICLE, 
        activeOffer.getCosting().getVehicleFuelAndAdblueTotalCostPerMonth(),
        activeOffer.getCosting().getVehicleFuelAndAdblueTotalCostPerMonthOverride(),
        vehicleRowObjects);
    createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleObject, costingModalType, true);

    if (activeOffer.getTrailer1() !== null) {
        var trailer1FuelAndAdblueEquipmentDetailRowObject = new FuelAndAdblueDetailModalRowObject(
            modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.COSTING_FUEL_AND_ADBLUE_ROW_TYPE.TRAILER_EQUIPMENT_FUEL, 
            config.CHASSIS_OBJECT_TYPES.TRAILER1, 
            config.getTranslationText('4584'), 
            getConvertedFuelPricePerHourValue(activeOffer.getTrailer1().getFuelAndAdblueEquipmentFuelConsumption()), // Fuel Consumption
            null, // Fuel Consumption Override
            getConvertedFuelPriceValue(activeOffer.getTrailer1().getFuelAndAdblueEquipmentFuelCostPerVolume()), // Fuel Cost Per Volume
            activeOffer.getTrailer1().getFuelAndAdblueEquipmentFuelCostPerVolumeOverride(), // Fuel Cost Per Volume Override
            activeOffer.getTrailer1().getFuelAndAdblueEquipmentUtilisation(), // Utilisation
            true, // Allow Utilisation Change
            FUEL_AND_ADBLUE_ROW_TOTAL_TYPE.EQUIPMENT, // Total Type
            0,
            getConvertedFuelPriceValue(activeOffer.getTrailer1().getFuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase())
            );
        trailer1RowObjects.push(trailer1FuelAndAdblueEquipmentDetailRowObject);

        trailer1Object = new FuelAndAdblueDetailModalObject(
            modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1, 
            config.CHASSIS_OBJECT_TYPES.TRAILER1, 
            activeOffer.getTrailer1().getFuelAndAdblueTotalCostPerMonth(),
            activeOffer.getTrailer1().getFuelAndAdblueTotalCostPerMonthOverride(),
            trailer1RowObjects);
        createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1, trailer1Object, costingModalType, true);
    }
    if (activeOffer.getTrailer2() !== null) {
        var trailer2FuelAndAdblueEquipmentDetailRowObject = new FuelAndAdblueDetailModalRowObject(
            modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.COSTING_FUEL_AND_ADBLUE_ROW_TYPE.TRAILER_EQUIPMENT_FUEL, 
            config.CHASSIS_OBJECT_TYPES.TRAILER2, 
            config.getTranslationText('4585'), 
            getConvertedFuelPricePerHourValue(activeOffer.getTrailer2().getFuelAndAdblueEquipmentFuelConsumption()), // Fuel Consumption
            null, // Fuel Consumption Override
            getConvertedFuelPriceValue(activeOffer.getTrailer2().getFuelAndAdblueEquipmentFuelCostPerVolume()), // Fuel Cost Per Volume
            activeOffer.getTrailer2().getFuelAndAdblueEquipmentFuelCostPerVolumeOverride(), // Fuel Cost Per Volume Override
            activeOffer.getTrailer2().getFuelAndAdblueEquipmentUtilisation(), // Utilisation
            true, // Allow Utilisation Change
            FUEL_AND_ADBLUE_ROW_TOTAL_TYPE.EQUIPMENT, // Total Type
            0,
            getConvertedFuelPriceValue(activeOffer.getTrailer2().getFuelAndAdblueEquipmentFuelCostPerVolumeOnDatabase())
            );
        trailer2RowObjects.push(trailer2FuelAndAdblueEquipmentDetailRowObject);

        trailer2Object = new FuelAndAdblueDetailModalObject(
            modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2, 
            config.CHASSIS_OBJECT_TYPES.TRAILER2,
            activeOffer.getTrailer2().getFuelAndAdblueTotalCostPerMonth(),
            activeOffer.getTrailer2().getFuelAndAdblueTotalCostPerMonthOverride(),
            trailer2RowObjects);
        createAttributeObservableGroupForModal(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2, trailer2Object, costingModalType, true);
    }

    // Create modal body
    activeMenu.value = createFuelAndAdblueDetailModalBody(vehicleObject, trailer1Object, trailer2Object);
    totalRow = computed(function () {
        offerManager.refreshData().value;
        var result = 0,
            resultObjects = getResultObjects();

        result += resultObjects.vehicleObject.getTotal();
        if(resultObjects.trailer1Object) {
            result += resultObjects.trailer1Object.getTotal();
        }
        if(resultObjects.trailer2Object) {
            result += resultObjects.trailer2Object.getTotal();
        }

        return offerManager.getUIFormattedCurrencyPerMonthValue(result);
    });
}
function createFuelAndAdblueDetailModalBody(vehicleObject, trailer1Object, trailer2Object) {
    var fuelAndAdblueDetailMenu = new Menu('fuel-and-adblue-detail-menu');
    fuelAndAdblueDetailMenu.createMenu(createFuelAndAdblueDetailMenuItems(vehicleObject, trailer1Object, trailer2Object));
    return fuelAndAdblueDetailMenu;
}
/**
 * 
 * @param {string} path 
 * @param {string} chassisObjectType 
 * @param {string} description 
 * @param {number} consumption 
 * @param {boolean} consumptionOverride 
 * @param {number} costPerVolume 
 * @param {boolean} costPerVolumeOverride 
 * @param {number} utilisation 
 * @param {boolean} allowUtilisationInput 
 * @param {string} totalType
 */
function FuelAndAdblueDetailModalRowObject(path, chassisObjectType, description, consumption, consumptionOverride, costPerVolume, costPerVolumeOverride, utilisation, allowUtilisationInput, totalType, originalConsumption, originalCostPerVolume) {
    ModalObject.call(this, path, chassisObjectType);
    
    var ro = this;
    this._description = typeof description === 'string' ? description : config.getTranslationText('110') + '-Test';
    this._consumption = consumption;
    this._consumptionOverride = consumptionOverride;
    this._costPerVolume = costPerVolume;
    this._costPerVolumeOverride = costPerVolumeOverride;
    this._utilisation = utilisation;
    this._allowUtilisationInput = allowUtilisationInput;
    this._totalType = totalType;
    this._originalConsumption = originalConsumption; 
    this._originalCostPerVolume = originalCostPerVolume;
    // this._id = id || null;
    this._costPerMonthFunction = null;

    ro.getDescription = function () { return this._description; };
    ro.getConsumption = function () { return this._consumption; };
    ro.setConsumption = function (value) { 
        this._consumption = value; };
    ro.getConsumptionOverride = function () { return this._consumptionOverride; };
    ro.setConsumptionOverride = function (value) { this._consumptionOverride = value; };
    ro.getCostPerVolume = function () { return this._costPerVolume; };
    ro.setCostPerVolume = function (value) { 
        this._costPerVolume = value; };
    ro.getCostPerVolumeOverride = function () { return this._costPerVolumeOverride; };
    ro.setCostPerVolumeOverride = function (value) { this._costPerVolumeOverride = value; };
    ro.getUtilisation = function () { return this._utilisation; };
    ro.setUtilisation = function (value) { 
        this._utilisation = value; };
    ro.getAllowUtilisationInput = function () { return this._allowUtilisationInput; };
    ro.getId = function () { return this._id; };
    ro.getCostPerMonthFunction = function () { return this._costPerMonthFunction; };
    ro.setCostPerMonthFunction = function (value) { this._costPerMonthFunction = value; };    
    ro.getTotalType = function () { return this._totalType; };
    ro.setTotalType = function (value) { this._totalType = value; };
    ro.getOriginalConsumption = function () { return this._originalConsumption; };
    ro.getOriginalCostPerVolume = function () { return this._originalCostPerVolume; };
    ro.getCostPerMonthValue = function(){
        var costPerMonthValue = 0;
        if(typeof ro.getCostPerMonthFunction() === 'function') {
            costPerMonthValue = ro.getCostPerMonthFunction()();
        } else {
            // costPerMonthValue = ro.getConsumption() * ro.getCostPerVolume() * ro.getUtilisation();
            // Utilisation / 100 * Fuel Consumption * Fuel Price 
            // costPerMonthValue = ro.getUtilisation() / 100 * ro.getFuelConsumptionForVolumePer100Distance() * ro.getCostPerVolume();
            // costPerMonthValue = globals.getMonthlyFuelCostUsingFuelConsumptionPerDistance(ro.getCostPerVolume(), ro.getFuelConsumptionForVolumePer100Distance(), ro.getUtilisation());
            if(ro.getTotalType() === FUEL_AND_ADBLUE_ROW_TOTAL_TYPE.VEHICLE_FUEL) {
                costPerMonthValue = globals.getMonthlyFuelCostUsingFuelConsumptionPerDistance(ro.getCostPerVolume(), ro.getFuelConsumptionForVolumePer100Distance(), ro.getMonthlyDistanceForMeasurementSystem());
            } else {
                costPerMonthValue = globals.getMonthlyFuelCostUsingFuelConsumptionPerHour(ro.getCostPerVolume(), ro.getConsumption(), ro.getUtilisation());
            }
        }
        return costPerMonthValue;
    };
    ro.getFuelConsumptionForVolumePer100Distance = function () {
        return globals.getFuelConsumptionForVolumePer100Distance(ro.getConsumption(), globals.user.getSpecifyFuelConsumptionAs());
    };
    ro.getMonthlyDistanceForMeasurementSystem = function () {
        return globals.getMonthlyDistanceForMeasurementSystem(ro.getUtilisation(), offerManager.getActiveOffer().getMeasurementSystem());
    };

    ro.costPerMonthComputed = computed(function () {
        offerManager.refreshData().value;
        return offerManager.getUIFormattedCurrencyPerMonthValue(ro.getCostPerMonthValue());
    });

    function updateNetPrice() {
        var discountValue = 0;
        if (ro.getDiscount() > 0) {
            discountValue = (ro.getListPrice() / 100) * ro.getDiscount();
        }
        ro.setNetPrice(ro.getListPrice() - discountValue);
        ro.setPricePerMonth(globals.getPricePerMonth(ro.getListPrice(), ro.getNetPrice(), ro.getPeriod(), ro.getInterestRate(), ro.getResidualRate(), ro.getResidualRateType()));
    }

    ro.clone = function () {
        return new FuelAndAdblueDetailModalRowObject(
            this.getPath(),
            this.getChassisObjectType(),
            this.getDescription(),
            this.getConsumption(),
            this.getConsumptionOverride(),
            this.getCostPerVolume(),
            this.getCostPerVolumeOverride(),
            this.getUtilisation(),
            this.getAllowUtilisationInput(),
            this.getTotalType()
        );
    };

    // updateNetPrice();
}

function FuelAndAdblueDetailModalObject(path, chassisObjectType, total, totalOverride, rowObjectArray) {
    ModalObject.call(this, path, chassisObjectType);

    var mo = this;
    this._rowObjects = rowObjectArray || [];
    this._total = total;
    this._totalOverride = totalOverride || false;

    mo.getTotal = function () { return this._total; };
    mo.setTotal = function (value) { this._total = value; };
    mo.getTotalOverride = function () { return this._totalOverride; };
    mo.setTotalOverride = function (value) { this._totalOverride = value; };
    mo.getRowObjects = function () { return this._rowObjects; };
    mo.setRowObjects = function (value) { this._rowObjects = value; };

    mo.getRowObjects().forEach(function (rowObject) {
        if(rowObject.getPath().includes(config.COSTING_FUEL_AND_ADBLUE_ROW_TYPE.VEHICLE_ADBLUE)) {
            rowObject.setCostPerMonthFunction(getVehicleAdBlueCostPerMonthValue);
        }
    });

    mo.clone = function() {
        return new FuelAndAdblueDetailModalRowObject(
            this.getPath(),
            this.getChassisObjectType(),
            this.getTotal(),
            this.getTotalOverride()
        );
    }

    mo.refreshData = function (newRegionId) {
        TScMessenger.writeDebugMessage('');
        // if (newRegionId !== this.getRegionId()) {
        //     // Get new licence category using id
        //     var newLicenceCategory = getLicenceCategoryOptionToUse(this.getPath(), newRegionId);
        //     if (newLicenceCategory !== undefined) {
        //         // Update values
        //         this.setRegionId(newLicenceCategory.RegionId);
        //         this.setRegionName(newLicenceCategory.RegionName);
        //         this.setRegionPrice(newLicenceCategory.TotalPrice);
        //         this.setRegionMass(newLicenceCategory.MassHigh);
        //         this.setEffectiveDate(chassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE ? newLicenceCategory.VehicleLicenceDate : newLicenceCategory.TrailerLicenceDate);

        //         // Update the observables
        //         this.regionPriceObv.value = lfo.getRegionPriceFormatted();
        //         this.effectiveDateObv.value = lfo.getEffectiveDate();

        //         // Update header value
        //         this.setLicenceFees(this.getRegionPrice());
        //         var attrObvGroup = offerManager.getAttributeGroup(this.getPath() + '.' + config.VALUE_TYPE.COSTINGLICENCEFEES);
        //         attrObvGroup.value.value = this.getLicenceFees();
        //     }
        // }
    };

    let objectWatch = watch(offerManager.refreshData(), function (prop, action, newvalue, oldvalue) {
        // if (prop === 'rowObjects') {
        //     updateTotalValue();
        // }
        updateTotalValue();
    });

    mo.getTotalFromRowObjects = function () {
        var total = 0;
        this.getRowObjects().forEach(function (ro) {
            total += parseFloat(ro.getCostPerMonthValue());
        });
        return total;
    };

    function updateTotalValue() {
        if(mo.getTotalOverride() === false) {
            mo.setTotal(mo.getTotalFromRowObjects());

            let attrObvGroup = offerManager.getAttributeGroup(mo.getPath() + '.' + config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERMONTH);
            if(attrObvGroup.value !== undefined) {
                attrObvGroup.value.value = mo.getTotal();
            }
        }
    }

    /**
     * Vehicle adblue depends on fuel consumption of the vehicle which is the first row object
     * Returns the vehicle AdBlue cost per month value
     * @returns {number}
     */
    function getVehicleAdBlueCostPerMonthValue() {
        var result = 0;
        // var consumptionValue = mo.getRowObjects()[0].getConsumption() * mo.getRowObjects()[1].getConsumption() / 100;
        // result = consumptionValue * mo.getRowObjects()[1].getCostPerVolume() * mo.getRowObjects()[1].getUtilisation();
        // Utilisation / 100 * Fuel Consumption * Fuel Price 
        // costPerMonthValue = ro.getUtilisation() / 100 * ro.getFuelConsumptionForVolumePer100Distance() * ro.getCostPerVolume();
        var consumptionValue = mo.getRowObjects()[0].getFuelConsumptionForVolumePer100Distance() * mo.getRowObjects()[1].getConsumption() / 100;
        // result = mo.getRowObjects()[1].getUtilisation() / 100 * consumptionValue * mo.getRowObjects()[1].getCostPerVolume();
        result = globals.getMonthlyFuelCostUsingFuelConsumptionPerDistance(mo.getRowObjects()[1].getCostPerVolume(), consumptionValue, globals.getMonthlyDistanceForMeasurementSystem(mo.getRowObjects()[1].getUtilisation(), offerManager.getActiveOffer().getMeasurementSystem()));
        return result;
    }

    updateTotalValue();
}
function createFuelAndAdblueDetailMenuItems(vehicleObject, trailer1Object, trailer2Object) {
    var tabs = [],
        financeItems = [];

    var vehicleCostingFuelAndAdblueCost = new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newStandardUpDown(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERMONTH)
        .build();

    tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newTab(config.getTranslationText('4586'))
        .displayMenuTabWithInput(vehicleCostingFuelAndAdblueCost)
        // .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleRowObjects))
        .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleObject.getRowObjects()))
        .build());

    // if (trailer1RowObjects.length > 0) {
    if(trailer1Object !== null){
        var trailer1CostingFuelAndAdblueCost = new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1)
            .newStandardUpDown(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERMONTH)
            .build();

        tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1)
            .newTab(config.getTranslationText('4587'))
            .displayMenuTabWithInput(trailer1CostingFuelAndAdblueCost)
            .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1, trailer1Object.getRowObjects()))
            .build());
    }

    if(trailer2Object !== null){
        var trailer2CostingFuelAndAdblueCost = new MenuItemBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2)
            .newStandardUpDown(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERMONTH)
            .build();

        tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2)
            .newTab(config.getTranslationText('4588'))
            .displayMenuTabWithInput(trailer2CostingFuelAndAdblueCost)
            .items(createMenuTable(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2, trailer2Object.getRowObjects()))
            .build());
    }

    function createMenuTable(path, rowObjectArray) {
        var items = [],
            tableRows = [],
            headerRow;

        headerRow = createMenuTableHeaderRow(path);
        rowObjectArray.forEach(function (rowObject) {
            tableRows.push(createMenuTableRow(rowObject.getPath(), rowObject));
        });

        items.push(new MenuPartBuilder(path).newMenuTable(tableRows, headerRow).build());

        return items;
    }

    function createMenuTableHeaderRow(path) {
        var tableCells = [];

        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('4589') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('4590') }));
        
        if (offerManager.getActiveOffer().getMeasurementSystem().id === config.MEASUREMENT_SYSTEM.METRIC) {
            tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('4591') }));
        } else {
            tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('4592') }));
        }

        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('4593') }));
        tableCells.push(createMenuTableCell(path, { value: config.getTranslationText('4594') }));
        return new MenuPartBuilder(path).newMenuTableRow(tableCells).build();
    }

    function createMenuTableRow(path, rowObject) {
        // var tableCells = [];
        var tableCells = [],
            consumptionValueType;
            
        if(path.includes(config.COSTING_FUEL_AND_ADBLUE_ROW_TYPE.VEHICLE_FUEL)) {
            consumptionValueType = config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILFUELCONSUMPTION;
        } else if(path.includes(config.COSTING_FUEL_AND_ADBLUE_ROW_TYPE.VEHICLE_ADBLUE)) {
            consumptionValueType = config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILADBLUECONSUMPTION;
        } else {
            consumptionValueType = config.VALUE_TYPE.COSTINGFUELANDADBLUEEQUIPMENTFUELCONSUMPTION;
        }

        var consumption = new MenuItemBuilder(path)
                .newStandardUpDown(consumptionValueType)
                .build(),
            costPerVolume = new MenuItemBuilder(path)
                .newStandardUpDown(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILCOSTPERVOLUME)
                .build(),
            utilisation;
        //     discount = new MenuItemBuilder(path)
        //         .newStandardUpDown(config.VALUE_TYPE.COSTINGFINANCEDETAILDISCOUNT)
        //         .customCssClasses('finance-detail-input-percentage')
        //         .build(),
        
        tableCells.push(createMenuTableCell(path, { value: rowObject.getDescription() }));
        tableCells.push(createMenuTableMenuInputCell(path, consumption));
        tableCells.push(createMenuTableMenuInputCell(path, costPerVolume));
        if(rowObject.getAllowUtilisationInput()) {
            utilisation = new MenuItemBuilder(path)
                .newStandardUpDown(config.VALUE_TYPE.COSTINGFUELANDADBLUEDETAILUTILISATION)
                .build()
            tableCells.push(createMenuTableMenuInputCell(path, utilisation));
        } else {
            tableCells.push(createMenuTableCell(path, { value: offerManager.getUIFormattedMonthlyDistanceValue(rowObject.getUtilisation()) }, 'costing-fuel-and-adblue-number-text-cell'));
        }
        tableCells.push(createMenuTableCell(path, { value: rowObject.costPerMonthComputed }, 'costing-fuel-and-adblue-number-text-cell'));
        
        return new MenuPartBuilder(path).newMenuTableRow(tableCells).build();
    }

    function createMenuTableCell(path, cellObject, cssClasses) {
        var cssClassesToUse = typeof cssClasses === 'string' ? cssClasses : '';

        return new MenuPartBuilder(path).newMenuTableTextCell(cellObject).addCustomCssClassesForTdElement(cssClassesToUse).build();
    }

    function createMenuTableMenuInputCell(path, cellObject, cssClasses) {
        var cssClassesToUse = typeof cssClasses === 'string' ? cssClasses : '';
        return new MenuPartBuilder(path).newMenuTableInputCell(cellObject).addCustomCssClassesForTdElement(cssClassesToUse).build();
    }

    var menuTabGroup = new MenuPartBuilder(modalPathForAttrObvGroup + '.' + config.CHASSIS_OBJECT_TYPES.VEHICLE)
        .newMenuItem(config.ITEM_TYPE.MENUTABGROUP)
        .asAccordion()
        .displayMenuTabsWithInput()
        .tabs(tabs)
        .build();
    financeItems.push(menuTabGroup);

    return new MenuPartBuilder(modalPathForAttrObvGroup)
        .newMenuStep('Home', 0)
        .items(financeItems)
        .build();
}

/**
 * returns the fuel consumption value converted to the measurement system of the offer and the user's specified fuel consumption
 * @param {number} consumptionValue - The fuel consumption value to be converted
 * @param {object} measurementSystem - The measurement system of the offer
 * @returns {number} - The converted fuel consumption value
 */
function getConvertedConsumptionValue(consumptionValue, measurementSystem) {
    if (measurementSystem.id === config.MEASUREMENT_SYSTEM.METRIC) {
        return globals.getFuelConsumptionForMetricMeasurementSystem(consumptionValue, globals.user.getSpecifyFuelConsumptionAs());
    } else {
        return globals.getFuelConsumptionForImperialMeasurementSystem(consumptionValue, offerManager.getActiveOffer().getMeasurementSystem(), globals.user.getSpecifyFuelConsumptionAs())
    }
}

/**
 * Returns the fuel price value converted to the measurement system of the offer
 * @param {number} fuelPriceValue 
 * @returns {number} - The converted fuel price value
 */
function getConvertedFuelPriceValue(fuelPriceValue) {
    return globals.getFuelPricePerVolumeConvertedUsingMeasurementSystem(fuelPriceValue, offerManager.getActiveOffer().getMeasurementSystem())
}

/**
 * Returns the fuel price per hour value converted to the measurement system of the offer
 * @param {number} fuelPriceValue 
 * @returns {number} - The converted fuel price value
 */
function getConvertedFuelPricePerHourValue(fuelPriceValue) {
    return globals.getFuelPricePerHourConvertedUsingMeasurementSystem(fuelPriceValue, offerManager.getActiveOffer().getMeasurementSystem())
}
// #endregion Fuel and Adblue Detail Modal Setup

// #region Common Setup Functions
function createAttributeObservableGroupForModal(path, objectToUse, costingModalType, isVehicleRow) {
    switch (costingModalType) {
        case config.COSTING_DETAIL_MODAL_TYPES.FINANCE:
            offerManager.createObservablesForFinanceDetailModal(path, objectToUse, isVehicleRow);
            break;
        case config.COSTING_DETAIL_MODAL_TYPES.LICENCE_FEES:
            offerManager.createObservablesForLicenceFeesModal(path, objectToUse);
            break;
        case config.COSTING_DETAIL_MODAL_TYPES.TOLL_FEES:
            offerManager.createObservablesForTollFeesModal(path, objectToUse);
            break;
        case config.COSTING_DETAIL_MODAL_TYPES.FUEL_AND_ADBLUE:
            // if() {
            //     offerManager.createObservablesForFuelAndAdblueModalChassisTotal(path, objectToUse, isVehicleRow);
            // } else {
            //     offerManager.createObservablesForFuelAndAdblueModal(path, objectToUse);
            // }
            offerManager.createObservablesForFuelAndAdblueModal(path, objectToUse);
            break;
        default:
            break;
    }
}

function cloneResultsObject(resultsObject) {
    var resultsObjectClone = {};
    Object.keys(resultsObject).forEach(function (key) {
        if (resultsObject[key] && resultsObject[key].length !== undefined) {
            resultsObjectClone[key] = [];
            resultsObject[key].forEach(function (ro) {
                resultsObjectClone[key].push(ro.clone());
            });
        } else if (resultsObject[key]){
            resultsObjectClone[key] = resultsObject[key].clone();
        } else {
            resultsObjectClone[key] = null;
        }
    });
    return resultsObjectClone;
}

function ModalObject(path, chassisObjectType) {
    var mo = this;
    this._path = path;
    this._chassisObjectType = chassisObjectType;

    mo.getPath = function () { return this._path; };
    mo.getChassisObjectType = function () { return this._chassisObjectType; };
    mo.setChassisObjectType = function (newValue) { this._chassisObjectType = newValue; };
}

function setupModal(costingModalType) {
    switch (costingModalType) {
        case config.COSTING_DETAIL_MODAL_TYPES.FINANCE:
            createFinanceModal(costingModalType);
            break;
        case config.COSTING_DETAIL_MODAL_TYPES.LICENCE_FEES:
            createLicenceFeesModal(costingModalType);
            break;
        case config.COSTING_DETAIL_MODAL_TYPES.TOLL_FEES:
            createTollFeesModal(costingModalType);
            break;
        case config.COSTING_DETAIL_MODAL_TYPES.FUEL_AND_ADBLUE:
            createFuelAndAdblueModal(costingModalType);
            break;
        default:
            break;
    }
}

function getResultObjects() {
    switch (costingModalType) {
        case config.COSTING_DETAIL_MODAL_TYPES.FINANCE:
            return {
                vehicleRowObjects: vehicleRowObjects,
                trailer1RowObjects: trailer1RowObjects,
                trailer2RowObjects: trailer2RowObjects
            };
        case config.COSTING_DETAIL_MODAL_TYPES.LICENCE_FEES:
        case config.COSTING_DETAIL_MODAL_TYPES.FUEL_AND_ADBLUE:
            return {
                vehicleObject: vehicleObject,
                trailer1Object: trailer1Object,
                trailer2Object: trailer2Object
            };
        case config.COSTING_DETAIL_MODAL_TYPES.TOLL_FEES:
            return {
                vehicleObject: vehicleObject
            };
        default:
            break;
    }
}
// #endregion Common Setup Functions

function getActiveVehicleLicenceCategory(mass) {
    return globals.getLicenceCategoryObjectToUse(activeOffer.getCosting().getLicenceCategories(), 'VEHICLE', mass, activeOffer.getCosting().getVehicleLicenceFeesRegionId());
}

function getActiveTrailer1LicenceCategory(mass) {
    return globals.getLicenceCategoryObjectToUse(activeOffer.getCosting().getLicenceCategories(), 'TRAILER', mass, activeOffer.getRig().getTrailer1().getLicenceFeesRegionId());
}

function getActiveTrailer2LicenceCategory(mass) {
    return globals.getLicenceCategoryObjectToUse(activeOffer.getCosting().getLicenceCategories(), 'TRAILER', mass, activeOffer.getRig().getTrailer2().getLicenceFeesRegionId());
}

// #region Durandal Functions
function activate(data) {
    try {
        setupModal(costingModalType);
        return true;
    } catch (e) {
        TScMessenger.writeDebugMessage('');
        return false;
    }
}
function compositionComplete() {
    try {
        activeMenu.value.updateJsElements();
    } catch (e) {
        TScMessenger.writeDebugMessage('');
        return false;
    }
}
// #endregion Durandal Functions

export default {
    name: 'CostingDetailModal',
    components: { MenuContainer, PopupModal },
    props: {
        propsForModal: Object
    },
    setup(props, context) {
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        
        let resolvePromise = undefined,
            closeButtonText = config.getTranslationText('65'),
            okButtonText = config.getTranslationText('271');
        
        costingModalType = opts.value.modalObject.costingModalType;
        activeOffer = offerManager.getActiveOffer();
        modalPathForAttrObvGroup = 'costingDetailModal' + '.' + costingModalType;
        path = modalPathForAttrObvGroup;
        activate();

        onMounted(() => {
            setTimeout(function () { compositionComplete(); }, 100);
        });

        onUnmounted(() => {
            if(isRef(totalRow)) {
                // totalRow();
                totalRow = null;
            }
        });

        onUpdated(() => {
            TScMessenger.writeDebugMessage('onUpdated');
        });

        function show(opts = {}) {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function ok() {
            var finishingValues = getResultObjects();
            offerManager.getUndoHandler().newCompoundChangeValueOp(path, startingValues, finishingValues);            
            let returnObject = {
                dialogAction: 'OK',
                results: getResultObjects()
            };
            close(returnObject);
        }

        function close(param) {
            offerManager.deleteObservables(path);
            vehicleObject = null;
            trailer1Object = null;
            trailer2Object = null;
            vehicleRowObjects = null;
            trailer1RowObjects = null;
            trailer2RowObjects = null;
            popup.value.close();
            resolvePromise(param);
        }

        return {
            popup,
            show,
            // refs
            title,
            bodyType,
            modalCssClasses,
            activeMenu,
            totalRow,
            // functions
            ok,
            close,
            COSTING_DETAIL_MODAL_BODY_TYPES: config.COSTING_DETAIL_MODAL_BODY_TYPES,
            closeButtonText,
            okButtonText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>