import config from '../../../services/config';
import globals from '../../../services/globals';
// import utils from '@/services/utils';
// import { config, utils, globals } from '../../../services';
import { shallowRef } from 'vue';

var PayloadStub = function (id, description, source, status, editLevel, accessoryType, accessLevel, fkTrailerId, updateCounter, cachedState) {
    var self = this;
    //#region Private members
    var _id = id,
        _description = description,
        _source = source,
        _status = status,
        _editLevel = editLevel,
        _accessoryType = accessoryType,
        _accessLevel = accessLevel,
        _updateCounter = updateCounter || 0,
        _cached = cachedState || 0;
    //#endregion Private members

    //#region Getters and setters
    function getId() {
        return _id;
    }
    function setId(newValue) {
        _id = newValue;
    }
    function getDescription() {
        return _description;
    }
    function setDescription(newValue) {
        _description = newValue;
    }
    function getSource() {
        return _source;
    }
    function setSource(newValue) {
        _source = newValue;
    }
    function getStatus() {
        return _status;
    }
    function setStatus(newValue) {
        _status = newValue;
    }
    function getEditLevel() {
        return _editLevel;
    }
    function getAccessoryType() {
        return _accessoryType;
    }
    function setAccessoryType(newValue) {
        _accessoryType = newValue;
    }
    function getAccessLevel() {
        return _accessLevel;
    }
    function setAccessLevel(newValue) {
        _accessLevel = newValue;
    }
    function getUpdateCounter() {
        return _updateCounter;
    }
    function setUpdateCounter(newValue) {
        _updateCounter = newValue;
    }
    function getCached() {
        return _cached;
    }
    function setCached(newValue) {
        _cached = newValue;
    }
    function getImageClass() {
        var folder = 'EU',
            userCultureCode = globals.user.getCultureCode();
        if (globals.isAmericanCultureCode(userCultureCode) === true) {
            folder = config.LANGUAGE_PACK.ENGLISH.US.imageFolder;
        }
        return folder.toLowerCase() + '-' + config.ACCESSORY_TYPES.PAYLOAD.toLowerCase();
    }
    //#endregion Getter and setters

    

    

    

    function getIntercomMetadata() {
        var returnObject = {};

        returnObject[config.INTERCOM_METADATA_ITEM.EQUIPMENT_TYPE] = getAccessoryType();

        return returnObject;
    }

    self.getId = getId;
    self.setId = setId;
    self.getDescription = getDescription;
    self.setDescription = setDescription;
    self.getSource = getSource;
    self.setSource = setSource;
    self.getStatus = getStatus;
    self.setStatus = setStatus;
    self.getEditLevel = getEditLevel;
    self.getAccessoryType = getAccessoryType;
    self.setAccessoryType = setAccessoryType;
    self.getUpdateCounter = getUpdateCounter;
    self.setUpdateCounter = setUpdateCounter;
    self.getCached = getCached;
    self.setCached = setCached;
    self.getIntercomMetadata = getIntercomMetadata;
    self.imageAvailable = shallowRef(true);
    self.getImageClass = getImageClass;
    self.getAccessLevel = getAccessLevel;
    self.setAccessLevel = setAccessLevel;
}
    
export default PayloadStub;
