// define(['services/config', 'services/offerManager', 'services/globals', 'services/permissionObject'],
// function (config, offerManager, globals, PermissionObject) {

import { shallowRef, computed } from "vue";
import config from "../config";
import globals from "../globals";
import offerManager from "../offerManager";
import PermissionObject from "../permissionObject";

function MenuItemBuilder(path) {
  var self = this;
  var newElement = {
    cleanUp: cleanUp,
  };
  var attributeObservableGroup;
  var tempPath = path || "VEHICLE";

  var permissionObject;
  /*
  id: 'bumperToBackOfCab-input',
      label: config.MENU_LABELS.BUMPER_TO_BACK_OF_CAB,
      value: offerManager.bumperToBackOfCabFormatted,
      itemType: config.ITEM_TYPE.VALUE,
      valueType: config.INCREMENT_TYPE.LENGTH,
      inputType: config.INPUT_TYPE.UPDOWNBOX,
      errorMessage: "BumperTobackOfCab",
      permissionFunction: offerManager.showCabAttributesNotCompleteVehicle,
      errorMessageObv: offerManager.bumperToBackOfCabErrorMessage,
      resetValueFunction: offerManager.resetValue,
      valueTypeName: config.VALUE_TYPE.BUMPERTOBACKOFCAB,
      resetValue: offerManager.bumperToBackOfCabResetValue
  */

  function base(valueType) {
    newElement.path = tempPath + "." + valueType;
    newElement.valueTypeName = valueType;
    attributeObservableGroup = offerManager.getAttributeGroup(newElement.path);

    newElement.label = "Label not set!!";
    newElement.attributeGroup = attributeObservableGroup;
    //newElement.id = newElement.valueTypeName + '-input-' + new Date().getTime();
    //newElement.id = newElement.valueTypeName + '-input-' + chassisObject;
    newElement.id = newElement.path.replace(/[.]/g, "-") + "-input";
    try {
      newElement.permissionFunction = attributeObservableGroup.permission;
      newElement.permissionObv = attributeObservableGroup.permissionObv;
      newElement.permissionObv.value = newElement.permissionFunction(newElement.path);
      newElement.override = attributeObservableGroup.override;
    } catch (catchIssue) {
      var breakHere = 0;
    }
    attributeObservableGroup.setInputName(newElement.id);
  }

  function newStandardUpDown(valueType) {
    base(valueType);
    standardBox();
    newElement.inputType = config.INPUT_TYPE.UPDOWNBOX;
    return self;
  }

  function newTel(valueType) {
    base(valueType);
    standardBox();
    newElement.inputType = config.INPUT_TYPE.TEL;
    return self;
  }

  function overrideDefaultWidth() {
    newElement.overrideDefaultWidth = true;

    return self;
  }

  function newText(valueType) {
    base(valueType);
    standardBox();
    newElement.inputType = config.INPUT_TYPE.TEXT;
    return self;
  }

  function newDivider(customCss) {
    newElement.itemType = config.ITEM_TYPE.DIVIDER;
    newElement.customCss = customCss !== undefined ? customCss : "";
    return self;
  }

  function standardBox() {
    newElement.value = attributeObservableGroup.formatted;
    newElement.itemType = config.ITEM_TYPE.VALUE;
    newElement.valueType = config.INCREMENT_TYPE.LENGTH;
    //newElement.errorMessage = newElement.valueTypeName + new Date().getTime();
    newElement.errorMessage = newElement.id + "-errorMessage";
    newElement.errorMessageObv = attributeObservableGroup.errorMessage;
    newElement.resetValueFunction = attributeObservableGroup.resetValue;
    newElement.resetValue = attributeObservableGroup.reset;
  }

  function defaultPermissionFunc() {
    //return config.defaultMenuPermissionObject;
    return JSON.parse(JSON.stringify(config.defaultMenuPermissionObject));
  }

  function newCustomBox(itemType, valueType, inputType, value, id) {
    newElement.itemType = itemType;
    newElement.valueType = valueType;
    newElement.id = id;
    newElement.inputType = inputType;
    newElement.value = value;
    newElement.label = "Label not set!!";

    newElement.permissionFunction = defaultPermissionFunc;
    newElement.permissionObv = shallowRef(defaultPermissionFunc());

    return self;
  }

  /**
   * Add a new breadcrumb button to menu header
   * @param {string} id - The ID to be used in HTML
   * @param {string} label - The label for the button
   * @param {string} iconClass - The icon class that will be used to show the button
   * @param {function} clickFunction - Callback function that will be triggered when the user clicks on the button
   * @param {boolean} useSprite - Is the icon on the sprite
   * @param {function} displayFunction - Function to determine whether the button will be displayed
   * @param {boolean} selectionWizardAttachedToButton - Flag to allow activateSelectionWizard to fire inside of clickFunction
   */
  function newBreadcrumbButton(
    id,
    label,
    iconClass,
    clickFunction,
    useSprite,
    displayFunction,
    selectionWizardAttachedToButton
  ) {
    self.id = id;
    self.label = label;
    self.iconClass = iconClass;
    self.clickFunction = clickFunction;
    self.useSprite = useSprite;
    self.displayFunction = displayFunction;
    self.selectionWizardAttachedToButton =
      typeof selectionWizardAttachedToButton === "boolean"
        ? selectionWizardAttachedToButton
        : false;

    return self;
  }

  function overrideDefaultPermissionObject() {
    permissionObject = new PermissionObject();

    newElement.permissionFunction = permissionObject.permission;
    newElement.permissionObv(newElement.permissionFunction());

    return self;
  }

  function readOnlyPermission(func) {
    permissionObject.readOnlyPermission(func);

    return self;
  }

  function visiblePermission(func) {
    permissionObject.visiblePermission(func);

    return self;
  }

  function maxLength(length) {
    newElement.maxLength = length;

    return self;
  }

  function width(w) {
    newElement.width = w;

    return self;
  }

  function value(valObvservable) {
    newElement.value = valObvservable;

    return self;
  }

  function valueObv(valIdObservable) {
    newElement.valueObv = valIdObservable;

    return self;
  }

  function newCheckbox(valueType) {
    base(valueType);
    newElement.value = attributeObservableGroup.value;
    newElement.itemType = config.ITEM_TYPE.VALUE;
    newElement.inputType = config.INPUT_TYPE.CHECKBOX;
    return self;
  }
  /*
              id: 'cab-tilt-input',
              label: config.MENU_LABELS.CAB_TILT,
              value: offerManager.cabTilt,
              itemType: config.ITEM_TYPE.VALUE,
              inputType: config.INPUT_TYPE.CHECKBOX,
              permissionFunction: offerManager.showCabTiltSection
      */
  function newSelect(valueType) {
    base(valueType);
    newElement.value = attributeObservableGroup.optionProxy;
    newElement.itemType = config.ITEM_TYPE.SELECT;
    newElement.valueType = config.INPUT_TYPE.SELECT;
    newElement.inputType = config.INPUT_TYPE.SELECT;
    newElement.array = [];
    newElement.valueObv = attributeObservableGroup.optionId;
    //newElement.errorMessage = newElement.valueTypeName + new Date().getTime();
    newElement.errorMessage = newElement.id + "-errorMessage";
    newElement.errorMessageObv = attributeObservableGroup.errorMessage;
    newElement.refreshObv = attributeObservableGroup.refreshObv;
    return self;
  }
  function newCombobox(valueType) {
    base(valueType);
    newElement.value = attributeObservableGroup.optionProxy;
    newElement.itemType = config.ITEM_TYPE.COMBOBOX;
    newElement.valueType = config.INPUT_TYPE.COMBOBOX;
    newElement.inputType = config.INPUT_TYPE.COMBOBOX;
    newElement.array = [];
    newElement.valueObv = attributeObservableGroup.optionId;
    //newElement.errorMessage = newElement.valueTypeName + new Date().getTime();
    newElement.errorMessage = newElement.id + "-errorMessage";
    newElement.errorMessageObv = attributeObservableGroup.errorMessage;
    return self;
  }

  /**
   *
   * @param {string} valueType
   * @param {object[]} [options] - Selectable options
   * @param {string} [alignment] - from RADIO_INPUT_ALIGNMENT_OPTIONS enum on config
   */
  function newRadioInput(valueType, options, alignment) {
    base(valueType);
    newElement.value = attributeObservableGroup.value;
    newElement.itemType = config.ITEM_TYPE.RADIO;
    newElement.valueType = config.INPUT_TYPE.RADIO;
    newElement.inputType = config.INPUT_TYPE.RADIO;
    newElement.array = options;
    newElement.errorMessage = newElement.id + "-errorMessage";
    newElement.errorMessageObv = attributeObservableGroup.errorMessage;
    newElement.refreshObv = attributeObservableGroup.refreshObv;
    newElement.alignment =
      typeof alignment === "string"
        ? alignment
        : config.RADIO_INPUT_ALIGNMENT_OPTIONS.VERTICAL;
    return self;
  }

  /**
   *
   * @param {string} label - Text to be displayed beside the radio button
   * @param {string} value - Value to be used in background
   * @param {string} [customCss=''] - Custom CSS class(es) to be applied on option
   * @param {boolean} [isDisabled=false] - Is option available for selection by the user
   * @param {string} [tooltipText=''] - Text to be displayed to user when they hover on the option
   * @param {string} [displayUpgradeIcon=false] - Display icon beside text
   */
  function newRadioInputOption(
    label,
    value,
    customCss,
    isDisabled,
    tooltipText,
    displayUpgradeIcon
  ) {
    newElement.label = label;
    newElement.value = value;
    newElement.itemType = config.ITEM_TYPE.RADIO_OPTION;
    newElement.customCss = typeof customCss === "string" ? customCss : "";
    newElement.isDisabled =
      typeof isDisabled === "boolean" ? isDisabled : false;
    newElement.tooltipText = typeof tooltipText === "string" ? tooltipText : "";
    newElement.displayUpgradeIcon =
      typeof displayUpgradeIcon === "boolean" ? displayUpgradeIcon : false;
    return self;
  }

  /*
      {
                          label: config.getTranslationText('242'),
                          id: 'select-measurement-system',
                          value: offerManager.activeMeasurementSystem,
                          itemType: config.ITEM_TYPE.SELECT,
                          valueType: config.INPUT_TYPE.SELECT,
                          inputType: config.INPUT_TYPE.SELECT,
                          array: globals.user.getAvailableMeasurementSystems(),
                          valueObv: offerManager.activeMeasurementSystemId,
                          permissionFunction: globals.displayMenuItem,
                          updateFunction: offerManager.setActiveMeasurementSystemUsingId,
                          width: 360
                      }
      */
  function newCustomSelect(value, array, valueObv, id) {
    newElement.permissionFunction = defaultPermissionFunc;
    newElement.permissionObv = shallowRef(defaultPermissionFunc());

    newElement.value = value;
    newElement.itemType = config.ITEM_TYPE.SELECT;
    (newElement.valueType = config.INPUT_TYPE.SELECT),
      (newElement.inputType = config.INPUT_TYPE.SELECT);
    newElement.array = array;
    newElement.valueObv = valueObv;

    newElement.label = "Label not set!!";
    newElement.id = id;

    return self;
  }
  /*
      label: config.MENU_LABELS.TOOLS_INCLUDED,
      id: 'tools-included-input',
      value: offerManager.toolsIncludedOption,
      itemType: config.ITEM_TYPE.SELECT,
      valueType: config.INPUT_TYPE.SELECT,
      inputType: config.INPUT_TYPE.SELECT,
      array: offerManager.getYesNoNotSpecifiedOptions(),
      valueObv: offerManager.toolsIncludedId,
      permissionFunction: offerManager.isNotStandardVehicle,
      updateFunction: offerManager.setToolsIncluded

      */

  function newEquipment(equipment) {
    newElement.path = tempPath;
    newElement.label = equipment.getDescription();
    newElement.itemType = config.ITEM_TYPE.TEXT;
    newElement.equpmentItem = true;
    newElement.equipmentObject = equipment;
    newElement.graphicsCallbackFunction = offerManager.graphicsCallback;
    newElement.graphicsCallbackValue = "ACCESSORY";

    return self;
  }

  function closeMenu(closeMenuFunc) {
    newElement.closeMenuFunction = closeMenuFunc;
    return self;
  }

  function equipItem(equipFunc) {
    newElement.equipmentItemFunction = equipFunc;
    return self;
  }
  /*
      label: equipment.getDescription(),
      itemType: config.ITEM_TYPE.TEXT,
      equipmentItem: true,
      equipmentItemFunction: equipmentItemFunction,
      equipmentObject: equipment,
      closeMenuFunction: closeMenuFunction,
      graphicsCallbackFunction: graphicsCallbackFunction,
      graphicsCallbackValue: graphicsCallbackValue
      */

  function asMass() {
    newElement.valueType = config.INCREMENT_TYPE.MASS;
    return self;
  }

  function asPercentage() {
    newElement.valueType = config.INCREMENT_TYPE.PERCENTAGE;
    return self;
  }

  function withOverride() {
    //newElement.override = attributeObservableGroup.override;
    return self;
  }

  function label(label, isDynamicLabel) {
    if (isDynamicLabel === true) {
      newElement.label = computed(function () {
        offerManager.refreshUILiveLabels().value;
        return label();
      });
    } else {
      newElement.label = typeof label === "function" ? label() : label;
    }
    //newElement.label = label;
    return self;
  }

  /**
   *
   * @param {string|function} titleText - Text to be displayed in the title attr HTML element
   */
  function titleText(titleText) {
    newElement.titleText = titleText;
    return self;
  }

  function permission(permissionFunction) {
    //newElement.permissionFunction = permissionFunction;
    return self;
  }

  function update(optionalUpdateFunc) {
    if (optionalUpdateFunc !== undefined) {
      newElement.updateFunction = optionalUpdateFunc;
    } else {
      newElement.updateFunction = newElement.attributeGroup.getUpdateFunction(
        newElement.array
      );
    }

    return self;
  }

  function addOptionsUpdateMechanism(optionsUpdateEventObv, func) {
    newElement.optionsUpdateFunc = func;
    newElement.optionsUpdateEventObv = optionsUpdateEventObv;
    return self;
  }

  function array(optionsArray) {
    newElement.array = optionsArray;
    return self;
  }

  /**
   *
   * @param {string|null} [tt] - Tooltip text
   * @param {boolean} [tmbl=false] - Indicates whether the tooltip icon will be displayed beside the label or beside the input
   */
  function tooltip(tt, tmbl) {
    var ttMessage;
    if (tt === undefined || tt === null) {
      if (newElement.valueTypeName !== undefined) {
        if (typeof newElement.dynamicTooltipTextCallback === "function") {
          ttMessage = newElement.dynamicTooltipTextCallback(
            newElement.tooltipTextId
          );
        } else {
          ttMessage = config.getTooltipText(
            newElement.tooltipTextId,
            globals.user.getCultureCode()
          );
        }
        if (
          ttMessage !== "" &&
          ttMessage !== config.codeMissingFromTranslationsObjectMessage
        ) {
          newElement.tooltipMessage = ttMessage;
        }
      }
    } else {
      newElement.tooltipMessage = tt;
    }
    newElement.tooltipMessageBesideLabel =
      typeof tmbl === "boolean" ? tmbl : false;
    return self;
  }

  /**
   *
   * @param {string} textId - String number that should match code in translations.js file
   * @param {function} [dynamicTooltipTextCallback] - Callback to help generate text dynamically
   */
  function addTooltipTextId(textId, dynamicTooltipTextCallback) {
    newElement.tooltipTextId = textId;
    newElement.dynamicTooltipTextCallback =
      typeof dynamicTooltipTextCallback === "function"
        ? dynamicTooltipTextCallback
        : null;
    return self;
  }
  function clickListener(clickCallbackFunc) {
    newElement.click = clickCallbackFunc;
    return self;
  }

  function customCssClasses(customCssClasses) {
    newElement.customCss = customCssClasses;
    return self;
  }

  function hideLabel() {
    newElement.hideLabel = true;
    return self;
  }

  function placeholderText(pht) {
    newElement.placeholderText = pht;
    return self;
  }

  /**
   *
   * @param {function} ef - Callback when edit button is clicked
   * @param {function} df - Callback for displaying the edit icon. Defaults to anonyymous function that returns true.
   * @param {function|string} tt - Tooltip text to be displayed on hover
   * @returns {Object} MenuItemBuilder object
   */
  function allowEdit(ef, df, tt) {
    if (typeof ef === "function") {
      newElement.allowEdit = true;
      newElement.editFunctionCallback = ef;
      newElement.displayEditFunctionCallback =
        df !== undefined
          ? df
          : function () {
              return true;
            };
      newElement.editButtonTooltipText =
        typeof tt === "function" ? tt : typeof tt === "string" ? tt : "";
    } else {
      newElement.allowEdit = false;
    }

    return self;
  }

  /**
   *
   * @param {function} sf - Callback for saving item to database
   * @param {function} df - Callback for displaying the save icon on the card. Defaults to anonyymous function that returns true.
   * @param {function|string} tt - Tooltip text to be displayed on hover
   * @param {function|string} showSaveDisabled - boolean to indicate whether or not save button should be disabled, defaults to false
   * @returns {Object} MenuItemBuilder object
   */
  function allowSave(sf, df, tt, showSaveDisabled) {
    if (sf !== undefined) {
      newElement.allowSave = true;
      newElement.saveFunctionCallback = sf;
      newElement.displaySaveFunctionCallback =
        df !== undefined
          ? df
          : function () {
              return true;
            };
      newElement.saveButtonTooltipText =
        typeof tt === "function" ? tt : typeof tt === "string" ? tt : "";
      newElement.showSaveDisabled = showSaveDisabled || false;
    } else {
      newElement.allowSave = false;
    }

    return self;
  }

  ///**
  // * https://github.com/sandywalker/webui-popover#default-options
  // * @param {string} id - id to be used in the HTML of input tooltip element
  // * @param {function|string} html - Function that creates the message to be displayed to the user. Should return valid HTML
  // * @param {function} [onShow] - Function to be called when the tooltip is displayed. Add click functions go here. Parameter is element
  // * @param {function} [onHide] - Function to be called when the tooltip is closed. Parameter is element
  // * @param {string} [classes] - Adds a classname to the container for styling, prefixed by `webui-popover-`. Default '' (light theme), 'inverse' for dark theme
  // * @returns {Object} MenuItemBuilder object
  // */
  //function addTooltipInput(id, html, onShow, onHide, classes) {
  //    if (typeof id !== 'string') {
  //        throw 'id parameter is not a string in addTooltipInput';
  //    }
  //    if (html === undefined) {
  //        throw 'html parameter not defined in addTooltipInput';
  //    }

  //    newElement.displayInputTooltip = true;
  //    var options = {
  //        trigger: 'manual',
  //        closeable: true,
  //        animation: 'pop',
  //        content: html
  //    };

  //    if (typeof onShow === 'function') {
  //        options.onShow = onShow;
  //    }

  //    if (typeof onHide === 'function') {
  //        options.onHide = onHide;
  //    }

  //    if (typeof classes === 'string') {
  //        options.style = classes;
  //    }

  //    newElement.inputTooltipObject = {
  //        id: id,
  //        options: options
  //    };

  //    return self;
  //}

  /**
   * Add MenuInputTooltip to menu item. Should be generated using MenuPartBuilder().newMenuInputTooltip
   * @param {Object} itt - Input tooltip generated using MenuPartBuilder.newMenuInputTooltip
   * @param {function} dttcb - Display tooltip callback function
   * @returns {Object} MenuItemBuilder object
   */
  function addTooltipInput(itt, dttcb) {
    if (typeof itt !== "object") {
      throw "itt parameter is not a object in addTooltipInput";
    }

    //newElement.displayInputTooltip = true;
    newElement.displayInputTooltip =
      typeof dttcb === "function"
        ? dttcb
        : function () {
            return true;
          };
    newElement.inputTooltipObject = itt;

    return self;
  }

  /**
   * Add MenuInfoTooltip to menu item. Should be generated using MenuPartBuilder().newMenuInfoTooltip
   * @param {Object} itt - Input tooltip generated using MenuPartBuilder.newMenuInputTooltip
   * @returns {Object} MenuItemBuilder object
   */
  function addTooltipInfo(itt) {
    if (typeof itt !== "object") {
      throw "itt parameter is not a object in addTooltipInfo";
    }

    newElement.displayInfoTooltip = true;
    newElement.infoTooltipObject = itt;

    return self;
  }

  /**
   * This control has a updownbox and a selectmenu displayed in the same row
   * @param {Object} udb - UpDownBox
   * @param {Object} sm - Select
   * @returns {Object} MenuItemBuilder object
   */
  function newInputGroupUpDownBoxAndSelect(udb, sm) {
    if (udb === undefined) {
      throw "udb param value is undefined in newInputGroupUpDownBoxAndSelect function";
    }
    if (sm === undefined) {
      throw "sm param value is undefined in newInputGroupUpDownBoxAndSelect function";
    }
    if (udb.inputType !== config.INPUT_TYPE.UPDOWNBOX) {
      throw "udb param inputType should be config.INPUT_TYPE.UPDOWNBOX newInputGroupUpDownBoxAndSelect function";
    }
    if (sm.inputType !== config.INPUT_TYPE.SELECT) {
      throw "sm param inputType should be config.INPUT_TYPE.SELECT newInputGroupUpDownBoxAndSelect function";
    }
    newElement.inputType = config.INPUT_TYPE.INPUT_GROUP;
    newElement.itemType = config.ITEM_TYPE.INPUT_GROUP;
    newElement.inputGroupType =
      config.INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU;
    newElement.upDownBox = udb;
    newElement.select = sm;

    return self;
  }

  function addFocusCallback() {
    newElement.focusCallback = offerManager.menuItemFocusCallback;

    return self;
  }

  function hideIncrementAndDecrementButtons() {
    newElement.displayIncrementAndDecrementButtons = false;
    return self;
  }

  function addOverrideTooltipMessage(otm) {
    //newElement.overrideTooltipMessage = typeof otm === 'string' ? otm : config.getTranslationText('80');
    newElement.overrideTooltipMessage =
      typeof otm === "string" ? otm : config.getTranslationText("80");
    return self;
  }

  /**
   *
   * @param {boolean=false} iod
   * @param {string} tooltipText
   */
  function isOverrideDisabled(iod, tooltipText, cb) {
    newElement.isOverrideDisabled = typeof iod === "boolean" ? iod : false;
    newElement.overrideTooltipMessage =
      typeof tooltipText === "string" && newElement.isOverrideDisabled === true
        ? tooltipText
        : config.getTranslationText("80");
    newElement.overrideDisabledCallback =
      typeof cb === "function" ? cb : function () {};
    return self;
  }

  /**
    * Disables input box when true
    * @param {boolean} [displayTooltip=false] - True to show icon on menu
    * @param {function|null} [callback=null]
    * @param {string} [tooltipMessage=''] - Message to be displayed when the user clicks on upgrade icon
    * @param {string} [linkId=''] - ID in html of message <a> tag to be triggered by user click
    * @param {string} [intercomVal=''] - From config.ESSENTIALS_CHECK_LOCATION
    * @returns {Object} MenuItemBuilder object
  */
  function displayUpgradeTooltip(displayTooltip, callback, tooltipMessage, linkId, intercomVal) {
    newElement.displayUpgradeIcon = typeof displayTooltip === 'boolean' ? displayTooltip : false;
    newElement.displayUpgradeFunction = typeof callback === 'function' ? callback : null;
    newElement.upgradeTooltipMessage = typeof tooltipMessage === 'string' ? tooltipMessage : '';
    newElement.upgradeTooltipMessageLinkId = typeof linkId === 'string' ? linkId : '';
    newElement.upgradeTooltipIntercomValue = typeof intercomVal === 'string' ? intercomVal : '';
    return self;
}

function addSubheadingHtml(html) {
    newElement.subheadingHtml = typeof html === 'string' ? html : null;
    return self;
}

  function addSecondIcon(iconClass, callback, tooltipMessage) {
    newElement.displaySecondIcon = true;
    newElement.secondIconClass = typeof iconClass === 'string' ? iconClass : '';
    newElement.secondIconFunction = typeof callback === 'function' ? callback : null;
    newElement.secondIconTooltipMessage = typeof tooltipMessage === 'string' ? tooltipMessage : '';

    return self;
  }

  function build() {
    return newElement;
  }

  function cleanUp() {
    // if (ko.isObservable(newElement.label)) {
    //   newElement.label.dispose();
    // }
    for (var member in newElement) {
      var breakHere = 0;
      delete newElement[member];
    }
  }

  this.newStandardUpDown = newStandardUpDown;
  this.newCheckbox = newCheckbox;
  this.newSelect = newSelect;
  this.newCombobox = newCombobox;
  this.newRadioInput = newRadioInput;
  this.newRadioInputOption = newRadioInputOption;
  this.newTel = newTel;
  this.newText = newText;
  this.asMass = asMass;
  this.asPercentage = asPercentage;
  this.withOverride = withOverride;
  this.label = label;
  this.permission = permission;
  this.update = update;
  this.array = array;
  this.closeMenu = closeMenu;
  this.equipItem = equipItem;
  this.newEquipment = newEquipment;
  this.newCustomBox = newCustomBox;
  this.newCustomSelect = newCustomSelect;
  this.maxLength = maxLength;
  this.value = value;
  this.valueObv = valueObv;
  this.width = width;
  //this.readOnly = readOnly;
  this.tooltip = tooltip;
  this.addTooltipTextId = addTooltipTextId;
  this.clickListener = clickListener;
  this.overrideDefaultPermissionObject = overrideDefaultPermissionObject;
  this.readOnlyPermission = readOnlyPermission;
  this.visiblePermission = visiblePermission;
  this.newBreadcrumbButton = newBreadcrumbButton;
  this.overrideDefaultWidth = overrideDefaultWidth;
  this.newDivider = newDivider;
  this.customCssClasses = customCssClasses;
  this.addOptionsUpdateMechanism = addOptionsUpdateMechanism;
  this.hideLabel = hideLabel;
  this.placeholderText = placeholderText;
  this.titleText = titleText;
  this.allowEdit = allowEdit;
  this.allowSave = allowSave;
  this.addTooltipInput = addTooltipInput;
  this.addTooltipInfo = addTooltipInfo;
  this.newInputGroupUpDownBoxAndSelect = newInputGroupUpDownBoxAndSelect;
  this.addFocusCallback = addFocusCallback;
  this.hideIncrementAndDecrementButtons = hideIncrementAndDecrementButtons;
  this.addOverrideTooltipMessage = addOverrideTooltipMessage;
  this.isOverrideDisabled = isOverrideDisabled;
  this.displayUpgradeTooltip = displayUpgradeTooltip;
  this.addSubheadingHtml = addSubheadingHtml;
  this.addSecondIcon = addSecondIcon;
  this.build = build;
  this.cleanUp = cleanUp;
}

// return MenuItemBuilder;

export default MenuItemBuilder;
