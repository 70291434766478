import config from '@/services/config';

class CustomMessageBox {

    _message;
    _title;
    _buttons;
    _messageRef;
    _callback = null;
    _modalDisplayMode = config.MODAL_DISPLAY_MODE.QUEUE;
    

    constructor(message, title, buttons, messageRef) {
        this._message = message;
        this._title = title;
        this._buttons = buttons;
        this._messageRef = messageRef;

    }

    getTitle() {
        return this._title;
    }

    getMessage() {
        return this._message;
    }

    getButtons() {
        return this._buttons;
    }

    getMessageRef() {
        return this._messageRef;
    }

    addCallback(callback) {
        this._callback = callback;
    }

    getCallback() {
        return this._callback;
    }

    setModalDisplayMode(displayMode) {
        this._modalDisplayMode = displayMode;
        return this;
    }

    getModalDisplayMode() {
        return this._modalDisplayMode;
    }

    getData() {
        return {
            title: this._title,
            message: this._message,
            messageRef: this._messageRef,
            buttons: this._buttons
        };
    }
}

export default CustomMessageBox;