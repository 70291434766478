<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox referral-modal-container">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title new-header-text" data-bind="text: getTranslationText('938')">{{getTranslationText('938')}}</h4>
            </div>
            <div class="modal-body modal-body-text">
                <div class="message-text" 
                    v-html="getTranslationText('939')"
                    data-bind="html: referralMessage">
                </div>
                <div class="table-container">
                    <table class="specification-modal-table offer-properties-table" style="overflow-y: auto; text-align: left;">
                        <tbody>
                            <tr class="columns-4">
                                <td class="summary-note-modal-td-desc-input">
                                    <input id="referral-firstName" 
                                        class="form-control summary-modal-input" 
                                        type="text" 
                                        maxlength="200" 
                                        data-toggle="tooltip" 
                                        data-placement="right"
                                        v-model="firstName"
                                        :placeholder="getTranslationText('943')"
                                        :data-title="getTranslationText('715')"
                                        data-bind="value: firstName, attr: { placeholder: getTranslationText('943'), 'data-title': getTranslationText('715') }" />
                                </td>
                                <td class="summary-note-modal-td-desc-input">
                                    <input id="referral-lastName" 
                                        class="form-control summary-modal-input" 
                                        type="text" 
                                        maxlength="200" 
                                        data-toggle="tooltip" 
                                        data-placement="right"
                                        v-model="lastName"
                                        :placeholder="getTranslationText('944')"
                                        :data-title="getTranslationText('715')"
                                        data-bind="value: lastName, attr: { placeholder: getTranslationText('944'), 'data-title': getTranslationText('715') }" />
                                </td>
                            </tr>
                            <tr>
                                <td class="summary-note-modal-td-desc-input" colspan="2">
                                    <div class="referral-company-input-container">
                                        <input id="referral-companyName" 
                                            class="form-control summary-modal-input" 
                                            type="text" 
                                            maxlength="200" 
                                            data-toggle="tooltip" 
                                            data-placement="right"
                                            v-model="companyName"
                                            :placeholder="getTranslationText('945')"
                                            :data-title="getTranslationText('715')"
                                            data-bind="value: companyName, attr: { placeholder: getTranslationText('945'), 'data-title': getTranslationText('715') }" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="summary-note-modal-td-desc-input" colspan="2">
                                    <input id="referral-telephoneNumber" 
                                        class="form-control summary-modal-input" 
                                        type="tel" 
                                        maxlength="200" 
                                        data-toggle="tooltip" 
                                        data-placement="right"
                                        v-model="telephoneNumber"
                                        :placeholder="getTranslationText('946')"
                                        data-bind="value: telephoneNumber, attr: { placeholder: getTranslationText('946') } " />
                                </td>
                            </tr>
                            <tr>
                                <td class="summary-note-modal-td-desc-input" colspan="2">
                                    <input id="referral-emailAddress" 
                                        class="form-control summary-modal-input" 
                                        type="email" 
                                        maxlength="200" 
                                        data-toggle="tooltip" 
                                        data-placement="right"
                                        v-model="emailAddress"
                                        :placeholder="getTranslationText('947')"
                                        :data-title="getTranslationText('528')"
                                        data-bind="value: emailAddress, attr: { placeholder: getTranslationText('947'), 'data-title': getTranslationText('528') }" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <div class="message-text message-text-small" data-bind="text: getTranslationText('2534')">{{getTranslationText('2534')}}</div> -->
            </div>
            <div class="modal-footer">
                <div class="checkbox-container">
                    <div v-if="displayDontAskMeAgain === true"
                        class="tsc-checkbox-with-message" 
                        @click="toggleDontAskMeAgain"
                        data-bind="click: toggleDontAskMeAgain, touchstart: toggleDontAskMeAgain">
                        <div class="tsc-icon-container">
                            <i class="far fa-lg"
                                :class="{ 'fa-square': dontAskMeAgain === false, 'fa-check-square': dontAskMeAgain === true }"
                                data-bind="css: { 'fa-square': dontAskMeAgain() === false, 'fa-check-square': dontAskMeAgain() === true }"></i>
                        </div>
                        <span data-bind="text: getTranslationText('941')">{{getTranslationText('941')}}</span>
                    </div>
                </div>
                <div class="button-container">
                    <button class="btn btn-default btn-truckscience btn-primary" 
                        @click="ok"
                        data-bind="click: ok, touchstart: ok">
                        <span data-bind="text: getTranslationText('936')">{{getTranslationText('936')}}</span>
                    </button>
                    <button class="btn btn-default btn-truckscience" 
                        @click="close"
                        data-bind="click: close, touchstart: close">
                        <span data-bind="text: getTranslationText('935')">{{getTranslationText('935')}}</span>
                    </button>
                </div>
            </div>
            <div class="get-started-background"></div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import dataManager from '@/services/dataManager';
import globals from '@/services/globals';
import PopupModal from './PopupModal.vue';
import { ref, shallowRef, toRef } from 'vue';
import CustomMessageBox from '@/services/CustomMessageBox';

const $ = window.$;

export default {
    name: 'ReferralModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup (props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        const firstName = shallowRef('');        
        const lastName = shallowRef('');
        const companyName = shallowRef('');
        const telephoneNumber = shallowRef('');
        const emailAddress = shallowRef('');
        const dontAskMeAgain = shallowRef(false);
        const displayDontAskMeAgain = shallowRef(opts.value.modalObject.displayDontAskMeAgain);
        
        let resolvePromise,
            dfd,
            message = '',
            messageCode = '';
        
        var intercomMetadata = {};
        if (displayDontAskMeAgain.value === true) {
            intercomMetadata[config.INTERCOM_METADATA_ITEM.PROMPT_METHOD] = config.REFERRAL_MODAL_TYPE.AUTO;
        } else {
            intercomMetadata[config.INTERCOM_METADATA_ITEM.PROMPT_METHOD] = config.REFERRAL_MODAL_TYPE.MANUAL;
        }
        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.REFERRAL_INITIATED, intercomMetadata));

        function show() {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function close(param) {
            popup.value.close();
            resolvePromise(param);
        }

        function toggleDontAskMeAgain() {
            if (dontAskMeAgain.value === true) {
                dontAskMeAgain.value = false;
            } else {
                dontAskMeAgain.value = true;
            }
        }

        function ok() {
            var referralObject = {};

            removeErrorFormatting();
            // On successful referral 
            message = config.getTranslationText('948', [firstName.value]);
            messageCode = '948';


            // If user already exists
            // message = We have just checked our system, and <First Name> has already subscribed to TruckScience. Would you like to refer someone else?

            // Button Text
            // <No Thanks><Yes, Why Not>
            if (checkFields()) {
                referralObject.firstName = firstName.value;
                referralObject.lastName = lastName.value;
                referralObject.companyName = companyName.value;
                referralObject.telephoneNumber = telephoneNumber.value;
                referralObject.emailAddress = emailAddress.value;

                if (globals.isOnline.value === true) {
                    globals.showSpinner();
                    //send referral to server
                    if (dontAskMeAgain.value) {
                        sendReferralAndDontAskMeAgain(referralObject);
                    } else {
                        sendReferralOnly(referralObject);
                    }
                } else {
                    // app.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')));
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')));
                }
            } else {
                // notify user to update the relevant fields                
            }
        }

        function displayMessageToUser(message, acceptButtonText, cancelButtonText, messageCode) {
            // app.showDialog(new CustomMessageBox(message, config.applicationTitle, [acceptButtonText, cancelButtonText])).then(function (dialogResult) {
            //     if (dialogResult === acceptButtonText) {
            //         // clear the dialog
            //         clearFields();
            //     } else if (dialogResult === cancelButtonText) {
            //         // close the dialog
            //         dialog.close(self);
            //     }
            // });
            globals.showDialog(new CustomMessageBox(message, config.applicationTitle, [acceptButtonText, cancelButtonText], config.getMessageReference(messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                .addCallback(function (dialogResult) {
                    if (dialogResult === acceptButtonText) {
                        // clear the dialog
                        clearFields();
                    } else if (dialogResult === cancelButtonText) {
                        // close the dialog
                        close();
                    }
                });
        }

        function sendReferralOnly(referralObject) {
            $.when(dataManager.sendReferral(referralObject)).done(sendReferralDoneFunction);
        }

        function sendReferralAndDontAskMeAgain(referralObject) {
            $.when(dataManager.sendReferral(referralObject), dismissDontAskMeAgain()).done(sendReferralDoneFunction);
        }

        function sendReferralDoneFunction(sendReferralResult, dismissDontAskMeAgainResult) {
            globals.hideSpinner();
            if (sendReferralResult.ReturnCode === 1) {
                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.REFERRAL_COMPLETED));
                displayMessageToUser(message, config.getTranslationText('937'), config.getTranslationText('935'), messageCode);
            } else {
                if (sendReferralResult.ReturnCode === -2) {
                    message = config.getTranslationText('949').replace("$firstName", firstName.value);
                    messageCode = '949';
                    displayMessageToUser(message, config.getTranslationText('937'), config.getTranslationText('935'), messageCode);
                } else {
                    // app.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));
                }
            }
        }

        function removeErrorFormatting() {
            $('#referral-firstName').removeClass('error');
            $('#referral-lastName').removeClass('error');
            $('#referral-companyName').removeClass('error');
            $('#referral-telephoneNumber').removeClass('error');
            $('#referral-emailAddress').removeClass('error');

            $('#referral-emailAddress').tooltip('destroy');
        }

        function clearFields() {
            firstName.value = '';
            lastName.value = '';
            companyName.value = '';
            telephoneNumber.value = '';
            emailAddress.value = '';

            removeErrorFormatting();
        }

        function checkFields() {
            var returnVal = true;

            if (firstName.value === '') {
                $('#referral-firstName').addClass('error');
                returnVal = false;
            }
            if (lastName.value === '') {
                $('#referral-lastName').addClass('error');
                returnVal = false;
            }
            if (companyName.value === '') {
                $('#referral-companyName').addClass('error');
                returnVal = false;
            }

            if (emailAddress.value !== '') {
                // check email address
                if (!globals.isValidEmailAddress(emailAddress.value)) {
                    $('#referral-emailAddress').tooltip({ trigger: 'manual' }).tooltip('show');
                    $('#referral-emailAddress').addClass('error');
                    returnVal = false;
                }
            }

            return returnVal;
        }

        function dismissDontAskMeAgain() {
            var itemCodeToDismiss = config.APP_OPTION_ITEMS.REFERRAL_DONT_ASK_ME_AGAIN.Code;
            dfd = $.Deferred();
            $.when(dataManager.dismissAppOptionItem(itemCodeToDismiss)).done(function (transactionResult) {                
                if (transactionResult.ReturnCode === 1) {
                    // Update the array on user and update user on localStorage
                    globals.user.dismissAppOptionItem(itemCodeToDismiss);
                    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.REFERRAL_DONT_ASK_AGAIN));
                } else {
                    // dataManager.log("rerferralModal.dismissDontAskMeAgain, dataManager.dismissAppOptionItem, done, silent", transactionresult.Message, system.getModuleId(referralModal), config.LOG_MESSAGE_TYPE.ERROR);
                }
                dfd.resolve({});
            })
                .fail(function (error) {
                    // dataManager.log("rerferralModal.dismissDontAskMeAgain, dataManager.dismissAppOptionItem, fail, silent", error, system.getModuleId(referralModal), config.LOG_MESSAGE_TYPE.ERROR);
                    // self.displayDashboardOpen(false);
                    dfd.resolve({});
            });

            return dfd.promise();
        }
        
        return {
            popup,
            show,
            // Variables
            firstName,
            lastName,
            companyName,
            telephoneNumber,
            emailAddress,
            dontAskMeAgain,
            displayDontAskMeAgain,
            // Functions
            toggleDontAskMeAgain,
            ok,
            close,
            // Text
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>