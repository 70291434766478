import config from '../../../services/config';
import globals from '../../../services/globals';
import utils from '../../../services/utils';
// import { config, globals, utils } from '../../../services';
import FuelTank from './model.fuelTank';
import PayloadObjectsHolder from './model.payloadObjectsHolder';
import AccessoryHolder from './model.accessoryHolder';
import ChassisObjectMixin from './mixin.chassisObject';
import AxlesHolder from './model.axlesHolder';
// import { FuelTank, PayloadObjectsHolder, AccessoryHolder, ChassisObjectMixin, AxlesHolder } from '../../offer';



var Configuration = function (bodyHeightExternal, bodyLengthExternalInclRearDoors, bumperToBackOfCab, bumperToStartOfLoadCompartment, cabGapRecommendedMin, cabHeight, colourOptions,
    completeOverallBodyAndEquipmentHeight, completeOverallLength, completeRearOverhang, floorHeight, frontOverhang, grossFront, grossRear, grossTotal,
    bodyHeightInternal, messages, payloadFront, payloadRear, payloadTotal, permissibleFront, permissibleRear, permissibleTotal,
    rearOverhang, unladenFront, unladenRear, unladenTotal, vehicleDrawing, vehicleTopViewDrawing, wheelbaseTheoretical, bumperToStartOfBodyInternal, bodyLengthInternal,
    rearProtrusion, axle1FrontToWheelbaseTheoreticalStart, fifthWheelOffset, fifthWheelHeightFromGround, axle1FrontToAxle2Front, axle2FrontToAxle1Rear,
    axle1RearToAxle2Rear, axle2RearToAxle3Rear, axle3RearToAxle4Rear, control, breakpointForWheelbaseFromRearMostAxleFront, breakpointForRearOverhangFromRearMostAxle,
    rearMostProtrudingItemBehindCabToRearAxles, type, tareFifthWheelIncluded, chassisHeightFront, chassisHeightRear, axles,
    source, verticalExhaustHeight, overallLength, groundClearanceFront, groundClearanceRear, crewMassPerPerson, crewFirstRowMax, crewFirstRowCOG, noOfCrewInFirstRow, crewSecondRowIncluded,
    crewSecondRowMax, crewSecondRowCOG, noOfCrewInSecondRow, fuelSpecificGravity, fuelTank1COG, fuelTank1Capacity, fuelTank2Included, fuelTank2COG, fuelTank2Capacity, fuelTank3Included,
    fuelTank3COG, fuelTank3Capacity, fuelTank4Included, fuelTank4COG, fuelTank4Capacity, tareFront, tareRear, fuelTank1VolumeIncludedInTare, fuelTank2VolumeIncludedInTare,
    fuelTank3VolumeIncludedInTare, fuelTank4VolumeIncludedInTare, wheelbaseTheoreticalManufacturerMin, wheelbaseTheoreticalManufacturerMax, originalAUFront, originalAURear,
    gvm, gcm, vRating, vehicleDT, frontRoadFriendlySuspension, rearRoadFriendlySuspension, rolloverProtection, steeringAngleInner, turningRadiusWallToWall, turningRadiusCurbToCurb,
    frontUnderrunProtection, wheelbaseTheoreticalMaxCut, rearOverhangManufacturerMin, cabTilt, cabTiltConverter, hingePointFromBumper, hingePointFromGround, cabTiltRadius, wheelbaseTheoreticalOverride,
    cabWidth, rearWidth, overallWidth, trackWidthFront, trackWidthRear, chassisWidth, axle1FrontToWheelbaseTheoreticalStartOverride, tareFrontOverride, tareRearOverride,
    vehicleVerticalCOG, vehicleVerticalCOGOverride, tareToolsIncluded, tareSpareWheelIncluded, aUFront, aURear, manufacturersFrontAxlesMassOverride, manufacturersRearAxlesMassOverride,
    manufacturersGrossVehicleMassOverride, permissibleRearOverride, permissibleFrontOverride, permissibleTotalOverride, crewFirstRowHorizontalCOGOverride,
    crewFirstRowVerticalCOG, crewFirstRowVerticalCOGOverride, crewSecondRow, crewSecondRowHorizontalCOGOverride, crewSecondRowVerticalCOG, crewSecondRowVerticalCOGOverride,
    fuelType, gAFront, gARear, fuelTank1VerticalCOG, fuelTank2VerticalCOG, fuelTank3VerticalCOG, fuelTank4VerticalCOG, fuelTanksArrayFromServer, bodyWidthManufacturerMax,
    payloadCOGType, payloadHorizontalCOG, payloadVerticalCOG, payloadVerticalCOGOverride,
    fuelTank1VerticalCOGOverride, fuelTank2VerticalCOGOverride, fuelTank3VerticalCOGOverride, fuelTank4VerticalCOGOverride,
    vehicleVerticalCOGConfirmed, payloadVerticalCOGConfirmed, fuelTank1VerticalCOGConfirmed, fuelTank2VerticalCOGConfirmed, fuelTank3VerticalCOGConfirmed, fuelTank4VerticalCOGConfirmed,
    crewFirstRowVerticalCOGConfirmed, crewSecondRowVerticalCOGConfirmed, tareDriverIncluded, fifthWheelOffsetMin, fifthWheelOffsetMax,
    vehicleDescription, vehicleTypeDistributionCode, frontAxleAverageTyreLife, rearAxleAverageTyreLife, secondsteerAverageTyreLife, pusherAxleAverageTyreLife, tagAxleAverageTyreLife, trailerFrontAxleAverageTyreLife, trailerRearAxleAverageTyreLife,
    fuelTank1LateralCOG, fuelTank1LateralCOGOverride, fuelTank1LateralCOGConfirmed, fuelTank2LateralCOG, fuelTank2LateralCOGOverride, fuelTank2LateralCOGConfirmed, fuelTank3LateralCOG, fuelTank3LateralCOGOverride, fuelTank3LateralCOGConfirmed,
    fuelTank4LateralCOG, fuelTank4LateralCOGOverride, fuelTank4LateralCOGConfirmed, crewFirstRowLateralCOG, crewFirstRowLateralCOGOverride, crewFirstRowLateralCOGConfirmed, crewSecondRowLateralCOG, crewSecondRowLateralCOGOverride, crewSecondRowLateralCOGConfirmed,
    vehicleLateralCOG, vehicleLateralCOGOverride, vehicleLateralCOGConfirmed, payloadLateralCOG, payloadLateralCOGOverride, payloadLateralCOGConfirmed, loadCompartmentWidth, tyrePriceFirstSet, tyrePriceRemainingSets, cabToAxlesOverride,
    vehicleBridgeFormulaPermissible, vehicleBridgeFormulaPermissibleOverride, combinationBridgeFormulaPermissible, combinationBridgeFormulaPermissibleOverride, bridgeFormulaRearPermissible, bridgeFormulaRearPermissibleOverride,
    fuelSpecificGravityDiesel, fuelSpecificGravityPetrol, fuelSpecificGravityEthanol, fuelTank5Capacity, fuelTank5VolumeIncludedInTare, fuelTank5COG, fuelTank5VerticalCOG, fuelTank5VerticalCOGOverride, fuelTank5LateralCOG, fuelTank5LateralCOGOverride, fuelTank5Included,
    fuelTank6Capacity, fuelTank6VolumeIncludedInTare, fuelTank6COG, fuelTank6VerticalCOG, fuelTank6VerticalCOGOverride, fuelTank6LateralCOG, fuelTank6LateralCOGOverride, fuelTank6Included,
    fuelTank7Capacity, fuelTank7VolumeIncludedInTare, fuelTank7COG, fuelTank7VerticalCOG, fuelTank7VerticalCOGOverride, fuelTank7LateralCOG, fuelTank7LateralCOGOverride, fuelTank7Included,
    fuelTank8Capacity, fuelTank8VolumeIncludedInTare, fuelTank8COG, fuelTank8VerticalCOG, fuelTank8VerticalCOGOverride, fuelTank8LateralCOG, fuelTank8LateralCOGOverride, fuelTank8Included, 
    fuelTank5VerticalCOGConfirmed, fuelTank6VerticalCOGConfirmed, fuelTank7VerticalCOGConfirmed, fuelTank8VerticalCOGConfirmed,
    fuelTank5LateralCOGConfirmed, fuelTank6LateralCOGConfirmed, fuelTank7LateralCOGConfirmed, fuelTank8LateralCOGConfirmed,
    fuelSpecificGravityBiodiesel, fuelSpecificGravityLNG, fuelSpecificGravityCNG, fuelSpecificGravityHydrogen, specificGravityAdBlue, cNGExpressedAs, lNGExpressedAs, hydrogenExpressedAs,
    devicesForIndirectVision, commercialVehicleBrakeSystems, advancedEmergencyBraking, laneDepartureWarning, sideUnderrunProtection, conspicuityMarkingsToTheRear, conspicuityMarkingsToBothSides, blindSpotInformationSystems,
    engineMaximumTorqueNm, engineMaximumTorqueLbFt, transmissionFirstGearRatio, transmissionReverseGearRatio, transmissionTorqueConverterMultiplication, transmissionParkPAWL,
    emissionsStandard,
    updateCounter) {

/*
.FuelSpecificGravityBiodiesel = If(h.Biodiesel_Specific_Gravity, 0),
                                 .FuelSpecificGravityLNG = If(h.LNG_Specific_Gravity, 0),
                                 .FuelSpecificGravityCNG = If(h.CNG_Specific_Gravity, 0),
                                 .FuelSpecificGravityHydrogen = If(h.Hydrogen_Specific_Gravity, 0),
                                 .SpecificGravityAdBlue = If(h.Adblue_Specific_Gravity, 0),
                                 .CNGExpressedAs = If(h.CNG_Expressed_As, String.Empty),
                                 .LNGExpressedAs = If(h.LNG_Expressed_As, String.Empty),
                                 .HydrogenExpressedAs = If(h.Hydrogen_Expressed_As, String.Empty)

*/


    var self = this;

    var _accessoryHolder = new AccessoryHolder(this),
        _fuelTanks = [],
        _payloadHolder = new PayloadObjectsHolder(config.CHASSIS_OBJECT_TYPES.VEHICLE),
        _axlesHolder = new AxlesHolder(config.CHASSIS_OBJECT_TYPES.VEHICLE, frontAxleAverageTyreLife, rearAxleAverageTyreLife, secondsteerAverageTyreLife, pusherAxleAverageTyreLife, tagAxleAverageTyreLife, tyrePriceFirstSet, tyrePriceRemainingSets);
    //#region Private members
    var _bodyHeightExternal,
        _bodyLengthExternalInclRearDoors,
        _bumperToBackOfCab,
        _bumperToStartOfLoadCompartment,
        _cabGapRecommendedMin,
        _cabHeight,
        _colourOptions,
        _activeCabColour,
        _completeOverallBodyAndEquipmentHeight,
        _completeOverallLength,
        _completeRearOverhang,
        _floorHeight,
        _frontOverhang,
        _grossFront,
        _grossRear,
        _grossTotal,
        _bodyHeightInternal,
        _bodyLengthInternal,
        _messages,
        _payloadFront,
        _payloadRear,
        _payloadTotal,
        _permissibleFront,
        _permissibleRear,
        _permissibleTotal,
        _rearOverhang,
        _unladenFront,
        _unladenRear,
        _unladenTotal,
        _vehicleDrawing,
        _vehicleTopViewDrawing,
        _decompressedVehicleDrawing = null,
        _decompressedVehicleTopViewDrawing = null,
        _wheelbaseTheoretical,
        _bumperToStartOfBodyInternal,
        _rearProtrusion,
        _axle1FrontToWheelbaseTheoreticalStart,
        _axle1FrontToWheelbaseTheoreticalStartOverride,
        _fifthWheelOffset,
        _fifthWheelHieghtFromGround,
        _axle1FrontToAxle2Front,
        _axle2FrontToAxle1Rear,
        _axle1RearToAxle2Rear,
        _axle2RearToAxle3Rear,
        _axle3RearToAxle4Rear,
        _control,
        _breakpointForRearOverhangFromRearMostAxle,
        _breakpointForWheelbaseFromRearMostAxleFront,
        _rearMostProtrudingItemBehindCabToRearAxles,
        _type,
        _tareFifthWheelIncluded,
        _chassisHeightFront,
        _chassisHeightRear,

        _source,
        _verticalExhaustHeight,
        _overallLengthExclAccessories,
        _overallLengthInclAccessories,
        _cabToAxles,
        _cabToAxlesOverride,
        _overallLength,
        _groundClearanceFront,
        _groundClearanceRear,
        _crewMassPerPerson,
        _crewFirstRowMax,
        _crewFirstRowCOG,
        _crewFirstRowVerticalCOG,
        _noOfCrewInFirstRow,
        _crewSecondRowIncluded,
        _crewSecondRowMax,
        _crewSecondRowCOG,
        _crewSecondRowVerticalCOG,
        _noOfCrewInSecondRow,
        _fuelSpecificGravity,
        _fuelSpecificGravityOverride = false,
        _fuelTank1COG,
        _fuelTank1Capacity,
        _fuelTank2Included,
        _fuelTank2COG,
        _fuelTank2Capacity,
        _fuelTank3Included,
        _fuelTank3COG,
        _fuelTank3Capacity,
        _fuelTank4Included,
        _fuelTank4COG,
        _fuelTank4Capacity,
        _tareFront,
        _tareRear,
        _tareRearRear,
        _fuelTank1VolumeIncludedInTare,
        _fuelTank2VolumeIncludedInTare,
        _fuelTank3VolumeIncludedInTare,
        _fuelTank4VolumeIncludedInTare,
        _wheelbaseTheoreticalManufacturerMin,
        _wheelbaseTheoreticalManufacturerMax,
        _originalAUFront,
        _originalAURear,
        _gvm,
        _gcm,
        _vRating,
        _dt,
        _frontRoadFriendlySuspension,
        _rearRoadFriendlySuspension,
        _rolloverProtection,
        _steeringAngleInner,
        _turningRadiusWallToWall,
        _turningRadiusCurbToCurb,
        _wheelbaseTheoreticalMaxCut,
        _rearOverhangManufacturerMin,
        _bullbarProtrusion,
        _bullbarAdded,
        _snowploughAdded,
        _craneAdded,
        _fifthWheelAdded,
        _rolloverAdded,
        _combinationType,
        _frontUnderrunProtection,
        _cabTilt,
        _cabTiltConverter,
        _hingePointFromBumper,
        _hingePointFromGround,
        _cabTiltRadius,
        _wheelbaseTheoreticalOverride,
        _cabWidth,
        _rearWidth,
        _overallWidth,
        _trackWidthFront,
        _trackWidthRear,
        _chassisWidth,
        _fifthWheelHeightFromGround,
        _tareFrontOverride,
        _tareRearOverride,
        _vehicleVerticalCOG,
        _vehicleVerticalCOGOverride,
        _tareToolsIncluded,
        _tareSpareWheelIncluded,
        _aUFront,
        _aURear,
        _manufacturersFrontAxlesMassOverride,
        _manufacturersRearAxlesMassOverride,
        _manufacturersGrossVehicleMassOverride,
        _permissibleRearOverride,
        _permissibleFrontOverride,
        _permissibleTotalOverride,
        _crewFirstRowHorizontalCOGOverride,
        _crewFirstRowVerticalCOGOverride,
        _crewSecondRow,
        _crewSecondRowHorizontalCOGOverride,
        _crewSecondRowVerticalCOGOverride,
        _fuelType,
        _gAFront,
        _gARear,
        _fuelTank1VerticalCOG,
        _fuelTank2VerticalCOG,
        _fuelTank3VerticalCOG,
        _fuelTank4VerticalCOG,
        _cabTiltMaxSweepAngle = 35,
        _cabTiltMinSweepAngle = 15,
        _bodyWidthManufacturerMax,
        _payloadCOGType,
        _payloadHorizontalCOG,
        _payloadVerticalCOG,
        _payloadVerticalCOGOverride,
        _fuelTank1VerticalCOGOverride,
        _fuelTank2VerticalCOGOverride,
        _fuelTank3VerticalCOGOverride,
        _fuelTank4VerticalCOGOverride,
        _vehicleVerticalCOGConfirmed,
        _payloadVerticalCOGConfirmed,
        _fuelTank1VerticalCOGConfirmed,
        _fuelTank2VerticalCOGConfirmed,
        _fuelTank3VerticalCOGConfirmed,
        _fuelTank4VerticalCOGConfirmed,
        _fuelTank5VerticalCOGConfirmed,
        _fuelTank6VerticalCOGConfirmed,
        _fuelTank7VerticalCOGConfirmed,
        _fuelTank8VerticalCOGConfirmed,
        _fuelTank1LateralCOGConfirmed,
        _fuelTank2LateralCOGConfirmed,
        _fuelTank3LateralCOGConfirmed,
        _fuelTank4LateralCOGConfirmed,
        _fuelTank5LateralCOGConfirmed,
        _fuelTank6LateralCOGConfirmed,
        _fuelTank7LateralCOGConfirmed,
        _fuelTank8LateralCOGConfirmed,
        _crewFirstRowVerticalCOGConfirmed,
        _crewSecondRowVerticalCOGConfirmed,
        _tareDriverIncluded,
        _fifthWheelOffsetMin,
        _fifthWheelOffsetMax,
        _manufacturersGrossCombinationMassOverride = false,
        _cabWidthOverride = false,
        _rearWidthOverride = false,
        _trackWidthFrontOverride = false,
        _trackWidthRearOverride = false,
        _chassisWidthOverride = false,
        _turningAbilitySpecifiedAs = '',
        _turningRadiusCurbToCurbOverride = false,
        _turningRadiusWallToWallOverride = false,
        _steeringAngleInnerOverride = false,
        _steeringAngleInnerCalculated,
        _turningRadiusWallToWallCalculated,
        _turningRadiusCurbToCurbCalculated,
        _steeringAngleAxle1RearInnerCalculated,
        _steeringAngleAxle2RearInnerCalculated,
        _steeringAngleAxle3RearInnerCalculated,
        _steeringAngleAxle4RearInnerCalculated,
        _steeringAngleOuter = 0,
        _axle1FrontToAxle2FrontOverride = false,
        _axle2FrontToAxle1RearOverride = false,
        _axle1RearToAxle2RearOverride = false,
        _axle2RearToAxle3RearOverride = false,
        _axle3RearToAxle4RearOverride = false,
        _breakPointForAxle1FrontToAxle2FrontFromAxle1Front = -5,
        _breakPointForAxle1RearToAxle2RearFromAxle1Rear = 5,
        _breakPointForAxle2RearToAxle3RearFromAxle2Rear = 5,
        _breakPointForAxle3RearToAxle4RearFromAxle3Rear = 5,
        _vehicleDescription,
        _vehicleTypeDistributionCode,
        _wheelbaseSpecified,
        _wheelbaseOverride = false,
        _frontAxleAverageTyreLife,
        _rearAxleAverageTyreLife,
        _secondsteerAverageTyreLife,
        _pusherAxleAverageTyreLife,
        _tagAxleAverageTyreLife,
        _trailerFrontAxleAverageTyreLife,
        _trailerRearAxleAverageTyreLife,
        _crewFirstRowLateralCOG,
        _crewFirstRowLateralCOGOverride,
        _crewFirstRowLateralCOGConfirmed,
        _crewSecondRowLateralCOG,
        _crewSecondRowLateralCOGOverride,
        _crewSecondRowLateralCOGConfirmed,
        _vehicleLateralCOG,
        _vehicleLateralCOGOverride,
        _vehicleLateralCOGConfirmed,
        _payloadLateralCOG,
        _payloadLateralCOGOverride,
        _payloadLateralCOGConfirmed,
        _loadCompartmentWidth,
        _tyrePriceFirstSet,
        _tyrePriceRemainingSets,
        _updateCounter,
        _axle1FrontToBackOfCab,
        _vehicleBridgeFormulaPermissible,
        _vehicleBridgeFormulaPermissibleOverride,
        _combinationBridgeFormulaPermissible,
        _combinationBridgeFormulaPermissibleOverride,
        _bridgeFormulaRearPermissible,
        _bridgeFormulaRearPermissibleOverride,
        _fuelSpecificGravityDiesel,
        _fuelSpecificGravityPetrol,
        _fuelSpecificGravityEthanol,
        _fuelSpecificGravityBiodiesel, 
        _fuelSpecificGravityLNG, 
        _fuelSpecificGravityCNG, 
        _fuelSpecificGravityHydrogen, 
        _specificGravityAdBlue,
        _specificGravityAdBlueOverride = false,
        _cNGExpressedAs, 
        _lNGExpressedAs, 
        _hydrogenExpressedAs,
        _fuelExpressedAs,
        _devicesForIndirectVision, 
        _commercialVehicleBrakeSystems,
        _advancedEmergencyBraking, 
        _laneDepartureWarning, 
        _sideUnderrunProtection, 
        _conspicuityMarkingsToTheRear, 
        _conspicuityMarkingsToBothSides, 
        _blindSpotInformationSystems,
        _engineMaximumTorqueNm = 0, 
        _engineMaximumTorqueLbFt = 0, 
        _transmissionFirstGearRatio = 0, 
        _transmissionReverseGearRatio = 0, 
        _transmissionTorqueConverterMultiplication = 0,
        _transmissionParkPAWL = '',
        _emissionsStandard = ''
        ;
    //#endregion Private members

    //#region Getters and setters
    function getUpdateCounter() {
        return _updateCounter;
    }
    function setUpdateCounter(newValue) {
        _updateCounter = newValue || 0;
    }
    function getBodyHeightExternal() {
        return _bodyHeightExternal;
    }
    function setBodyHeightExternal(newValue) {
        _bodyHeightExternal = newValue;
    }
    function getBodyLengthExternalInclRearDoors() {
        return _bodyLengthExternalInclRearDoors;
    }
    function setBodyLengthExternalInclRearDoors(newValue) {
        _bodyLengthExternalInclRearDoors = newValue;
    }
    function getBumperToBackOfCab(forceVariable) {
        return _bumperToBackOfCab;
        //var forceVariableToUse = typeof forceVariable === 'boolean' ? forceVariable : false;
        //if (globals.user.getSpecifyCabDimensionsAsOption() === config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.AC && forceVariableToUse === false) {
        //    return getFrontOverhang() + getAxle1FrontToBackOfCab();
        //} else {
        //    return _bumperToBackOfCab;
        //}
    }
    function setBumperToBackOfCab(newValue) {
        _bumperToBackOfCab = newValue;
    }
    function getBumperToStartOfLoadCompartment() {
        return _bumperToStartOfLoadCompartment;
    }
    function setBumperToStartOfLoadCompartment(newValue) {
        _bumperToStartOfLoadCompartment = newValue;
    }
    function getCabGapRecommendedMin() {
        return _cabGapRecommendedMin;
    }
    function setCabGapRecommendedMin(newValue) {
        _cabGapRecommendedMin = newValue;
    }
    function getCabHeight() {
        return _cabHeight;
    }
    function setCabHeight(newValue) {
        _cabHeight = newValue;
    }
    function getColourOptions() {
        return _colourOptions;
    }
    function setColourOptions(newValue) {
        _colourOptions = newValue;
    }
    function getActiveCabColour() {
        return _activeCabColour;
    }
    function setActiveCabColour(newValue) {
        var tempObj = {},
            tempColour = '';
        if (typeof newValue === 'string') {
            tempColour = newValue.replace(/([^a-z0-9]+)/g, '');
            tempObj.itemId = 'colour' + tempColour;
            tempObj.colour = newValue;
        } else {
            tempObj = newValue;
        }
        _activeCabColour = tempObj;
    }
    function getActiveCabColourToBeSaved() {
        if (getActiveCabColour() !== undefined && getActiveCabColour().colour !== undefined) {
            return getActiveCabColour().colour;
        } else {
            return '';
        }

    }
    function getCompleteOverallBodyAndEquipmentHeight() {
        return _completeOverallBodyAndEquipmentHeight;
    }
    function setCompleteOverallBodyAndEquipmentHeight(newValue) {
        _completeOverallBodyAndEquipmentHeight = newValue;
    }
    function getCompleteOverallLength() {
        return _completeOverallLength;
    }
    function setCompleteOverallLength(newValue) {
        _completeOverallLength = newValue;
    }
    function getCompleteRearOverhang() {
        return _completeRearOverhang;
    }
    function setCompleteRearOverhang(newValue) {
        _completeRearOverhang = newValue;
    }
    function getFloorHeight() {
        return _floorHeight;
    }
    function setFloorHeight(newValue) {
        _floorHeight = newValue;
    }
    function getFrontOverhang() {
        if (_frontOverhang !== undefined) {
            return _frontOverhang;
        }
        return 0;
    }
    function setFrontOverhang(newValue) {
        _frontOverhang = newValue;
    }
    function getGrossFront() {
        //if (_grossFront > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return _grossFront;
        //} else {
        //    return getUnladenFront() + getPayloadFront();
        //}
        return getUnladenFront() + getPayloadFront();
    }
    function getRevisedGrossFront(totalRevisedDistributedTareFront) {
        return getRevisedUnladenFront(totalRevisedDistributedTareFront) + getPayloadFront();
    }
    function setGrossFront(newValue) {
        _grossFront = newValue;
    }
    function getGrossRear() {
        //if (_grossRear > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return _grossRear;
        //} else {
        //    return getUnladenRear() + getPayloadRear();
        //}
        return getUnladenRear() + getPayloadRear();
    }
    function getRevisedGrossRear(totalRevisedDistributedTareRear) {
        return getRevisedUnladenRear(totalRevisedDistributedTareRear) + getPayloadRear();
    }
    function setGrossRear(newValue) {
        _grossRear = newValue;
    }
    function getGrossTotal() {
        //if (_grossTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return _grossTotal;
        //} else {
        //    return getUnladenTotal() + getPayloadTotal();
        //}
        return getUnladenTotal() + getPayloadTotal();
    }
    function setGrossTotal(newValue) {
        _grossTotal = newValue;
    }

    function getPusherOrTagRearAxleGross(axleNumber, rearGrossVal) {
        return _axlesHolder.getPusherOrTagRearAxleGross(axleNumber, rearGrossVal);
    }

    function getRearRearAxlesGross(rearGrossVal) {
        return _axlesHolder.getRearRearAxlesDistribution(rearGrossVal);
    }

    function getPusherOrTagRearAxleDistribution(params) {
        var massValToUse = params.rearMassVal || getRearMassValToUse(params);
        //params = objectOrAccessoryType, axleNumber, rearMassVal, objectInternalId
        
        return _axlesHolder.getPusherOrTagRearAxleDistribution(params.axleNumber, massValToUse);
    }

    function getRearRearAxlesDistribution(params) {
        var massValToUse = params.rearMassVal || getRearMassValToUse(params);

        return _axlesHolder.getRearRearAxlesDistribution(massValToUse);
    }

    function getRearMassValToUse(params) {
        var massValToUse = 0;
        //objectOrAccessoryType, axleNumber, rearMassVal

        
        var wheelbaseTheoretical = getWheelbaseTheoretical();

        switch (params.objectOrAccessoryType) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                if (params.vehicleValSubCode === 'unladen') {
                    massValToUse = params.rearMassVal || getUnladenRear();
                } else if (params.vehicleValSubCode === 'tare') {
                    massValToUse = params.rearMassVal || getTareRear();
                }
                break;
            case config.ACCESSORY_TYPES.BODY:
                var tempBody = _accessoryHolder.getBody();
                massValToUse = params.rearMassVal || tempBody.getRearAxleLoad(getDistanceFromWBTEndForAccessory(tempBody), wheelbaseTheoretical);
                break;
            case config.ACCESSORY_TYPES.CRANE:
                var tempCrane = _accessoryHolder.getCrane();
                massValToUse = params.rearMassVal || tempCrane.getRearAxleLoad(getDistanceFromWBTEndForAccessory(tempCrane), wheelbaseTheoretical);
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                var tempTaillift = _accessoryHolder.getTaillift();
                massValToUse = params.rearMassVal || tempTaillift.getRearAxleLoad(getDistanceFromWBTEndForAccessory(tempTaillift), wheelbaseTheoretical);
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                var tempFridge = _accessoryHolder.getFridge();
                massValToUse = params.rearMassVal || tempFridge.getRearAxleLoad(getDistanceFromWBTEndForAccessory(tempFridge), wheelbaseTheoretical);
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                var tempFifthWheel = _accessoryHolder.getFifthWheel();
                massValToUse = params.rearMassVal || tempFifthWheel.getRearAxleLoad(getDistanceFromWBTEndForAccessory(tempFifthWheel), wheelbaseTheoretical);
                break;
            case config.ACCESSORY_TYPES.HITCH:
                var tempHitch = _accessoryHolder.getHitch();
                massValToUse = params.rearMassVal || tempHitch.getRearAxleLoad(getDistanceFromWBTEndForAccessory(tempHitch), wheelbaseTheoretical);
                break;
            case config.ACCESSORY_TYPES.OTHER:
                var tempOther = _accessoryHolder.getOtherUsingId(params.objectInternalId);
                massValToUse = params.rearMassVal || tempOther.getRearAxleLoad(getDistanceFromWBTEndForAccessory(tempOther), wheelbaseTheoretical);
                break;
            case config.ACCESSORY_TYPES.PAYLOAD:
                massValToUse = params.rearMassVal || _payloadHolder.getRearAxleLoad(getFrontOfVehicleToWheelbaseTheoreticalEnd(), getFrontOfVehicleToPayloadDeckStart(), getWheelbaseTheoretical());
                break;
            case config.OBJECT_TYPES.FUEL_TANKS:
                var fuelTank = getFuelTankById(params.objectInternalId);
                massValToUse = params.rearMassVal || getRearFuelTank(fuelTank);
                break;
            case config.VALUE_TYPE.CREWFIRSTROW:
                massValToUse = params.rearMassVal || getRearCrewFirst();
                break;
            case config.VALUE_TYPE.CREWSECONDROW:
                massValToUse = params.rearMassVal || getRearCrewSecond();
                break;
        }
        return massValToUse;
    }

    //function getTotalPusherOrTagFuelAndCrew(axleNumber) {
    //    var total = 0;

    //    total += getRearRearAxlesDistribution({ objectOrAccessoryType: config.OBJECT_TYPES.FUEL_TANKS, axleNumber: axleNumber, objectInternalId: getFuelTank1().getId() });
    //    var fuelTank2 = getFuelTank2();
    //    if (fuelTank2 !== null && fuelTank2.getIncludeInCalculation()) {
    //        total += getRearRearAxlesDistribution({ objectOrAccessoryType: config.OBJECT_TYPES.FUEL_TANKS, axleNumber: axleNumber, objectInternalId: getFuelTank2().getId() });
    //    }
    //    var fuelTank3 = getFuelTank3();
    //    if (fuelTank3 !== null && fuelTank3.getIncludeInCalculation()) {
    //        total += getRearRearAxlesDistribution({ objectOrAccessoryType: config.OBJECT_TYPES.FUEL_TANKS, axleNumber: axleNumber, objectInternalId: getFuelTank3().getId() });
    //    }
    //    var fuelTank4 = getFuelTank4();
    //    if (fuelTank4 !== null && fuelTank4.getIncludeInCalculation()) {
    //        total += getRearRearAxlesDistribution({ objectOrAccessoryType: config.OBJECT_TYPES.FUEL_TANKS, axleNumber: axleNumber, objectInternalId: getFuelTank4().getId() });
    //    }

    //    total += getRearRearAxlesDistribution({ objectOrAccessoryType: config.VALUE_TYPE.CREWFIRSTROW, axleNumber: axleNumber });
    //    if (getCrewSecondRow()) {
    //        total += getRearRearAxlesDistribution({ objectOrAccessoryType: config.VALUE_TYPE.CREWSECONDROW, axleNumber: axleNumber });
    //    }

    //    return total;
    //}

    function getDistanceFromWBTEndForAccessory(accessory) {
        return _accessoryHolder.getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory,
                    getFrontOverhang(), getAxle1FrontToWheelbaseTheoreticalStart(), getWheelbaseTheoretical(), getBumperToBackOfCab(), _accessoryHolder.getBodyGap());
    }

    function getBodyHeightInternal() {
        // named as loadCompartmentHeight on cloud services side
        return _bodyHeightInternal;
    }
    function setBodyHeightInternal(newValue) {
        _bodyHeightInternal = newValue;
    }
    
    function getMessages() {
        return _messages;
    }
    function setMessages(newValue) {
        _messages = newValue;
    }
    function getPayloadFront() {

        /*
        If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then

            Return _Accessories.Body.PayloadObjects.FrontAxleLoad(FrontOfVehicleToWheelbaseTheoreticalEnd,
                                                                    FrontOfVehicleToPayloadDeckStart,
                                                                    WheelbaseTheoretical)

        ElseIf _Type = VehicleTypes.CompleteVehicle Then

            Return _PayloadObjects.FrontAxleLoad(FrontOfVehicleToWheelbaseTheoreticalEnd,
                                                    FrontOfVehicleToPayloadDeckStart,
                                                    WheelbaseTheoretical)

        Else
            Return 0
        End If
        
        */

        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return _payloadFront;
        //} else {
        if (getBody() !== null || getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return getPayloadHolder().getFrontAxleLoad(getFrontOfVehicleToWheelbaseTheoreticalEnd(), getFrontOfVehicleToPayloadDeckStart(), getWheelbaseTheoretical());
        } else {
            return 0;
        }
        //}


    }
    function setPayloadFront(newValue) {
        _payloadFront = newValue;
    }
    function getPayloadRear() {

        //If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then

        //Return _Accessories.Body.PayloadObjects.RearAxleLoad(FrontOfVehicleToWheelbaseTheoreticalEnd,
        //                                                     FrontOfVehicleToPayloadDeckStart,
        //                                                     WheelbaseTheoretical)

        //ElseIf _Type = VehicleTypes.CompleteVehicle Then

        //Return _PayloadObjects.RearAxleLoad(FrontOfVehicleToWheelbaseTheoreticalEnd,
        //                                    FrontOfVehicleToPayloadDeckStart,
        //                                    WheelbaseTheoretical)

        //Else
        //Return 0
        //End If
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return _payloadRear;
        //}else {
        if (getBody() !== null || getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return getPayloadHolder().getRearAxleLoad(getFrontOfVehicleToWheelbaseTheoreticalEnd(), getFrontOfVehicleToPayloadDeckStart(), getWheelbaseTheoretical());
        } else {
            return 0;
        }
        //}
    }
    function setPayloadRear(newValue) {
        _payloadRear = newValue;
    }
    function getPayloadTotal() {
        //If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
        //Return _Accessories.Body.PayloadObjects.TotalLoad
        //ElseIf _Type = VehicleTypes.CompleteVehicle Then
        //Return _PayloadObjects.TotalLoad
        //Else
        //Return 0
        //End If
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return _payloadTotal;
        //} else {
        if (getBody() !== null || getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return getPayloadHolder().getTotalLoad();
        } else {
            return 0;
        }
        //}


        //return _payloadTotal;
    }
    function setPayloadTotal(newValue) {
        _payloadTotal = newValue;
    }
    function getPermissibleFront() {
        return _permissibleFront;
    }
    function getPayloadCOGType() {
        return _payloadCOGType;
    }
    function setPayloadCOGType(newValue) {
        _payloadCOGType = newValue;
    }
    function getPayloadHorizontalCOG() {
        return _payloadHorizontalCOG;
    }
    function setPayloadHorizontalCOG(newValue) {
        _payloadHorizontalCOG = newValue;
    }
    function getPayloadVerticalCOG() {
        return _payloadVerticalCOG;
    }
    function setPayloadVerticalCOG(newValue) {
        _payloadVerticalCOG = newValue;
    }
    function getPayloadVerticalCOGOverride() {
        return _payloadVerticalCOGOverride;
    }
    function setPayloadVerticalCOGOverride(newValue) {
        _payloadVerticalCOGOverride = newValue;
    }
    function getPayloadLateralCOG() {
        return _payloadLateralCOG;
    }
    function setPayloadLateralCOG(newValue) {
        _payloadLateralCOG = newValue;
    }
    function getPayloadLateralCOGOverride() {
        return _payloadLateralCOGOverride;
    }
    function setPayloadLateralCOGOverride(newValue) {
        _payloadLateralCOGOverride = newValue;
    }
    function setPermissibleFront(newValue) {
        _permissibleFront = newValue;
    }
    function getPermissibleRear() {
        return _permissibleRear;
    }
    function setPermissibleRear(newValue) {
        _permissibleRear = newValue;
    }
    function getPermissibleTotal() {
        return _permissibleTotal;
    }
    function setPermissibleTotal(newValue) {
        _permissibleTotal = newValue;
    }
    function getRearOverhang() {
        return _rearOverhang;
    }
    function setRearOverhang(newValue) {
        _rearOverhang = newValue;
    }

    function getUnladenFront() {


        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return _unladenFront;
        //} else {
        return getTareFront() + getFrontExtrasTotal() + getBodyFrontAxle() + _accessoryHolder.getVehicleFrontAxleTotalAccessories(getFrontOverhang(), getAxle1FrontToWheelbaseTheoreticalStart(), getWheelbaseTheoretical(), getBumperToBackOfCab(), _accessoryHolder.getBodyGap());
        //}
        //return getTareFront() + getFrontExtrasTotal() + getBodyFrontAxle();
    }
    
    function getRevisedUnladenFront(totalRevisedDistributedTareFront) {
        return totalRevisedDistributedTareFront + getFrontExtrasTotal() + getBodyFrontAxle() + _accessoryHolder.getVehicleFrontAxleTotalAccessories(getFrontOverhang(), getAxle1FrontToWheelbaseTheoreticalStart(), getWheelbaseTheoretical(), getBumperToBackOfCab(), _accessoryHolder.getBodyGap());
    }
    function getBodyFrontAxle() {
        var bodyFrontAxle = 0;

        if (hasAccessory(config.ACCESSORY_TYPES.BODY) === true) {
            var body = getBody();
            bodyFrontAxle = body.getFrontAxleLoad((getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical()) -
                (getBumperToBackOfCab() + _accessoryHolder.getBodyGap() + body.getActualHorizontalCOG()),
                getWheelbaseTheoretical());
        }
        return bodyFrontAxle;
    }

    function setUnladenFront(newValue) {
        _unladenFront = newValue;
    }
    function getUnladenRear() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return _unladenRear;
        //} else {
        var tareRearVal;
        if (_tareRearOverride) {
            tareRearVal = getTareRearRear() + (_axlesHolder.getRearAxleWeight() - _axlesHolder.getRearRearAxleWeight());
        } else {
            tareRearVal = getTareRear();
        }

        return tareRearVal + getRearExtrasTotal() + getBodyRearAxle() + _accessoryHolder.getVehicleRearAxleTotalAccessories(getFrontOverhang(), getAxle1FrontToWheelbaseTheoreticalStart(), getWheelbaseTheoretical(), getBumperToBackOfCab(), _accessoryHolder.getBodyGap());
        //}
        //return getTareRear() + getRearExtrasTotal() + getBodyRearAxle();
    }
    function getRevisedUnladenRear(totalRevisedDistributedTareRear) {
        return totalRevisedDistributedTareRear + getRearExtrasTotal() + getBodyRearAxle() + _accessoryHolder.getVehicleRearAxleTotalAccessories(getFrontOverhang(), getAxle1FrontToWheelbaseTheoreticalStart(), getWheelbaseTheoretical(), getBumperToBackOfCab(), _accessoryHolder.getBodyGap());
    }
    function getBodyRearAxle() {
        var bodyRearAxle = 0;

        if (hasAccessory(config.ACCESSORY_TYPES.BODY) === true) {
            var body = getBody();
            bodyRearAxle = body.getRearAxleLoad((getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical()) -
                (getBumperToBackOfCab() + _accessoryHolder.getBodyGap() + body.getActualHorizontalCOG()),
                getWheelbaseTheoretical());
        }
        return bodyRearAxle;
    }
    function setUnladenRear(newValue) {
        _unladenRear = newValue;
    }
    function getUnladenTotal() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return _unladenTotal;
        //} else {

        var tareRearVal;
        if (_tareRearOverride) {
            tareRearVal = getTareRearRear() + (_axlesHolder.getRearAxleWeight() - _axlesHolder.getRearRearAxleWeight());
        } else {
            tareRearVal = getTareRear();
        }

        return getTareFront() + tareRearVal + getExtrasTotal() + getBodyTotalAxle() + _accessoryHolder.getTotalTotalAccessories();
        //}
        //return getTareFront() + getTareRear() + getExtrasTotal() + getBodyTotalAxle();
    }
    function getRevisedUnladenTotal(totalRevisedDistributedTareTotal) {
        
        return totalRevisedDistributedTareTotal + getExtrasTotal() + getBodyTotalAxle() + _accessoryHolder.getTotalTotalAccessories();
        
    }
    function getDBUnladenTotal() {
        return _unladenTotal;
    }
    function getBodyTotalAxle() {
        var bodyTotalAxle = 0;
        if (getBody() !== null) {
            bodyTotalAxle = getBody().getTotalLoad();
        }
        return bodyTotalAxle;
    }
    function setUnladenTotal(newValue) {
        _unladenTotal = newValue;
    }
    function getGraphicBlobByElevation(elevation) {
        if (elevation === undefined || elevation === config.ELEVATION.SIDE) {
            return _vehicleDrawing;
        } else {
            return _vehicleTopViewDrawing;
        }
    }
    function getDecompressedGraphicBlobByElevation(elevation) {
        if (elevation === undefined || elevation === config.ELEVATION.SIDE) {
            return _decompressedVehicleDrawing;
        } else {
            return _decompressedVehicleTopViewDrawing;
        }
    }
    
    function getVehicleDrawing(elevation) {
        return _vehicleDrawing;
    }
    function setVehicleDrawing(newValue) {
        _vehicleDrawing = newValue;
    }
    function getVehicleTopViewDrawing() {
        return _vehicleTopViewDrawing;
    }
    function setVehicleTopViewDrawing(newValue) {
        _vehicleTopViewDrawing = newValue;
    }
    function getDecompressedVehicleDrawing() {
        return _decompressedVehicleDrawing;
    }
    function setDecompressedVehicleDrawing(decompressedVehicleDrawing) {
        _decompressedVehicleDrawing = decompressedVehicleDrawing;
    }
    function getDecompressedVehicleTopViewDrawing() {
        return _decompressedVehicleTopViewDrawing;
    }
    function setDecompressedVehicleTopViewDrawing(decompressedVehicleTopViewDrawing) {
        _decompressedVehicleTopViewDrawing = decompressedVehicleTopViewDrawing;
    }

    
    //function getWheelbaseTheoretical() {
    //    return _wheelbaseTheoretical;
    //}
    function setWheelbaseTheoretical(newValue) {
        _wheelbaseTheoretical = newValue;
    }
    function getBumperToStartOfBodyInternal() {
        return _bumperToStartOfBodyInternal;
    }
    function setBumperToStartOfBodyInternal(newValue) {
        _bumperToStartOfBodyInternal = newValue;
    }
    function getBodyLengthInternal() {
        // named as loadCompartmentLength on CloudServices side
        return _bodyLengthInternal;
    }
    function setBodyLengthInternal(newValue) {
        // named as loadCompartmentLength on CloudServices side
        _bodyLengthInternal = newValue;
    }
    function getRearProtrusion() {
        return _rearProtrusion;
    }
    function setRearProtrusion(newValue) {
        _rearProtrusion = newValue;
    }
    function getAxle1FrontToWheelbaseTheoreticalStart() {
        //If _Axle1FrontToWheelbaseTheoreticalStartOverride Then
        //Return _Axle1FrontToWheelbaseTheoreticalStart
        //Else
        //If Axles.Axle1Front IsNot Nothing AndAlso Axles.Axle2Front IsNot Nothing AndAlso Not Axles.Axle2Front.Raised AndAlso
        //   (Axles.Axle1Front.ManufacturerRating + Axles.Axle2Front.ManufacturerRating <> 0) Then
        //Return Axles.Axle2Front.ManufacturerRating / (Axles.Axle1Front.ManufacturerRating + Axles.Axle2Front.ManufacturerRating) * Axle1FrontToAxle2Front
        //Else
        //Return 0
        //End If
        //End If


        if (_axle1FrontToWheelbaseTheoreticalStartOverride === true) {
            return _axle1FrontToWheelbaseTheoreticalStart;
        } else {
            if (_axlesHolder.getAxle1Front() !== null && _axlesHolder.getAxle2Front() !== null && _axlesHolder.getAxle2Front().getRaised() !== true && (_axlesHolder.getAxle1Front().getSuspensionMassRating() + _axlesHolder.getAxle2Front().getSuspensionMassRating() !== 0)) {
                return _axlesHolder.getAxle2Front().getSuspensionMassRating() / (_axlesHolder.getAxle1Front().getSuspensionMassRating() + _axlesHolder.getAxle2Front().getSuspensionMassRating()) * getAxle1FrontToAxle2Front();
            } else {
                return 0;
            }
        }
    }
    function setAxle1FrontToWheelbaseTheoreticalStart(newValue) {
        _axle1FrontToWheelbaseTheoreticalStart = newValue;
    }
    function getAxle1FrontToWheelbaseTheoreticalStartOverride() {
        return _axle1FrontToWheelbaseTheoreticalStartOverride;
    }
    function setAxle1FrontToWheelbaseTheoreticalStartOverride(newValue) {
        _axle1FrontToWheelbaseTheoreticalStartOverride = newValue;
    }
    function getFifthWheelOffset() {
        return _fifthWheelOffset;
    }
    function setFifthWheelOffset(newValue) {
        _fifthWheelOffset = newValue;
    }
    function getFifthWheelHeightFromGround() {
        return _fifthWheelHeightFromGround;
    }
    function setFifthWheelHeightFromGround(newValue) {
        _fifthWheelHeightFromGround = newValue;
    }
    function getAxle1FrontToAxle2Front() {
        if (_axle1FrontToAxle2Front !== undefined) {
            return _axle1FrontToAxle2Front;
        }
        return 0;
    }
    function setAxle1FrontToAxle2Front(newValue) {
        _axle1FrontToAxle2Front = newValue;
    }
    function getAxle1FrontToAxle2FrontOverride() {
        return _axle1FrontToAxle2FrontOverride;
    }
    function setAxle1FrontToAxle2FrontOverride(newValue) {
        _axle1FrontToAxle2FrontOverride = newValue;
    }
    function getAxle2FrontToAxle1Rear() {
        if (_axle2FrontToAxle1Rear !== undefined) {
            return _axle2FrontToAxle1Rear;
        }
        return 0;
    }
    function setAxle2FrontToAxle1Rear(newValue) {
        _axle2FrontToAxle1Rear = newValue;
    }
    function getAxle2FrontToAxle1RearOverride() {
        return _axle2FrontToAxle1RearOverride;
    }
    function setAxle2FrontToAxle1RearOverride(newValue) {
        _axle2FrontToAxle1RearOverride = newValue;
    }
    function getAxle1RearToAxle2Rear() {
        if (_axle1RearToAxle2Rear !== undefined) {
            return _axle1RearToAxle2Rear;
        }
        return 0;
    }
    function setAxle1RearToAxle2Rear(newValue) {
        _axle1RearToAxle2Rear = newValue;
    }
    function getAxle1RearToAxle2RearOverride() {
        return _axle1RearToAxle2RearOverride;
    }
    function setAxle1RearToAxle2RearOverride(newValue) {
        _axle1RearToAxle2RearOverride = newValue;
    }
    function getAxle2RearToAxle3Rear() {
        if (_axle2RearToAxle3Rear !== undefined) {
            return _axle2RearToAxle3Rear;
        }
        return 0;
    }
    function setAxle2RearToAxle3Rear(newValue) {
        _axle2RearToAxle3Rear = newValue;
    }
    function getAxle2RearToAxle3RearOverride() {
        return _axle2RearToAxle3RearOverride;
    }
    function setAxle2RearToAxle3RearOverride(newValue) {
        _axle2RearToAxle3RearOverride = newValue;
    }
    function getAxle3RearToAxle4Rear() {
        if (_axle3RearToAxle4Rear !== undefined) {
            return _axle3RearToAxle4Rear;
        }
        return 0;
    }
    function setAxle3RearToAxle4Rear(newValue) {
        _axle3RearToAxle4Rear = newValue;
    }
    function getAxle3RearToAxle4RearOverride() {
        return _axle3RearToAxle4RearOverride;
    }
    function setAxle3RearToAxle4RearOverride(newValue) {
        _axle3RearToAxle4RearOverride = newValue;
    }
    function getControl() {
        return _control;
    }
    function setControl(newValue) {
        _control = newValue;
    }
    function getBreakpointForRearOverhangFromRearMostAxle() {
        return _breakpointForRearOverhangFromRearMostAxle;
    }
    function setBreakpointForRearOverhangFromRearMostAxle(newValue) {
        _breakpointForRearOverhangFromRearMostAxle = newValue;
    }
    function getBreakpointForWheelbaseFromRearMostAxleFront() {
        return _breakpointForWheelbaseFromRearMostAxleFront;
    }
    function setBreakpointForWheelbaseFromRearMostAxleFront(newValue) {
        _breakpointForWheelbaseFromRearMostAxleFront = newValue;
    }
    function getBreakPointForAxle1FrontToAxle2FrontFromAxle1Front() {
        return _breakPointForAxle1FrontToAxle2FrontFromAxle1Front;
    }
    function setBreakPointForAxle1FrontToAxle2FrontFromAxle1Front(newValue) {
        _breakPointForAxle1FrontToAxle2FrontFromAxle1Front = newValue;
    }
    function getBreakPointForAxle1RearToAxle2RearFromAxle1Rear() {
        return _breakPointForAxle1RearToAxle2RearFromAxle1Rear;
    }
    function setBreakPointForAxle1RearToAxle2RearFromAxle1Rear(newValue) {
        _breakPointForAxle1RearToAxle2RearFromAxle1Rear = newValue;
    }
    function getBreakPointForAxle2RearToAxle3RearFromAxle2Rear() {
        return _breakPointForAxle2RearToAxle3RearFromAxle2Rear;
    }
    function setBreakPointForAxle2RearToAxle3RearFromAxle2Rear(newValue) {
        _breakPointForAxle2RearToAxle3RearFromAxle2Rear = newValue;
    }
    function getBreakPointForAxle3RearToAxle4RearFromAxle3Rear() {
        return _breakPointForAxle3RearToAxle4RearFromAxle3Rear;
    }
    function setBreakPointForAxle3RearToAxle4RearFromAxle3Rear(newValue) {
        _breakPointForAxle3RearToAxle4RearFromAxle3Rear = newValue;
    }
    function getRearMostProtrudingItemBehindCabToRearAxles() {
        //return Math.floor(_rearMostProtrudingItemBehindCabToRearAxles);
        return _rearMostProtrudingItemBehindCabToRearAxles;
    }
    function setRearMostProtrudingItemBehindCabToRearAxles(newValue) {
        _rearMostProtrudingItemBehindCabToRearAxles = newValue;
    }
    function getType() {
        return _type;
    }
    function setType(newValue) {
        _type = newValue;
    }
    function getTareFifthWheelIncluded() {
        return _tareFifthWheelIncluded;
    }
    function setTareFifthWheelIncluded(newValue) {
        _tareFifthWheelIncluded = newValue;
    }
    function getChassisHeightFront() {
        return _chassisHeightFront;
    }
    function setChassisHeightFront(newValue) {
        _chassisHeightFront = newValue;
    }
    function getChassisHeightRear() {
        return _chassisHeightRear;
    }
    function setChassisHeightRear(newValue) {
        _chassisHeightRear = newValue;
    }
    function getChassisHeightMax() {
        return Math.max(_chassisHeightFront, _chassisHeightRear);
    }
    function getAxles() {
        return _axlesHolder.getAxles();
    }
    function setAxles(newValue) {
        _axlesHolder.setAxles(newValue);
    }
    function getAxleGroups() {
        return _axlesHolder.getAxleGroups();
    }
    function getAxleObjectsForMenu() {
        return _axlesHolder.getAxleObjectsForMenu();
    }
    function hasPusherOrTag() {
        //var hasPusherOrTag = false;
        //_axles.forEach(function (axle) {
        //    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
        //        hasPusherOrTag = true;
        //    }
        //});
        //return hasPusherOrTag;
        return _axlesHolder.hasPusherOrTag();
    }
    function getSource() {
        return _source;
    }
    function setSource(newValue) {
        _source = newValue;
    }
    function getVerticalExhaustHeight() {
        return _verticalExhaustHeight;
    }
    function setVerticalExhaustHeight(newValue) {
        _verticalExhaustHeight = newValue;
    }
    function getOverallLengthExclAccessories() {
        return calculateOverallLengthExcludingAccessories();
        //return _overallLengthExclAccessories;
        /*
        getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() +
                                    getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() +
                                    getRearOverhang() + getRearProtrusion()
        */
    }
    function getOverallLengthExclAccessoriesExceptRearBumper() {
        return getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() +
                    getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() +
                    getRearOverhang() + Math.max(getRearBumperProtrusion(), getRearProtrusion());
    }
    function setOverallLengthExclAccessories(newValue) {
        _overallLengthExclAccessories = newValue;
    }
    //function getOverallLengthInclAccessories() {
    //    return _overallLengthInclAccessories;
    //}
    function setOverallLengthInclAccessories(newValue) {
        _overallLengthInclAccessories = newValue;
    }
    function getPortionOfOverallLengthOverhangingFront(includeBullbar) {
        //If _Accessories.AccessoryLeftMostPoint(BumperToBackOfCab, FrontOverhang) < 0 Then
        //Return Math.Abs(Accessories.AccessoryLeftMostPoint(BumperToBackOfCab, FrontOverhang))
        //Else
        //Return 0
        //End If
        if (_accessoryHolder.getAccessoryLeftMostPoint(getBumperToBackOfCab(), getFrontOverhang(), includeBullbar) < 0) {
            return Math.abs(_accessoryHolder.getAccessoryLeftMostPoint(getBumperToBackOfCab(), getFrontOverhang(), includeBullbar));
        } else {
            return 0;
        }
        //return 0;
    }
    function getPortionOfOverallLengthOverhangingRear() {
        //If _Accessories.AccessoryRightMostPoint(BumperToBackOfCab, FrontOverhang) > OverallLengthExclAccessories Then
        //Return Accessories.AccessoryRightMostPoint(BumperToBackOfCab, FrontOverhang) - OverallLengthExclAccessories
        //Else
        //Return 0
        //End If


        var accessoryRightMostPoint = _accessoryHolder.getAccessoryRightMostPoint(getBumperToBackOfCab(), getFrontOverhang(), getWheelbaseTheoreticalEnd());

        if (accessoryRightMostPoint > getOverallLengthExclAccessories()) {
            return accessoryRightMostPoint - getOverallLengthExclAccessories();
        } else {
            return 0;
        }
    }
    

    function getCabToAxles() {
        return _cabToAxles;
    }
    function getCabToAxlesOverride() {
        return _cabToAxlesOverride;
    }
    function setCabToAxlesOverride(newValue) {
        _cabToAxlesOverride = newValue;
    }
    function getRearMostAccessoryToAxles() {
        return _frontOverhang + _axle1FrontToAxle2Front + _axle2FrontToAxle1Rear + (_axle1RearToAxle2Rear + _axle2RearToAxle3Rear + _axle3RearToAxle4Rear) / 2 - getBumperToBackOfRearMostEquipment();
    }
    function getCabToChassisEnd() {
        return calculateCabToChassisEnd();
    }
    //function getCabProtrusion() {
    //    var cabProtrusion = getCabToAxles() - getRearMostProtrudingItemBehindCabToRearAxles();
    //    if (cabProtrusion < 0) {
    //        return 0;
    //    } else {
    //        return cabProtrusion;
    //    }
    //}

    function getOverallLength() {
        return calculateOverallLength();
        //return _overallLength;
    }
    //function setOverallLength(newValue) {
    //    _overallLength = newValue;
    //}
    function getGroundClearanceFront() {
        return _groundClearanceFront;
    }
    function setGroundClearanceFront(newValue) {
        _groundClearanceFront = newValue;
    }
    function getGroundClearanceRear() {
        return _groundClearanceRear;
    }
    function setGroundClearanceRear(newValue) {
        _groundClearanceRear = newValue;
    }
    function getCrewMassPerPerson() {
        return _crewMassPerPerson;
    }
    function setCrewMassPerPerson(newValue) {
        _crewMassPerPerson = newValue;
    }
    function getCrewFirstRowMax() {
        return _crewFirstRowMax;
    }
    function setCrewFirstRowMax(newValue) {
        _crewFirstRowMax = newValue;
    }
    function getCrewFirstRowCOG() {
        return _crewFirstRowCOG;
    }
    function setCrewFirstRowCOG(newValue) {
        _crewFirstRowCOG = newValue;
    }
    function getNoOfCrewInFirstRow() {
        return _noOfCrewInFirstRow;
    }
    function setNoOfCrewInFirstRow(newValue) {
        _noOfCrewInFirstRow = newValue;
    }
    function getCrewSecondRowIncluded() {
        return _crewSecondRowIncluded;
    }
    function setCrewSecondRowIncluded(newValue) {
        _crewSecondRowIncluded = newValue;
    }
    function getCrewSecondRowMax() {
        return _crewSecondRowMax;
    }
    function setCrewSecondRowMax(newValue) {
        _crewSecondRowMax = newValue;
    }
    function getCrewSecondRowCOG() {
        return _crewSecondRowCOG;
    }
    function setCrewSecondRowCOG(newValue) {
        _crewSecondRowCOG = newValue;
    }
    function getNoOfCrewInSecondRow() {
        return _noOfCrewInSecondRow;
    }
    function setNoOfCrewInSecondRow(newValue) {
        _noOfCrewInSecondRow = newValue;
    }
    function getFuelSpecificGravity(optionalFuelTank) {
        if(optionalFuelTank && optionalFuelTank.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
            return _specificGravityAdBlue;
        }
        if (_fuelSpecificGravityOverride) {
            return _fuelSpecificGravity;
        } else {
            return  _fuelType === config.FUEL_TYPE_OPTIONS.DIESEL.CODE ? getFuelSpecificGravityDiesel() : 
            _fuelType === config.FUEL_TYPE_OPTIONS.PETROL.CODE ? getFuelSpecificGravityPetrol() : 
            _fuelType === config.FUEL_TYPE_OPTIONS.ETHANOL.CODE ? getFuelSpecificGravityEthanol() :
            _fuelType === config.FUEL_TYPE_OPTIONS.BIODIESEL.CODE ? getFuelSpecificGravityBiodiesel() :
            _fuelType === config.FUEL_TYPE_OPTIONS.CNG.CODE ? getFuelSpecificGravityCNG() :
            _fuelType === config.FUEL_TYPE_OPTIONS.LNG.CODE ? getFuelSpecificGravityLNG() :
            _fuelType === config.FUEL_TYPE_OPTIONS.HYDROGEN_FUEL_CELL.CODE ? getFuelSpecificGravityHydrogen() : 0;
        }
    }
    function setFuelSpecificGravity(newValue) {
        _fuelSpecificGravity = newValue;
    }
    function getFuelSpecificGravityOverride() {
        return _fuelSpecificGravityOverride;
    }
    function setFuelSpecificGravityOverride(newValue) {
        _fuelSpecificGravityOverride = newValue;
    }
    function getFuelSpecificGravityDiesel() {
        return _fuelSpecificGravityDiesel;
    }
    function setFuelSpecificGravityDiesel(newValue) {
        _fuelSpecificGravityDiesel = newValue;
    }
    function getFuelSpecificGravityPetrol() {
        return _fuelSpecificGravityPetrol;
    }
    function setFuelSpecificGravityPetrol(newValue) {
        _fuelSpecificGravityPetrol = newValue;
    }
    function getFuelSpecificGravityEthanol() {
        return _fuelSpecificGravityEthanol;
    }
    function setFuelSpecificGravityEthanol(newValue) {
        _fuelSpecificGravityEthanol = newValue;
    }
    function getFuelSpecificGravityBiodiesel() {
        return _fuelSpecificGravityBiodiesel;
    }
    function setFuelSpecificGravityBiodiesel(newValue) {
        _fuelSpecificGravityBiodiesel = newValue;
    }
    function getFuelSpecificGravityLNG() {
        return _fuelSpecificGravityLNG;
    }
    function setFuelSpecificGravityLNG(newValue) {
        _fuelSpecificGravityLNG = newValue;
    }
    function getFuelSpecificGravityCNG() {
        return _fuelSpecificGravityCNG;
    }
    function setFuelSpecificGravityCNG(newValue) {
        _fuelSpecificGravityCNG = newValue;
    }
    function getFuelSpecificGravityHydrogen() {
        return _fuelSpecificGravityHydrogen;
    }
    function setFuelSpecificGravityHydrogen(newValue) {
        _fuelSpecificGravityHydrogen = newValue;
    }
    function getSpecificGravityAdBlue() {
        return _specificGravityAdBlue;
    }
    function setSpecificGravityAdBlue(newValue) {
        _specificGravityAdBlue = newValue;
    }
    function getSpecificGravityAdBlueOverride() {
        return _specificGravityAdBlueOverride;
    }
    function setSpecificGravityAdBlueOverride(newValue) {
        _specificGravityAdBlueOverride = newValue;
    }
    function getCNGExpressedAs() {
        return _cNGExpressedAs;
    }
    function setCNGExpressedAs(newValue) {
        _cNGExpressedAs = newValue;
    }
    function getLNGExpressedAs() {
        return _lNGExpressedAs;
    }
    function setLNGExpressedAs(newValue) {
        _lNGExpressedAs = newValue;
    }
    function getHydrogenExpressedAs() {
        return _hydrogenExpressedAs;
    }
    function setHydrogenExpressedAs(newValue) {
        _hydrogenExpressedAs = newValue;
    }
    function getFuelTankByNumber(number) {
        return getFuelTanks().filter(function (fuelTank) {
            return fuelTank.getNumber() === number;
        })[0] || null;
    }
    function getFuelTankById(id) {
        return getFuelTanks().filter(function (fuelTank) {
            return fuelTank.getId() === id;
        })[0] || null;
    }
    function getFuelTank1() {
        return getFuelTankByNumber(1);
    }
    function getFuelTank2() {
        return getFuelTankByNumber(2);
    }
    function getFuelTank3() {
        return getFuelTankByNumber(3);
    }
    function getFuelTank4() {
        return getFuelTankByNumber(4);
    }
    function getFuelTank5() {
        return getFuelTankByNumber(5);
    }
    function getFuelTank6() {
        return getFuelTankByNumber(6);
    }
    function getFuelTank7() {
        return getFuelTankByNumber(7);
    }
    function getFuelTank8() {
        return getFuelTankByNumber(8);
    }
    function getFuelTank1IncludeInCalculation() {
        return getFuelTank1().getIncludeInCalculation();
    }
    function setFuelTank1IncludeInCalculation(newValue) {
        //_fuelTank1COG = newValue;
        getFuelTank1().setIncludeInCalculation(newValue);
    }
    function getFuelTank1OfferedAs() {
        return getFuelTank1().getOfferedAs();
    }
    function setFuelTank1OfferedAs(newValue) {
        //_fuelTank1COG = newValue;
        getFuelTank1().setOfferedAs(newValue);
    }
    function getFuelTank1COG() {
        return getFuelTank1().getHorizontalCog();
    }
    function setFuelTank1COG(newValue) {
        //_fuelTank1COG = newValue;
        getFuelTank1().setHorizontalCog(newValue);
    }
    function getFuelTank1COGOverride() {
        return getFuelTank1().getHorizontalCogOverride();
    }
    function setFuelTank1COGOverride(newValue) {
        //_fuelTank1COG = newValue;
        getFuelTank1().setHorizontalCogOverride(newValue);
    }
    function getFuelTank1VerticalCOG() {
        //return _fuelTank1VerticalCOG;
        return getFuelTank1().getVerticalCog();
    }
    function setFuelTank1VerticalCOG(newValue) {
        //_fuelTank1VerticalCOG = newValue;
        getFuelTank1().setVerticalCog(newValue)
    }
    function getFuelTank1VerticalCOGOverride() {
        //return _fuelTank1VerticalCOG;
        return getFuelTank1().getVerticalCogOverride();
    }
    function setFuelTank1VerticalCOGOverride(newValue) {
        //_fuelTank1VerticalCOG = newValue;
        getFuelTank1().setVerticalCogOverride(newValue)
    }
    function getFuelTank1LateralCOG() {
        //return _fuelTank1LateralCOG;
        return getFuelTank1().getLateralCog();
    }
    function setFuelTank1LateralCOG(newValue) {
        //_fuelTank1LateralCOG = newValue;
        getFuelTank1().setLateralCog(newValue)
    }
    function getFuelTank1LateralCOGOverride() {
        //return _fuelTank1LateralCOG;
        return getFuelTank1().getLateralCogOverride();
    }
    function setFuelTank1LateralCOGOverride(newValue) {
        //_fuelTank1LateralCOG = newValue;
        getFuelTank1().setLateralCogOverride(newValue)
    }
    function getFuelTank1Capacity() {
        return getFuelTank1().getCapacity();
    }
    function setFuelTank1Capacity(newValue) {
        getFuelTank1().setCapacity(newValue);
    }

    function getFuelTank2IncludeInCalculation() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getIncludeInCalculation();
        } else {
            return false;
        }
    }
    function setFuelTank2IncludeInCalculation(newValue) {
        if (getFuelTank2() !== null) {
            getFuelTank2().setIncludeInCalculation(newValue);
        }
    }
    function getFuelTank2OfferedAs() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getOfferedAs();
        } else {
            return '';
        }
    }
    function setFuelTank2OfferedAs(newValue) {
        if (getFuelTank2() !== null) {
            getFuelTank2().setOfferedAs(newValue);
        }
    }
    function getFuelTank2COG() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getHorizontalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank2COG(newValue) {
        if (getFuelTank2() !== null) {
            return getFuelTank2().setHorizontalCog(newValue);
        }
    }
    function getFuelTank2COGOverride() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getHorizontalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank2COGOverride(newValue) {
        if (getFuelTank2() !== null) {
            return getFuelTank2().setHorizontalCogOverride(newValue);
        }
    }
    function getFuelTank2VerticalCOG() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getVerticalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank2VerticalCOG(newValue) {
        if (getFuelTank2() !== null) {
            return getFuelTank2().setVerticalCog(newValue);
        }
    }
    function getFuelTank2VerticalCOGOverride() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getVerticalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank2VerticalCOGOverride(newValue) {
        if (getFuelTank2() !== null) {
            return getFuelTank2().setVerticalCogOverride(newValue);
        }
    }
    function getFuelTank2LateralCOG() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getLateralCog();
        } else {
            return 0;
        }
    }
    function setFuelTank2LateralCOG(newValue) {
        if (getFuelTank2() !== null) {
            return getFuelTank2().setLateralCog(newValue);
        }
    }
    function getFuelTank2LateralCOGOverride() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getLateralCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank2LateralCOGOverride(newValue) {
        if (getFuelTank2() !== null) {
            return getFuelTank2().setLateralCogOverride(newValue);
        }
    }
    function getFuelTank2Capacity() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getCapacity();
        } else {
            return 0;
        }
    }
    function setFuelTank2Capacity(newValue) {
        if (getFuelTank2() !== null) {
            return getFuelTank2().setCapacity(newValue);
        }
    }


    function getFuelTank3IncludeInCalculation() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getIncludeInCalculation();
        } else {
            return false;
        }
    }
    function setFuelTank3IncludeInCalculation(newValue) {
        if (getFuelTank3() !== null) {
            getFuelTank3().setIncludeInCalculation(newValue);
        }
    }
    function getFuelTank3OfferedAs() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getOfferedAs();
        } else {
            return '';
        }
    }
    function setFuelTank3OfferedAs(newValue) {
        if (getFuelTank3() !== null) {
            getFuelTank3().setOfferedAs(newValue);
        }
    }
    function getFuelTank3COG() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getHorizontalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank3COG(newValue) {
        if (getFuelTank3() !== null) {
            return getFuelTank3().setHorizontalCog(newValue);
        }
    }
    function getFuelTank3COGOverride() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getHorizontalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank3COGOverride(newValue) {
        if (getFuelTank3() !== null) {
            return getFuelTank3().setHorizontalCogOverride(newValue);
        }
    }
    function getFuelTank3VerticalCOG() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getVerticalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank3VerticalCOG(newValue) {
        if (getFuelTank3() !== null) {
            return getFuelTank3().setVerticalCog(newValue);
        }
    }
    function getFuelTank3VerticalCOGOverride() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getVerticalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank3VerticalCOGOverride(newValue) {
        if (getFuelTank3() !== null) {
            return getFuelTank3().setVerticalCogOverride(newValue);
        }
    }
    function getFuelTank3LateralCOG() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getLateralCog();
        } else {
            return 0;
        }
    }
    function setFuelTank3LateralCOG(newValue) {
        if (getFuelTank3() !== null) {
            return getFuelTank3().setLateralCog(newValue);
        }
    }
    function getFuelTank3LateralCOGOverride() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getLateralCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank3LateralCOGOverride(newValue) {
        if (getFuelTank3() !== null) {
            return getFuelTank3().setLateralCogOverride(newValue);
        }
    }
    function getFuelTank3Capacity() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getCapacity();
        } else {
            return 0;
        }
    }
    function setFuelTank3Capacity(newValue) {
        if (getFuelTank3() !== null) {
            return getFuelTank3().setCapacity(newValue);
        }
    }

    function getFuelTank4IncludeInCalculation() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getIncludeInCalculation();
        } else {
            return false;
        }
    }
    function setFuelTank4IncludeInCalculation(newValue) {
        if (getFuelTank4() !== null) {
            getFuelTank4().setIncludeInCalculation(newValue);
        }
    }
    function getFuelTank4OfferedAs() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getOfferedAs();
        } else {
            return '';
        }
    }
    function setFuelTank4OfferedAs(newValue) {
        if (getFuelTank4() !== null) {
            getFuelTank4().setOfferedAs(newValue);
        }
    }
    function getFuelTank4COG() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getHorizontalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank4COG(newValue) {
        if (getFuelTank4() !== null) {
            return getFuelTank4().setHorizontalCog(newValue);
        }
    }
    function getFuelTank4COGOverride() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getHorizontalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank4COGOverride(newValue) {
        if (getFuelTank4() !== null) {
            return getFuelTank4().setHorizontalCogOverride(newValue);
        }
    }
    function getFuelTank4VerticalCOG() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getVerticalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank4VerticalCOG(newValue) {
        if (getFuelTank4() !== null) {
            return getFuelTank4().setVerticalCog(newValue);
        }
    }
    function getFuelTank4VerticalCOGOverride() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getVerticalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank4VerticalCOGOverride(newValue) {
        if (getFuelTank4() !== null) {
            return getFuelTank4().setVerticalCogOverride(newValue);
        }
    }
    function getFuelTank4LateralCOG() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getLateralCog();
        } else {
            return 0;
        }
    }
    function setFuelTank4LateralCOG(newValue) {
        if (getFuelTank4() !== null) {
            return getFuelTank4().setLateralCog(newValue);
        }
    }
    function getFuelTank4LateralCOGOverride() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getLateralCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank4LateralCOGOverride(newValue) {
        if (getFuelTank4() !== null) {
            return getFuelTank4().setLateralCogOverride(newValue);
        }
    }
    function getFuelTank4Capacity() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getCapacity();
        } else {
            return 0;
        }
    }
    function setFuelTank4Capacity(newValue) {
        if (getFuelTank4() !== null) {
            return getFuelTank4().setCapacity(newValue);
        }
    }


    function getFuelTank5IncludeInCalculation() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getIncludeInCalculation();
        } else {
            return false;
        }
    }
    function setFuelTank5IncludeInCalculation(newValue) {
        if (getFuelTank5() !== null) {
            getFuelTank5().setIncludeInCalculation(newValue);
        }
    }
    function getFuelTank5OfferedAs() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getOfferedAs();
        } else {
            return '';
        }
    }
    function setFuelTank5OfferedAs(newValue) {
        if (getFuelTank5() !== null) {
            getFuelTank5().setOfferedAs(newValue);
        }
    }
    function getFuelTank5COG() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getHorizontalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank5COG(newValue) {
        if (getFuelTank5() !== null) {
            return getFuelTank5().setHorizontalCog(newValue);
        }
    }
    function getFuelTank5COGOverride() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getHorizontalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank5COGOverride(newValue) {
        if (getFuelTank5() !== null) {
            return getFuelTank5().setHorizontalCogOverride(newValue);
        }
    }
    function getFuelTank5VerticalCOG() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getVerticalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank5VerticalCOG(newValue) {
        if (getFuelTank5() !== null) {
            return getFuelTank5().setVerticalCog(newValue);
        }
    }
    function getFuelTank5VerticalCOGOverride() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getVerticalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank5VerticalCOGOverride(newValue) {
        if (getFuelTank5() !== null) {
            return getFuelTank5().setVerticalCogOverride(newValue);
        }
    }
    function getFuelTank5LateralCOG() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getLateralCog();
        } else {
            return 0;
        }
    }
    function setFuelTank5LateralCOG(newValue) {
        if (getFuelTank5() !== null) {
            return getFuelTank5().setLateralCog(newValue);
        }
    }
    function getFuelTank5LateralCOGOverride() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getLateralCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank5LateralCOGOverride(newValue) {
        if (getFuelTank5() !== null) {
            return getFuelTank5().setLateralCogOverride(newValue);
        }
    }
    function getFuelTank5Capacity() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getCapacity();
        } else {
            return 0;
        }
    }
    function setFuelTank5Capacity(newValue) {
        if (getFuelTank5() !== null) {
            return getFuelTank5().setCapacity(newValue);
        }
    }



    function getFuelTank6IncludeInCalculation() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getIncludeInCalculation();
        } else {
            return false;
        }
    }
    function setFuelTank6IncludeInCalculation(newValue) {
        if (getFuelTank6() !== null) {
            getFuelTank6().setIncludeInCalculation(newValue);
        }
    }
    function getFuelTank6OfferedAs() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getOfferedAs();
        } else {
            return '';
        }
    }
    function setFuelTank6OfferedAs(newValue) {
        if (getFuelTank6() !== null) {
            getFuelTank6().setOfferedAs(newValue);
        }
    }
    function getFuelTank6COG() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getHorizontalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank6COG(newValue) {
        if (getFuelTank6() !== null) {
            return getFuelTank6().setHorizontalCog(newValue);
        }
    }
    function getFuelTank6COGOverride() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getHorizontalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank6COGOverride(newValue) {
        if (getFuelTank6() !== null) {
            return getFuelTank6().setHorizontalCogOverride(newValue);
        }
    }
    function getFuelTank6VerticalCOG() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getVerticalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank6VerticalCOG(newValue) {
        if (getFuelTank6() !== null) {
            return getFuelTank6().setVerticalCog(newValue);
        }
    }
    function getFuelTank6VerticalCOGOverride() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getVerticalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank6VerticalCOGOverride(newValue) {
        if (getFuelTank6() !== null) {
            return getFuelTank6().setVerticalCogOverride(newValue);
        }
    }
    function getFuelTank6LateralCOG() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getLateralCog();
        } else {
            return 0;
        }
    }
    function setFuelTank6LateralCOG(newValue) {
        if (getFuelTank6() !== null) {
            return getFuelTank6().setLateralCog(newValue);
        }
    }
    function getFuelTank6LateralCOGOverride() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getLateralCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank6LateralCOGOverride(newValue) {
        if (getFuelTank6() !== null) {
            return getFuelTank6().setLateralCogOverride(newValue);
        }
    }
    function getFuelTank6Capacity() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getCapacity();
        } else {
            return 0;
        }
    }
    function setFuelTank6Capacity(newValue) {
        if (getFuelTank6() !== null) {
            return getFuelTank6().setCapacity(newValue);
        }
    }



    function getFuelTank7IncludeInCalculation() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getIncludeInCalculation();
        } else {
            return false;
        }
    }
    function setFuelTank7IncludeInCalculation(newValue) {
        if (getFuelTank7() !== null) {
            getFuelTank7().setIncludeInCalculation(newValue);
        }
    }
    function getFuelTank7OfferedAs() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getOfferedAs();
        } else {
            return '';
        }
    }
    function setFuelTank7OfferedAs(newValue) {
        if (getFuelTank7() !== null) {
            getFuelTank7().setOfferedAs(newValue);
        }
    }
    function getFuelTank7COG() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getHorizontalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank7COG(newValue) {
        if (getFuelTank7() !== null) {
            return getFuelTank7().setHorizontalCog(newValue);
        }
    }
    function getFuelTank7COGOverride() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getHorizontalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank7COGOverride(newValue) {
        if (getFuelTank7() !== null) {
            return getFuelTank7().setHorizontalCogOverride(newValue);
        }
    }
    function getFuelTank7VerticalCOG() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getVerticalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank7VerticalCOG(newValue) {
        if (getFuelTank7() !== null) {
            return getFuelTank7().setVerticalCog(newValue);
        }
    }
    function getFuelTank7VerticalCOGOverride() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getVerticalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank7VerticalCOGOverride(newValue) {
        if (getFuelTank7() !== null) {
            return getFuelTank7().setVerticalCogOverride(newValue);
        }
    }
    function getFuelTank7LateralCOG() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getLateralCog();
        } else {
            return 0;
        }
    }
    function setFuelTank7LateralCOG(newValue) {
        if (getFuelTank7() !== null) {
            return getFuelTank7().setLateralCog(newValue);
        }
    }
    function getFuelTank7LateralCOGOverride() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getLateralCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank7LateralCOGOverride(newValue) {
        if (getFuelTank7() !== null) {
            return getFuelTank7().setLateralCogOverride(newValue);
        }
    }
    function getFuelTank7Capacity() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getCapacity();
        } else {
            return 0;
        }
    }
    function setFuelTank7Capacity(newValue) {
        if (getFuelTank7() !== null) {
            return getFuelTank7().setCapacity(newValue);
        }
    }




    function getFuelTank8IncludeInCalculation() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getIncludeInCalculation();
        } else {
            return false;
        }
    }
    function setFuelTank8IncludeInCalculation(newValue) {
        if (getFuelTank8() !== null) {
            getFuelTank8().setIncludeInCalculation(newValue);
        }
    }
    function getFuelTank8OfferedAs() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getOfferedAs();
        } else {
            return '';
        }
    }
    function setFuelTank8OfferedAs(newValue) {
        if (getFuelTank8() !== null) {
            getFuelTank8().setOfferedAs(newValue);
        }
    }
    function getFuelTank8COG() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getHorizontalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank8COG(newValue) {
        if (getFuelTank8() !== null) {
            return getFuelTank8().setHorizontalCog(newValue);
        }
    }
    function getFuelTank8COGOverride() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getHorizontalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank8COGOverride(newValue) {
        if (getFuelTank8() !== null) {
            return getFuelTank8().setHorizontalCogOverride(newValue);
        }
    }
    function getFuelTank8VerticalCOG() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getVerticalCog();
        } else {
            return 0;
        }
    }
    function setFuelTank8VerticalCOG(newValue) {
        if (getFuelTank8() !== null) {
            return getFuelTank8().setVerticalCog(newValue);
        }
    }
    function getFuelTank8VerticalCOGOverride() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getVerticalCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank8VerticalCOGOverride(newValue) {
        if (getFuelTank8() !== null) {
            return getFuelTank8().setVerticalCogOverride(newValue);
        }
    }
    function getFuelTank8LateralCOG() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getLateralCog();
        } else {
            return 0;
        }
    }
    function setFuelTank8LateralCOG(newValue) {
        if (getFuelTank8() !== null) {
            return getFuelTank8().setLateralCog(newValue);
        }
    }
    function getFuelTank8LateralCOGOverride() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getLateralCogOverride();
        } else {
            return 0;
        }
    }
    function setFuelTank8LateralCOGOverride(newValue) {
        if (getFuelTank8() !== null) {
            return getFuelTank8().setLateralCogOverride(newValue);
        }
    }
    function getFuelTank8Capacity() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getCapacity();
        } else {
            return 0;
        }
    }
    function setFuelTank8Capacity(newValue) {
        if (getFuelTank8() !== null) {
            return getFuelTank8().setCapacity(newValue);
        }
    }



    function getTareFront() {
        if (_tareFrontOverride === true) {
            return _tareFront;
        } else {
            return _axlesHolder.getFrontAxleWeight();
        }

    }
    function getTareFrontIgnoreOverride() {
        return _tareFront;
    }
    function setTareFront(newValue) {
        _tareFront = newValue;
    }
    function getTareRear() {
        if (_tareRearOverride === true) {
            return _tareRear;
        } else {
            return _axlesHolder.getRearAxleWeight();
        }
    }
    function getTareRearIgnoreOverride() {
        return _tareRear;
    }
    function setTareRear(newValue) {
        _tareRear = newValue;
    }
    function getTareRearRear() {
        if (_tareRearOverride === true) {
            return _tareRearRear;
        } else {
            return _axlesHolder.getRearRearAxleWeight();
        }
    }
    function getTareRearRearIgnoreOverride() {
        return _tareRearOverride;
    }
    function setTareRearRear(newValue) {
        _tareRearRear = newValue;
    }
    function getFuelTank1VolumeIncludedInTare() {
        if (getFuelTank1() !== null) {
            return getFuelTank1().getVolumeIncludedInTare();
        } else {
            return 0;
        }
    }
    function getFuelTank1VolumeIncludedInExtras() {
        if (getFuelTank1() !== null) {
            return getFuelTank1().getVolumeIncludedInExtras();
        } else {
            return 0;
        }
    }
    function setFuelTank1VolumeIncludedInTare(newValue) {
        if (getFuelTank1() !== null) {
            getFuelTank1().setVolumeIncludedInTare(newValue);
        }
    }
    function getFuelTank2VolumeIncludedInTare() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getVolumeIncludedInTare();
        } else {
            return 0;
        }
    }
    function getFuelTank2VolumeIncludedInExtras() {
        if (getFuelTank2() !== null) {
            return getFuelTank2().getVolumeIncludedInExtras();
        } else {
            return 0;
        }
    }
    function setFuelTank2VolumeIncludedInTare(newValue) {
        if (getFuelTank2() !== null) {
            getFuelTank2().setVolumeIncludedInTare(newValue);
        }
    }
    function getFuelTank3VolumeIncludedInTare() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getVolumeIncludedInTare();
        } else {
            return 0;
        }
    }
    function getFuelTank3VolumeIncludedInExtras() {
        if (getFuelTank3() !== null) {
            return getFuelTank3().getVolumeIncludedInExtras();
        } else {
            return 0;
        }
    }
    function setFuelTank3VolumeIncludedInTare(newValue) {
        if (getFuelTank3() !== null) {
            getFuelTank3().setVolumeIncludedInTare(newValue);
        }
    }
    function getFuelTank4VolumeIncludedInTare() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getVolumeIncludedInTare();
        } else {
            return 0;
        }
    }
    function getFuelTank4VolumeIncludedInExtras() {
        if (getFuelTank4() !== null) {
            return getFuelTank4().getVolumeIncludedInExtras();
        } else {
            return 0;
        }
    }
    function setFuelTank4VolumeIncludedInTare(newValue) {
        if (getFuelTank4() !== null) {
            getFuelTank4().setVolumeIncludedInTare(newValue);
        }
    }
    function getFuelTank5VolumeIncludedInTare() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getVolumeIncludedInTare();
        } else {
            return 0;
        }
    }
    function getFuelTank5VolumeIncludedInExtras() {
        if (getFuelTank5() !== null) {
            return getFuelTank5().getVolumeIncludedInExtras();
        } else {
            return 0;
        }
    }
    function setFuelTank5VolumeIncludedInTare(newValue) {
        if (getFuelTank5() !== null) {
            getFuelTank5().setVolumeIncludedInTare(newValue);
        }
    }
    function getFuelTank6VolumeIncludedInTare() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getVolumeIncludedInTare();
        } else {
            return 0;
        }
    }
    function getFuelTank6VolumeIncludedInExtras() {
        if (getFuelTank6() !== null) {
            return getFuelTank6().getVolumeIncludedInExtras();
        } else {
            return 0;
        }
    }
    function setFuelTank6VolumeIncludedInTare(newValue) {
        if (getFuelTank6() !== null) {
            getFuelTank6().setVolumeIncludedInTare(newValue);
        }
    }
    function getFuelTank7VolumeIncludedInTare() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getVolumeIncludedInTare();
        } else {
            return 0;
        }
    }
    function getFuelTank7VolumeIncludedInExtras() {
        if (getFuelTank7() !== null) {
            return getFuelTank7().getVolumeIncludedInExtras();
        } else {
            return 0;
        }
    }
    function setFuelTank7VolumeIncludedInTare(newValue) {
        if (getFuelTank7() !== null) {
            getFuelTank7().setVolumeIncludedInTare(newValue);
        }
    }
    function getFuelTank8VolumeIncludedInTare() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getVolumeIncludedInTare();
        } else {
            return 0;
        }
    }
    function getFuelTank8VolumeIncludedInExtras() {
        if (getFuelTank8() !== null) {
            return getFuelTank8().getVolumeIncludedInExtras();
        } else {
            return 0;
        }
    }
    function setFuelTank8VolumeIncludedInTare(newValue) {
        if (getFuelTank8() !== null) {
            getFuelTank8().setVolumeIncludedInTare(newValue);
        }
    }
    //function getBullbarProtrusion() {
    //    return _bullbarProtrusion;
    //}
    //function setBullbarProtrusion(newValue) {
    //    _bullbarProtrusion = newValue;
    //}

    function getWheelbaseTheoreticalManufacturerMin() {
        return _wheelbaseTheoreticalManufacturerMin;
    }
    function setWheelbaseTheoreticalManufacturerMin(newValue) {
        _wheelbaseTheoreticalManufacturerMin = newValue;
    }
    function getWheelbaseTheoreticalManufacturerMax() {
        return _wheelbaseTheoreticalManufacturerMax;
    }
    function setWheelbaseTheoreticalManufacturerMax(newValue) {
        _wheelbaseTheoreticalManufacturerMax = newValue;
    }
    function getOriginalAUFront() {
        return _originalAUFront;
    }
    function setOriginalAUFront(newValue) {
        _originalAUFront = newValue;
    }
    function getOriginalAURear() {
        return _originalAURear;
    }
    function setOriginalAURear(newValue) {
        _originalAURear = newValue;
    }
    function getGVM() {
        return _gvm;
    }
    function setGVM(newValue) {
        _gvm = newValue;
    }
    function getGCM() {
        return _gcm;
    }
    function setGCM(newValue) {
        _gcm = newValue;
    }
    function getVRating() {
        return _vRating;
    }
    function setVRating(newValue) {
        _vRating = newValue;
    }
    function getDT() {
        return _dt;
    }
    function setDT(newValue) {
        _dt = newValue;
    }
    function getFrontRoadFriendlySuspension() {
        return _frontRoadFriendlySuspension;
    }
    function setFrontRoadFriendlySuspension(newValue) {
        _frontRoadFriendlySuspension = newValue;
    }
    function getRearRoadFriendlySuspension() {
        return _rearRoadFriendlySuspension;
    }
    function setRearRoadFriendlySuspension(newValue) {
        _rearRoadFriendlySuspension = newValue;
    }
    function getRolloverProtection() {
        return _rolloverProtection;
    }
    function setRolloverProtection(newValue) {
        _rolloverProtection = newValue;
    }
    function getTurningAbilitySpecifiedAs() {
        return _turningAbilitySpecifiedAs;
    }
    function setTurningAbilitySpecifiedAs(newValue) {
        _turningAbilitySpecifiedAs = newValue;
    }
    function getSteeringAngleInner() {
        return _steeringAngleInner;
    }
    function setSteeringAngleInner(newValue) {
        _steeringAngleInner = newValue;
    }
    function getSteeringAngleInnerOverride() {
        return _steeringAngleInnerOverride;
    }
    function setSteeringAngleInnerOverride(newValue) {
        _steeringAngleInnerOverride = newValue;
    }
    function getSteeringAngleInnerCalculated() {
        return _steeringAngleInnerCalculated;
    }
    function setSteeringAngleInnerCalculated(newValue) {
        //if (_steeringAngleInnerOverride === false && _turningAbilitySpecifiedAs !== config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
        _steeringAngleInner = newValue;
        //}
        _steeringAngleInnerCalculated = newValue;
    }
    function getSteeringAngleAxle1RearInnerCalculated() {
        return _steeringAngleAxle1RearInnerCalculated;
    }
    function setSteeringAngleAxle1RearInnerCalculated(newValue) {
        
        _steeringAngleAxle1RearInnerCalculated = newValue;
    }
    function getSteeringAngleAxle2RearInnerCalculated() {
        return _steeringAngleAxle2RearInnerCalculated;
    }
    function setSteeringAngleAxle2RearInnerCalculated(newValue) {
        
        _steeringAngleAxle2RearInnerCalculated = newValue;
    }
    function getSteeringAngleAxle3RearInnerCalculated() {
        return _steeringAngleAxle3RearInnerCalculated;
    }
    function setSteeringAngleAxle3RearInnerCalculated(newValue) {
        
        _steeringAngleAxle3RearInnerCalculated = newValue;
    }
    function getSteeringAngleAxle4RearInnerCalculated() {
        return _steeringAngleAxle4RearInnerCalculated;
    }
    function setSteeringAngleAxle4RearInnerCalculated(newValue) {
        
        _steeringAngleAxle4RearInnerCalculated = newValue;
    }
    function getSteeringAngleOuter() {
        return _steeringAngleOuter;
    }
    function getTurningRadiusWallToWall() {
        return _turningRadiusWallToWall;
    }
    function setTurningRadiusWallToWall(newValue) {
        _turningRadiusWallToWall = newValue;
    }
    function getTurningRadiusWallToWallOverride() {
        return _turningRadiusWallToWallOverride;
    }
    function setTurningRadiusWallToWallOverride(newValue) {
        _turningRadiusWallToWallOverride = newValue;
    }
    function getTurningRadiusWallToWallCalculated() {
        return _turningRadiusWallToWallCalculated;
    }
    function setTurningRadiusWallToWallCalculated(newValue) {
        //if (_turningRadiusWallToWallOverride === false && _turningAbilitySpecifiedAs !== config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
        _turningRadiusWallToWall = newValue;
        //}
        _turningRadiusWallToWallCalculated = newValue;
    }
    function getTurningRadiusCurbToCurb() {
        return _turningRadiusCurbToCurb;
    }
    function setTurningRadiusCurbToCurb(newValue) {
        _turningRadiusCurbToCurb = newValue;
    }
    function getTurningRadiusCurbToCurbOverride() {
        return _turningRadiusCurbToCurbOverride;
    }
    function setTurningRadiusCurbToCurbOverride(newValue) {
        _turningRadiusCurbToCurbOverride = newValue;
    }
    function getTurningRadiusCurbToCurbCalculated() {
        return _turningRadiusCurbToCurbCalculated;
    }
    function setTurningRadiusCurbToCurbCalculated(newValue) {
        //if (_turningRadiusCurbToCurbOverride === false && _turningAbilitySpecifiedAs !== config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
        _turningRadiusCurbToCurb = newValue;
        //}
        _turningRadiusCurbToCurbCalculated = newValue;
    }
    function getWheelbaseTheoreticalMaxCut() {
        return _wheelbaseTheoreticalMaxCut;
    }
    function setWheelbaseTheoreticalMaxCut(newValue) {
        _wheelbaseTheoreticalMaxCut = newValue;
    }
    function getRearOverhangManufacturerMin() {
        return _rearOverhangManufacturerMin;
    }
    function setRearOverhangManufacturerMin(newValue) {
        _rearOverhangManufacturerMin = newValue;
    }
    function getFrontUnderrunProtection() {
        return _frontUnderrunProtection;
    }
    function setFrontUnderrunProtection(newValue) {
        _frontUnderrunProtection = newValue;
    }
    function getDevicesForIndirectVision() {
        return _devicesForIndirectVision;
    }
    function setDevicesForIndirectVision(newValue) {
        _devicesForIndirectVision = newValue;
    }
    function getCommercialVehicleBrakeSystems() {
        return _commercialVehicleBrakeSystems;
    }
    function setCommercialVehicleBrakeSystems(newValue) {
        _commercialVehicleBrakeSystems = newValue;
    }
    function getAdvancedEmergencyBraking() {
        return _advancedEmergencyBraking;
    }
    function setAdvancedEmergencyBraking(newValue) {
        _advancedEmergencyBraking = newValue;
    }
    
    function getLaneDepartureWarning() {
        return _laneDepartureWarning;
    }
    function setLaneDepartureWarning(newValue) {
        _laneDepartureWarning = newValue;
    }
    function getSideUnderrunProtection() {
        return _sideUnderrunProtection;
    }
    function setSideUnderrunProtection(newValue) {
        _sideUnderrunProtection = newValue;
    }
    function getConspicuityMarkingsToTheRear() {
        return _conspicuityMarkingsToTheRear;
    }
    function setConspicuityMarkingsToTheRear(newValue) {
        _conspicuityMarkingsToTheRear = newValue;
    }
    function getConspicuityMarkingsToBothSides() {
        return _conspicuityMarkingsToBothSides;
    }
    function setConspicuityMarkingsToBothSides(newValue) {
        _conspicuityMarkingsToBothSides = newValue;
    }
    function getBlindSpotInformationSystems() {
        return _blindSpotInformationSystems;
    }
    function setBlindSpotInformationSystems(newValue) {
        _blindSpotInformationSystems = newValue;
    }
    function getEngineMaximumTorqueNm() {
        return _engineMaximumTorqueNm;
    }
    function setEngineMaximumTorqueNm(newValue) {
        _engineMaximumTorqueNm = newValue;
    }
    function getEngineMaximumTorqueLbFt() {
        return _engineMaximumTorqueLbFt;
    }
    function setEngineMaximumTorqueLbFt(newValue) {
        _engineMaximumTorqueLbFt = newValue;
    }
    function getTransmissionFirstGearRatio() {
        return _transmissionFirstGearRatio;
    }
    function setTransmissionFirstGearRatio(newValue) {
        _transmissionFirstGearRatio = newValue;
    }
    function getTransmissionReverseGearRatio() {
        return _transmissionReverseGearRatio;
    }
    function setTransmissionReverseGearRatio(newValue) {
        _transmissionReverseGearRatio = newValue;
    }
    function getTransmissionTorqueConverterMultiplication() {
        return _transmissionTorqueConverterMultiplication;
    }
    function setTransmissionTorqueConverterMultiplication(newValue) {
        _transmissionTorqueConverterMultiplication = newValue;
    }
    function getTransmissionParkPAWL() {
        return _transmissionParkPAWL;
    }
    function setTransmissionParkPAWL(newValue) {
        _transmissionParkPAWL = newValue;
    }
    function getEmissionsStandard() {
        return _emissionsStandard;
    }
    function setEmissionsStandard(newValue) {
        _emissionsStandard = newValue;
    }

    function getBullbarProtrusion() {
        //return _bullbarProtrusion;
        var bullbar = _accessoryHolder.getBullbar();
        if (bullbar !== null) {
            return Math.max(-bullbar.getHorizontalGap() - getBumperToBackOfCab(), 0);
        }
        return 0;
        
    }
    function setBullbarProtrusion(newValue) {
        _bullbarProtrusion = newValue;
    }
    function getBullbarAdded() {
        return _bullbarAdded;
    }
    function setBullbarAdded(newValue) {
        _bullbarAdded = newValue;
    }
    function getSnowploughAdded() {
        return _snowploughAdded;
    }
    function setSnowploughAdded(newValue) {
        _snowploughAdded = newValue;
    }
    function getCraneAdded() {
        return _craneAdded;
    }
    function setCraneAdded(newValue) {
        _craneAdded = newValue;
    }
    function getFifthWheelAdded() {
        return _fifthWheelAdded;
    }
    function setFifthWheelAdded(newValue) {
        _fifthWheelAdded = newValue;
    }
    function getRolloverAdded() {
        return _rolloverAdded;
    }
    function setRolloverAdded(newValue) {
        _rolloverAdded = newValue;
    }
    function getCombinationType() {
        //return _combinationType;
        //#unfinished, hardcoding combinationType = VEH for the moment as we are only dealing with Vehicle, will need to be changed when we start with trailers
        return config.VEHICLE_COMBINATION_TYPE.Vehicle;
    }
    function setCombinationType(newValue) {
        _combinationType = newValue;
    }
    function getCabTilt() {
        return _cabTilt;
    }
    function setCabTilt(newValue) {
        _cabTilt = newValue;
    }
    function getCabTiltConverter() {
        return _cabTiltConverter;
    }
    function setCabTiltConverter(newValue) {
        _cabTiltConverter = newValue;
    }
    function getCalculatedCabTiltStartPoint() {
        /*
        If CalculatedCabTiltRadius >= StartOfVehicle + BumperToBackOfCab - CalculatedCabTiltHingePoint.X Then
            Return New Point(StartOfVehicle + BumperToBackOfCab,
                                CalculatedCabTiltHingePoint.Y + Math.Sqrt(Math.Pow(CalculatedCabTiltRadius, 2) - Math.Pow(StartOfVehicle + BumperToBackOfCab - CalculatedCabTiltHingePoint.X, 2)))
        Else
            Return New Point(CalculatedCabTiltHingePoint.X + CalculatedCabTiltRadius, CalculatedCabTiltHingePoint.Y)
        End If
        */
        if (getCalculatedCabTiltRadius() >= getStartOfVehicle() + getBumperToBackOfCab() - getCalculatedCabTiltHingePoint().x) {
            return {
                x: getStartOfVehicle() + getBumperToBackOfCab(),
                y: getCalculatedCabTiltHingePoint().y + Math.sqrt(Math.pow(getCalculatedCabTiltRadius(), 2) - Math.pow(getStartOfVehicle() + getBumperToBackOfCab() - getCalculatedCabTiltHingePoint().x, 2))
            };
        } else {
            return { x: getCalculatedCabTiltHingePoint().x + getCalculatedCabTiltRadius(), y: getCalculatedCabTiltHingePoint().y };
        }
    }
    function getCalculatedCabTiltSweepAngle() {
        /*
        Dim intersectionPoint As Point
        Dim intersectionSweepAngle As Double
        Dim myUtil As New UtilCoordinate

        'calculate sweep angle up to highest point
        If CalculatedCabTiltRadius - (OverallHeightMeasuredFromFront - CalculatedCabTiltHingePoint.Y) < 0 Then
            'there is no intersection point as the accessories are higher than the tilt radius
            intersectionSweepAngle = _CabTiltMaxSweepAngle
        Else
            intersectionPoint.X = CalculatedCabTiltHingePoint.X + Math.Sqrt(Math.Pow(CalculatedCabTiltRadius, 2) - Math.Pow(OverallHeightMeasuredFromFront - CalculatedCabTiltHingePoint.Y, 2))
            intersectionPoint.Y = OverallHeightMeasuredFromFront
            intersectionSweepAngle = myUtil.RadiansToDegrees(Math.Asin((intersectionPoint.Y - CalculatedCabTiltHingePoint.Y) / CalculatedCabTiltRadius)) -
                myUtil.RadiansToDegrees(Math.Asin((CalculatedCabTiltStartPoint.Y - CalculatedCabTiltHingePoint.Y) / CalculatedCabTiltRadius))
        End If

        If intersectionSweepAngle < _CabTiltMaxSweepAngle Then
            If intersectionSweepAngle < _CabTiltMinSweepAngle Then
                Return _CabTiltMinSweepAngle
            Else
                Return intersectionSweepAngle
            End If
        Else
            Return _CabTiltMaxSweepAngle
        End If
        */
        var intersectionPoint = { x: 0, y: 0 }, intersectionSweepAngle = 0;

        if (getCalculatedCabTiltRadius() - (getOverallHeightMeasuredFromFront() - getCalculatedCabTiltHingePoint().y) < 0) {
            //'there is no intersection point as the accessories are higher than the tilt radius
            intersectionSweepAngle = _cabTiltMaxSweepAngle;
        } else {
            intersectionPoint.x = getCalculatedCabTiltHingePoint().x + Math.sqrt(Math.pow(getCalculatedCabTiltRadius(), 2) - Math.pow(getOverallHeightMeasuredFromFront() - getCalculatedCabTiltHingePoint().y, 2));
            intersectionPoint.y = getOverallHeightMeasuredFromFront();
            intersectionSweepAngle = utils.convertRadiansToDegrees(Math.asin((intersectionPoint.y - getCalculatedCabTiltHingePoint().y) / getCalculatedCabTiltRadius())) -
                utils.convertRadiansToDegrees(Math.asin((getCalculatedCabTiltStartPoint().y - getCalculatedCabTiltHingePoint().y) / getCalculatedCabTiltRadius()));
        }

        if (intersectionSweepAngle < _cabTiltMaxSweepAngle) {
            if (intersectionSweepAngle < _cabTiltMinSweepAngle) {
                return _cabTiltMinSweepAngle;
            } else {
                return intersectionSweepAngle;
            }
        } else {
            return _cabTiltMaxSweepAngle;
        }


    }
    function getCalculatedCabTiltHingePoint() {
        /*
            If HingePointX <> 0 Or HingePointY <> 0 Or TiltRadius <> 0 Then
            Return New Point(StartOfVehicle + HingePointX, HingePointY)
        Else
            Return New Point(StartOfVehicle + 150, ChassisHeightFront + 150)
        End If
        */
        if (getHingePoint().x !== 0 || getHingePoint().y !== 0 || getCabTiltRadius() !== 0) {
            return {
                x: getStartOfVehicle() + getHingePoint().x,
                y: getHingePoint().y
            };
        } else {
            return {
                x: getStartOfVehicle() + 150,
                y: _chassisHeightFront + 150
            };
        }
    }
    function getCalculatedCabTiltRadius() {
        /*
        If TiltRadius <> 0 Or HingePointX <> 0 Or HingePointY <> 0 Then
            Return TiltRadius
        Else
            Return Math.Sqrt(Math.Pow(CabHeight - CalculatedCabTiltHingePoint.Y, 2) + Math.Pow(StartOfVehicle + BumperToBackOfCab - CalculatedCabTiltHingePoint.X, 2))
        End If
        */
        if (getCabTiltRadius() !== 0 || getHingePoint().x !== 0 || getHingePoint().y !== 0) {
            return getCabTiltRadius();
        } else {
            return Math.sqrt(Math.pow(getCabHeight() - getCalculatedCabTiltHingePoint().y, 2) + Math.pow(getStartOfVehicle() + getBumperToBackOfCab() - getCalculatedCabTiltHingePoint().x, 2));
        }
    }
    function getOverallHeightMeasuredFromFront() {
        /*
        Dim maxHeight As Long

        maxHeight = Math.Max(FrontalAreaHeight, VerticalExhaustHeight)
        maxHeight = Math.Max(maxHeight, _Accessories.AccessoryHeight(ChassisHeightRear))

        Return maxHeight
        */
        var maxHeight = 0;
        var includeFrontAndRearAdditionInFrontalAreaCalculation = true;
        maxHeight = Math.max(getFrontalAreaHeight(includeFrontAndRearAdditionInFrontalAreaCalculation), getVerticalExhaustHeight());
        maxHeight = Math.max(maxHeight, _accessoryHolder.getAccessoryHeight(_chassisHeightRear));

        return maxHeight;

    }
    function getHingePointFromBumper() {
        return _hingePointFromBumper;
    }
    function setHingePointFromBumper(newValue) {
        _hingePointFromBumper = newValue;
    }
    function getHingePointFromGround() {
        return _hingePointFromGround;
    }
    function getHingePoint() {
        return {
            x: getHingePointFromBumper(),
            y: getHingePointFromGround()
        }
    }
    function setHingePointFromGround(newValue) {
        _hingePointFromGround = newValue;
    }
    function getCabTiltRadius() {
        return _cabTiltRadius;
    }
    function setCabTiltRadius(newValue) {
        _cabTiltRadius = newValue;
    }
    function getWheelbaseTheoreticalOverride() {
        return _wheelbaseTheoreticalOverride;
    }
    function setWheelbaseTheoreticalOverride(newValue) {
        _wheelbaseTheoreticalOverride = newValue;
    }
    function getCabWidth() {
        return _cabWidth;
    }
    function setCabWidth(newValue) {
        _cabWidth = newValue;
    }
    function getCabWidthOverride() {
        return _cabWidthOverride;
    }
    function setCabWidthOverride(newValue) {
        _cabWidthOverride = newValue;
    }
    function getRearWidth() {
        return _rearWidth;
    }
    function setRearWidth(newValue) {
        _rearWidth = newValue;
    }
    function getRearWidthOverride() {
        return _rearWidthOverride;
    }
    function setRearWidthOverride(newValue) {
        _rearWidthOverride = newValue;
    }
    function getOverallWidth() {
        //return _overallWidth;
        
        //var maxAxleWidth = getMaxAxleWidth();

        //var overallWidth = Math.max(getCabWidth(), getRearWidth());
        //return Math.max(overallWidth, maxAxleWidth);
        return Math.max(getCabWidth(), getRearWidth());
    }
    function getMaxAxleWidth() {
        var maxWidth = 0;
        _axlesHolder.getAxles().forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                if (axle.getNoOfTyresOnAxle() === 2) {
                    maxWidth = Math.max(maxWidth, getTrackWidthRear() + axle.getTyreWidth());
                } else {
                    maxWidth = Math.max(maxWidth, getTrackWidthRear() + ((axle.getTyreWidth() + 15) * 2));
                }
            }
        });

        return maxWidth;
    }
    //function setOverallWidth(newValue) {
    //    _overallWidth = Math.round(newValue);
    //}        
    function getTrackWidthFront() {
        return _trackWidthFront;
    }
    function setTrackWidthFront(newValue) {
        _trackWidthFront = newValue;
    }
    function getTrackWidthFrontOverride() {
        return _trackWidthFrontOverride;
    }
    function setTrackWidthFrontOverride(newValue) {
        _trackWidthFrontOverride = newValue;
    }
    function getTrackWidthRear() {
        return _trackWidthRear;
    }
    function setTrackWidthRear(newValue) {
        _trackWidthRear = newValue;
    }
    function getTrackWidthRearOverride() {
        return _trackWidthRearOverride;
    }
    function setTrackWidthRearOverride(newValue) {
        _trackWidthRearOverride = newValue;
    }
    function getChassisWidth() {
        return _chassisWidth;
    }
    function setChassisWidth(newValue) {
        _chassisWidth = newValue;
    }
    function getChassisWidthOverride() {
        return _chassisWidthOverride;
    }
    function setChassisWidthOverride(newValue) {
        _chassisWidthOverride = newValue;
    }
    function getVehicleVerticalCOGConfirmed() {
        return _vehicleVerticalCOGConfirmed;
    }
    function setVehicleVerticalCOGConfirmed(newValue) {
        _vehicleVerticalCOGConfirmed = newValue;
    }
    function getVehicleLateralCOGConfirmed() {
        return _vehicleLateralCOGConfirmed;
    }
    function setVehicleLateralCOGConfirmed(newValue) {
        _vehicleLateralCOGConfirmed = newValue;
    }
    function getPayloadVerticalCOGConfirmed() {
        return _payloadVerticalCOGConfirmed;
    }
    function setPayloadVerticalCOGConfirmed(newValue) {
        _payloadVerticalCOGConfirmed = newValue;
    }
    function getPayloadLateralCOGConfirmed() {
        return _payloadLateralCOGConfirmed;
    }
    function setPayloadLateralCOGConfirmed(newValue) {
        _payloadLateralCOGConfirmed = newValue;
    }
    function getFuelTank1VerticalCOGConfirmed() {
        return _fuelTank1VerticalCOGConfirmed;
    }
    function setFuelTank1VerticalCOGConfirmed(newValue) {
        _fuelTank1VerticalCOGConfirmed = newValue;
    }
    function getFuelTank2VerticalCOGConfirmed() {
        return _fuelTank2VerticalCOGConfirmed;
    }
    function setFuelTank2VerticalCOGConfirmed(newValue) {
        _fuelTank2VerticalCOGConfirmed = newValue;
    }
    function getFuelTank3VerticalCOGConfirmed() {
        return _fuelTank3VerticalCOGConfirmed;
    }
    function setFuelTank3VerticalCOGConfirmed(newValue) {
        _fuelTank3VerticalCOGConfirmed = newValue;
    }
    function getFuelTank4VerticalCOGConfirmed() {
        return _fuelTank4VerticalCOGConfirmed;
    }
    function setFuelTank4VerticalCOGConfirmed(newValue) {
        _fuelTank4VerticalCOGConfirmed = newValue;
    }
    function getFuelTank5VerticalCOGConfirmed() {
        return _fuelTank5VerticalCOGConfirmed;
    }
    function setFuelTank5VerticalCOGConfirmed(newValue) {
        _fuelTank5VerticalCOGConfirmed = newValue;
    }
    function getFuelTank6VerticalCOGConfirmed() {
        return _fuelTank6VerticalCOGConfirmed;
    }
    function setFuelTank6VerticalCOGConfirmed(newValue) {
        _fuelTank6VerticalCOGConfirmed = newValue;
    }
    function getFuelTank7VerticalCOGConfirmed() {
        return _fuelTank7VerticalCOGConfirmed;
    }
    function setFuelTank7VerticalCOGConfirmed(newValue) {
        _fuelTank7VerticalCOGConfirmed = newValue;
    }
    function getFuelTank8VerticalCOGConfirmed() {
        return _fuelTank8VerticalCOGConfirmed;
    }
    function setFuelTank8VerticalCOGConfirmed(newValue) {
        _fuelTank8VerticalCOGConfirmed = newValue;
    }
    function getCrewFirstRowVerticalCOGConfirmed() {
        return _crewFirstRowVerticalCOGConfirmed;
    }
    function setCrewFirstRowVerticalCOGConfirmed(newValue) {
        _crewFirstRowVerticalCOGConfirmed = newValue;
    }
    function getCrewSecondRowVerticalCOGConfirmed() {
        return _crewSecondRowVerticalCOGConfirmed;
    }
    function setCrewSecondRowVerticalCOGConfirmed(newValue) {
        _crewSecondRowVerticalCOGConfirmed = newValue;
    }
    function getFuelTank1LateralCOGConfirmed() {
        return _fuelTank1LateralCOGConfirmed;
    }
    function setFuelTank1LateralCOGConfirmed(newValue) {
        _fuelTank1LateralCOGConfirmed = newValue;
    }
    function getFuelTank2LateralCOGConfirmed() {
        return _fuelTank2LateralCOGConfirmed;
    }
    function setFuelTank2LateralCOGConfirmed(newValue) {
        _fuelTank2LateralCOGConfirmed = newValue;
    }
    function getFuelTank3LateralCOGConfirmed() {
        return _fuelTank3LateralCOGConfirmed;
    }
    function setFuelTank3LateralCOGConfirmed(newValue) {
        _fuelTank3LateralCOGConfirmed = newValue;
    }
    function getFuelTank4LateralCOGConfirmed() {
        return _fuelTank4LateralCOGConfirmed;
    }
    function setFuelTank4LateralCOGConfirmed(newValue) {
        _fuelTank4LateralCOGConfirmed = newValue;
    }
    function getFuelTank5LateralCOGConfirmed() {
        return _fuelTank5LateralCOGConfirmed;
    }
    function setFuelTank5LateralCOGConfirmed(newValue) {
        _fuelTank5LateralCOGConfirmed = newValue;
    }
    function getFuelTank6LateralCOGConfirmed() {
        return _fuelTank6LateralCOGConfirmed;
    }
    function setFuelTank6LateralCOGConfirmed(newValue) {
        _fuelTank6LateralCOGConfirmed = newValue;
    }
    function getFuelTank7LateralCOGConfirmed() {
        return _fuelTank7LateralCOGConfirmed;
    }
    function setFuelTank7LateralCOGConfirmed(newValue) {
        _fuelTank7LateralCOGConfirmed = newValue;
    }
    function getFuelTank8LateralCOGConfirmed() {
        return _fuelTank8LateralCOGConfirmed;
    }
    function setFuelTank8LateralCOGConfirmed(newValue) {
        _fuelTank8LateralCOGConfirmed = newValue;
    }
    function getCrewFirstRowLateralCOGConfirmed() {
        return _crewFirstRowLateralCOGConfirmed;
    }
    function setCrewFirstRowLateralCOGConfirmed(newValue) {
        _crewFirstRowLateralCOGConfirmed = newValue;
    }
    function getCrewSecondRowLateralCOGConfirmed() {
        return _crewSecondRowLateralCOGConfirmed;
    }
    function setCrewSecondRowLateralCOGConfirmed(newValue) {
        _crewSecondRowLateralCOGConfirmed = newValue;
    }
    function getTareDriverIncluded() {
        return _tareDriverIncluded;
    }
    function setTareDriverIncluded(newValue) {
        _tareDriverIncluded = newValue;
    }
    function getFifthWheelOffsetMin() {
        return _fifthWheelOffsetMin;
    }
    function setFifthWheelOffsetMin(newValue) {
        _fifthWheelOffsetMin = newValue;
    }
    function getFifthWheelOffsetMax() {
        return _fifthWheelOffsetMax;
    }
    function setFifthWheelOffsetMax(newValue) {
        _fifthWheelOffsetMax = newValue;
    }
    function getFrontFuelTank1() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //}else {
        return utils.getTurningMomentsFront(getMassFuelTank1(), getDistanceFromWheelbaseTheoreticalEnd(getFuelTank1COG()), getWheelbaseTheoretical());
        //}
    }
    function getFrontFuelTank2() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return utils.getTurningMomentsFront(getMassFuelTank2(), getDistanceFromWheelbaseTheoreticalEnd(getFuelTank2COG()), getWheelbaseTheoretical());
        //}
    }
    function getFrontFuelTank3() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return utils.getTurningMomentsFront(getMassFuelTank3(), getDistanceFromWheelbaseTheoreticalEnd(getFuelTank3COG()), getWheelbaseTheoretical());
        //}
    }
    function getFrontFuelTank4() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return utils.getTurningMomentsFront(getMassFuelTank4(), getDistanceFromWheelbaseTheoreticalEnd(getFuelTank4COG()), getWheelbaseTheoretical());
        //}
    }
    function getFrontFuelTank5() {
        return utils.getTurningMomentsFront(getMassFuelTank5(), getDistanceFromWheelbaseTheoreticalEnd(getFuelTank5COG()), getWheelbaseTheoretical());
    }
    function getFrontFuelTank6() {
        return utils.getTurningMomentsFront(getMassFuelTank6(), getDistanceFromWheelbaseTheoreticalEnd(getFuelTank6COG()), getWheelbaseTheoretical());
    }
    function getFrontFuelTank7() {
        return utils.getTurningMomentsFront(getMassFuelTank7(), getDistanceFromWheelbaseTheoreticalEnd(getFuelTank7COG()), getWheelbaseTheoretical());
    }
    function getFrontFuelTank8() {
        return utils.getTurningMomentsFront(getMassFuelTank8(), getDistanceFromWheelbaseTheoreticalEnd(getFuelTank8COG()), getWheelbaseTheoretical());
    }
    function getFuelIncludedInUnladen() {
        //Dim total As Double

        //For Each fuelTank As FileLoadingVehicleFuelTank In _FuelTanks
        //If fuelTank.Included Then
        //total += fuelTank.VolumeIncludedInTare + fuelTank.VolumeIncludedInExtras
        //End If
        //Next

        //Return total
        var total = 0;
        _fuelTanks.forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true && fuelTank.getContents() !== config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                total += fuelTank.getVolumeIncludedInTare() + fuelTank.getVolumeIncludedInExtras();
            }
        });
        return total;
    }
    function getFuelExcludedInUnladen() {
        //Dim total As Double

        //For Each fuelTank As FileLoadingVehicleFuelTank In _FuelTanks
        //If fuelTank.Included Then
        //total += (fuelTank.Capacity - fuelTank.VolumeIncludedInTare - fuelTank.VolumeIncludedInExtras)
        //End If
        //Next

        //Return total
        var total = 0;
        _fuelTanks.forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true && fuelTank.getContents() !== config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                total += fuelTank.getCapacity() - fuelTank.getVolumeIncludedInTare() - fuelTank.getVolumeIncludedInExtras();
            }
        });
        return total;
    }
    function getFuelOnlyIncludedInChassis() {
        //Dim total As Double

        //For Each fuelTank As FileLoadingVehicleFuelTank In _FuelTanks
        //If fuelTank.Included Then
        //total += fuelTank.VolumeIncludedInTare + fuelTank.VolumeIncludedInExtras
        //End If
        //Next

        //Return total
        var total = 0;
        _fuelTanks.forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true && fuelTank.getContents() !== config.FUEL_TYPE_OPTIONS.ADBLUE.CODE && fuelTank.getContents() !== config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                total += fuelTank.getVolumeIncludedInTare();
            }
        });
        return total;
    }
    function getFuelOnlyIncludedInExtras() {
        //Dim total As Double

        //For Each fuelTank As FileLoadingVehicleFuelTank In _FuelTanks
        //If fuelTank.Included Then
        //total += fuelTank.VolumeIncludedInTare + fuelTank.VolumeIncludedInExtras
        //End If
        //Next

        //Return total
        var total = 0;
        _fuelTanks.forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true && fuelTank.getContents() !== config.FUEL_TYPE_OPTIONS.ADBLUE.CODE && fuelTank.getContents() !== config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                total += fuelTank.getVolumeIncludedInExtras();
            }
        });
        return total;
    }
    function getFuelOnlyExcludedInUnladen() {
        //Dim total As Double

        //For Each fuelTank As FileLoadingVehicleFuelTank In _FuelTanks
        //If fuelTank.Included Then
        //total += (fuelTank.Capacity - fuelTank.VolumeIncludedInTare - fuelTank.VolumeIncludedInExtras)
        //End If
        //Next

        //Return total
        var total = 0;
        _fuelTanks.forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true && fuelTank.getContents() !== config.FUEL_TYPE_OPTIONS.ADBLUE.CODE && fuelTank.getContents() !== config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                total += fuelTank.getCapacity() - fuelTank.getVolumeIncludedInTare() - fuelTank.getVolumeIncludedInExtras();
            }
        });
        return total;
    }
    function getAdBlueIncludedInChassis() {
        //Dim total As Double

        //For Each fuelTank As FileLoadingVehicleFuelTank In _FuelTanks
        //If fuelTank.Included Then
        //total += fuelTank.VolumeIncludedInTare + fuelTank.VolumeIncludedInExtras
        //End If
        //Next

        //Return total
        var total = 0;
        _fuelTanks.forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true && fuelTank.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                total += fuelTank.getVolumeIncludedInTare();
            }
        });
        return total;
    }
    function getAdBlueIncludedInExtras() {
        //Dim total As Double

        //For Each fuelTank As FileLoadingVehicleFuelTank In _FuelTanks
        //If fuelTank.Included Then
        //total += fuelTank.VolumeIncludedInTare + fuelTank.VolumeIncludedInExtras
        //End If
        //Next

        //Return total
        var total = 0;
        _fuelTanks.forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true && fuelTank.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                total += fuelTank.getVolumeIncludedInExtras();
            }
        });
        return total;
    }
    function getAdBlueExcludedInUnladen() {
        //Dim total As Double

        //For Each fuelTank As FileLoadingVehicleFuelTank In _FuelTanks
        //If fuelTank.Included Then
        //total += (fuelTank.Capacity - fuelTank.VolumeIncludedInTare - fuelTank.VolumeIncludedInExtras)
        //End If
        //Next

        //Return total
        var total = 0;
        _fuelTanks.forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true && fuelTank.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                total += fuelTank.getCapacity() - fuelTank.getVolumeIncludedInTare() - fuelTank.getVolumeIncludedInExtras();
            }
        });
        return total;
    }
    function getFrontCrewFirst() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return utils.getTurningMomentsFront(getMassCrewFirst(), getDistanceFromWheelbaseTheoreticalEnd(getCrewFirstRowCOG()), getWheelbaseTheoretical());
        //}
    }
    function getFrontCrewSecond() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        if (_crewSecondRow === true) {
            return utils.getTurningMomentsFront(getMassCrewSecond(), getDistanceFromWheelbaseTheoreticalEnd(getCrewSecondRowCOG()), getWheelbaseTheoretical());
        } else {
            return 0;
        }

        //}
    }
    function getFrontExtrasTotal() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return getFrontFuelTank1() + getFrontFuelTank2() + getFrontFuelTank3() + getFrontFuelTank4() + getFrontFuelTank5() + getFrontFuelTank6() + getFrontFuelTank7() + getFrontFuelTank8() + getFrontCrewFirst() + getFrontCrewSecond();
        //}
    }
    function getWeightIncludedInFuelAndCrew(tankNumber) {
        switch(tankNumber) {
            case 1:
                return getMassFuelTank1();
            
            case 2:
                return getMassFuelTank2();
            
            case 3:
                return getMassFuelTank3();
            
            case 4:
                return getMassFuelTank4();
            
            case 5:
                return getMassFuelTank5();
            
            case 6:
                return getMassFuelTank6();
            
            case 7:
                return getMassFuelTank7();
            
            case 8:
                return getMassFuelTank8();
            
        }
        return 0;
    }
    function getMassFuelTank1() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        if (getFuelTank1() !== null && getFuelTank1().getIncludeInCalculation() === true) {
            // return getFuelSpecificGravity() * (getFuelTank1Capacity() - getFuelTank1VolumeIncludedInTare());
            return getFuelSpecificGravity(getFuelTank1()) * getFuelTank1VolumeIncludedInExtras();
        } else {
            return 0;
        }

        //}
    }

    function getMassFuelTank2() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        if (getFuelTank2() !== null && getFuelTank2().getIncludeInCalculation() === true) {
            // return getFuelSpecificGravity() * (getFuelTank2Capacity() - getFuelTank2VolumeIncludedInTare());
            return getFuelSpecificGravity(getFuelTank2()) * getFuelTank2VolumeIncludedInExtras();
        } else {
            return 0;
        }
        //}
    }

    function getMassFuelTank3() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        if (getFuelTank3() !== null && getFuelTank3().getIncludeInCalculation() === true) {
            // return getFuelSpecificGravity() * (getFuelTank3Capacity() - getFuelTank3VolumeIncludedInTare());
            return getFuelSpecificGravity(getFuelTank3()) * getFuelTank3VolumeIncludedInExtras();
        } else {
            return 0;
        }
        //}
    }

    function getMassFuelTank4() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        if (getFuelTank4() !== null && getFuelTank4().getIncludeInCalculation() === true) {
            return getFuelSpecificGravity(getFuelTank4()) * getFuelTank4VolumeIncludedInExtras();
        } else {
            return 0;
        }
        //}
    }

    function getMassFuelTank5() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        if (getFuelTank5() !== null && getFuelTank5().getIncludeInCalculation() === true) {
            return getFuelSpecificGravity(getFuelTank5()) * getFuelTank5VolumeIncludedInExtras();
        } else {
            return 0;
        }
        //}
    }

    function getMassFuelTank6() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        if (getFuelTank6() !== null && getFuelTank6().getIncludeInCalculation() === true) {
            return getFuelSpecificGravity(getFuelTank6()) * getFuelTank6VolumeIncludedInExtras();
        } else {
            return 0;
        }
        //}
    }

    function getMassFuelTank7() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        if (getFuelTank7() !== null && getFuelTank7().getIncludeInCalculation() === true) {
            return getFuelSpecificGravity(getFuelTank7()) * getFuelTank7VolumeIncludedInExtras();
        } else {
            return 0;
        }
        //}
    }

    function getMassFuelTank8() {

        if (getFuelTank8() !== null && getFuelTank8().getIncludeInCalculation() === true) {
            return getFuelSpecificGravity(getFuelTank8()) * getFuelTank8VolumeIncludedInExtras();
        } else {
            return 0;
        }
        //}
    }

    function getMassCrewFirst() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        if (_tareDriverIncluded === config.TARE_DRIVER_INCLUDED_OPTIONS.YES) {
            return getCrewMassPerPerson() * (getNoOfCrewInFirstRow() - 1);
        } else {
            return getCrewMassPerPerson() * getNoOfCrewInFirstRow();
        }

        //}
    }

    function getMassCrewSecond() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return getCrewMassPerPerson() * getNoOfCrewInSecondRow();
        //}
    }

    function getExtrasTotal() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return getMassFuelTank1() + getMassFuelTank2() + getMassFuelTank3() + getMassFuelTank4() + getMassFuelTank5() + getMassFuelTank6() + getMassFuelTank7() + getMassFuelTank8() + getMassCrewFirst() + getMassCrewSecond();
        //}
    }
    function getRearFuelTank(fuelTank) {
        var fuelTankMass = 0;
        if (fuelTank !== null && fuelTank.getIncludeInCalculation() === true) {
            fuelTankMass =  getFuelSpecificGravity() * (fuelTank.getCapacity() - fuelTank.getVolumeIncludedInTare());
        } else {
            fuelTankMass = 0;
        }

        return fuelTankMass - utils.getTurningMomentsFront(fuelTankMass, getDistanceFromWheelbaseTheoreticalEnd(fuelTank.getHorizontalCog()), getWheelbaseTheoretical());
    }
    function getRearFuelTank1() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return getMassFuelTank1() - getFrontFuelTank1();
        //}
    }

    function getRearFuelTank2() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return getMassFuelTank2() - getFrontFuelTank2();
        //}
    }

    function getRearFuelTank3() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return getMassFuelTank3() - getFrontFuelTank3();
        //}
    }

    function getRearFuelTank4() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return getMassFuelTank4() - getFrontFuelTank4();
        //}
    }

    function getRearFuelTank5() {
        return getMassFuelTank5() - getFrontFuelTank5();
    }

    function getRearFuelTank6() {
        return getMassFuelTank6() - getFrontFuelTank6();
    }

    function getRearFuelTank7() {
        return getMassFuelTank7() - getFrontFuelTank7();
    }

    function getRearFuelTank8() {
        return getMassFuelTank8() - getFrontFuelTank8();
    }

    function getRearCrewFirst() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return getMassCrewFirst() - getFrontCrewFirst();
        //}
    }

    function getRearCrewSecond() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return getMassCrewSecond() - getFrontCrewSecond();
        //}
    }

    function getRearExtrasTotal() {
        //if (_unladenTotal > 0 && getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    return 0;
        //} else {
        return getRearFuelTank1() + getRearFuelTank2() + getRearFuelTank3() + getRearFuelTank4() + getRearFuelTank5() + getRearFuelTank6() + getRearFuelTank7() + getRearFuelTank8() + getRearCrewFirst() + getRearCrewSecond();
        //}
    }

    function getRearOverhangTotal() {
        return getRearOverhang() + getRearProtrusion();
    }

    function getRearOverhangTotalInclAccessories() {
        return getRearOverhangTotal() + getPortionOfOverallLengthOverhangingRear();
    }

    function getRearOverhangTotalInclAccessoriesAndPayload() {
        var rearOverhangTotalInclAccessories = getRearOverhangTotalInclAccessories();



        return Math.max(rearOverhangTotalInclAccessories, getRearOverhangTotal() + ((getFrontOfVehicleToPayloadDeckStart() + _payloadHolder.getMaxLengthOfPayloadObjects()) - getOverallLengthExclAccessories()));
    }

    function getVehicleEffectiveRearOverhang() {
        // RETURN VehicleAxle1FrontToAxle2Front + VehicleRearMostAxleFrontToAxle1Rear + VehicleAxle1RearToAxle2Rear + VehicleAxle2RearToAxle3Rear + VehicleAxle3RearToAxle4Rear + ComVehRearOverhangTotal - ComVehAxle1FrontToWheelbaseLegislationStart - VehicleWheelbaseSpecified
        // return (getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() + getRearOverhangTotal()) - getAxle1FrontToWheelbaseTheoreticalStart() - getWheelbaseSpecified(); 
        return (getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() + getRearOverhangTotal()) - getWheelbaseTurning(); 
    }

    function getStartOfVehicle() {
        /*
        Public ReadOnly Property StartOfVehicle() As Double
            Get

                Dim maxProtrusion As Double = 0

                For Each accessory In Accessories

                    If TypeOf accessory Is FileLoadingAccessoryBullbar Then
                        maxProtrusion = Math.Min(maxProtrusion, -DirectCast(accessory, FileLoadingAccessoryBullbar).Protrusion)
                    ElseIf TypeOf accessory Is FileLoadingAccessoryOther Then
                        maxProtrusion = Math.Min(maxProtrusion, DirectCast(accessory, FileLoadingAccessoryOther).HorizontalGap + BumperToBackOfCab)
                    End If

                Next

                Return -maxProtrusion

            End Get
        End Property
        */


        var maxProtrusion = 0;

        _accessoryHolder.getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BULLBAR) {
                //maxProtrusion = Math.min(maxProtrusion, -accessory.getProtrusion()); //#unfinished, this code needs to be uncommented when we have BULLBAR support and bullbar needs to have getProtrusion() Method
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                maxProtrusion = Math.min(maxProtrusion, accessory.getHorizontalGap() + getBumperToBackOfCab());
            } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.FRIDGE) {
                maxProtrusion = Math.min(maxProtrusion, getBumperToBackOfCab() + _accessoryHolder.getBodyGap() - accessory.getProtrusion() - accessory.getFromFrontOfBody());
            }
        });

        return -maxProtrusion;
    }



    function getAxle1FrontNoOfTyres() {
        if (_axlesHolder.getAxle1Front() !== null) {
            return _axlesHolder.getAxle1Front().getNoOfTyresOnAxle();
        } else {
            return 0;
        }
    }

    function getAxle2FrontNoOfTyres() {
        if (_axlesHolder.getAxle2Front() !== null) {
            return _axlesHolder.getAxle2Front().getNoOfTyresOnAxle();
        } else {
            return 0;
        }
    }

    function getAxle1RearNoOfTyres() {
        if (_axlesHolder.getAxle1Rear() !== null) {
            return _axlesHolder.getAxle1Rear().getNoOfTyresOnAxle();
        } else {
            return 0;
        }
    }
    function getAxle2RearNoOfTyres() {
        if (_axlesHolder.getAxle2Rear() !== null) {
            return _axlesHolder.getAxle2Rear().getNoOfTyresOnAxle();
        } else {
            return 0;
        }
    }
    function getAxle3RearNoOfTyres() {
        if (_axlesHolder.getAxle3Rear() !== null) {
            return _axlesHolder.getAxle3Rear().getNoOfTyresOnAxle();
        } else {
            return 0;
        }
    }
    function getAxle4RearNoOfTyres() {
        if (_axlesHolder.getAxle4Rear() !== null) {
            return _axlesHolder.getAxle4Rear().getNoOfTyresOnAxle();
        } else {
            return 0;
        }
    }
    function getAxle1FrontTyreWidth() {
        if (_axlesHolder.getAxle1Front() !== null) {
            return _axlesHolder.getAxle1Front().getTyreWidth();
        } else {
            return 0;
        }
    }
    function getAxle2FrontTyreWidth() {
        if (_axlesHolder.getAxle2Front() !== null) {
            return _axlesHolder.getAxle2Front().getTyreWidth();
        } else {
            return 0;
        }
    }
    function getAxle1RearTyreWidth() {
        if (_axlesHolder.getAxle1Rear() !== null) {
            return _axlesHolder.getAxle1Rear().getTyreWidth();
        } else {
            return 0;
        }
    }
    function getAxle2RearTyreWidth() {
        if (_axlesHolder.getAxle2Rear() !== null) {
            return _axlesHolder.getAxle2Rear().getTyreWidth();
        } else {
            return 0;
        }
    }
    function getAxle3RearTyreWidth() {
        if (_axlesHolder.getAxle3Rear() !== null) {
            return _axlesHolder.getAxle3Rear().getTyreWidth();
        } else {
            return 0;
        }
    }
    function getAxle4RearTyreWidth() {
        if (_axlesHolder.getAxle4Rear() !== null) {
            return _axlesHolder.getAxle4Rear().getTyreWidth();
        } else {
            return 0;
        }
    }
    function getTareFrontOverride() {
        return _tareFrontOverride;
    }
    function setTareFrontOverride(newValue) {
        _tareFrontOverride = newValue;
    }
    function getTareRearOverride() {
        return _tareRearOverride;
    }
    function setTareRearOverride(newValue) {
        _tareRearOverride = newValue;
    }
    function getCrewSecondRowVerticalCOG() {
        return _crewSecondRowVerticalCOG;
    }
    function setCrewSecondRowVerticalCOG(newValue) {
        _crewSecondRowVerticalCOG = newValue;
    }
    function getCrewSecondRowVerticalCOGOverride() {
        return _crewSecondRowVerticalCOGOverride;
    }
    function setCrewSecondRowVerticalCOGOverride(newValue) {
        _crewSecondRowVerticalCOGOverride = newValue;
    }
    function getCrewSecondRowLateralCOG() {
        return _crewSecondRowLateralCOG;
    }
    function setCrewSecondRowLateralCOG(newValue) {
        _crewSecondRowLateralCOG = newValue;
    }
    function getCrewSecondRowLateralCOGOverride() {
        return _crewSecondRowLateralCOGOverride;
    }
    function setCrewSecondRowLateralCOGOverride(newValue) {
        _crewSecondRowLateralCOGOverride = newValue;
    }
    function getCrewFirstRowVerticalCOG() {
        return _crewFirstRowVerticalCOG;
    }
    function setCrewFirstRowVerticalCOG(newValue) {
        _crewFirstRowVerticalCOG = newValue;
    }
    function getCrewFirstRowVerticalCOGOverride() {
        return _crewFirstRowVerticalCOGOverride;
    }
    function setCrewFirstRowVerticalCOGOverride(newValue) {
        _crewFirstRowVerticalCOGOverride = newValue;
    }
    function getCrewFirstRowLateralCOG() {
        return _crewFirstRowLateralCOG;
    }
    function setCrewFirstRowLateralCOG(newValue) {
        _crewFirstRowLateralCOG = newValue;
    }
    function getCrewFirstRowLateralCOGOverride() {
        return _crewFirstRowLateralCOGOverride;
    }
    function setCrewFirstRowLateralCOGOverride(newValue) {
        _crewFirstRowLateralCOGOverride = newValue;
    }
    function getVehicleVerticalCOG() {
        return _vehicleVerticalCOG;
    }
    function setVehicleVerticalCOG(newValue) {
        _vehicleVerticalCOG = newValue;
    }
    function getVehicleVerticalCOGOverride() {
        return _vehicleVerticalCOGOverride;
    }
    function setVehicleVerticalCOGOverride(newValue) {
        _vehicleVerticalCOGOverride = newValue;
    }
    function getVehicleLateralCOG() {
        return _vehicleLateralCOG;
    }
    function setVehicleLateralCOG(newValue) {
        _vehicleLateralCOG = newValue;
    }
    function getVehicleLateralCOGOverride() {
        return _vehicleLateralCOGOverride;
    }
    function setVehicleLateralCOGOverride(newValue) {
        _vehicleLateralCOGOverride = newValue;
    }
    function getTareToolsIncluded() {
        return _tareToolsIncluded;
    }
    function setTareToolsIncluded(newValue) {
        _tareToolsIncluded = newValue;
    }
    function getTareSpareWheelIncluded() {
        return _tareSpareWheelIncluded;
    }
    function setTareSpareWheelIncluded(newValue) {
        _tareSpareWheelIncluded = newValue;
    }
    function getAUFront() {
        return _aUFront;
    }
    function setAUFront(newValue) {
        _aUFront = newValue;
    }
    function getAURear() {
        return _aURear;
    }
    function setAURear(newValue) {
        _aURear = newValue;
    }
    function getFuelType() {
        return _fuelType;
    }
    function setFuelType(newValue) {
        _fuelType = newValue;
        if (_fuelType === config.FUEL_TYPE_OPTIONS.LNG.CODE) {
           _fuelExpressedAs = _lNGExpressedAs;
        } else if (_fuelType === config.FUEL_TYPE_OPTIONS.CNG.CODE) {
            _fuelExpressedAs = _cNGExpressedAs;
        } else if (_fuelType === config.FUEL_TYPE_OPTIONS.HYDROGEN_FUEL_CELL.CODE) {
            _fuelExpressedAs = _hydrogenExpressedAs;
        }else {
            _fuelExpressedAs = '';
        }
        //if (_fuelType === config.FUEL_TYPE_OPTIONS.DIESEL.CODE) {
        //    _fuelSpecificGravity = config.FUEL_TYPE_OPTIONS.DIESEL.VALUE;
        //} else if (_fuelType === config.FUEL_TYPE_OPTIONS.PETROL.CODE) {
        //    _fuelSpecificGravity = config.FUEL_TYPE_OPTIONS.PETROL.VALUE;
        //} else if (_fuelType === config.FUEL_TYPE_OPTIONS.ETHANOL.CODE) {
        //    _fuelSpecificGravity = config.FUEL_TYPE_OPTIONS.ETHANOL.VALUE;
        //}
    }
    function getBodyWidthManufacturerMax() {
        return _bodyWidthManufacturerMax;
    }
    function setBodyWidthManufacturerMax(newValue) {
        _bodyWidthManufacturerMax = newValue;
    }
    function getManufacturersFrontAxlesMassOverride() {
        return _manufacturersFrontAxlesMassOverride;
    }
    function setManufacturersFrontAxlesMassOverride(newValue) {
        _manufacturersFrontAxlesMassOverride = newValue;
    }
    function getManufacturersRearAxlesMassOverride() {
        return _manufacturersRearAxlesMassOverride;
    }
    function setManufacturersRearAxlesMassOverride(newValue) {
        _manufacturersRearAxlesMassOverride = newValue;
    }
    function getManufacturersGrossVehicleMassOverride() {
        return _manufacturersGrossVehicleMassOverride;
    }
    function setManufacturersGrossVehicleMassOverride(newValue) {
        _manufacturersGrossVehicleMassOverride = newValue;
    }
    function getManufacturersGrossCombinationMassOverride() {
        return _manufacturersGrossCombinationMassOverride;
    }
    function setManufacturersGrossCombinationMassOverride(newValue) {
        _manufacturersGrossCombinationMassOverride = newValue;
    }
    function getPermissibleRearOverride() {
        return _permissibleRearOverride;
    }
    function setPermissibleRearOverride(newValue) {
        _permissibleRearOverride = newValue;
    }
    function getPermissibleFrontOverride() {
        return _permissibleFrontOverride;
    }
    function setPermissibleFrontOverride(newValue) {
        _permissibleFrontOverride = newValue;
    }
    function getPermissibleTotalOverride() {
        return _permissibleTotalOverride;
    }
    function setPermissibleTotalOverride(newValue) {
        _permissibleTotalOverride = newValue;
    }
    function getCrewFirstRowHorizontalCOGOverride() {
        return _crewFirstRowHorizontalCOGOverride;
    }
    function setCrewFirstRowHorizontalCOGOverride(newValue) {
        _crewFirstRowHorizontalCOGOverride = newValue;
    }
    function getCrewSecondRow() {
        return _crewSecondRow;
    }
    function setCrewSecondRow(newValue) {
        _crewSecondRow = newValue;
    }
    function getCrewSecondRowHorizontalCOGOverride() {
        return _crewSecondRowHorizontalCOGOverride;
    }
    function setCrewSecondRowHorizontalCOGOverride(newValue) {
        _crewSecondRowHorizontalCOGOverride = newValue;
    }
    function getDescription() {
        return _vehicleDescription;
    }
    function setDescription(newValue) {
        _vehicleDescription = newValue;
    }
    function getVehicleTypeDistributionCode() {
        return _vehicleTypeDistributionCode;
    }
    function setVehicleTypeDistributionCode(newValue) {
        _vehicleTypeDistributionCode = newValue;
    }

    function getWheelbaseSpecified() {
        return _wheelbaseSpecified;
    }

    function setWheelbaseSpecified(newValue) {
        _wheelbaseSpecified = newValue;
    }

    function getWheelbaseOverride() {
        return _wheelbaseOverride;
    }

    function setWheelbaseOverride(newValue) {
        _wheelbaseOverride = newValue;
    }

    function getFrontAxleAverageTyreLife() {
        return _frontAxleAverageTyreLife;
    }
    function setFrontAxleAverageTyreLife(newValue) {
        _frontAxleAverageTyreLife = newValue;
    }
    function getRearAxleAverageTyreLife() {
        return _rearAxleAverageTyreLife;
    }
    function setRearAxleAverageTyreLife(newValue) {
        _rearAxleAverageTyreLife = newValue;
    }
    function getSecondsteerAverageTyreLife() {
        return _secondsteerAverageTyreLife;
    }
    function setSecondsteerAverageTyreLife(newValue) {
        _secondsteerAverageTyreLife = newValue;
    }
    function getPusherAxleAverageTyreLife() {
        return _pusherAxleAverageTyreLife;
    }
    function setPusherAxleAverageTyreLife(newValue) {
        _pusherAxleAverageTyreLife = newValue;
    }   
    function getTagAxleAverageTyreLife() {
        return _tagAxleAverageTyreLife;
    }
    function setTagAxleAverageTyreLife(newValue) {
        _tagAxleAverageTyreLife = newValue;
    }
    function getTrailerFrontAxleAverageTyreLife() {
        return _trailerFrontAxleAverageTyreLife;
    }
    function setTrailerFrontAxleAverageTyreLife(newValue) {
        _trailerFrontAxleAverageTyreLife = newValue;
    }
    function getTrailerRearAxleAverageTyreLife() {
        return _trailerRearAxleAverageTyreLife;
    }
    function setTrailerRearAxleAverageTyreLife(newValue) {
        _trailerRearAxleAverageTyreLife = newValue;
    }
    function getTyrePriceFirstSet() {
        return _tyrePriceFirstSet;
    }
    function setTyrePriceFirstSet(newValue) {
        _tyrePriceFirstSet = newValue;
    }
    function getTyrePriceRemainingSets() {
        return _tyrePriceRemainingSets;
    }
    function setTyrePriceRemainingSets(newValue) {
        _tyrePriceRemainingSets = newValue;
    }
    function getBridgeFormulaRearPermissible() {
        return _bridgeFormulaRearPermissible;
    }
    function setBridgeFormulaRearPermissible(newValue) {
        _bridgeFormulaRearPermissible = newValue;
    }
    function getBridgeFormulaRearPermissibleOverride() {
        return _bridgeFormulaRearPermissibleOverride;
    }
    function setBridgeFormulaRearPermissibleOverride(newValue) {
        _bridgeFormulaRearPermissibleOverride = newValue;
    }
    function getVehicleBridgeFormulaPermissible() {
        return _vehicleBridgeFormulaPermissible;
    }
    function setVehicleBridgeFormulaPermissible(newValue) {
        _vehicleBridgeFormulaPermissible = newValue;
    }
    function getVehicleBridgeFormulaPermissibleOverride() {
        return _vehicleBridgeFormulaPermissibleOverride;
    }
    function setVehicleBridgeFormulaPermissibleOverride(newValue) {
        _vehicleBridgeFormulaPermissibleOverride = newValue;
    }
    function getCombinationBridgeFormulaPermissible() {
        return _combinationBridgeFormulaPermissible;
    }
    function setCombinationBridgeFormulaPermissible(newValue) {
        _combinationBridgeFormulaPermissible = newValue;
    }
    function getCombinationBridgeFormulaPermissibleOverride() {
        return _combinationBridgeFormulaPermissibleOverride;
    }
    function setCombinationBridgeFormulaPermissibleOverride(newValue) {
        _combinationBridgeFormulaPermissibleOverride = newValue;
    }   
    
    // function getWheelbaseTheoretical(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) {
        
    //     if (_wheelbaseTheoreticalOverride === true || _axlesHolder.getAxles().length === 0) {
    //         return _wheelbaseTheoretical;
    //     } else {
    //         var betweenAxle1RearAndAxle2Rear, betweenAxle2RearAndAxle3Rear, betweenAxle3RearAndAxle4Rear, wheelbaseTheoreticalStartToAxle1Rear;
        
    //         if (_axlesHolder.getAxle1Rear() !== null && _axlesHolder.getAxle2Rear() !== null && (_axlesHolder.getAxle1Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) + _axlesHolder.getAxle2Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) !== 0)) {
    //             betweenAxle1RearAndAxle2Rear = _axlesHolder.getAxle2Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) /
    //                                             (_axlesHolder.getAxle1Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) +
    //                                                 _axlesHolder.getAxle2Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised)) *
    //                                             getAxle1RearToAxle2Rear();
    //         } else {
    //             betweenAxle1RearAndAxle2Rear = 0;
    //         }
        
    //         if (_axlesHolder.getAxle1Rear() !== null && _axlesHolder.getAxle2Rear() !== null && _axlesHolder.getAxle3Rear() !== null && (_axlesHolder.getAxle1Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) + _axlesHolder.getAxle2Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) + _axlesHolder.getAxle3Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) !== 0)) {
    //             betweenAxle2RearAndAxle3Rear = _axlesHolder.getAxle3Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) /
    //                                             (_axlesHolder.getAxle1Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) +
    //                                                 _axlesHolder.getAxle2Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) +
    //                                                 _axlesHolder.getAxle3Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised)) *
    //                                             (getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() - betweenAxle1RearAndAxle2Rear);
    //         } else {
    //             betweenAxle2RearAndAxle3Rear = 0;
    //         }
        
    //         if (_axlesHolder.getAxle1Rear() !== null && _axlesHolder.getAxle2Rear() !== null && _axlesHolder.getAxle3Rear() !== null && _axlesHolder.getAxle4Rear() !== null && (_axlesHolder.getAxle1Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) + _axlesHolder.getAxle2Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) + _axlesHolder.getAxle3Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) + _axlesHolder.getAxle4Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) !== 0)) {
    //             betweenAxle3RearAndAxle4Rear = _axlesHolder.getAxle4Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) /
    //                                             (_axlesHolder.getAxle1Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) +
    //                                                 _axlesHolder.getAxle2Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) +
    //                                                 _axlesHolder.getAxle3Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) +
    //                                                 _axlesHolder.getAxle4Rear().getManufacturerRatingForWheelbaseCalculation(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised)) *
    //                                             (getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() - betweenAxle1RearAndAxle2Rear - betweenAxle2RearAndAxle3Rear);
    //         } else {
    //             betweenAxle3RearAndAxle4Rear = 0;
    //         }
        
    //         wheelbaseTheoreticalStartToAxle1Rear = getAxle1FrontToAxle2Front() - getAxle1FrontToWheelbaseTheoreticalStart() + getAxle2FrontToAxle1Rear();

    //         return wheelbaseTheoreticalStartToAxle1Rear + betweenAxle1RearAndAxle2Rear + betweenAxle2RearAndAxle3Rear + betweenAxle3RearAndAxle4Rear;
    //     }
        
    // }

    function getWheelbaseTheoretical(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) {
        
        
        if (_wheelbaseTheoreticalOverride === true || _axlesHolder.getAxles().length === 0) {
            return _wheelbaseTheoretical;
        } else {

            let axle1Rear = _axlesHolder.getAxle1Rear(), 
                axle2Rear = _axlesHolder.getAxle2Rear(), 
                axle3Rear = _axlesHolder.getAxle3Rear(), 
                axle4Rear = _axlesHolder.getAxle4Rear();

            let axle1RearManufacturerRating, axle2RearManufacturerRating, axle3RearManufacturerRating, axle4RearManufacturerRating;
            
            if(axle1Rear) {
                axle1RearManufacturerRating = _axlesHolder.getManufacturerRatingForWheelbaseCalculation(axle1Rear, raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised);
            }
            if(axle2Rear) {
                axle2RearManufacturerRating = _axlesHolder.getManufacturerRatingForWheelbaseCalculation(axle2Rear, raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised);
            }
            if(axle3Rear) {
                axle3RearManufacturerRating = _axlesHolder.getManufacturerRatingForWheelbaseCalculation(axle3Rear, raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised);
            }
            if(axle4Rear) {
                axle4RearManufacturerRating = _axlesHolder.getManufacturerRatingForWheelbaseCalculation(axle4Rear, raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised);
            }


            var betweenAxle1RearAndAxle2Rear, betweenAxle2RearAndAxle3Rear, betweenAxle3RearAndAxle4Rear, wheelbaseTheoreticalStartToAxle1Rear;
        
            if (axle1Rear && axle2Rear && (axle1RearManufacturerRating + axle2RearManufacturerRating !== 0)) {
                betweenAxle1RearAndAxle2Rear = axle2RearManufacturerRating / (axle1RearManufacturerRating + axle2RearManufacturerRating) * getAxle1RearToAxle2Rear();
            } else {
                betweenAxle1RearAndAxle2Rear = 0;
            }
        
            if (axle1Rear && axle2Rear && axle3Rear && (axle1RearManufacturerRating + axle2RearManufacturerRating + axle3RearManufacturerRating !== 0)) {
                betweenAxle2RearAndAxle3Rear = axle3RearManufacturerRating / (axle1RearManufacturerRating + axle2RearManufacturerRating + axle3RearManufacturerRating) * (getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() - betweenAxle1RearAndAxle2Rear);
            } else {
                betweenAxle2RearAndAxle3Rear = 0;
            }
        
            if (axle1Rear && axle2Rear && axle3Rear && axle4Rear && (axle1RearManufacturerRating + axle2RearManufacturerRating + axle3RearManufacturerRating + axle4RearManufacturerRating)) {
                betweenAxle3RearAndAxle4Rear = axle4RearManufacturerRating / (axle1RearManufacturerRating + axle2RearManufacturerRating + axle3RearManufacturerRating + axle4RearManufacturerRating) * (getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() - betweenAxle1RearAndAxle2Rear - betweenAxle2RearAndAxle3Rear);
            } else {
                betweenAxle3RearAndAxle4Rear = 0;
            }
        
            wheelbaseTheoreticalStartToAxle1Rear = getAxle1FrontToAxle2Front() - getAxle1FrontToWheelbaseTheoreticalStart() + getAxle2FrontToAxle1Rear();

            return wheelbaseTheoreticalStartToAxle1Rear + betweenAxle1RearAndAxle2Rear + betweenAxle2RearAndAxle3Rear + betweenAxle3RearAndAxle4Rear;
        }
        
    }

    function getWheelbaseTheoreticalEndToAxle4Rear() {
        return (getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear()) - getAxle1FrontToWheelbaseTheoreticalStart() - getWheelbaseTheoretical();
    }

    

    function getCabTiltArc() {
        return 0;
    }
    
    function getAxle1FrontToBackOfCab() {
        //if (globals.user.getSpecifyCabDimensionsAsOption() === config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.AC) {
        //    return _axle1FrontToBackOfCab;
        //} else {
        //    return getBumperToBackOfCab() - getFrontOverhang();
        //}
        return getBumperToBackOfCab() - getFrontOverhang();
    }
    function getGAFront() {
        //If _GAFrontOverride Then
        //Return _GAFront
        //Else
        //Return Axles.FrontAxleManufacturerRating
        //End If
        if (_manufacturersFrontAxlesMassOverride === true) {
            return _gAFront;
        } else {
            //return _axlesHolder.getFrontAxleManufacturerRating();
            return _axlesHolder.getLesserOfFrontAxleManufacturerRatings();
        }
    }
    function setGAFront(newValue) {
        _gAFront = newValue;
    }
    function getGARear() {
        //If _GARearOverride Then
        //Return _GARear
        //Else
        //Return Axles.RearAxleManufacturerRating
        //End If
        if (_manufacturersRearAxlesMassOverride === true) {
            return _gARear;
        } else {
            //return _axlesHolder.getRearAxleManufacturerRating();
            return _axlesHolder.getLesserOfRearAxleManufacturerRatings();
        }
    }
    function setGARear(newValue) {
        _gARear = newValue;
    }
    function getLoadCompartmentWidth() {
        return _loadCompartmentWidth;
    }
    function setLoadCompartmentWidth(newValue) {
        _loadCompartmentWidth = newValue;
    }
    // function getOverallLengthExclAccessories() {
    //     return getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() + getRearOverhang() + getRearProtrusion();
    // }

    function getOverallLengthInclAccessories(includeBullbar) {
        return getOverallLengthExclAccessories() + getPortionOfOverallLengthOverhangingFront(includeBullbar) + getPortionOfOverallLengthOverhangingRear();
    }
    function getOverallLengthInclAccessoriesAndPayload(includeBullbar) {
        var maxLength = getOverallLengthInclAccessories(includeBullbar);
        var payloadMaxLength = getPortionOfOverallLengthOverhangingFront(includeBullbar) + getFrontOfVehicleToPayloadDeckStart() + _payloadHolder.getMaxLengthOfPayloadObjects();
        return Math.max(maxLength, payloadMaxLength);
    }
    function getOverallWidthInclAccessories(topviewCentreLine, leftOfVehicleRearWidth, leftOfBodyWidthExternal) {
        //Dim maxWidth As Double

        //maxWidth = _Common.VehicleOverallWidth
        //maxWidth = Math.Max(maxWidth, _Common.VehicleCabWidth)
        //maxWidth = Math.Max(maxWidth, _Accessories.AccessoryWidth)

        //Return maxWidth
        var maxWidth = getOverallWidth();
        // maxWidth = Math.max(maxWidth, getCabWidth());
        maxWidth = Math.max(maxWidth, _accessoryHolder.getAccessoryWidth(topviewCentreLine, leftOfVehicleRearWidth, leftOfBodyWidthExternal));
        return maxWidth;
    }
    function getOverallWidthInclAccessoriesAndPayload(topviewCentreLine, leftOfVehicleRearWidth, leftOfBodyWidthExternal) {
        //Dim maxWidth As Double

        //maxWidth = _Common.VehicleOverallWidth
        //maxWidth = Math.Max(maxWidth, _Common.VehicleCabWidth)
        //maxWidth = Math.Max(maxWidth, _Accessories.AccessoryWidth)
        let tempBody = _accessoryHolder.getBody();
        let sideWallLeftThickness = 0;
        if(tempBody !== null) {
            sideWallLeftThickness = tempBody.getSidewallLeft();
        }
        //Return maxWidth
        var maxWidth = getOverallWidth();
        // maxWidth = Math.max(maxWidth, getCabWidth());
        maxWidth = Math.max(maxWidth, _accessoryHolder.getAccessoryWidth(topviewCentreLine, leftOfVehicleRearWidth, leftOfBodyWidthExternal));
        maxWidth = Math.max(maxWidth, _payloadHolder.getPayloadWidth(topviewCentreLine, leftOfBodyWidthExternal, sideWallLeftThickness));
        return maxWidth;
    }
    
    function getRearMostAccessoryEndFromRearMostAxle() {
        
        if (_accessoryHolder.getCount(true) > 0) {
            return _accessoryHolder.getAccessoryRightMostPoint(getBumperToBackOfCab(), getFrontOverhang()) - getFrontOverhang() - getAxle1FrontToAxle2Front() -
                    getAxle2FrontToAxle1Rear() - getAxle1RearToAxle2Rear() - getAxle2RearToAxle3Rear() - getAxle3RearToAxle4Rear();
        } else {
            return 0;
        }

        //If _Accessories.Count(True) > 0 Then
        //    Return _Accessories.AccessoryRightMostPoint(BumperToBackOfCab, FrontOverhang) - _FrontOverhang - _Axle1FrontToAxle2Front -
        //           _Axle2FrontToAxle1Rear - _Axle1RearToAxle2Rear - _Axle2RearToAxle3Rear - _Axle3RearToAxle4Rear
        //Else
        //    Return 0
        //End If
        
    }
        
    
    //function getFrontAxleTare() {
    //    var overallTare = 0;
    //    ko.utils.arrayForEach(getAxles(), function (axle) {
    //        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
    //            overallTare += axle.getAxleWeight();
    //        }
    //    });
    //    return overallTare;
    //}
    //function getRearAxleTare() {
    //    var overallTare = 0;
    //    ko.utils.arrayForEach(getAxles(), function (axle) {
    //        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
    //            overallTare += axle.getAxleWeight();
    //        }
    //    });
    //    return overallTare;
    //}
    //function getVehicleGroup1BridgeFormulaDistance() {
    //    return getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear();
    //}
    

    function getVehicleAndDrawbarTotalGross() {
        //Return _Vehicle.TotalUnladen + _Vehicle.TotalPayload + _Trailer1(0).TotalUnladen + _Trailer1(0).TotalPayload
        return getUnladenTotal() + getPayloadTotal();
    }

    function clearAccessories() {
        _accessoryHolder.setAccessories([]);
    }

    function getAccessories() {
        return _accessoryHolder.getAccessories();
    }

    function setAccessories(newValue) {
        _accessoryHolder.setAccessories(newValue);
    }

    function getAccessoryHolder() {
        return _accessoryHolder;
    }

    function addBody(body) {
        _accessoryHolder.getAccessories().push(body);
    }

    function addAccessory(accessory) {
        _accessoryHolder.getAccessories().push(accessory);
    }

    function getBody() {
        return _accessoryHolder.getBody();
    }

    function hasServiceBody() {
        var body = getBody();
        return body !== null && body.getType() === config.BODY_TYPES.SERVICE;
    }

    function getAxlesHolder() {
        return _axlesHolder;
    }
    function clearFuelTanks() {
        _fuelTanks = [];
    }
    function getFuelTanks() {
        return _fuelTanks;
    }
    function setFuelTanks(newValue) {
        _fuelTanks = newValue;
    }
    function countFuelTankType(fuelTankType) {
        let fuelTankTypeCounter = 0;

        getFuelTanks().forEach(function (fuelTank) {
            if(fuelTank.getContents() === fuelTankType) {
                fuelTankTypeCounter++;
            }
        });

        return fuelTankTypeCounter;
    }
    function getSumBatteryCapacity () {
        let sumCapacity = 0;

        if(_fuelType === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
            getFuelTanks().forEach(function (fuelTank){
                if(fuelTank.getIncludeInCalculation()) {
                    sumCapacity += parseFloat(fuelTank.getCapacity());
                }
            });
        }

        return sumCapacity;
    }
    function setExpressedAs(newValue) {
        _fuelExpressedAs = newValue;
    }
    function getExpressedAs() {
        return _fuelExpressedAs;
    }
    function getFuelTank(fuelTankNumber) {
        return _fuelTanks.filter(function (fuelTank) {
            return fuelTank.getNumber() === fuelTankNumber;
        })[0] || null;
    }
    function addFuelTank(fuelTank, fuelTankContents) {
        var fuelTankToAdd = null;
        if (getFuelTanks().length < config.allowedNumberOfFuelTanksOrBatteries) {
            fuelTankToAdd = fuelTank || new FuelTank(globals.nextId(), getFuelTanks().length+1, false, 'OPTIONAL', false, 0, false, 0, 0, 0, 0, false, 0, false, false, false, fuelTankContents || _fuelType);
            if(fuelTankToAdd.getContents() === config.FUEL_TYPE_OPTIONS.DIESEL.CODE) {
                var indexOfFirstAdBlueTankIfAny = _fuelTanks.findIndex(function (item) { 
                    return item.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE; 
                });
                if(indexOfFirstAdBlueTankIfAny !== -1) {
                    _fuelTanks.splice(indexOfFirstAdBlueTankIfAny, 0, fuelTankToAdd);            
                }else {
                    _fuelTanks.push(fuelTankToAdd);
                }
            } else {
                _fuelTanks.push(fuelTankToAdd);
            }
            

            updateFuelTankNumbers();

        }
        return fuelTankToAdd;
    }
    function getCurrentFuelTypeExpressedAs() {
        
        return _fuelType === config.FUEL_TYPE_OPTIONS.HYDROGEN_FUEL_CELL.CODE ? getHydrogenExpressedAs() : _fuelType === config.FUEL_TYPE_OPTIONS.LNG.CODE ? getLNGExpressedAs() : _fuelType === config.FUEL_TYPE_OPTIONS.CNG.CODE ? getCNGExpressedAs() : '';
    }
    function getFrontAddition() {
        if (getChassisHeightFront() > getChassisHeightRear()) {
            return 0;
        } else {
            return getChassisHeightRear() - getChassisHeightFront();
        }
    }

    function getRearAddition() {
        if (getChassisHeightFront() > getChassisHeightRear()) {
            return getChassisHeightFront() - getChassisHeightRear();
        } else {
            return 0;
        }
    }

    function getPayloadCOGToWheelbaseTheoreticalEnd() {
        //Get
        //If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
        //Return PayloadDeckStartToWheelbaseTheoreticalEnd - _Accessories.Body.PayloadObjects.averagepayloadhorizontalcog
        //ElseIf _Type = VehicleTypes.CompleteVehicle Then
        //Return PayloadDeckStartToWheelbaseTheoreticalEnd - _PayloadObjects.averagepayloadhorizontalcog
        //Else
        //Return 0
        //End If
        //End Get
        if (getBody() !== null || getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return getPayloadDeckStartToWheelbaseTheoreticalEnd() - getPayloadHolder().getAveragePayloadHorizontalCOG();
        } else {
            return 0;
        }

    }
    function getPayloadCOGToWheelbaseTheoreticalStart() {
        //If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
        //_Type = VehicleTypes.CompleteVehicle Then
        //Return WheelbaseTheoretical - PayloadCOGToWheelbaseTheoreticalEnd
        //Else
        //Return 0
        //End If
        if (getBody() !== null || getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return getWheelbaseTheoretical() - getPayloadCOGToWheelbaseTheoreticalEnd();
        } else {
            return 0;
        }

    }
    function getPayloadDeckStartToWheelbaseTheoreticalEnd() {
        //If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
        //Return FrontOfVehicleToWheelbaseTheoreticalEnd -
        //       (BumperToBackOfCab + _Accessories.Body.HorizontalGap +
        //        Accessories.Body.HeadboardThickness)
        //ElseIf _Type = VehicleTypes.CompleteVehicle Then
        //Return FrontOfVehicleToWheelbaseTheoreticalEnd - BumperToStartOfBodyInternal
        //Else
        //Return 0
        //End If
        if (getBody() !== null) {
            return getFrontOfVehicleToWheelbaseTheoreticalEnd() - (getBumperToBackOfCab() + _accessoryHolder.getBodyGap() + getBody().getHeadboardThickness());
        } else if (getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return getFrontOfVehicleToWheelbaseTheoreticalEnd() - getBumperToStartOfBodyInternal();
        }else {
            return 0;
        }
    }
    function getFrontOfVehicleToWheelbaseTheoreticalEnd() {
        //Return FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical
        return getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical();
    }
    function getFrontToWheelbaseTheoreticalEnd() {
        return getFrontOfVehicleToWheelbaseTheoreticalEnd();
    }
    function getPayloadHolder() {
        return _payloadHolder;
    }

    function getBodyCOGToRearAxles() {
        //If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
            //If _Accessories.Body.Length > 0 Then
                //If _Accessories.Body.COGType = ApplicationCOGTypes.Length Then
                    //Return (_FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical) -
                    //       (_BumperToBackOfCab + _Accessories.Body.HorizontalGap + _Accessories.Body.HorizontalCOG)
                //Else
                    //Return (_FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical) -
                    //       (_BumperToBackOfCab + _Accessories.Body.HorizontalGap + (_Accessories.Body.HorizontalCOG / 100 * _Accessories.Body.ActualLength))
                //End If
            //Else
                //Return 0
            //End If
        //Else
            //Return 0
        //End If
        if (getBody() !== null) {
            var tempBody = getBody();
            if (tempBody.getLength() > 0) {
                //if (tempBody.getCOGType() === config.COG_TYPES.LENGTH) {
                //    return (getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical()) - (getBumperToBackOfCab() + tempBody.getHorizontalGap() + tempBody.getHeadboardThickness() + tempBody.getHorizontalCOG());
                //} else {
                //    return (getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical()) - (getBumperToBackOfCab() + tempBody.getHorizontalGap() + tempBody.getHeadboardThickness() + (tempBody.getHorizontalCOG() / 100 * tempBody.getActualLength()));
                //}
                if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                    var tempHooklift = _accessoryHolder.getHooklift();
                    return (getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical()) - (getBumperToBackOfCab() + tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() + tempBody.getHorizontalGap() + tempBody.getActualHorizontalCOG());
                }
                return (getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical()) - (getBumperToBackOfCab() + tempBody.getHorizontalGap() + tempBody.getActualHorizontalCOG());
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    }

    function getOverallHeight() {
        var frontAddition, rearAddition, maxHeight;
        if (getChassisHeightRear() > getChassisHeightFront()) {
            frontAddition = getChassisHeightRear() - getChassisHeightFront();
            rearAddition = 0;
        } else {
            frontAddition = 0;
            rearAddition = getChassisHeightFront() - getChassisHeightRear();
        }
        
        maxHeight = Math.max(getFrontalAreaHeight(), getVerticalExhaustHeight() + frontAddition);
        maxHeight = Math.max(maxHeight, _accessoryHolder.getAccessoryHeight(getChassisHeightRear()) + rearAddition);
        //maxHeight = Math.Max(maxHeight, _Accessories.AccessoryHeight(ChassisHeightRear) + rearAddition)

        // let maxHeight = getOverallHeightExcludingPayload();

        if (getBody() !== null) {
            maxHeight = Math.max(maxHeight, getBottomOfVehicleToPayloadDeckStart() + getPayloadHolder().getMaxHeightOfPayloadObjects())
        }

        return maxHeight;
    }

    function getOverallHeightExcludingPayload() {
        var frontAddition, rearAddition, maxHeight;
        if (getChassisHeightRear() > getChassisHeightFront()) {
            frontAddition = getChassisHeightRear() - getChassisHeightFront();
            rearAddition = 0;
        } else {
            frontAddition = 0;
            rearAddition = getChassisHeightFront() - getChassisHeightRear();
        }
        
        maxHeight = Math.max(getFrontalAreaHeightExcludingPayload(), getVerticalExhaustHeight() + frontAddition);
        maxHeight = Math.max(maxHeight, _accessoryHolder.getAccessoryHeight(getChassisHeightRear()) + rearAddition);
        //maxHeight = Math.Max(maxHeight, _Accessories.AccessoryHeight(ChassisHeightRear) + rearAddition)

        // if (getBody() !== null) {
        //     maxHeight = Math.max(maxHeight, getBottomOfVehicleToPayloadDeckStart() + getPayloadHolder().getMaxHeightOfPayloadObjects())
        // }

        return maxHeight;
    }

    function getOverallHeightAtRear() {
        var frontAddition, rearAddition, maxHeight = 0;
        if (getChassisHeightRear() > getChassisHeightFront()) {
            frontAddition = getChassisHeightRear() - getChassisHeightFront();
            rearAddition = 0;
        } else {
            frontAddition = 0;
            rearAddition = getChassisHeightFront() - getChassisHeightRear();
        }

        //maxHeight = Math.max(getFrontalAreaHeight(), getVerticalExhaustHeight() + frontAddition);
        maxHeight = Math.max(maxHeight, _accessoryHolder.getAccessoryHeight(getChassisHeightRear()) + rearAddition);
        //maxHeight = Math.Max(maxHeight, _Accessories.AccessoryHeight(ChassisHeightRear) + rearAddition)

        if (getBody() !== null) {
            maxHeight = Math.max(maxHeight, getBottomOfVehicleToPayloadDeckStart() + getPayloadHolder().getMaxHeightOfPayloadObjects())
        }

        return maxHeight;
    }

    function getWheelbaseTurning(forceAddedRaised) {
        let raiseSteerablePusherOrTagForWheelbaseTurning = true;
        return getWheelbaseTheoretical(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised) + getAxle1FrontToWheelbaseTheoreticalStart();
    }

    function getRearWidthOfVehicleOrBodyForTurningCircleCalculation() {
        return Math.max(getRearWidthOfBodyForTurningCircleCalculation(), getRearWidthOfVehicleForTurningCircleCalculation());
    }

    function getRearWidthOfBodyForTurningCircleCalculation() {
        if (_accessoryHolder.getBody() !== null) {
            return _accessoryHolder.getBody().getActualWidth();
        }else {
            return 0;
        }            
    }

    function getRearWidthOfVehicleForTurningCircleCalculation() {
        return getRearWidth();
    }
    
    function getRearSwingClearanceRadius() {

        var swingRadius = 0;
        var tempHitch = _accessoryHolder.getHitch();
        if (tempHitch !== null) {
            swingRadius = Math.sqrt(Math.pow((getRearOverhangTotal() + getWheelbaseTheoreticalEndToAxle4Rear() - (getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempHitch.getOffset())), 2) + Math.pow((getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2), 2));

            var tempBody = _accessoryHolder.getBody();
            if (tempBody !== null) {
                var gapBetweenHitchandEndOfBody = tempBody.getHorizontalGap() + tempBody.getActualLength() - (getCabToChassisEnd() - getRearOverhangTotal() - getWheelbaseTheoreticalEndToAxle4Rear() + (getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempHitch.getOffset()));

                if (gapBetweenHitchandEndOfBody > 0) {
                    swingRadius = Math.max(swingRadius, Math.sqrt(Math.pow(gapBetweenHitchandEndOfBody, 2) + Math.pow(tempBody.getActualWidth() / 2, 2)));
                }
            }
        }
        return swingRadius;

        /*
        Dim swingRadius As Double = 0

        If Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Hitch) = 1 Then

            swingRadius = Math.Sqrt((RearOverhangTotal + WheelbaseTheoreticalEndToAxle4Rear - Accessories.Hitch.HorizontalGap) ^ 2 + (RearWidthOfVehicleForTurningCircleCalculation / 2) ^ 2)

            'if there is a body and the end of the body extends past the hitch
            If Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then

                Dim gapBetweenHitchAndEndOfBody As Double = Accessories.Body.HorizontalGap + Accessories.Body.ActualLength -
                                                            (CabToChassisEnd + RearProtrusion - RearOverhangTotal - WheelbaseTheoreticalEndToAxle4Rear + Accessories.Hitch.HorizontalGap)

                If gapBetweenHitchAndEndOfBody > 0 Then
                    swingRadius = Math.Max(swingRadius, Math.Sqrt(gapBetweenHitchAndEndOfBody ^ 2 + (Accessories.Body.ActualWidth / 2) ^ 2))
                End If

            End If

        End If

        Return swingRadius
        */
    }

    

    function getBodyAvailableChassisLength() {

        var tempBody = _accessoryHolder.getBody();

        if (tempBody !== null) {
            if (tempBody.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                return getCabToChassisEnd() - _accessoryHolder.getBodyGap();
            } else {
                return getCabToChassisEnd() -
                    _accessoryHolder.getBodyGap() -
                        tempBody.getHeadboardThickness() -
                        tempBody.getTailboardThickness()
            }
        } else {
            return getCabToChassisEnd();
        }
    }

    function convertToExpressedASFromKGIfNecessary() {
        if(_fuelType === config.FUEL_TYPE_OPTIONS.CNG.CODE || _fuelType === config.FUEL_TYPE_OPTIONS.LNG.CODE || _fuelType === config.FUEL_TYPE_OPTIONS.HYDROGEN_FUEL_CELL.CODE) {
            getFuelTanks().forEach(function (fuelTank) {
                fuelTank.setCapacity(fuelTank.getCapacity()/getFuelSpecificGravity());
                if(fuelTank.getVolumeIncludedInExtras() > 0) {
                    fuelTank.setVolumeIncludedInExtras(fuelTank.getVolumeIncludedInExtras()/getFuelSpecificGravity());
                }
                if(fuelTank.getVolumeIncludedInTare() > 0) {
                    fuelTank.setVolumeIncludedInTare(fuelTank.getVolumeIncludedInTare()/getFuelSpecificGravity());
                }
            });   
        }
    }
    //#endregion Getters and setters

    function updateValues(bodyHeightExternal, bodyLengthExternalInclRearDoors, bumperToBackOfCab, bumperToStartOfLoadCompartment, cabGapRecommendedMin, cabHeight, colourOptions,
                                completeOverallBodyAndEquipmentHeight, completeOverallLength, completeRearOverhang, floorHeight, frontOverhang, grossFront, grossRear, grossTotal,
                                bodyHeightInternal, messages, payloadFront, payloadRear, payloadTotal, permissibleFront, permissibleRear, permissibleTotal,
                                rearOverhang, unladenFront, unladenRear, unladenTotal, vehicleDrawing, vehicleTopViewDrawing, wheelbaseTheoretical, bumperToStartOfBodyInternal, bodyLengthInternal,
                                rearProtrusion, axle1FrontToWheelbaseTheoreticalStart, fifthWheelOffset, fifthWheelHeightFromGround, axle1FrontToAxle2Front, axle2FrontToAxle1Rear,
                                axle1RearToAxle2Rear, axle2RearToAxle3Rear, axle3RearToAxle4Rear, control, breakpointForWheelbaseFromRearMostAxleFront, breakpointForRearOverhangFromRearMostAxle,
                                rearMostProtrudingItemBehindCabToRearAxles, type, tareFifthWheelIncluded, chassisHeightFront, chassisHeightRear, axles,
                                source, verticalExhaustHeight, overallLength, groundClearanceFront, groundClearanceRear, crewMassPerPerson, crewFirstRowMax, crewFirstRowCOG, noOfCrewInFirstRow, crewSecondRowIncluded,
                                crewSecondRowMax, crewSecondRowCOG, noOfCrewInSecondRow, fuelSpecificGravity, fuelTank1COG, fuelTank1Capacity, fuelTank2Included, fuelTank2COG, fuelTank2Capacity, fuelTank3Included,
                                fuelTank3COG, fuelTank3Capacity, fuelTank4Included, fuelTank4COG, fuelTank4Capacity, tareFront, tareRear, fuelTank1VolumeIncludedInTare, fuelTank2VolumeIncludedInTare,
                                fuelTank3VolumeIncludedInTare, fuelTank4VolumeIncludedInTare, wheelbaseTheoreticalManufacturerMin, wheelbaseTheoreticalManufacturerMax, originalAUFront, originalAURear,
                                gvm, gcm, vRating, vehicleDT, frontRoadFriendlySuspension, rearRoadFriendlySuspension, rolloverProtection, steeringAngleInner, turningRadiusWallToWall, turningRadiusCurbToCurb,
                                frontUnderrunProtection, wheelbaseTheoreticalMaxCut, rearOverhangManufacturerMin, cabTilt, cabTiltConverter, hingePointFromBumper, hingePointFromGround, cabTiltRadius, wheelbaseTheoreticalOverride,
                                cabWidth, rearWidth, overallWidth, trackWidthFront, trackWidthRear, chassisWidth, axle1FrontToWheelbaseTheoreticalStartOverride, tareFrontOverride, tareRearOverride, 
                                vehicleVerticalCOG, vehicleVerticalCOGOverride, tareToolsIncluded, tareSpareWheelIncluded, aUFront, aURear, manufacturersFrontAxlesMassOverride, manufacturersRearAxlesMassOverride, 
                                manufacturersGrossVehicleMassOverride, permissibleRearOverride, permissibleFrontOverride, permissibleTotalOverride, crewFirstRowHorizontalCOGOverride,
                                crewFirstRowVerticalCOG, crewFirstRowVerticalCOGOverride, crewSecondRow, crewSecondRowHorizontalCOGOverride, crewSecondRowVerticalCOG, crewSecondRowVerticalCOGOverride, 
                                fuelType, gAFront, gARear, fuelTank1VerticalCOG, fuelTank2VerticalCOG, fuelTank3VerticalCOG, fuelTank4VerticalCOG, fuelTanksArrayFromServer, bodyWidthManufacturerMax, 
                                payloadCOGType, payloadHorizontalCOG, payloadVerticalCOG, payloadVerticalCOGOverride,
                                fuelTank1VerticalCOGOverride, fuelTank2VerticalCOGOverride, fuelTank3VerticalCOGOverride, fuelTank4VerticalCOGOverride,
                                vehicleVerticalCOGConfirmed, payloadVerticalCOGConfirmed, fuelTank1VerticalCOGConfirmed, fuelTank2VerticalCOGConfirmed, fuelTank3VerticalCOGConfirmed, fuelTank4VerticalCOGConfirmed,
                                crewFirstRowVerticalCOGConfirmed, crewSecondRowVerticalCOGConfirmed, tareDriverIncluded, fifthWheelOffsetMin, fifthWheelOffsetMax,
                                vehicleDescription, vehicleTypeDistributionCode, frontAxleAverageTyreLife, rearAxleAverageTyreLife, secondsteerAverageTyreLife, pusherAxleAverageTyreLife, tagAxleAverageTyreLife, trailerFrontAxleAverageTyreLife, trailerRearAxleAverageTyreLife,
                                fuelTank1LateralCOG, fuelTank1LateralCOGOverride, fuelTank1LateralCOGConfirmed, fuelTank2LateralCOG, fuelTank2LateralCOGOverride, fuelTank2LateralCOGConfirmed, fuelTank3LateralCOG, fuelTank3LateralCOGOverride, fuelTank3LateralCOGConfirmed,
                                fuelTank4LateralCOG, fuelTank4LateralCOGOverride, fuelTank4LateralCOGConfirmed, crewFirstRowLateralCOG, crewFirstRowLateralCOGOverride, crewFirstRowLateralCOGConfirmed, crewSecondRowLateralCOG, crewSecondRowLateralCOGOverride, crewSecondRowLateralCOGConfirmed,
                                vehicleLateralCOG, vehicleLateralCOGOverride, vehicleLateralCOGConfirmed, payloadLateralCOG, payloadLateralCOGOverride, payloadLateralCOGConfirmed, loadCompartmentWidth, tyrePriceFirstSet, tyrePriceRemainingSets, 
                                cabToAxlesOverride, vehicleBridgeFormulaPermissible, vehicleBridgeFormulaPermissibleOverride, combinationBridgeFormulaPermissible, combinationBridgeFormulaPermissibleOverride, bridgeFormulaRearPermissible, bridgeFormulaRearPermissibleOverride,
                                fuelSpecificGravityDiesel, fuelSpecificGravityPetrol, fuelSpecificGravityEthanol, fuelTank5Capacity, fuelTank5VolumeIncludedInTare, fuelTank5COG, fuelTank5VerticalCOG, fuelTank5VerticalCOGOverride, fuelTank5LateralCOG, fuelTank5LateralCOGOverride, fuelTank5Included,
                                fuelTank6Capacity, fuelTank6VolumeIncludedInTare, fuelTank6COG, fuelTank6VerticalCOG, fuelTank6VerticalCOGOverride, fuelTank6LateralCOG, fuelTank6LateralCOGOverride, fuelTank6Included,
                                fuelTank7Capacity, fuelTank7VolumeIncludedInTare, fuelTank7COG, fuelTank7VerticalCOG, fuelTank7VerticalCOGOverride, fuelTank7LateralCOG, fuelTank7LateralCOGOverride, fuelTank7Included,
                                fuelTank8Capacity, fuelTank8VolumeIncludedInTare, fuelTank8COG, fuelTank8VerticalCOG, fuelTank8VerticalCOGOverride, fuelTank8LateralCOG, fuelTank8LateralCOGOverride, fuelTank8Included, 
                                fuelTank5VerticalCOGConfirmed, fuelTank6VerticalCOGConfirmed, fuelTank7VerticalCOGConfirmed, fuelTank8VerticalCOGConfirmed,
                                fuelTank5LateralCOGConfirmed, fuelTank6LateralCOGConfirmed, fuelTank7LateralCOGConfirmed, fuelTank8LateralCOGConfirmed, 
                                fuelSpecificGravityBiodiesel, fuelSpecificGravityLNG, fuelSpecificGravityCNG, fuelSpecificGravityHydrogen, specificGravityAdBlue, cNGExpressedAs, lNGExpressedAs, hydrogenExpressedAs,
                                devicesForIndirectVision, commercialVehicleBrakeSystems, advancedEmergencyBraking, laneDepartureWarning, sideUnderrunProtection, conspicuityMarkingsToTheRear, conspicuityMarkingsToBothSides, blindSpotInformationSystems,
                                engineMaximumTorqueNm, engineMaximumTorqueLbFt, transmissionFirstGearRatio, transmissionReverseGearRatio, transmissionTorqueConverterMultiplication, transmissionParkPAWL,
                                emissionsStandard,
                                updateCounter) {

        


                                    _fuelTanks = [];
                                    var frontAxleGroupId = _axlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId();
                                    var rearAxleGroupId = _axlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId();
                                    _axlesHolder.setAxles([]);

                                    setFuelSpecificGravityDiesel(fuelSpecificGravityDiesel);
                                    setFuelSpecificGravityPetrol(fuelSpecificGravityPetrol);
                                    setFuelSpecificGravityEthanol(fuelSpecificGravityEthanol);
                                    setFuelSpecificGravityBiodiesel(fuelSpecificGravityBiodiesel);
                                    setFuelSpecificGravityCNG(fuelSpecificGravityCNG);
                                    setFuelSpecificGravityLNG(fuelSpecificGravityLNG);
                                    setFuelSpecificGravityHydrogen(fuelSpecificGravityHydrogen);
                                    setSpecificGravityAdBlue(specificGravityAdBlue);

                                    setCNGExpressedAs(cNGExpressedAs);
                                    setLNGExpressedAs(lNGExpressedAs);
                                    setHydrogenExpressedAs(hydrogenExpressedAs);
                                    setFuelType(fuelType);
                                    setUpFuelTanks(config.OFFERED_AS_OPTIONS.STANDARD, false, fuelTank1COG, fuelTank1Capacity, fuelTank1VerticalCOGConfirmed, fuelTank1VerticalCOG, fuelTank1VolumeIncludedInTare, fuelTank1LateralCOG, fuelTank1LateralCOGConfirmed,
                                                fuelTank2Included, false, fuelTank2COG, fuelTank2Capacity, fuelTank2VerticalCOGConfirmed, fuelTank2VerticalCOG, fuelTank2VolumeIncludedInTare, fuelTank2LateralCOG, fuelTank2LateralCOGConfirmed,
                                                fuelTank3Included, false, fuelTank3COG, fuelTank3Capacity, fuelTank3VerticalCOGConfirmed, fuelTank3VerticalCOG, fuelTank3VolumeIncludedInTare, fuelTank3LateralCOG, fuelTank3LateralCOGConfirmed,
                                                fuelTank4Included, false, fuelTank4COG, fuelTank4Capacity, fuelTank4VerticalCOGConfirmed, fuelTank4VerticalCOG, fuelTank4VolumeIncludedInTare, fuelTank4LateralCOG, fuelTank4LateralCOGConfirmed,
                                                fuelTank5Included, false, fuelTank5COG, fuelTank5Capacity, fuelTank5VerticalCOGConfirmed, fuelTank5VerticalCOG, fuelTank5VolumeIncludedInTare, fuelTank5LateralCOG, fuelTank5LateralCOGConfirmed,
                                                fuelTank6Included, false, fuelTank6COG, fuelTank6Capacity, fuelTank6VerticalCOGConfirmed, fuelTank6VerticalCOG, fuelTank6VolumeIncludedInTare, fuelTank6LateralCOG, fuelTank6LateralCOGConfirmed,
                                                fuelTank7Included, false, fuelTank7COG, fuelTank7Capacity, fuelTank7VerticalCOGConfirmed, fuelTank7VerticalCOG, fuelTank7VolumeIncludedInTare, fuelTank7LateralCOG, fuelTank7LateralCOGConfirmed,
                                                fuelTank8Included, false, fuelTank8COG, fuelTank8Capacity, fuelTank8VerticalCOGConfirmed, fuelTank8VerticalCOG, fuelTank8VolumeIncludedInTare, fuelTank8LateralCOG, fuelTank8LateralCOGConfirmed);
                                    setUpFuelTanksFromArray(fuelTanksArrayFromServer);
                                    setBodyHeightExternal(bodyHeightExternal);
                                    setBodyLengthExternalInclRearDoors(bodyLengthExternalInclRearDoors);
                                    setBumperToBackOfCab(bumperToBackOfCab);
                                    setBumperToStartOfLoadCompartment(bumperToStartOfLoadCompartment);
                                    setCabGapRecommendedMin(cabGapRecommendedMin);
                                    setCabHeight(cabHeight);
                                    var tempColour = '',
                                        colourOptionsObject = [];
                                    if (colourOptions) {
                                        for (var i = 0; i < colourOptions.length; i++) {
                                            tempColour = colourOptions[i].replace(/([^a-z0-9]+)/g, '');
                                            colourOptionsObject.push({ itemId: 'colour' + tempColour, colour: colourOptions[i] });
                                        }
                                        setActiveCabColour(colourOptionsObject[0]);
                                        setColourOptions(colourOptionsObject);
                                    } else {
                                        setActiveCabColour({});
                                        setColourOptions([]);
                                        //NOTE: This is only for testing
                                        //setActiveCabColour({ colour: '#FF0000' });
                                        //setColourOptions([{ itemId: 'colour1', colour: '#FF0000' }, { itemId: 'colour2', colour: '#0000FF' }, { itemId: 'colour3', colour: '#008000' }]);
                                    }

                                    setCompleteOverallBodyAndEquipmentHeight(completeOverallBodyAndEquipmentHeight);
                                    setCompleteOverallLength(completeOverallLength);
                                    setCompleteRearOverhang(completeRearOverhang);
                                    setFloorHeight(floorHeight);
                                    setFrontOverhang(frontOverhang);
                                    setGrossFront(grossFront);
                                    setGrossRear(grossRear);
                                    setGrossTotal(grossTotal);
                                    setBodyHeightInternal(bodyHeightInternal);
                                    setBodyLengthInternal(bodyLengthInternal);
        //var messagesObjectArray = [];
        //for (var i = 0; i < messages.length; i++) {
        //    messagesObjectArray.push({ messageText: messages[i] });
        //}
                                    setMessages(messages);
                                    setPayloadFront(payloadFront);
                                    setPayloadRear(payloadRear);
                                    setPayloadTotal(payloadTotal);
                                    setPermissibleFront(permissibleFront);
                                    setPermissibleRear(permissibleRear);
                                    setPermissibleTotal(permissibleTotal);
                                    setRearOverhang(rearOverhang);
                                    setUnladenFront(unladenFront);
                                    setUnladenRear(unladenRear);
                                    setUnladenTotal(unladenTotal);
                                    setVehicleDrawing(vehicleDrawing);
                                    setVehicleTopViewDrawing(vehicleTopViewDrawing);

        //new for drawing template
                                    setBumperToStartOfBodyInternal(bumperToStartOfBodyInternal);
                                    setBodyLengthInternal(bodyLengthInternal);
                                    setRearProtrusion(rearProtrusion);
                                    setAxle1FrontToWheelbaseTheoreticalStart(axle1FrontToWheelbaseTheoreticalStart);
                                    setAxle1FrontToWheelbaseTheoreticalStartOverride(axle1FrontToWheelbaseTheoreticalStartOverride);
                                    setFifthWheelOffset(fifthWheelOffset);
                                    setFifthWheelHeightFromGround(fifthWheelHeightFromGround);
                                    setAxle1FrontToAxle2Front(axle1FrontToAxle2Front);
                                    setAxle2FrontToAxle1Rear(axle2FrontToAxle1Rear);
                                    setAxle1RearToAxle2Rear(axle1RearToAxle2Rear);
                                    setAxle2RearToAxle3Rear(axle2RearToAxle3Rear);
                                    setAxle3RearToAxle4Rear(axle3RearToAxle4Rear);
                                    setControl(control);
                                    setBreakpointForRearOverhangFromRearMostAxle(breakpointForRearOverhangFromRearMostAxle);
                                    setBreakpointForWheelbaseFromRearMostAxleFront(breakpointForWheelbaseFromRearMostAxleFront);
                                    setRearMostProtrudingItemBehindCabToRearAxles(rearMostProtrudingItemBehindCabToRearAxles);
                                    setType(type);
                                    setTareFifthWheelIncluded(tareFifthWheelIncluded);

        ////if (type === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    setChassisHeightFront(chassisHeightRear);
        //} else {
                                    setChassisHeightFront(chassisHeightFront);
        //}

                                    setChassisHeightRear(chassisHeightRear);
        //setAxles(axles);
                                    _axlesHolder.createAxles(axles, config.CHASSIS_OBJECT_TYPES.VEHICLE);

                                    _axlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).setId(frontAxleGroupId);
                                    _axlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).setId(rearAxleGroupId);

                                    setSource(source);
                                    setVerticalExhaustHeight(verticalExhaustHeight);
        //setOverallLength(calculateOverallLength());
                                    setBullbarProtrusion(0);
                                    setOverallLengthExclAccessories(calculateOverallLengthExcludingAccessories());
                                    setOverallLengthInclAccessories(getOverallLengthInclAccessories());
                                    setGroundClearanceFront(groundClearanceFront);
                                    setGroundClearanceRear(groundClearanceRear);
                                    setCrewMassPerPerson(crewMassPerPerson);
                                    setCrewFirstRowMax(crewFirstRowMax);
                                    setCrewFirstRowCOG(crewFirstRowCOG);
                                    setNoOfCrewInFirstRow(noOfCrewInFirstRow);
                                    setCrewSecondRowIncluded(crewSecondRowIncluded);
                                    setCrewSecondRowMax(crewSecondRowMax);
                                    setCrewSecondRowCOG(crewSecondRowCOG);
                                    setNoOfCrewInSecondRow(noOfCrewInSecondRow);
                                    if (fuelType === getFuelType()) {
                                        setFuelSpecificGravityOverride(getFuelSpecificGravityOverride());
                                    } else {
                                        setFuelSpecificGravityOverride(false);
                                    }
                                    if (getFuelSpecificGravityOverride() === false) {
                                        setFuelSpecificGravity(fuelSpecificGravity);
                                    }
                                    
        

        //setFuelTank1COG(fuelTank1COG);
        //setFuelTank1VerticalCOG(fuelTank1VerticalCOG);
        //setFuelTank1Capacity(fuelTank1Capacity);
        //setFuelTank2Included(fuelTank2Included);
        //setFuelTank2COG(fuelTank2COG);
        //setFuelTank2VerticalCOG(fuelTank2VerticalCOG);
        //setFuelTank2Capacity(fuelTank2Capacity);
        //setFuelTank3Included(fuelTank3Included);
        //setFuelTank3COG(fuelTank3COG);
        //setFuelTank3VerticalCOG(fuelTank3VerticalCOG);
        //setFuelTank3Capacity(fuelTank3Capacity);
        //setFuelTank4Included(fuelTank4Included);
        //setFuelTank4COG(fuelTank4COG);
        //setFuelTank4VerticalCOG(fuelTank4VerticalCOG);
        //setFuelTank4Capacity(fuelTank4Capacity);
        //setFuelTank1VolumeIncludedInTare(fuelTank1VolumeIncludedInTare);
        //setFuelTank2VolumeIncludedInTare(fuelTank2VolumeIncludedInTare);
        //setFuelTank3VolumeIncludedInTare(fuelTank3VolumeIncludedInTare);
        //setFuelTank4VolumeIncludedInTare(fuelTank4VolumeIncludedInTare);
                                    setTareFront(tareFront);
                                    setTareRear(tareRear);
                                    setTareRearRear(tareRear);

        //New values for interpreter
                                    setWheelbaseTheoreticalManufacturerMin(wheelbaseTheoreticalManufacturerMin);
                                    setWheelbaseTheoreticalManufacturerMax(wheelbaseTheoreticalManufacturerMax)
                                    setOriginalAUFront(originalAUFront);
                                    setOriginalAURear(originalAURear);
                                    setGVM(gvm);
                                    setGCM(gcm);
                                    setVRating(vRating)
                                    setDT(vehicleDT);
                                    setFrontRoadFriendlySuspension(frontRoadFriendlySuspension);
                                    setRearRoadFriendlySuspension(rearRoadFriendlySuspension);
                                    setRolloverProtection(rolloverProtection)
                                    setSteeringAngleInner(steeringAngleInner);
                                    setTurningRadiusWallToWall(turningRadiusWallToWall);
                                    setTurningRadiusCurbToCurb(turningRadiusCurbToCurb);
                                    setFrontUnderrunProtection(frontUnderrunProtection);
                                    setWheelbaseTheoreticalMaxCut(wheelbaseTheoreticalMaxCut);
                                    setRearOverhangManufacturerMin(rearOverhangManufacturerMin);
                                    var cabTiltOpt = cabTiltConverter === config.YES_NO_OPTIONS.YES ? true : false;
                                    setCabTilt(cabTiltOpt);
                                    setCabTiltConverter(cabTiltConverter);
                                    setHingePointFromBumper(hingePointFromBumper);
                                    setHingePointFromGround(hingePointFromGround);
                                    setCabTiltRadius(cabTiltRadius);
                                    setWheelbaseTheoreticalOverride(wheelbaseTheoreticalOverride);
                                    setCabWidth(cabWidth);
                                    setRearWidth(rearWidth);
        //setOverallWidth(overallWidth);
                                    setTrackWidthFront(trackWidthFront);
                                    setTrackWidthRear(trackWidthRear);
                                    setChassisWidth(chassisWidth);
                                    setFifthWheelHeightFromGround(fifthWheelHeightFromGround);
                                    setTareFrontOverride(tareFrontOverride);
                                    setTareRearOverride(tareRearOverride);
                                    setCrewSecondRowVerticalCOG(crewSecondRowVerticalCOG);
                                    setCrewFirstRowVerticalCOG(crewFirstRowVerticalCOG);
                                    setVehicleVerticalCOG(vehicleVerticalCOG);
                                    setVehicleVerticalCOGOverride(vehicleVerticalCOGOverride);
                                    setTareToolsIncluded(tareToolsIncluded);
                                    setTareSpareWheelIncluded(tareSpareWheelIncluded);
                                    setAUFront(aUFront);
                                    setAURear(aURear);
                                    
                                    var gaFrontFromAxleGroup = _axlesHolder.getLesserOfFrontAxleManufacturerRatings();
                                    if (gaFrontFromAxleGroup !== 0) {
                                        setGAFront(gaFrontFromAxleGroup);
                                    } else {
                                        setGAFront(gAFront);
                                    }

                                    var gaRearFromAxleGroup = _axlesHolder.getLesserOfRearAxleManufacturerRatings();
                                    if (gaRearFromAxleGroup !== 0) {
                                        setGARear(gaRearFromAxleGroup);
                                    } else {
                                        setGARear(gARear);
                                    }

                                    setManufacturersFrontAxlesMassOverride(manufacturersFrontAxlesMassOverride);
                                    setManufacturersRearAxlesMassOverride(manufacturersRearAxlesMassOverride);
                                    setManufacturersGrossVehicleMassOverride(manufacturersGrossVehicleMassOverride);
                                    setPermissibleRearOverride(permissibleRearOverride);
                                    setPermissibleFrontOverride(permissibleFrontOverride);
                                    setPermissibleTotalOverride(permissibleTotalOverride);
                                    setBodyWidthManufacturerMax(bodyWidthManufacturerMax);
                                    setPayloadCOGType(payloadCOGType);
                                    setPayloadHorizontalCOG(payloadHorizontalCOG);
                                    setPayloadVerticalCOG(payloadVerticalCOG);
                                    setPayloadVerticalCOGOverride(payloadVerticalCOGOverride);

                                    setVehicleVerticalCOGConfirmed(vehicleVerticalCOGConfirmed);
                                    setPayloadVerticalCOGConfirmed(payloadVerticalCOGConfirmed);
                                    setFuelTank1VerticalCOGConfirmed(fuelTank1VerticalCOGConfirmed);
                                    setFuelTank2VerticalCOGConfirmed(fuelTank2VerticalCOGConfirmed);
                                    setFuelTank3VerticalCOGConfirmed(fuelTank3VerticalCOGConfirmed);
                                    setFuelTank4VerticalCOGConfirmed(fuelTank4VerticalCOGConfirmed);
                                    setFuelTank1LateralCOGConfirmed(fuelTank1LateralCOGConfirmed);
                                    setFuelTank2LateralCOGConfirmed(fuelTank2LateralCOGConfirmed);
                                    setFuelTank3LateralCOGConfirmed(fuelTank3LateralCOGConfirmed);
                                    setFuelTank4LateralCOGConfirmed(fuelTank4LateralCOGConfirmed);
                                    setCrewFirstRowVerticalCOGConfirmed(crewFirstRowVerticalCOGConfirmed);
                                    setCrewSecondRowVerticalCOGConfirmed(crewSecondRowVerticalCOGConfirmed);
                                    setTareDriverIncluded(tareDriverIncluded);
                                    setFifthWheelOffsetMin(fifthWheelOffsetMin);
                                    setFifthWheelOffsetMax(fifthWheelOffsetMax);
                                    setWheelbaseTheoretical(getWheelbaseTheoretical());

                                    if (getCrewSecondRowIncluded() === true) {
                                        setCrewSecondRow(true);
                                    } else {
                                        setCrewSecondRow(false);
                                    }

        //if (_source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
        //    setCrewFirstRowHorizontalCOGOverride(true);
        //    setCrewFirstRowVerticalCOGOverride(true);
        //    setCrewSecondRowHorizontalCOGOverride(true);
        //    setCrewSecondRowVerticalCOGOverride(true);
        //} else {
                                    setCrewFirstRowHorizontalCOGOverride(crewFirstRowHorizontalCOGOverride);
                                    setCrewFirstRowVerticalCOGOverride(crewFirstRowVerticalCOGOverride);
                                    setCrewSecondRowHorizontalCOGOverride(crewSecondRowHorizontalCOGOverride);
                                    setCrewSecondRowVerticalCOGOverride(crewSecondRowVerticalCOGOverride);
        //}

                                    if (_source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                                        setCrewFirstRowHorizontalCOGOverride(true);
                                        setCrewFirstRowVerticalCOGOverride(true);
                                        setCrewSecondRowHorizontalCOGOverride(true);
                                        setCrewSecondRowVerticalCOGOverride(true);
                                        //setTareFrontOverride(true);
                                        //setTareRearOverride(true);
                                        setVehicleVerticalCOGOverride(true);
                                        //setManufacturersFrontAxlesMassOverride(true);
                                        //setManufacturersRearAxlesMassOverride(true);
                                        //setManufacturersGrossVehicleMassOverride(true);
                                        //setPermissibleFrontOverride(true);
                                        //setPermissibleRearOverride(true);
                                        //setPermissibleTotalOverride(true);
                                        setManufacturersGrossCombinationMassOverride(true);
                                        setCabWidthOverride(true);
                                        setRearWidthOverride(true);
                                        setTrackWidthFrontOverride(true);
                                        setTrackWidthRearOverride(true);
                                        setChassisWidthOverride(true);

                                        if (axle1FrontToAxle2Front > 0) {
                                            setAxle1FrontToAxle2FrontOverride(true);
                                        }
                                        //setAxle2FrontToAxle1RearOverride(true);
                                        if (axle1RearToAxle2Rear > 0) {
                                            setAxle1RearToAxle2RearOverride(true);
                                        }
                                        if (axle2RearToAxle3Rear > 0) {
                                            setAxle2RearToAxle3RearOverride(true);
                                        }
                                        if (axle3RearToAxle4Rear > 0) {
                                            setAxle3RearToAxle4RearOverride(true);
                                        }
                                        
                                    }
                                    setDescription(vehicleDescription);
                                    setVehicleTypeDistributionCode(vehicleTypeDistributionCode);

                                    
                                    setCrewFirstRowLateralCOG(crewFirstRowLateralCOG);
                                    setCrewFirstRowLateralCOGOverride(crewFirstRowLateralCOGOverride);
                                    setCrewFirstRowLateralCOGConfirmed(crewFirstRowLateralCOGConfirmed);
                                    setCrewSecondRowLateralCOG(crewSecondRowLateralCOG);
                                    setCrewSecondRowLateralCOGOverride(crewSecondRowLateralCOGOverride);
                                    setCrewSecondRowLateralCOGConfirmed(crewSecondRowLateralCOGConfirmed);
                                    setVehicleLateralCOG(vehicleLateralCOG);
                                    setVehicleLateralCOGOverride(vehicleLateralCOGOverride);
                                    setVehicleLateralCOGConfirmed(vehicleLateralCOGConfirmed);
                                    setPayloadLateralCOG(payloadLateralCOG);
                                    setPayloadLateralCOGOverride(payloadLateralCOGOverride);
                                    setPayloadLateralCOGConfirmed(payloadLateralCOGConfirmed);

                                    setFrontAxleAverageTyreLife(frontAxleAverageTyreLife);
                                    setRearAxleAverageTyreLife(rearAxleAverageTyreLife);
                                    setSecondsteerAverageTyreLife(secondsteerAverageTyreLife);
                                    setPusherAxleAverageTyreLife(pusherAxleAverageTyreLife);
                                    setTagAxleAverageTyreLife(tagAxleAverageTyreLife);
                                    setTrailerFrontAxleAverageTyreLife(trailerFrontAxleAverageTyreLife);
                                    setTrailerRearAxleAverageTyreLife(trailerRearAxleAverageTyreLife);

                                    setLoadCompartmentWidth(loadCompartmentWidth);
                                    setTyrePriceFirstSet(tyrePriceFirstSet);
                                    setTyrePriceRemainingSets(tyrePriceRemainingSets);
                                    
                                    setVehicleBridgeFormulaPermissible(vehicleBridgeFormulaPermissible || 0);
                                    setVehicleBridgeFormulaPermissibleOverride(vehicleBridgeFormulaPermissibleOverride || false);
                                    setCombinationBridgeFormulaPermissible(combinationBridgeFormulaPermissible || 0);
                                    setCombinationBridgeFormulaPermissibleOverride(combinationBridgeFormulaPermissibleOverride || false);
                                    setBridgeFormulaRearPermissible(bridgeFormulaRearPermissible || 0);
                                    setBridgeFormulaRearPermissibleOverride(bridgeFormulaRearPermissibleOverride || false);

                                    convertToExpressedASFromKGIfNecessary();

                                    // setExpressedAs(getCurrentFuelTypeExpressedAs());
                                    setDevicesForIndirectVision(devicesForIndirectVision);
                                    setCommercialVehicleBrakeSystems(commercialVehicleBrakeSystems);
                                    setAdvancedEmergencyBraking(advancedEmergencyBraking);
                                    setLaneDepartureWarning(laneDepartureWarning);
                                    setSideUnderrunProtection(sideUnderrunProtection);
                                    setConspicuityMarkingsToTheRear(conspicuityMarkingsToTheRear);
                                    setConspicuityMarkingsToBothSides(conspicuityMarkingsToBothSides);
                                    setBlindSpotInformationSystems(blindSpotInformationSystems);
                                    
                                    setEngineMaximumTorqueNm(engineMaximumTorqueNm);
                                    setEngineMaximumTorqueLbFt(engineMaximumTorqueLbFt);
                                    setTransmissionFirstGearRatio(transmissionFirstGearRatio);
                                    setTransmissionReverseGearRatio(transmissionReverseGearRatio);
                                    setTransmissionTorqueConverterMultiplication(transmissionTorqueConverterMultiplication);
                                    setTransmissionParkPAWL(transmissionParkPAWL);

                                    setEmissionsStandard(emissionsStandard);

                                    setUpdateCounter(updateCounter);
    }

    //#region Initialize
    clearAccessories();
    setFuelSpecificGravityDiesel(fuelSpecificGravityDiesel);
    setFuelSpecificGravityPetrol(fuelSpecificGravityPetrol);
    setFuelSpecificGravityEthanol(fuelSpecificGravityEthanol);
    setFuelSpecificGravityBiodiesel(fuelSpecificGravityBiodiesel);
    setFuelSpecificGravityCNG(fuelSpecificGravityCNG);
    setFuelSpecificGravityLNG(fuelSpecificGravityLNG);
    setFuelSpecificGravityHydrogen(fuelSpecificGravityHydrogen);
    setSpecificGravityAdBlue(specificGravityAdBlue);

    setCNGExpressedAs(cNGExpressedAs);
    setLNGExpressedAs(lNGExpressedAs);
    setHydrogenExpressedAs(hydrogenExpressedAs);
    setFuelType(fuelType);
    setUpFuelTanks(config.OFFERED_AS_OPTIONS.STANDARD, false, fuelTank1COG, fuelTank1Capacity, fuelTank1VerticalCOGConfirmed, fuelTank1VerticalCOG, fuelTank1VolumeIncludedInTare, fuelTank1LateralCOG, fuelTank1LateralCOGConfirmed,
                    fuelTank2Included, false, fuelTank2COG, fuelTank2Capacity, fuelTank2VerticalCOGConfirmed, fuelTank2VerticalCOG, fuelTank2VolumeIncludedInTare, fuelTank2LateralCOG, fuelTank2LateralCOGConfirmed,
                    fuelTank3Included, false, fuelTank3COG, fuelTank3Capacity, fuelTank3VerticalCOGConfirmed, fuelTank3VerticalCOG, fuelTank3VolumeIncludedInTare, fuelTank3LateralCOG, fuelTank3LateralCOGConfirmed,
                    fuelTank4Included, false, fuelTank4COG, fuelTank4Capacity, fuelTank4VerticalCOGConfirmed, fuelTank4VerticalCOG, fuelTank4VolumeIncludedInTare, fuelTank4LateralCOG, fuelTank4LateralCOGConfirmed,
                    fuelTank5Included, false, fuelTank5COG, fuelTank5Capacity, fuelTank5VerticalCOGConfirmed, fuelTank5VerticalCOG, fuelTank5VolumeIncludedInTare, fuelTank5LateralCOG, fuelTank5LateralCOGConfirmed,
                    fuelTank6Included, false, fuelTank6COG, fuelTank6Capacity, fuelTank6VerticalCOGConfirmed, fuelTank6VerticalCOG, fuelTank6VolumeIncludedInTare, fuelTank6LateralCOG, fuelTank6LateralCOGConfirmed,
                    fuelTank7Included, false, fuelTank7COG, fuelTank7Capacity, fuelTank7VerticalCOGConfirmed, fuelTank7VerticalCOG, fuelTank7VolumeIncludedInTare, fuelTank7LateralCOG, fuelTank7LateralCOGConfirmed,
                    fuelTank8Included, false, fuelTank8COG, fuelTank8Capacity, fuelTank8VerticalCOGConfirmed, fuelTank8VerticalCOG, fuelTank8VolumeIncludedInTare, fuelTank8LateralCOG, fuelTank8LateralCOGConfirmed);
    setUpFuelTanksFromArray(fuelTanksArrayFromServer);
    setBodyHeightExternal(bodyHeightExternal);
    setBodyLengthExternalInclRearDoors(bodyLengthExternalInclRearDoors);
    setBumperToBackOfCab(bumperToBackOfCab);
    setBumperToStartOfLoadCompartment(bumperToStartOfLoadCompartment);
    setCabGapRecommendedMin(cabGapRecommendedMin);
    setCabHeight(cabHeight);
    var tempColour = '',
        colourOptionsObject = [];
    if (colourOptions) {
        for (var i = 0; i < colourOptions.length; i++) {
            tempColour = colourOptions[i].replace(/([^a-z0-9]+)/g, '');
            colourOptionsObject.push({ itemId: 'colour' + tempColour, colour: colourOptions[i] });
        }
        setActiveCabColour(colourOptionsObject[0]);
        setColourOptions(colourOptionsObject);
    } else {
        setActiveCabColour({});
        setColourOptions([]);
        //NOTE: This is only for testing
        //setActiveCabColour({ colour: '#FF0000' });
        //setColourOptions([{ itemId: 'colour1', colour: '#FF0000' }, { itemId: 'colour2', colour: '#0000FF' }, { itemId: 'colour3', colour: '#008000' }]);
    }

    setCompleteOverallBodyAndEquipmentHeight(completeOverallBodyAndEquipmentHeight);
    setCompleteOverallLength(completeOverallLength);
    setCompleteRearOverhang(completeRearOverhang);
    setFloorHeight(floorHeight);
    setFrontOverhang(frontOverhang);
    setGrossFront(grossFront);
    setGrossRear(grossRear);
    setGrossTotal(grossTotal);
    setBodyHeightInternal(bodyHeightInternal);
    setBodyLengthInternal(bodyLengthInternal);
    //var messagesObjectArray = [];
    //for (var i = 0; i < messages.length; i++) {
    //    messagesObjectArray.push({ messageText: messages[i] });
    //}
    setMessages(messages);
    setPayloadFront(payloadFront);
    setPayloadRear(payloadRear);
    setPayloadTotal(payloadTotal);
    setPermissibleFront(permissibleFront);
    setPermissibleRear(permissibleRear);
    setPermissibleTotal(permissibleTotal);
    setRearOverhang(rearOverhang);
    setUnladenFront(unladenFront);
    setUnladenRear(unladenRear);
    setUnladenTotal(unladenTotal);
    setVehicleDrawing(vehicleDrawing);
    setVehicleTopViewDrawing(vehicleTopViewDrawing);
    
    //new for drawing template
    setBumperToStartOfBodyInternal(bumperToStartOfBodyInternal);
    setBodyLengthInternal(bodyLengthInternal);
    setRearProtrusion(rearProtrusion);
    setAxle1FrontToWheelbaseTheoreticalStart(axle1FrontToWheelbaseTheoreticalStart);
    setAxle1FrontToWheelbaseTheoreticalStartOverride(axle1FrontToWheelbaseTheoreticalStartOverride);
    setFifthWheelOffset(fifthWheelOffset);
    setFifthWheelHeightFromGround(fifthWheelHeightFromGround);
    setAxle1FrontToAxle2Front(axle1FrontToAxle2Front);
    setAxle2FrontToAxle1Rear(axle2FrontToAxle1Rear);
    setAxle1RearToAxle2Rear(axle1RearToAxle2Rear);
    setAxle2RearToAxle3Rear(axle2RearToAxle3Rear);
    setAxle3RearToAxle4Rear(axle3RearToAxle4Rear);
    setControl(control);
    setBreakpointForRearOverhangFromRearMostAxle(breakpointForRearOverhangFromRearMostAxle);
    setBreakpointForWheelbaseFromRearMostAxleFront(breakpointForWheelbaseFromRearMostAxleFront);
    setRearMostProtrudingItemBehindCabToRearAxles(rearMostProtrudingItemBehindCabToRearAxles);
    setType(type);
    setTareFifthWheelIncluded(tareFifthWheelIncluded);

    ////if (type === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
    //    setChassisHeightFront(chassisHeightRear);
    //} else {
        setChassisHeightFront(chassisHeightFront);
    //}

    setChassisHeightRear(chassisHeightRear);
    //setAxles(axles);
    _axlesHolder.createAxles(axles, config.CHASSIS_OBJECT_TYPES.VEHICLE);
    setSource(source);
    setVerticalExhaustHeight(verticalExhaustHeight);
    //setOverallLength(calculateOverallLength());
    setBullbarProtrusion(0);
    setOverallLengthExclAccessories(calculateOverallLengthExcludingAccessories());
    setOverallLengthInclAccessories(getOverallLengthInclAccessories());
    setGroundClearanceFront(groundClearanceFront);
    setGroundClearanceRear(groundClearanceRear);
    setCrewMassPerPerson(crewMassPerPerson);
    setCrewFirstRowMax(crewFirstRowMax);
    setCrewFirstRowCOG(crewFirstRowCOG);
    setNoOfCrewInFirstRow(noOfCrewInFirstRow);
    setCrewSecondRowIncluded(crewSecondRowIncluded);
    setCrewSecondRowMax(crewSecondRowMax);
    setCrewSecondRowCOG(crewSecondRowCOG);
    setNoOfCrewInSecondRow(noOfCrewInSecondRow);
    setFuelSpecificGravity(fuelSpecificGravity);
    //setFuelTank1COG(fuelTank1COG);
    //setFuelTank1VerticalCOG(fuelTank1VerticalCOG);
    //setFuelTank1Capacity(fuelTank1Capacity);
    //setFuelTank2Included(fuelTank2Included);
    //setFuelTank2COG(fuelTank2COG);
    //setFuelTank2VerticalCOG(fuelTank2VerticalCOG);
    //setFuelTank2Capacity(fuelTank2Capacity);
    //setFuelTank3Included(fuelTank3Included);
    //setFuelTank3COG(fuelTank3COG);
    //setFuelTank3VerticalCOG(fuelTank3VerticalCOG);
    //setFuelTank3Capacity(fuelTank3Capacity);
    //setFuelTank4Included(fuelTank4Included);
    //setFuelTank4COG(fuelTank4COG);
    //setFuelTank4VerticalCOG(fuelTank4VerticalCOG);
    //setFuelTank4Capacity(fuelTank4Capacity);
    //setFuelTank1VolumeIncludedInTare(fuelTank1VolumeIncludedInTare);
    //setFuelTank2VolumeIncludedInTare(fuelTank2VolumeIncludedInTare);
    //setFuelTank3VolumeIncludedInTare(fuelTank3VolumeIncludedInTare);
    //setFuelTank4VolumeIncludedInTare(fuelTank4VolumeIncludedInTare);
    setTareFront(tareFront);
    setTareRear(tareRear);
    setTareRearRear(tareRear);
    
    //New values for interpreter
    setWheelbaseTheoreticalManufacturerMin(wheelbaseTheoreticalManufacturerMin);
    setWheelbaseTheoreticalManufacturerMax(wheelbaseTheoreticalManufacturerMax)
    setOriginalAUFront(originalAUFront);
    setOriginalAURear(originalAURear);
    setGVM(gvm);
    setGCM(gcm);
    setVRating(vRating)
    setDT(vehicleDT);
    setFrontRoadFriendlySuspension(frontRoadFriendlySuspension);
    setRearRoadFriendlySuspension(rearRoadFriendlySuspension);
    setRolloverProtection(rolloverProtection)
    setSteeringAngleInner(steeringAngleInner);
    setTurningRadiusWallToWall(turningRadiusWallToWall);
    setTurningRadiusCurbToCurb(turningRadiusCurbToCurb);
    setFrontUnderrunProtection(frontUnderrunProtection);
    setWheelbaseTheoreticalMaxCut(wheelbaseTheoreticalMaxCut);
    setRearOverhangManufacturerMin(rearOverhangManufacturerMin);
    var cabTiltOpt = cabTiltConverter === config.YES_NO_OPTIONS.YES ? true : false;
    setCabTilt(cabTiltOpt);
    setCabTiltConverter(cabTiltConverter);
    setHingePointFromBumper(hingePointFromBumper);
    setHingePointFromGround(hingePointFromGround);
    setCabTiltRadius(cabTiltRadius);
    setWheelbaseTheoreticalOverride(wheelbaseTheoreticalOverride);
    setCabWidth(cabWidth);
    setRearWidth(rearWidth);
    //setOverallWidth(overallWidth);
    setTrackWidthFront(trackWidthFront);
    setTrackWidthRear(trackWidthRear);
    setChassisWidth(chassisWidth);
    setFifthWheelHeightFromGround(fifthWheelHeightFromGround);
    setTareFrontOverride(tareFrontOverride);
    setTareRearOverride(tareRearOverride);
    setCrewSecondRowVerticalCOG(crewSecondRowVerticalCOG);
    setCrewFirstRowVerticalCOG(crewFirstRowVerticalCOG);
    setVehicleVerticalCOG(vehicleVerticalCOG);
    setVehicleVerticalCOGOverride(vehicleVerticalCOGOverride);
    setTareToolsIncluded(tareToolsIncluded);
    setTareSpareWheelIncluded(tareSpareWheelIncluded);
    setAUFront(aUFront);
    setAURear(aURear);
    

    var gaFrontFromAxleGroup = _axlesHolder.getLesserOfFrontAxleManufacturerRatings();
    if (gaFrontFromAxleGroup !== 0) {
        setGAFront(gaFrontFromAxleGroup);
    } else {
        setGAFront(gAFront);
    }

    var gaRearFromAxleGroup = _axlesHolder.getLesserOfRearAxleManufacturerRatings();
    if (gaRearFromAxleGroup !== 0) {
        setGARear(gaRearFromAxleGroup);
    } else {
        setGARear(gARear);
    }

    setManufacturersFrontAxlesMassOverride(manufacturersFrontAxlesMassOverride);
    setManufacturersRearAxlesMassOverride(manufacturersRearAxlesMassOverride);
    setManufacturersGrossVehicleMassOverride(manufacturersGrossVehicleMassOverride);
    setPermissibleRearOverride(permissibleRearOverride);
    setPermissibleFrontOverride(permissibleFrontOverride);
    setPermissibleTotalOverride(permissibleTotalOverride);
    setBodyWidthManufacturerMax(bodyWidthManufacturerMax);
    setPayloadCOGType(payloadCOGType);
    setPayloadHorizontalCOG(payloadHorizontalCOG);
    setPayloadVerticalCOG(payloadVerticalCOG);
    setPayloadVerticalCOGOverride(payloadVerticalCOGOverride);

    setVehicleVerticalCOGConfirmed(vehicleVerticalCOGConfirmed);
    setPayloadVerticalCOGConfirmed(payloadVerticalCOGConfirmed);
    setFuelTank1VerticalCOGConfirmed(fuelTank1VerticalCOGConfirmed);
    setFuelTank2VerticalCOGConfirmed(fuelTank2VerticalCOGConfirmed);
    setFuelTank3VerticalCOGConfirmed(fuelTank3VerticalCOGConfirmed);
    setFuelTank4VerticalCOGConfirmed(fuelTank4VerticalCOGConfirmed);
    setFuelTank1LateralCOGConfirmed(fuelTank1LateralCOGConfirmed);
    setFuelTank2LateralCOGConfirmed(fuelTank2LateralCOGConfirmed);
    setFuelTank3LateralCOGConfirmed(fuelTank3LateralCOGConfirmed);
    setFuelTank4LateralCOGConfirmed(fuelTank4LateralCOGConfirmed);
    setCrewFirstRowVerticalCOGConfirmed(crewFirstRowVerticalCOGConfirmed);
    setCrewSecondRowVerticalCOGConfirmed(crewSecondRowVerticalCOGConfirmed);
    setTareDriverIncluded(tareDriverIncluded);
    setFifthWheelOffsetMin(fifthWheelOffsetMin);
    setFifthWheelOffsetMax(fifthWheelOffsetMax);
    setWheelbaseTheoretical(getWheelbaseTheoretical());

    if (getCrewSecondRowIncluded() === true) {
        setCrewSecondRow(true);
    } else {
        setCrewSecondRow(false);
    }
    
    //if (_source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
    //    setCrewFirstRowHorizontalCOGOverride(true);
    //    setCrewFirstRowVerticalCOGOverride(true);
    //    setCrewSecondRowHorizontalCOGOverride(true);
    //    setCrewSecondRowVerticalCOGOverride(true);
    //} else {
        setCrewFirstRowHorizontalCOGOverride(crewFirstRowHorizontalCOGOverride);
        setCrewFirstRowVerticalCOGOverride(crewFirstRowVerticalCOGOverride);
        setCrewSecondRowHorizontalCOGOverride(crewSecondRowHorizontalCOGOverride);
        setCrewSecondRowVerticalCOGOverride(crewSecondRowVerticalCOGOverride);
    //}
    
    
    setDescription(vehicleDescription);
    setVehicleTypeDistributionCode(vehicleTypeDistributionCode);

    
    setCrewFirstRowLateralCOG(crewFirstRowLateralCOG);
    setCrewFirstRowLateralCOGOverride(crewFirstRowLateralCOGOverride);
    setCrewFirstRowLateralCOGConfirmed(crewFirstRowLateralCOGConfirmed);
    setCrewSecondRowLateralCOG(crewSecondRowLateralCOG);
    setCrewSecondRowLateralCOGOverride(crewSecondRowLateralCOGOverride);
    setCrewSecondRowLateralCOGConfirmed(crewSecondRowLateralCOGConfirmed);
    setVehicleLateralCOG(vehicleLateralCOG);
    setVehicleLateralCOGOverride(vehicleLateralCOGOverride);
    setVehicleLateralCOGConfirmed(vehicleLateralCOGConfirmed);
    setPayloadLateralCOG(payloadLateralCOG);
    setPayloadLateralCOGOverride(payloadLateralCOGOverride);
    setPayloadLateralCOGConfirmed(payloadLateralCOGConfirmed);

    setFrontAxleAverageTyreLife(frontAxleAverageTyreLife);
    setRearAxleAverageTyreLife(rearAxleAverageTyreLife);
    setSecondsteerAverageTyreLife(secondsteerAverageTyreLife);
    setPusherAxleAverageTyreLife(pusherAxleAverageTyreLife);
    setTagAxleAverageTyreLife(tagAxleAverageTyreLife);
    setTrailerFrontAxleAverageTyreLife(trailerFrontAxleAverageTyreLife);
    setTrailerRearAxleAverageTyreLife(trailerRearAxleAverageTyreLife);

    setLoadCompartmentWidth(loadCompartmentWidth);
    setTyrePriceFirstSet(tyrePriceFirstSet);
    setTyrePriceRemainingSets(tyrePriceRemainingSets);
    setCabToAxlesOverride(cabToAxlesOverride || false);
    
    setVehicleBridgeFormulaPermissible(vehicleBridgeFormulaPermissible || 0);
    setVehicleBridgeFormulaPermissibleOverride(vehicleBridgeFormulaPermissibleOverride || false);
    setCombinationBridgeFormulaPermissible(combinationBridgeFormulaPermissible || 0);
    setCombinationBridgeFormulaPermissibleOverride(combinationBridgeFormulaPermissibleOverride || false);
    setBridgeFormulaRearPermissible(bridgeFormulaRearPermissible || 0);
    setBridgeFormulaRearPermissibleOverride(bridgeFormulaRearPermissibleOverride || false);
    
    convertToExpressedASFromKGIfNecessary();

    
    // setExpressedAs(getCurrentFuelTypeExpressedAs());

    setDevicesForIndirectVision(devicesForIndirectVision);
    setCommercialVehicleBrakeSystems(commercialVehicleBrakeSystems);
    setAdvancedEmergencyBraking(advancedEmergencyBraking);
    setLaneDepartureWarning(laneDepartureWarning);
    setSideUnderrunProtection(sideUnderrunProtection);
    setConspicuityMarkingsToTheRear(conspicuityMarkingsToTheRear);
    setConspicuityMarkingsToBothSides(conspicuityMarkingsToBothSides);
    setBlindSpotInformationSystems(blindSpotInformationSystems);

    
    setEngineMaximumTorqueNm(engineMaximumTorqueNm);
    setEngineMaximumTorqueLbFt(engineMaximumTorqueLbFt);
    setTransmissionFirstGearRatio(transmissionFirstGearRatio);
    setTransmissionReverseGearRatio(transmissionReverseGearRatio);
    setTransmissionTorqueConverterMultiplication(transmissionTorqueConverterMultiplication);
    setTransmissionParkPAWL(transmissionParkPAWL);
    setEmissionsStandard(emissionsStandard);

    setUpdateCounter(updateCounter);

    if (_source === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
        setCrewFirstRowHorizontalCOGOverride(true);
        setCrewFirstRowVerticalCOGOverride(true);
        setCrewFirstRowLateralCOGOverride(true);
        setCrewSecondRowHorizontalCOGOverride(true);
        setCrewSecondRowVerticalCOGOverride(true);
        setCrewSecondRowLateralCOGOverride(true);
        //setTareFrontOverride(true);
        //setTareRearOverride(true);
        setVehicleVerticalCOGOverride(true);
        setVehicleLateralCOGOverride(true);
        
        //setManufacturersFrontAxlesMassOverride(true);
        //setManufacturersRearAxlesMassOverride(true);
        //setManufacturersGrossVehicleMassOverride(true);
        //setPermissibleFrontOverride(true);
        //setPermissibleRearOverride(true);
        //setPermissibleTotalOverride(true);
        setManufacturersGrossCombinationMassOverride(true);
        setCabWidthOverride(true);
        setRearWidthOverride(true);
        setTrackWidthFrontOverride(true);
        setTrackWidthRearOverride(true);
        setChassisWidthOverride(true);
        if (axle1FrontToAxle2Front > 0) {
            setAxle1FrontToAxle2FrontOverride(true);
        }
        //setAxle2FrontToAxle1RearOverride(true);
        if (axle1RearToAxle2Rear > 0) {
            setAxle1RearToAxle2RearOverride(true);
        }
        if (axle2RearToAxle3Rear > 0) {
            setAxle2RearToAxle3RearOverride(true);
        }
        if (axle3RearToAxle4Rear > 0) {
            setAxle3RearToAxle4RearOverride(true);
        }
        //setFuelSpecificGravityOverride(true);
    }

    //#endregion Initialize

    //#region Public members
    
    
    

    function getFrontalAreaHeight(includeFrontAndRearAdditionInFrontalAreaCalculation) {
        /*
            Dim frontAddition, rearAddition, totalHeight As Double

        If ChassisHeightRear > ChassisHeightFront Then
            frontAddition = ChassisHeightRear - ChassisHeightFront
            rearAddition = 0
        Else
            frontAddition = 0
            rearAddition = ChassisHeightFront - ChassisHeightRear
        End If

        If _Type = VehicleTypes.CompleteVehicle Then
            totalHeight = Math.Max(_Common.VehicleCabHeight + frontAddition, _Common.VehicleCabHeight + rearAddition)
        Else

            totalHeight = Math.Max(_Common.VehicleCabHeight + frontAddition, _Accessories.AccessoryTopMostPoint(_ChassisHeightRear) + rearAddition)

            If _Accessories.Body IsNot Nothing Then
                totalHeight = Math.Max(totalHeight, BottomOfVehicleToPayloadDeckStart + _Accessories.Body.PayloadObjects.MaxHeightOfPayloadObjects)
            End If

        End If

        Return totalHeight
        */

        
        var totalHeight;
        //var hookliftAddition = 0;
        var tempBody = getBody();
        //if (tempBody !== null && tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
        //    var tempHooklift = _accessoryHolder.getHooklift();
        //    hookliftAddition = tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight();
        //}
        if (includeFrontAndRearAdditionInFrontalAreaCalculation !== undefined && includeFrontAndRearAdditionInFrontalAreaCalculation === true) {
            //if (getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            //    totalHeight = Math.max(getCabHeight() + getFrontAddition(), getCabHeight() + getRearAddition());
            //    //totalHeight = getCabHeight();
            //} else {
            //    totalHeight = Math.max(getCabHeight() + getFrontAddition(), _accessoryHolder.getAccessoryTopMostPoint(getChassisHeightRear()) + getRearAddition());
            //    //totalHeight = Math.max(getCabHeight(), _accessoryHolder.getAccessoryTopMostPoint(getChassisHeightRear()));

            //    if (getBody() !== null) {
            //        totalHeight = Math.max(totalHeight, getBottomOfVehicleToPayloadDeckStart() + getPayloadHolder().getMaxHeightOfPayloadObjects());
            //    }
            //}
            
            totalHeight = Math.max(getCabHeight() + getFrontAddition(), getCabHeight() + getRearAddition());
            totalHeight = Math.max(totalHeight, _accessoryHolder.getAccessoryTopMostPoint(getChassisHeightRear()) + getRearAddition());
            totalHeight = Math.max(totalHeight, getBottomOfVehicleToPayloadDeckStart() + getPayloadHolder().getMaxHeightOfPayloadObjects());
                
            
        } else {
            //if (getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            //    //totalHeight = Math.max(getCabHeight() + getFrontAddition(), getCabHeight() + getRearAddition());
            //    totalHeight = getCabHeight();
            //} else {
            //    //totalHeight = Math.max(getCabHeight() + getFrontAddition(), _accessoryHolder.getAccessoryTopMostPoint(getChassisHeightRear()) + getRearAddition());
            //    totalHeight = Math.max(getCabHeight(), _accessoryHolder.getAccessoryTopMostPoint(getChassisHeightRear()));

            //    if (getBody() !== null) {
            //        totalHeight = Math.max(totalHeight, getBottomOfVehicleToPayloadDeckStart() + getPayloadHolder().getMaxHeightOfPayloadObjects());
            //    }
            //}
            
            totalHeight = getCabHeight();
            totalHeight = Math.max(getCabHeight(), _accessoryHolder.getAccessoryTopMostPoint(getChassisHeightRear()));
            totalHeight = Math.max(totalHeight, getBottomOfVehicleToPayloadDeckStart() + getPayloadHolder().getMaxHeightOfPayloadObjects());
            
        }
        
        return totalHeight;
    }

    function getFrontalAreaHeightExcludingPayload(includeFrontAndRearAdditionInFrontalAreaCalculation) {
       

        
        var totalHeight;
       
        var tempBody = getBody();
       
        if (includeFrontAndRearAdditionInFrontalAreaCalculation !== undefined && includeFrontAndRearAdditionInFrontalAreaCalculation === true) {
            
            
            totalHeight = Math.max(getCabHeight() + getFrontAddition(), getCabHeight() + getRearAddition());
            totalHeight = Math.max(totalHeight, _accessoryHolder.getAccessoryTopMostPoint(getChassisHeightRear()) + getRearAddition());
            // totalHeight = Math.max(totalHeight, getBottomOfVehicleToPayloadDeckStart() + getPayloadHolder().getMaxHeightOfPayloadObjects());
                
            
        } else {
            
            
            totalHeight = getCabHeight();
            totalHeight = Math.max(getCabHeight(), _accessoryHolder.getAccessoryTopMostPoint(getChassisHeightRear()));
            // totalHeight = Math.max(totalHeight, getBottomOfVehicleToPayloadDeckStart() + getPayloadHolder().getMaxHeightOfPayloadObjects());
            
        }
        
        return totalHeight;
    }

    function getFrontOfVehicleToPayloadDeckStart() {
        //If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
        //Return BumperToBackOfCab + _Accessories.Body.HorizontalGap +
        //        Accessories.Body.HeadboardThickness
        //ElseIf _Type = VehicleTypes.CompleteVehicle Then
        //Return BumperToStartOfBodyInternal
        //Else
        //Return 0
        //End If
        if (getBody() !== null) {
            return getBumperToBackOfCab() + _accessoryHolder.getBodyGap() + getBody().getHeadboardThickness();
        } else if (getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return getBumperToStartOfBodyInternal();
        } else {
            return 0;
        }

    }

    function getFrontToPayloadDeckStart() {
        return getFrontOfVehicleToPayloadDeckStart();
    }

    function getBottomOfVehicleToPayloadDeckStart() {
        //If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
        //Return ChassisHeightMax + Accessories.Body.SubframeHeight + Accessories.Body.SubstructureHeight
        //ElseIf _Type = VehicleTypes.CompleteVehicle Then
        //Return FloorHeight
        //Else
        //Return 0
        //End If
        var tempBody = getBody();
        if (tempBody !== null) {
            if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
                return getChassisHeightMax() - tempBody.getBottomOfBodyToTopOfChassis();
            } else {
                var hookliftEquipmentVerticalAddition = 0;
                if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                    var tempHooklift = _accessoryHolder.getHooklift();
                    hookliftEquipmentVerticalAddition = tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight();
                }
                return getChassisHeightMax() + hookliftEquipmentVerticalAddition + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
            }
            
        } else {
            return getFloorHeight();
        }
    }

    function getBottomToPayloadDeckStart() {
        return getBottomOfVehicleToPayloadDeckStart();
    }

    function getLeftOfVehicleToPayloadDeckStart() {
        //If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
        //Return ChassisHeightMax + Accessories.Body.SubframeHeight + Accessories.Body.SubstructureHeight
        //ElseIf _Type = VehicleTypes.CompleteVehicle Then
        //Return FloorHeight
        //Else
        //Return 0
        //End If

        if (getBody() !== null) {
            return getBody().getSidewallLeft();
        } else if (getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return (getRearWidth() - getLoadCompartmentWidth())/2;
        } else {
            return 0;
        }
    }
    
    function getLeftToPayloadDeckStart() {
        return getLeftOfVehicleToPayloadDeckStart();
    }
    

    function getDistanceFromWheelbaseTheoreticalEnd(COG, valueFromFront) {
        if (valueFromFront === true) {
            return getWheelbaseTheoretical() - (COG - (getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart()));
        } else {
            return getWheelbaseTheoretical() - (COG - getAxle1FrontToWheelbaseTheoreticalStart());
        }
        
    }

    function getDistanceFromWheelbaseTheoreticalStart(cogFromFront) {
        //return (getAxle1Front() + getAxle1FrontToWheelbaseTheoreticalStart()) - COG;
        return cogFromFront - (getAxle1FrontToWheelbaseTheoreticalStart() + getFrontOverhang());
    }

    function calculateCabToChassisEnd() {
        return getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() + getRearOverhang() - getBumperToBackOfCab();
    }

    function calculateOverallLength() {
        return getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() +
                getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() +
                getRearOverhang() + getRearProtrusion();
    }

    function calculateOverallLengthExcludingAccessories() {
        return getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() +
                getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() +
                getRearOverhang() + getRearProtrusion();
    }

    //function getStartOfVehicle() {
    //    /*
    //    Dim maxProtrusion As Double = 0

    //    For Each accessory In Accessories

    //        If TypeOf accessory Is FileLoadingAccessoryBullbar Then
    //            maxProtrusion = Math.Min(maxProtrusion, -DirectCast(accessory, FileLoadingAccessoryBullbar).Protrusion)
    //        ElseIf TypeOf accessory Is FileLoadingAccessoryOther Then
    //            maxProtrusion = Math.Min(maxProtrusion, DirectCast(accessory, FileLoadingAccessoryOther).HorizontalGap + BumperToBackOfCab)
    //        End If

    //    Next

    //    Return -maxProtrusion
    //    */
    //    var maxProtrusion = 0;

    //    _accessoryHolder.getAccessories().forEach(function (accessory) {
    //        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BULLBAR) {
    //            maxProtrusion = Math.min(maxProtrusion, -accessory.getProtrusion());
    //        } else if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
    //            maxProtrusion = Math.min(maxProtrusion, -accessory.getHorizontalGap() + getBumperToBackOfCab());
    //        }
    //    });

    //    return maxProtrusion;
    //}


    //function calculateCabToAxles() {

    //    var distanceToAxle1Rear = getDistanceToAxle1Rear();

    //    //var nextSection;
    //    //if (getAxle3RearToAxle4Rear() !== 0) {
    //    //    nextSection = (getRearAxleSpan()) / 2;
    //    //} else if (getAxle2RearToAxle3Rear() !== 0) {
    //    //    nextSection = (getRearAxleSpan()) / 2;
    //    //} else if (getAxle1RearToAxle2Rear() !== 0) {
    //    //    nextSection = getRearAxleSpan() / 2;
    //    //} else {
    //    //    nextSection = 0;
    //    //}

    //    //#important
    //    /*
    //      ' NOTE THAT WE ARE DELIBERATELY FLOORING THIS, AS IT WAS IMPLICITLY FLOORING IT BEFORE. THIS MEANT THAT WHEN THE SAME CALC WAS DONE IN THE HTML5
    //   ' APP, IT WAS GETTING A DIFFERENT RESULT. NOW WE WILL ALSO BE EXPLICITLY FLOORING IT THERE TO SOLVE THE PROBLEM. WHEN WE CONVERT THIS ATTRIBUTE
    //   ' TO A FLOAT, WE WILL HAVE TO REMOVE THE FLOORING HERE AND IN THE HTML5 APP AGAIN
    //    */

    //    //return Math.floor((distanceToAxle1Rear + getRearAxleSpan() / 2) - getBumperToBackOfCab());
    //    return (distanceToAxle1Rear + getRearAxleSpan() / 2) - getBumperToBackOfCab();
    //}

    function getDistanceToAxle1Rear() {
        return getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear();
    }

    function getDistanceFromAxle1RearToFirstDownAxle() {
        var frontMostDownAxlePositionAtRear = _axlesHolder.getFrontMostDownAxlePositionAtRear();

        switch (frontMostDownAxlePositionAtRear) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                return 0;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                return getAxle1RearToAxle2Rear();
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                return getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear();
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                return getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear();
        }
        return 0;
    }

    function getDistanceFromAxle1RearToFirstDownDrivenAxle() {
        var frontMostDownDrivenAxlePositionAtRear = _axlesHolder.getFirstRearDrivenAxlePosition();

        switch (frontMostDownDrivenAxlePositionAtRear) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                return 0;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                return getAxle1RearToAxle2Rear();
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                return getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear();
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                return getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear();
        }
        return 0;
    }

    function getRearAxleSpan(axlePos, replacementAxlePosValue) {
        var numRearAxles = _axlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) + _axlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) + _axlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.TAG);

            if (numRearAxles === 4) {
                return locGetAxle1RearToAxle2Rear() + locGetAxle2RearToAxle3Rear() + locGetAxle3RearToAxle4Rear();
            } else if (numRearAxles === 3) {
                return locGetAxle1RearToAxle2Rear() + locGetAxle2RearToAxle3Rear();
            } else if (numRearAxles === 2) {
                return locGetAxle1RearToAxle2Rear();
            } else {
                return 0;
            }

            function locGetAxle1RearToAxle2Rear() {
                if (axlePos && axlePos === 1) {
                    return replacementAxlePosValue;
                }
                return getAxle1RearToAxle2Rear();
            }
            
            function locGetAxle2RearToAxle3Rear() {
                if (axlePos && axlePos === 2) {
                    return replacementAxlePosValue;
                }
                return getAxle2RearToAxle3Rear();
            }

            function locGetAxle3RearToAxle4Rear() {
                if (axlePos && axlePos === 3) {
                    return replacementAxlePosValue;
                }
                return getAxle3RearToAxle4Rear();
            }
    }

    function getAxle1RearToCentreOfRearAxles() {
        var centreOfRearAxleSpanFromAxle1Rear = 0;
        var rearAxleSpan = getRearAxleSpan();
        if (rearAxleSpan > 0) {
            centreOfRearAxleSpanFromAxle1Rear = rearAxleSpan / 2;
        }
        return centreOfRearAxleSpanFromAxle1Rear;
    }

    function getAxle1RearToCentreOfRearDrivenAxles() {
        var centreOfRearDrivenAxleSpanFromAxle1Rear = 0;
        var rearDrivenAxleSpan = getRearDrivenAxleSpan();
        if (rearDrivenAxleSpan > 0) {
            centreOfRearDrivenAxleSpanFromAxle1Rear = rearDrivenAxleSpan / 2;
        }
        return getAxle1RearToFirstRearDrivenAxle() + centreOfRearDrivenAxleSpanFromAxle1Rear;
    }

    //function getAxle1ToWheelbaseEnd() {
    //    return getWheelbaseSpecified() - (getAxle1FrontToAxle2Front() - getAxle1FrontToWheelbaseStart() + getAxle1FrontToAxle2Front());
    //}

    //function getAxle1FrontToWheelbaseStart() {
    //    return getOverallLengthExclAccessories() - getFrontOverhang() - getWheelbaseSpecified() - getRearOverhangTotal();
    //}

    function getAxle1RearToStartOfAxleSection(rearAxleSection) {
        switch (rearAxleSection) {
            case config.AXLE_SECTIONS.AXLE_1_REAR_TO_AXLE_2_REAR:
                return 0;
            case config.AXLE_SECTIONS.AXLE_2_REAR_TO_AXLE_3_REAR:
                return getAxle1RearToAxle2Rear();
            case config.AXLE_SECTIONS.AXLE_3_REAR_TO_AXLE_4_REAR:
                return getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear();
        }
        return 0;
    }

    function getRearDrivenAxleSpan() {
        var axle1Rear = _axlesHolder.getAxle1Rear(),
            axle2Rear = _axlesHolder.getAxle2Rear(),
            axle3Rear = _axlesHolder.getAxle3Rear(),
            axle4Rear = _axlesHolder.getAxle4Rear();
        
        var rearDrivenAxleSpan = 0;

        
        if (axle1Rear !== null && axle2Rear !== null) {
            if (axle1Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle1Rear.getDrivenAxle() === true && axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle2Rear.getDrivenAxle() === true) {
                rearDrivenAxleSpan += getAxle1RearToAxle2Rear();
            } 
        }
        if (axle2Rear !== null && axle3Rear !== null) {
            if (axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle2Rear.getDrivenAxle() === true && axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle3Rear.getDrivenAxle() === true) {
                rearDrivenAxleSpan += getAxle2RearToAxle3Rear();
            } 
        }
        if (axle3Rear !== null && axle4Rear !== null) {
            if (axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle3Rear.getDrivenAxle() === true && axle4Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle4Rear.getDrivenAxle() === true) {
                rearDrivenAxleSpan += getAxle3RearToAxle4Rear();
            } 
        }
        

        return rearDrivenAxleSpan;
                    
    }

    function getAxle1RearToFirstRearDrivenAxle() {
        var axle1Rear = _axlesHolder.getAxle1Rear(),
            axle2Rear = _axlesHolder.getAxle2Rear(),
            axle3Rear = _axlesHolder.getAxle3Rear(),
            axle4Rear = _axlesHolder.getAxle4Rear();

        

        if (axle1Rear !== null && axle1Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle1Rear.getDrivenAxle() === true) {
            return 0;
        }
        if (axle2Rear !== null && axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle2Rear.getDrivenAxle() === true) {
            return getAxle1RearToAxle2Rear();
        }
        if (axle3Rear !== null && axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle3Rear.getDrivenAxle() === true) {
            return getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear();
        }
        if (axle4Rear !== null && axle4Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle4Rear.getDrivenAxle() === true) {
            return getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear();
        }
        return 0;
    }

    function getNumberOfRearDrivenAxles() {
        var numberOfRearDrivenAxles = 0,
            axle1Rear = _axlesHolder.getAxle1Rear(),
            axle2Rear = _axlesHolder.getAxle2Rear(),
            axle3Rear = _axlesHolder.getAxle3Rear(),
            axle4Rear = _axlesHolder.getAxle4Rear();

        if (axle1Rear !== null && axle1Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle1Rear.getDrivenAxle() === true) {
            numberOfRearDrivenAxles += 1;
        }
        if (axle2Rear !== null && axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle2Rear.getDrivenAxle() === true) {
            numberOfRearDrivenAxles += 1;
        }
        if (axle3Rear !== null && axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle3Rear.getDrivenAxle() === true) {
            numberOfRearDrivenAxles += 1;
        }
        if (axle4Rear !== null && axle4Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle4Rear.getDrivenAxle() === true) {
            numberOfRearDrivenAxles += 1;
        }
        return numberOfRearDrivenAxles;
    }

    function getBumperToBackOfRearMostEquipment() {
        var ignoreItemsForBumperToBackOfRearMostEquipment = true;
        var rightMostaccessoryPoint = _accessoryHolder.getAccessoryRightMostPoint(getBumperToBackOfCab(), getFrontOverhang(), 0, ignoreItemsForBumperToBackOfRearMostEquipment);
        return rightMostaccessoryPoint === 0 ? getBumperToBackOfCab() : rightMostaccessoryPoint;

        /*#
        Dim rightMostAccessory As Double = _BumperToBackOfCab

        If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Crane) = 1 Then
            rightMostAccessory = Math.Max(rightMostAccessory,
                                            _BumperToBackOfCab + _Accessories.Crane.HorizontalGap + _Accessories.Crane.Length)
        End If

        If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.CrewCab) = 1 Then
            rightMostAccessory = Math.Max(rightMostAccessory,
                                            _BumperToBackOfCab + _Accessories.CrewCab.HorizontalGap + _Accessories.CrewCab.Length)
        End If

        If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FuelTank) > 0 Then
            If _Accessories.FuelTank(1).Type = FuelTankTypes.BehindCab Then
                rightMostAccessory = Math.Max(rightMostAccessory,
                                                _BumperToBackOfCab + _Accessories.FuelTank(1).HorizontalGap + _Accessories.FuelTank(1).Length)
            End If
        End If

        If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FuelTank) > 1 Then
            If _Accessories.FuelTank(2).Type = FuelTankTypes.BehindCab Then
                rightMostAccessory = Math.Max(rightMostAccessory,
                                                _BumperToBackOfCab + _Accessories.FuelTank(2).HorizontalGap + _Accessories.FuelTank(2).Length)
            End If
        End If

        If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FuelTank) > 2 Then
            If _Accessories.FuelTank(3).Type = FuelTankTypes.BehindCab Then
                rightMostAccessory = Math.Max(rightMostAccessory,
                                                _BumperToBackOfCab + _Accessories.FuelTank(3).HorizontalGap + _Accessories.FuelTank(3).Length)
            End If
        End If

        If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FuelTank) > 3 Then
            If _Accessories.FuelTank(4).Type = FuelTankTypes.BehindCab Then
                rightMostAccessory = Math.Max(rightMostAccessory,
                                                _BumperToBackOfCab + _Accessories.FuelTank(4).HorizontalGap + _Accessories.FuelTank(4).Length)
            End If
        End If

        If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Headboard) = 1 Then
            rightMostAccessory = Math.Max(rightMostAccessory,
                                            _BumperToBackOfCab + _Accessories.Headboard.HorizontalGap + _Accessories.Headboard.Length)
        End If

        If _Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Rollover) = 1 Then
            rightMostAccessory = Math.Max(rightMostAccessory,
                                            _BumperToBackOfCab + _Accessories.Rollover.HorizontalGap + _Accessories.Rollover.Length)
        End If

        Return rightMostAccessory
        */
    }

    function getVehicleRearMinus1ToFirstMainAxle() {
        let numMinusAxles = _axlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);

        if(numMinusAxles === 1) {
            return getAxle1RearToAxle2Rear();
        }else if(numMinusAxles === 2) {
            return getAxle2RearToAxle3Rear();
        }else if(numMinusAxles === 3) {
            return getAxle3RearToAxle4Rear();
        }
        return 0;
    }

    function getChassisSectionLabel(sectionName) {
        switch (sectionName) {
            case config.VALUE_TYPE.FRONTOVERHANG:
                return config.getTranslationText('156');
            case config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT:
                if (_axlesHolder.getAxle(0, 2) !== null) {
                    return config.getTranslationText('1229');//'A - B';
                } else {
                    return '';
                }
            case config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR:
                if (_axlesHolder.getAxle(0, 2) !== null) {
                    return config.getTranslationText('1230');//'B - C';
                } else {
                    return config.getTranslationText('1229');//'A - B';
                }
            case config.VALUE_TYPE.AXLE1REARTOAXLE2REAR:
                if (_axlesHolder.getAxle(0, 2) !== null && _axlesHolder.getAxle(1, 2) !== null) {
                    return config.getTranslationText('1231');//'C - D';
                } else if (_axlesHolder.getAxle(1, 2) !== null) {
                    return config.getTranslationText('1230');//'B - C';
                } else {
                    return '';
                }
            case config.VALUE_TYPE.AXLE2REARTOAXLE3REAR:
                if (_axlesHolder.getAxle(0, 2) !== null && _axlesHolder.getAxle(1, 2) !== null && _axlesHolder.getAxle(1, 3) !== null) {
                    return config.getTranslationText('1236');//'D - E';
                } else if (_axlesHolder.getAxle(1, 2) !== null && _axlesHolder.getAxle(1, 3) !== null) {
                    return config.getTranslationText('1235');//'C - D';
                } else {
                    return '';
                }
            case config.VALUE_TYPE.AXLE3REARTOAXLE4REAR:
                if (_axlesHolder.getAxle(0, 2) !== null && _axlesHolder.getAxle(1, 2) !== null && _axlesHolder.getAxle(1, 3) !== null && _axlesHolder.getAxle(1, 4) !== null) {
                    return config.getTranslationText('1237');//'E - F';
                } else if (_axlesHolder.getAxle(1, 2) !== null && _axlesHolder.getAxle(1, 3) !== null && _axlesHolder.getAxle(1, 4) !== null) {
                    return config.getTranslationText('1236');//'D - E';
                } else {
                    return '';
                }
            case config.VALUE_TYPE.REAROVERHANG:
                if (_axlesHolder.getAxle(0, 2) !== null && _axlesHolder.getAxle(1, 2) !== null && _axlesHolder.getAxle(1, 3) !== null && _axlesHolder.getAxle(1, 4) !== null) {
                    return config.getTranslationText('1242');//'F - End';
                } else if (_axlesHolder.getAxle(0, 2) !== null && _axlesHolder.getAxle(1, 2) !== null && _axlesHolder.getAxle(1, 3) !== null) {
                    return config.getTranslationText('1241');//'E - End';
                } else if (_axlesHolder.getAxle(0, 2) !== null && _axlesHolder.getAxle(1, 2) !== null) {
                    return config.getTranslationText('1240');//'D - End';
                } else if (_axlesHolder.getAxle(1, 2) !== null && _axlesHolder.getAxle(1, 3) !== null && _axlesHolder.getAxle(1, 4) !== null) {
                    return config.getTranslationText('1241');//'E - End';
                } else if (_axlesHolder.getAxle(1, 2) !== null && _axlesHolder.getAxle(1, 3) !== null) {
                    return config.getTranslationText('1240');//'D - End';
                } else if (_axlesHolder.getAxle(1, 2) !== null) {
                    return config.getTranslationText('1239');//'C - End';
                } else {
                    return config.getTranslationText('1238');//'B - End';
                }
            default:
                return '';
        }
    }

    function hasAccessory(accessoryType) {
        return _accessoryHolder.hasAccessory(accessoryType);
    }

    function deleteAccessory(accessoryId) {

        var accessoryToDelete = _accessoryHolder.getAccessoryById(accessoryId);
        if (accessoryToDelete.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            _payloadHolder.setPayloadObjects([]);
        }
        if (accessoryToDelete.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && accessoryToDelete.getAccessorySubType() === config.ACCESSORY_TYPES.BULLBAR) {
            setBullbarAdded(false);
        }else if(accessoryToDelete.getAccessoryType() === config.ACCESSORY_TYPES.OTHER && accessoryToDelete.getAccessorySubType() === config.ACCESSORY_TYPES.SNOWPLOW) {
            setSnowploughAdded(false);
        }else if(accessoryToDelete.getAccessoryType() === config.ACCESSORY_TYPES.CRANE) {
            setCraneAdded(false);
        }else if(accessoryToDelete.getAccessoryType() === config.ACCESSORY_TYPES.FIFTH_WHEEL) {
            setFifthWheelAdded(false);
        }
        _accessoryHolder.removeAccessoryById(accessoryId);
        //var filteredAccessoriesArray = ko.utils.arrayFilter(getAccessories(), function (accessory) {
        //    if (accessory.getId !== undefined) {
        //        return accessoryId !== accessory.getId();
        //    } 
        //});
        //setAccessories(filteredAccessoriesArray);
    }

    function deleteFuelTank(fuelTankIdToDelete) {
        var filteredFuelTanks = getFuelTanks().filter(function (fuelTank) {
            return fuelTank.getId() !== fuelTankIdToDelete || fuelTank.isFactoryFitted();
        });
        setFuelTanks(filteredFuelTanks);
        updateFuelTankNumbers();
    }

    function updateFuelTankNumbers() {
        getFuelTanks().forEach(function (fuelTank, index){
            fuelTank.setNumber(index + 1);
        })
    }

    function addNewFuelTank(number, includeInCalculation, offeredAs, cogOverride, cog, verticalCogOverride, verticalCog, capacity, volumeIncludedInTare, volumeIncludedInExtras, doNotDelete, lateralCog, lateralCogOverride, verticalCOGConfirmed, lateralCOGConfirmed, contents) {
        addFuelTank(new FuelTank(globals.nextId(), number, includeInCalculation, offeredAs, cogOverride, cog, verticalCogOverride, verticalCog, capacity, volumeIncludedInTare, volumeIncludedInExtras, doNotDelete, lateralCog, lateralCogOverride, verticalCOGConfirmed, lateralCOGConfirmed, contents));
    }


    function setUpFuelTanks(fuelTank1OfferedAs, fuelTank1COGOverride, fuelTank1COG, fuelTank1Capacity, fuelTank1VerticalCOGOverride, fuelTank1VerticalCOG, fuelTank1VolumeIncludedInTare, fuelTank1LateralCog, fuelTank1LateralCogOverride, 
                            fuelTank2OfferedAs, fuelTank2COGOverride, fuelTank2COG, fuelTank2Capacity, fuelTank2VerticalCOGOverride, fuelTank2VerticalCOG, fuelTank2VolumeIncludedInTare, fuelTank2LateralCog, fuelTank2LateralCogOverride,
                            fuelTank3OfferedAs, fuelTank3COGOverride, fuelTank3COG, fuelTank3Capacity, fuelTank3VerticalCOGOverride, fuelTank3VerticalCOG, fuelTank3VolumeIncludedInTare, fuelTank3LateralCog, fuelTank3LateralCogOverride,
                            fuelTank4OfferedAs, fuelTank4COGOverride, fuelTank4COG, fuelTank4Capacity, fuelTank4VerticalCOGOverride, fuelTank4VerticalCOG, fuelTank4VolumeIncludedInTare, fuelTank4LateralCog, fuelTank4LateralCogOverride,
                            fuelTank5OfferedAs, fuelTank5COGOverride, fuelTank5COG, fuelTank5Capacity, fuelTank5VerticalCOGOverride, fuelTank5VerticalCOG, fuelTank5VolumeIncludedInTare, fuelTank5LateralCog, fuelTank5LateralCogOverride,
                            fuelTank6OfferedAs, fuelTank6COGOverride, fuelTank6COG, fuelTank6Capacity, fuelTank6VerticalCOGOverride, fuelTank6VerticalCOG, fuelTank6VolumeIncludedInTare, fuelTank6LateralCog, fuelTank6LateralCogOverride,
                            fuelTank7OfferedAs, fuelTank7COGOverride, fuelTank7COG, fuelTank7Capacity, fuelTank7VerticalCOGOverride, fuelTank7VerticalCOG, fuelTank7VolumeIncludedInTare, fuelTank7LateralCog, fuelTank7LateralCogOverride,
                            fuelTank8OfferedAs, fuelTank8COGOverride, fuelTank8COG, fuelTank8Capacity, fuelTank8VerticalCOGOverride, fuelTank8VerticalCOG, fuelTank8VolumeIncludedInTare, fuelTank8LateralCog, fuelTank8LateralCogOverride) {
        clearFuelTanks();
        let fuelTankNumber = 1;
        addNewFuelTank(fuelTankNumber++, true, fuelTank1OfferedAs, fuelTank1COGOverride, fuelTank1COG, false, fuelTank1VerticalCOG, fuelTank1Capacity, fuelTank1VolumeIncludedInTare, fuelTank1Capacity - fuelTank1VolumeIncludedInTare, true, fuelTank1LateralCog, false, fuelTank1VerticalCOGOverride, fuelTank1LateralCogOverride, _fuelType);
        

        if (fuelTank2OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD || fuelTank2OfferedAs === config.OFFERED_AS_OPTIONS.OPTIONAL) {
            var fuelTank2IncludeInCalculation = false;
            if (fuelTank2OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD) {
                fuelTank2IncludeInCalculation = true;
            }
            addNewFuelTank(fuelTankNumber++, fuelTank2IncludeInCalculation, fuelTank2OfferedAs, fuelTank2COGOverride, fuelTank2COG, false, fuelTank2VerticalCOG, fuelTank2Capacity, fuelTank2VolumeIncludedInTare, fuelTank2Capacity - fuelTank2VolumeIncludedInTare, false, fuelTank2LateralCog, false, fuelTank2VerticalCOGOverride, fuelTank2LateralCogOverride, _fuelType);
        }

        if (fuelTank3OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD || fuelTank3OfferedAs === config.OFFERED_AS_OPTIONS.OPTIONAL) {
            var fuelTank3IncludeInCalculation = false;
            if (fuelTank3OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD) {
                fuelTank3IncludeInCalculation = true;
            }
            addNewFuelTank(fuelTankNumber++, fuelTank3IncludeInCalculation, fuelTank3OfferedAs, fuelTank3COGOverride, fuelTank3COG, false, fuelTank3VerticalCOG, fuelTank3Capacity, fuelTank3VolumeIncludedInTare, fuelTank3Capacity - fuelTank3VolumeIncludedInTare, false, fuelTank3LateralCog, false, fuelTank3VerticalCOGOverride, fuelTank3LateralCogOverride, _fuelType);
        }

        if (fuelTank4OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD || fuelTank4OfferedAs === config.OFFERED_AS_OPTIONS.OPTIONAL) {
            var fuelTank4IncludeInCalculation = false;
            if (fuelTank4OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD) {
                fuelTank4IncludeInCalculation = true;
            }
            addNewFuelTank(fuelTankNumber++, fuelTank4IncludeInCalculation, fuelTank4OfferedAs, fuelTank4COGOverride, fuelTank4COG, false, fuelTank4VerticalCOG, fuelTank4Capacity, fuelTank4VolumeIncludedInTare, fuelTank4Capacity - fuelTank4VolumeIncludedInTare, false, fuelTank4LateralCog, false, fuelTank4VerticalCOGOverride, fuelTank4LateralCogOverride, _fuelType);
        }

        if (fuelTank5OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD || fuelTank5OfferedAs === config.OFFERED_AS_OPTIONS.OPTIONAL) {
            var fuelTank5IncludeInCalculation = false;
            if (fuelTank5OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD) {
                fuelTank5IncludeInCalculation = true;
            }
            addNewFuelTank(fuelTankNumber++, fuelTank5IncludeInCalculation, fuelTank5OfferedAs, fuelTank5COGOverride, fuelTank5COG, false, fuelTank5VerticalCOG, fuelTank5Capacity, fuelTank5VolumeIncludedInTare, fuelTank5Capacity - fuelTank5VolumeIncludedInTare, false, fuelTank5LateralCog, false, fuelTank5VerticalCOGOverride, fuelTank5LateralCogOverride, _fuelType);
        }

        if (fuelTank6OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD || fuelTank6OfferedAs === config.OFFERED_AS_OPTIONS.OPTIONAL) {
            var fuelTank6IncludeInCalculation = false;
            if (fuelTank6OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD) {
                fuelTank6IncludeInCalculation = true;
            }
            addNewFuelTank(fuelTankNumber++, fuelTank6IncludeInCalculation, fuelTank6OfferedAs, fuelTank6COGOverride, fuelTank6COG, false, fuelTank6VerticalCOG, fuelTank6Capacity, fuelTank6VolumeIncludedInTare, fuelTank6Capacity - fuelTank6VolumeIncludedInTare, false, fuelTank6LateralCog, false, fuelTank6VerticalCOGOverride, fuelTank6LateralCogOverride, _fuelType);
        }

        if (fuelTank7OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD || fuelTank7OfferedAs === config.OFFERED_AS_OPTIONS.OPTIONAL) {
            var fuelTank7IncludeInCalculation = false;
            if (fuelTank7OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD) {
                fuelTank7IncludeInCalculation = true;
            }
            addNewFuelTank(fuelTankNumber++, fuelTank7IncludeInCalculation, fuelTank7OfferedAs, fuelTank7COGOverride, fuelTank7COG, false, fuelTank7VerticalCOG, fuelTank7Capacity, fuelTank7VolumeIncludedInTare, fuelTank7Capacity - fuelTank7VolumeIncludedInTare, false, fuelTank7LateralCog, false, fuelTank7VerticalCOGOverride, fuelTank7LateralCogOverride, _fuelType === config.FUEL_TYPE_OPTIONS.DIESEL.CODE ? config.FUEL_TYPE_OPTIONS.ADBLUE.CODE : _fuelType);
        }

        if (fuelTank8OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD || fuelTank8OfferedAs === config.OFFERED_AS_OPTIONS.OPTIONAL) {
            var fuelTank8IncludeInCalculation = false;
            if (fuelTank8OfferedAs === config.OFFERED_AS_OPTIONS.STANDARD) {
                fuelTank8IncludeInCalculation = true;
            }
            addNewFuelTank(fuelTankNumber++, fuelTank8IncludeInCalculation, fuelTank8OfferedAs, fuelTank8COGOverride, fuelTank8COG, false, fuelTank8VerticalCOG, fuelTank8Capacity, fuelTank8VolumeIncludedInTare, fuelTank8Capacity - fuelTank8VolumeIncludedInTare, false, fuelTank8LateralCog, false, fuelTank8VerticalCOGOverride, fuelTank8LateralCogOverride, _fuelType === config.FUEL_TYPE_OPTIONS.DIESEL.CODE ? config.FUEL_TYPE_OPTIONS.ADBLUE.CODE : _fuelType);
        }

        updateFuelTankNumbers();
    }

    function setUpFuelTanksFromArray(fuelTanksArray) {
        if (fuelTanksArray !== undefined && fuelTanksArray.length > 0) {
            clearFuelTanks();
            fuelTanksArray.forEach(function (fuelTank) {
                addNewFuelTank(fuelTank.Number, fuelTank.IncludeInCalculation, fuelTank.OfferedAs, fuelTank.COGOverride, fuelTank.COG, fuelTank.VerticalCOGOverride, fuelTank.VerticalCOG, fuelTank.Capacity, fuelTank.VolumeIncludedInTare, fuelTank.VolumeIncludedInExtras, fuelTank.DoNotDelete, fuelTank.LateralCOG, fuelTank.LateralCOGOverride, false, false, fuelTank.Contents);
            });
        }
    }

    function saveFuelTanks() {
        var tempArray = [];

        getFuelTanks().forEach(function (fuelTank) {
            //tempObject = fuelTank.returnFuelTankObjectToBeSaved();
            tempArray.push(fuelTank.returnFuelTankObjectToBeSaved());
        });
        return tempArray;
    }

    function getFrontalArea() {
        var bodyWidthExternal = 0;
        if (_accessoryHolder.getBody() !== null) {
            bodyWidthExternal = _accessoryHolder.getBody().getActualWidth();
        }

        return ((getFrontalAreaHeight() - getGroundClearanceRear()) * Math.max(getOverallWidth(), bodyWidthExternal)/1000000);
    }
    
    function allowTurningCircleCalculation() {
        return getWheelbaseTurning() > 0 && getFrontOverhang() > 0 && getCabWidth() > 0 && getTrackWidthFront() > 0 && _axlesHolder.getAxle1Front().getTyreWidth() > 0 && getRearWidthOfVehicleOrBodyForTurningCircleCalculation() > 0 &&
                (getTurningRadiusWallToWall() > 0 || getTurningRadiusCurbToCurb() > 0 /*Or SteeringAngleOuter > 0*/ || getSteeringAngleInner() > 0);
    }

    function getWheelbaseTurningToRearOfVehicleForTurningCircleCalculation() {
        return getWheelbaseTheoreticalEndToRearMostAxle() + getRearOverhangTotal();
    }

    function getWheelbaseTheoreticalEndToRearMostAxle() {
        return getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() +
                getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() -
                (getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical());
    }
    function getWheelbaseTheoreticalEndToRearMostDrivenAxle() {
        var frontToRearMostDrivenAxle = getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear();

        var rearMostDrivenAxlePosition = _axlesHolder.getLastRearDrivenAxlePosition();

        switch (rearMostDrivenAxlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                //no change
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                frontToRearMostDrivenAxle += getAxle1RearToAxle2Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                frontToRearMostDrivenAxle += getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                frontToRearMostDrivenAxle += getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear();
                break;

        }
        
        return frontToRearMostDrivenAxle -
                (getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical());
    }
    function getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() {
        var frontToRearMostDrivenAxle = getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear();

        var rearMostDrivenAxlePosition = _axlesHolder.getLastRearDrivenAxlePosition();

        switch (rearMostDrivenAxlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                //no change
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                frontToRearMostDrivenAxle += getAxle1RearToAxle2Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                frontToRearMostDrivenAxle += getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                frontToRearMostDrivenAxle += getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear();
                break;

        }
        let raiseSteerablePusherOrTagForWheelbaseTurning = true;
        return frontToRearMostDrivenAxle -
                (getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical(raiseSteerablePusherOrTagForWheelbaseTurning));
    }
    function getWheelbaseTheoreticalEnd() {
        return getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical();
    }
    function getWheelbaseTurningToRearOfBodyForTurningCircleCalculation() {
        if( _accessoryHolder.getBody() !== null){ 
            return getStartOfVehicle() + getBumperToBackOfCab() + _accessoryHolder.getBodyGap() + _accessoryHolder.getBody().getLength() - (getStartOfVehicle() + getFrontOverhang() + getWheelbaseTurning());
        } else {
            return 0
        }
    }

    function getBackOfBodyToRearOfVehicle() {
        var tempBody = getBody();
        if (tempBody !== null) {
            var hookOffset = 0, hookliftCabGap = 0;
            if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                var tempHooklift = _accessoryHolder.getHooklift();
                hookOffset = tempHooklift.getHookOffset();
                hookliftCabGap = tempHooklift.getHorizontalGap();
            }
            return getOverallLengthInclAccessories() - (getBumperToBackOfCab() + hookliftCabGap + hookOffset + tempBody.getHorizontalGap() + tempBody.getActualLength() + Math.max( getBullbarProtrusion(), getPortionOfOverallLengthOverhangingFront(false)));
        }
        return 0;
    }

    function getAxleLastToChassisEnd() {
        //return getOverallLengthExclAccessories() - (getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical() + getWheelbaseTheoreticalEndToRearMostAxle());

        var cabGap = 0, bodyLengthExternal = 0;
        var tempBody = getBody();
        if (tempBody !== null) {
            cabGap = tempBody.getCabGap();
            bodyLengthExternal = tempBody.getActualLength();
        }

        return (getBumperToBackOfCab() + cabGap + bodyLengthExternal) - (getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear());
    }

    function getRearMostDrivenAxleToChassisEnd() {
        //return getOverallLengthExclAccessories() - (getFrontOverhang() + getAxle1FrontToWheelbaseTheoreticalStart() + getWheelbaseTheoretical() + getWheelbaseTheoreticalEndToRearMostAxle());

        var rearMostDrivenAxlePosition = _axlesHolder.getLastRearDrivenAxlePosition();

        switch (rearMostDrivenAxlePosition) {
            case config.VEHICLE_AXLE_POSITION_TYPES.AXLE_1_REAR:
                return getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() + getRearOverhang();
            case config.VEHICLE_AXLE_POSITION_TYPES.AXLE_2_REAR:
                return getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() + getRearOverhang();
            case config.VEHICLE_AXLE_POSITION_TYPES.AXLE_3_REAR:
                return getAxle3RearToAxle4Rear() + getRearOverhang();
            case config.VEHICLE_AXLE_POSITION_TYPES.AXLE_4_REAR:
                return getRearOverhang();

        }

    }

    function getBodyLengthForTurningCircleCalculation() {

        if (_accessoryHolder.getBody() !== null) {
            return _accessoryHolder.getBody().getLength();
        } else {
            return 0;
        }
        /*
        If Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
            Return Accessories.Body.Length
        Else
            Return 0
        End If
        */
    }

    function getCurrentTurningAbilityVal() {
        if (_turningAbilitySpecifiedAs === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
            return getSteeringAngleInner();
        } else if (_turningAbilitySpecifiedAs === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
            return getTurningRadiusCurbToCurb();
        } else if (_turningAbilitySpecifiedAs === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
            return getTurningRadiusWallToWall();
        }
        return 0;
    }

    function getAxle2FrontToAxle1RearText() {
        var firstAxleLetterNumber = _axlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT);
        return config.getTranslationText('3060', [firstAxleLetterNumber, (firstAxleLetterNumber + 1)]);
    }
    
    function getRearOverhangText() {
        var firstAxleLetterNumber = _axlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT) + _axlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR);
        return config.getTranslationText('3061', [firstAxleLetterNumber, config.getTranslationText('1034')]);
    }

    function getVehicleTotalPermissible() {
        var returnValue = getGVM();
        
        if (returnValue > getVehicleBridgeFormulaPermissible()) {
            returnValue = getVehicleBridgeFormulaPermissible()
        }

        return returnValue;
    }

    function getCombinationTotalPermissible() {
        var returnValue = getGCM();

        if (returnValue > getCombinationBridgeFormulaPermissible()) {
            returnValue = getCombinationBridgeFormulaPermissible()
        }

        return returnValue;
    }

    function getBodyOverhang() {
        var tempBody = getBody();
        if (tempBody !== null) {
            var hookOffset = 0, hookliftCabGap = 0;
            if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                var tempHooklift = _accessoryHolder.getHooklift();
                hookOffset = tempHooklift.getHookOffset();
                hookliftCabGap = tempHooklift.getHorizontalGap();
            }
            return (getAxle1FrontToBackOfCab() + hookliftCabGap + hookOffset + tempBody.getHorizontalGap() + tempBody.getActualLength()) - (getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + getAxle3RearToAxle4Rear() + getRearOverhang() + Math.max(getRearBumperProtrusion(), getRearProtrusion()));
        }
        return 0;
            //(Body Length External + Hook Offset + Cab to Hooklift Gap + AC) - (Axle 1 Front to Axle 2 Front + Axle 2 Front to Axle 1 Rear + Axle 1 Rear to Axle 2 Rear + Axle 2 Rear to Axle 3 Rear + Axle 3 Rear to Axle 4 Rear + Rear Overhang + Rear Bumper Protrusion)
    }

    function getRearProtrusionVehicleOnly() {
        //var rearBumper = _accessoryHolder.getRearmostRearBumper();
        //var rearBumperProtrusion = 0;
        //if (rearBumper !== null) {
        //    var endOfChassis = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear() + curVehicle.getRearOverhang();
        //    var rearBumperStart = curVehicle.getBumperToBackOfCab() + rearBumper.getHorizontalGap();
        //    rearBumperProtrusion = rearBumper.getLength() + (rearBumperStart - endOfChassis);

        //}
        return Math.max(getRearProtrusion(), getRearBumperProtrusion());
    }

    function getRearBumperProtrusion() {
        var rearBumper = _accessoryHolder.getRearmostRearBumper();
        if (rearBumper !== null) {
            return (rearBumper.getHorizontalGap() + rearBumper.getLength()) - getCabToChassisEnd();
        }
        return 0;
    }

    function getVehiclePayloadCOGMM() {
        if(_type === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return _payloadHolder.getAveragePayloadHorizontalCOG();
        } else {
            let tempBody = _accessoryHolder.getBody();
            if(tempBody !== null) {
                return _payloadHolder.getAveragePayloadHorizontalCOG();
            }
        }
        return 0;
    }

    function getVehiclePayloadCOGPercent() {
        if(_type === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return _payloadHolder.getAveragePayloadHorizontalCOGPercent(getBodyLengthInternal());
        } else {
            let tempBody = _accessoryHolder.getBody();
            if(tempBody !== null) {
                return _payloadHolder.getAveragePayloadHorizontalCOGPercent(tempBody.getActualInternalLength());
            }
        }
        return 0;
    }
    //#endregion Functions

    self.getColourOptions = getColourOptions;
    self.setColourOptions = setColourOptions;
    self.getActiveCabColour = getActiveCabColour;
    self.setActiveCabColour = setActiveCabColour;
    self.getActiveCabColourToBeSaved = getActiveCabColourToBeSaved;
    self.getBodyHeightExternal = getBodyHeightExternal;
    self.setBodyHeightExternal = setBodyHeightExternal;
    self.getBodyLengthExternalInclRearDoors = getBodyLengthExternalInclRearDoors;
    self.setBodyLengthExternalInclRearDoors = setBodyLengthExternalInclRearDoors;
    self.getBumperToBackOfCab = getBumperToBackOfCab;
    self.setBumperToBackOfCab = setBumperToBackOfCab;
    self.getBumperToStartOfLoadCompartment = getBumperToStartOfLoadCompartment;
    self.setBumperToStartOfLoadCompartment = setBumperToStartOfLoadCompartment;
    self.getCabGapRecommendedMin = getCabGapRecommendedMin;
    self.setCabGapRecommendedMin = setCabGapRecommendedMin;
    self.getCabHeight = getCabHeight;
    self.setCabHeight = setCabHeight;
    self.getCompleteOverallBodyAndEquipmentHeight = getCompleteOverallBodyAndEquipmentHeight;
    self.setCompleteOverallBodyAndEquipmentHeight = setCompleteOverallBodyAndEquipmentHeight;
    self.getCompleteOverallLength = getCompleteOverallLength;
    self.setCompleteOverallLength = setCompleteOverallLength;
    self.getCompleteRearOverhang = getCompleteRearOverhang;
    self.setCompleteRearOverhang = setCompleteRearOverhang;
    self.getFloorHeight = getFloorHeight;
    self.setFloorHeight = setFloorHeight;
    self.getFrontOverhang = getFrontOverhang;
    self.setFrontOverhang = setFrontOverhang;
    self.getGrossFront = getGrossFront;
    self.setGrossFront = setGrossFront;
    self.getGrossRear = getGrossRear;
    self.setGrossRear = setGrossRear;
    self.getGrossTotal = getGrossTotal;
    self.setGrossTotal = setGrossTotal;
    self.getMessages = getMessages;
    self.setMessages = setMessages;
    self.getPayloadFront = getPayloadFront;
    self.setPayloadFront = setPayloadFront;
    self.getPayloadRear = getPayloadRear;
    self.setPayloadRear = setPayloadRear;
    self.getPayloadTotal = getPayloadTotal;
    self.setPayloadTotal = setPayloadTotal;
    self.getPermissibleFront = getPermissibleFront;
    self.setPermissibleFront = setPermissibleFront;
    self.getPermissibleRear = getPermissibleRear;
    self.setPermissibleRear = setPermissibleRear;
    self.getPermissibleTotal = getPermissibleTotal;
    self.setPermissibleTotal = setPermissibleTotal;
    self.getRearOverhang = getRearOverhang;
    self.setRearOverhang = setRearOverhang;
    self.getUnladenFront = getUnladenFront;
    self.setUnladenFront = setUnladenFront;
    self.getUnladenRear = getUnladenRear;
    self.setUnladenRear = setUnladenRear;
    self.getUnladenTotal = getUnladenTotal;
    self.setUnladenTotal = setUnladenTotal;
    self.getDBUnladenTotal = getDBUnladenTotal;
    self.getVehicleDrawing = getVehicleDrawing;
    self.setVehicleDrawing = setVehicleDrawing;
    self.getWheelbaseTheoretical = getWheelbaseTheoretical;
    self.setWheelbaseTheoretical = setWheelbaseTheoretical;
    self.getWheelbaseTheoreticalOverride = getWheelbaseTheoreticalOverride;
    self.setWheelbaseTheoreticalOverride = setWheelbaseTheoreticalOverride;
    self.getBumperToStartOfBodyInternal = getBumperToStartOfBodyInternal;
    self.setBumperToStartOfBodyInternal = setBumperToStartOfBodyInternal;
    self.getBodyLengthInternal = getBodyLengthInternal;
    self.setBodyLengthInternal = setBodyLengthInternal;
    self.getBodyHeightInternal = getBodyHeightInternal;
    self.setBodyHeightInternal = setBodyHeightInternal;
    self.getRearProtrusion = getRearProtrusion;
    self.setRearProtrusion = setRearProtrusion;
    self.getAxle1FrontToWheelbaseTheoreticalStart = getAxle1FrontToWheelbaseTheoreticalStart;
    self.setAxle1FrontToWheelbaseTheoreticalStart = setAxle1FrontToWheelbaseTheoreticalStart;
    self.getAxle1FrontToWheelbaseTheoreticalStartOverride = getAxle1FrontToWheelbaseTheoreticalStartOverride;
    self.setAxle1FrontToWheelbaseTheoreticalStartOverride = setAxle1FrontToWheelbaseTheoreticalStartOverride;
    self.getFifthWheelOffset = getFifthWheelOffset;
    self.setFifthWheelOffset = setFifthWheelOffset;
    self.getFifthWheelHeightFromGround = getFifthWheelHeightFromGround;
    self.setFifthWheelHeightFromGround = setFifthWheelHeightFromGround;
    self.getAxle1FrontToAxle2Front = getAxle1FrontToAxle2Front;
    self.setAxle1FrontToAxle2Front = setAxle1FrontToAxle2Front;
    self.getAxle2FrontToAxle1Rear = getAxle2FrontToAxle1Rear;
    self.setAxle2FrontToAxle1Rear = setAxle2FrontToAxle1Rear;
    self.getAxle1RearToAxle2Rear = getAxle1RearToAxle2Rear;
    self.setAxle1RearToAxle2Rear = setAxle1RearToAxle2Rear;
    self.getAxle2RearToAxle3Rear = getAxle2RearToAxle3Rear;
    self.setAxle2RearToAxle3Rear = setAxle2RearToAxle3Rear;
    self.getAxle3RearToAxle4Rear = getAxle3RearToAxle4Rear;
    self.setAxle3RearToAxle4Rear = setAxle3RearToAxle4Rear;
    self.getControl = getControl;
    self.setControl = setControl;
    self.getBreakpointForWheelbaseFromRearMostAxleFront = getBreakpointForWheelbaseFromRearMostAxleFront;
    self.setBreakpointForWheelbaseFromRearMostAxleFront = setBreakpointForWheelbaseFromRearMostAxleFront;
    self.getBreakpointForRearOverhangFromRearMostAxle = getBreakpointForRearOverhangFromRearMostAxle;
    self.setBreakpointForRearOverhangFromRearMostAxle = setBreakpointForRearOverhangFromRearMostAxle;
    self.getRearMostProtrudingItemBehindCabToRearAxles = getRearMostProtrudingItemBehindCabToRearAxles;
    self.setRearMostProtrudingItemBehindCabToRearAxles = setRearMostProtrudingItemBehindCabToRearAxles;
    self.getType = getType;
    self.setType = setType;
    self.getTareFifthWheelIncluded = getTareFifthWheelIncluded;
    self.setTareFifthWheelIncluded = setTareFifthWheelIncluded;
    self.getChassisHeightFront = getChassisHeightFront;
    self.setChassisHeightFront = setChassisHeightFront;
    self.getChassisHeightRear = getChassisHeightRear;
    self.setChassisHeightRear = setChassisHeightRear;
    self.getAxles = getAxles;
    self.setAxles = setAxles;
    self.getSource = getSource;
    self.setSource = setSource;
    self.getVerticalExhaustHeight = getVerticalExhaustHeight;
    self.setVerticalExhaustHeight = setVerticalExhaustHeight;
    self.getOverallLength = getOverallLength;
    //self.setOverallLength = setOverallLength;
    //self.getBullbarProtrusion = getBullbarProtrusion;
    //self.setBullbarProtrusion = setBullbarProtrusion;
    self.getOverallLengthExclAccessories = getOverallLengthExclAccessories;
    self.setOverallLengthExclAccessories = setOverallLengthExclAccessories;
    self.getOverallLengthInclAccessories = getOverallLengthInclAccessories;
    self.setOverallLengthInclAccessories = setOverallLengthInclAccessories;
    //self.getCabToAxles = getCabToAxles;
    self.getCabToChassisEnd = getCabToChassisEnd;
    
    self.getGroundClearanceFront = getGroundClearanceFront;
    self.setGroundClearanceFront = setGroundClearanceFront;
    self.getGroundClearanceRear = getGroundClearanceRear;
    self.setGroundClearanceRear = setGroundClearanceRear;
    self.getCrewMassPerPerson = getCrewMassPerPerson;
    self.setCrewMassPerPerson = setCrewMassPerPerson;
    self.getCrewFirstRowMax = getCrewFirstRowMax;
    self.setCrewFirstRowMax = setCrewFirstRowMax;
    self.getCrewFirstRowCOG = getCrewFirstRowCOG;
    self.setCrewFirstRowCOG = setCrewFirstRowCOG;
    self.getNoOfCrewInFirstRow = getNoOfCrewInFirstRow;
    self.setNoOfCrewInFirstRow = setNoOfCrewInFirstRow;
    self.getCrewSecondRowIncluded = getCrewSecondRowIncluded;
    self.setCrewSecondRowIncluded = setCrewSecondRowIncluded;
    self.getCrewSecondRowMax = getCrewSecondRowMax;
    self.setCrewSecondRowMax = setCrewSecondRowMax;
    self.getCrewSecondRowCOG = getCrewSecondRowCOG;
    self.setCrewSecondRowCOG = setCrewSecondRowCOG;
    self.getNoOfCrewInSecondRow = getNoOfCrewInSecondRow;
    self.setNoOfCrewInSecondRow = setNoOfCrewInSecondRow;
    self.getFuelSpecificGravity = getFuelSpecificGravity;
    self.setFuelSpecificGravity = setFuelSpecificGravity;
    self.getFuelTank1COG = getFuelTank1COG;
    self.setFuelTank1COG = setFuelTank1COG;
    self.getFuelTank1VerticalCOG = getFuelTank1VerticalCOG;
    self.setFuelTank1VerticalCOG = setFuelTank1VerticalCOG;        
    self.getFuelTank1Capacity = getFuelTank1Capacity;
    self.setFuelTank1Capacity = setFuelTank1Capacity;
    self.getFuelTank1VolumeIncludedInTare = getFuelTank1VolumeIncludedInTare;
    self.getFuelTank2COG = getFuelTank2COG;
    self.setFuelTank2COG = setFuelTank2COG;
    self.getFuelTank2Capacity = getFuelTank2Capacity;
    self.setFuelTank2Capacity = setFuelTank2Capacity;
    self.getFuelTank3COG = getFuelTank3COG;
    self.setFuelTank3COG = setFuelTank3COG;
    self.getFuelTank3Capacity = getFuelTank3Capacity;
    self.setFuelTank3Capacity = setFuelTank3Capacity;
    self.getFuelTank4COG = getFuelTank4COG;
    self.setFuelTank4COG = setFuelTank4COG;
    self.getFuelTank4Capacity = getFuelTank4Capacity;
    self.setFuelTank4Capacity = setFuelTank4Capacity;
    self.getFuelTank5COG = getFuelTank5COG;
    self.setFuelTank5COG = setFuelTank5COG;
    self.getFuelTank5Capacity = getFuelTank5Capacity;
    self.setFuelTank5Capacity = setFuelTank5Capacity;
    self.getFuelTank6COG = getFuelTank6COG;
    self.setFuelTank6COG = setFuelTank6COG;
    self.getFuelTank6Capacity = getFuelTank6Capacity;
    self.setFuelTank6Capacity = setFuelTank6Capacity;
    self.getFuelTank7COG = getFuelTank7COG;
    self.setFuelTank7COG = setFuelTank7COG;
    self.getFuelTank7Capacity = getFuelTank7Capacity;
    self.setFuelTank7Capacity = setFuelTank7Capacity;
    self.getFuelTank8COG = getFuelTank8COG;
    self.setFuelTank8COG = setFuelTank8COG;
    self.getFuelTank8Capacity = getFuelTank8Capacity;
    self.setFuelTank8Capacity = setFuelTank8Capacity;
    self.getTareFront = getTareFront;
    self.setTareFront = setTareFront;
    self.getTareRear = getTareRear;
    self.setTareRear = setTareRear;
    self.getFrontFuelTank1 = getFrontFuelTank1;
    self.getFrontFuelTank2 = getFrontFuelTank2;
    self.getFrontFuelTank3 = getFrontFuelTank3;
    self.getFrontFuelTank4 = getFrontFuelTank4;
    self.getFrontFuelTank5 = getFrontFuelTank5;
    self.getFrontFuelTank6 = getFrontFuelTank6;
    self.getFrontFuelTank7 = getFrontFuelTank7;
    self.getFrontFuelTank8 = getFrontFuelTank8;
    self.getFrontCrewFirst = getFrontCrewFirst;
    self.getFrontCrewSecond = getFrontCrewSecond;
    self.getFrontExtrasTotal = getFrontExtrasTotal;
    self.getMassFuelTank1 = getMassFuelTank1;
    self.getMassFuelTank2 = getMassFuelTank2;
    self.getMassFuelTank3 = getMassFuelTank3;
    self.getMassFuelTank4 = getMassFuelTank4;
    self.getMassFuelTank5 = getMassFuelTank5;
    self.getMassFuelTank6 = getMassFuelTank6;
    self.getMassFuelTank7 = getMassFuelTank7;
    self.getMassFuelTank8 = getMassFuelTank8;
    self.getMassCrewFirst = getMassCrewFirst;
    self.getMassCrewSecond = getMassCrewSecond;
    self.getExtrasTotal = getExtrasTotal;
    self.getRearFuelTank1 = getRearFuelTank1;
    self.getRearFuelTank2 = getRearFuelTank2;
    self.getRearFuelTank3 = getRearFuelTank3;
    self.getRearFuelTank4 = getRearFuelTank4;
    self.getRearFuelTank5 = getRearFuelTank5;
    self.getRearFuelTank6 = getRearFuelTank6;
    self.getRearFuelTank7 = getRearFuelTank7;
    self.getRearFuelTank8 = getRearFuelTank8;
    self.getRearCrewFirst = getRearCrewFirst;
    self.getRearCrewSecond = getRearCrewSecond;
    self.getRearExtrasTotal = getRearExtrasTotal;
    self.getRearOverhangTotal = getRearOverhangTotal;
    self.getRearOverhangTotalInclAccessories = getRearOverhangTotalInclAccessories;
    //self.getAxle1Front = getAxle1Front;
    //self.getAxle2Front = getAxle2Front;
    //self.getAxle1Rear = getAxle1Rear;
    //self.getAxle2Rear = getAxle2Rear;
    //self.getAxle3Rear = getAxle3Rear;
    //self.getAxle4Rear = getAxle4Rear;
    self.getAxle1FrontNoOfTyres = getAxle1FrontNoOfTyres;
    self.getAxle2FrontNoOfTyres = getAxle2FrontNoOfTyres;
    self.getAxle1RearNoOfTyres = getAxle1RearNoOfTyres;
    self.getAxle2RearNoOfTyres = getAxle2RearNoOfTyres;
    self.getAxle3RearNoOfTyres = getAxle3RearNoOfTyres;
    self.getAxle4RearNoOfTyres = getAxle4RearNoOfTyres;
    self.getAxle1FrontTyreWidth = getAxle1FrontTyreWidth;
    self.getAxle2FrontTyreWidth = getAxle2FrontTyreWidth;
    self.getAxle1RearTyreWidth = getAxle1RearTyreWidth;
    self.getAxle2RearTyreWidth = getAxle2RearTyreWidth;
    self.getAxle3RearTyreWidth = getAxle3RearTyreWidth;
    self.getAxle4RearTyreWidth = getAxle4RearTyreWidth;
    self.getWheelbaseTheoretical = getWheelbaseTheoretical;
    self.getCabTiltArc = getCabTiltArc;
    self.getWheelbaseTheoreticalManufacturerMin = getWheelbaseTheoreticalManufacturerMin;
    self.getWheelbaseTheoreticalManufacturerMax = getWheelbaseTheoreticalManufacturerMax;
    self.getGAFront = getGAFront;
    self.setGAFront = setGAFront;
    self.getGARear = getGARear;
    self.setGARear = setGARear;
    self.getOriginalAUFront = getOriginalAUFront;
    self.getOriginalAURear = getOriginalAURear;
    self.getGVM = getGVM;
    self.setGVM = setGVM
    self.getGCM = getGCM;
    self.setGCM = setGCM;
    self.getVRating = getVRating;
    self.getDT = getDT;
    self.getFrontRoadFriendlySuspension = getFrontRoadFriendlySuspension;
    self.setFrontRoadFriendlySuspension = setFrontRoadFriendlySuspension;
    self.getRearRoadFriendlySuspension = getRearRoadFriendlySuspension;
    self.setRearRoadFriendlySuspension = setRearRoadFriendlySuspension;
    self.getRolloverProtection = getRolloverProtection;
    self.setRolloverProtection = setRolloverProtection;
    self.getSteeringAngleInner = getSteeringAngleInner;
    self.getTurningRadiusWallToWall = getTurningRadiusWallToWall;
    self.getTurningRadiusCurbToCurb = getTurningRadiusCurbToCurb;
    self.getWheelbaseTheoreticalMaxCut = getWheelbaseTheoreticalMaxCut;
    self.getRearOverhangManufacturerMin = getRearOverhangManufacturerMin;
    self.getBullbarProtrusion = getBullbarProtrusion;
    self.getBullbarAdded = getBullbarAdded;
    self.getSnowploughAdded = getSnowploughAdded;
    self.setSnowploughAdded = setSnowploughAdded;
    self.getCraneAdded = getCraneAdded;
    self.setCraneAdded = setCraneAdded;
    self.getFifthWheelAdded = getFifthWheelAdded;
    self.setFifthWheelAdded = setFifthWheelAdded;
    self.getRolloverAdded = getRolloverAdded;
    self.getCombinationType = getCombinationType;
    self.getRearMostAccessoryEndFromRearMostAxle = getRearMostAccessoryEndFromRearMostAxle;
    self.getVehicleAndDrawbarTotalGross = getVehicleAndDrawbarTotalGross;
    self.getFrontUnderrunProtection = getFrontUnderrunProtection;
    self.setFrontUnderrunProtection = setFrontUnderrunProtection;
    self.getCabTilt = getCabTilt;
    self.setCabTilt = setCabTilt;
    self.getCabTiltConverter = getCabTiltConverter;
    self.setCabTiltConverter = setCabTiltConverter;
    self.getHingePointFromBumper = getHingePointFromBumper;
    self.setHingePointFromBumper = setHingePointFromBumper;
    self.getHingePointFromGround = getHingePointFromGround;
    self.setHingePointFromGround = setHingePointFromGround;
    self.getCabTiltRadius = getCabTiltRadius;
    self.setCabTiltRadius = setCabTiltRadius;
    self.getCabWidth = getCabWidth;
    self.setCabWidth = setCabWidth;
    self.getRearWidth = getRearWidth;
    self.setRearWidth = setRearWidth;
    self.getOverallWidth = getOverallWidth;
    //self.setOverallWidth = setOverallWidth;
    self.getTrackWidthFront = getTrackWidthFront;
    self.setTrackWidthFront = setTrackWidthFront;
    self.getTrackWidthRear = getTrackWidthRear;
    self.setTrackWidthRear = setTrackWidthRear;
    self.getChassisWidth = getChassisWidth;
    self.setChassisWidth = setChassisWidth;
    self.getWheelbaseTheoreticalOverride = getWheelbaseTheoreticalOverride;
    self.setWheelbaseTheoreticalOverride = setWheelbaseTheoreticalOverride;
    self.getFifthWheelHeightFromGround = getFifthWheelHeightFromGround;
    self.setFifthWheelHeightFromGround = setFifthWheelHeightFromGround;
    self.getChassisSectionLabel = getChassisSectionLabel;
    //self.getCabProtrusion = getCabProtrusion;
    self.getTareFrontOverride = getTareFrontOverride;
    self.setTareFrontOverride = setTareFrontOverride;
    self.getTareRearOverride = getTareRearOverride;
    self.setTareRearOverride = setTareRearOverride;
    self.getCrewSecondRowVerticalCOG = getCrewSecondRowVerticalCOG;
    self.setCrewSecondRowVerticalCOG = setCrewSecondRowVerticalCOG;
    self.getCrewSecondRowVerticalCOGOverride = getCrewSecondRowVerticalCOGOverride;
    self.setCrewSecondRowVerticalCOGOverride = setCrewSecondRowVerticalCOGOverride;
    self.getCrewFirstRowVerticalCOG = getCrewFirstRowVerticalCOG;
    self.setCrewFirstRowVerticalCOG = setCrewFirstRowVerticalCOG;
    self.getCrewFirstRowVerticalCOGOverride = getCrewFirstRowVerticalCOGOverride;
    self.setCrewFirstRowVerticalCOGOverride = setCrewFirstRowVerticalCOGOverride;
    self.getVehicleVerticalCOG = getVehicleVerticalCOG;
    self.setVehicleVerticalCOG = setVehicleVerticalCOG;
    self.getVehicleVerticalCOGOverride = getVehicleVerticalCOGOverride;
    self.setVehicleVerticalCOGOverride = setVehicleVerticalCOGOverride;
    self.getTareToolsIncluded = getTareToolsIncluded;
    self.setTareToolsIncluded = setTareToolsIncluded;
    self.getTareSpareWheelIncluded = getTareSpareWheelIncluded;
    self.setTareSpareWheelIncluded = setTareSpareWheelIncluded;
    self.getAUFront = getAUFront;
    self.setAUFront = setAUFront;
    self.getAURear = getAURear;
    self.setAURear = setAURear;
    self.getFuelType = getFuelType;
    self.setFuelType = setFuelType;
    self.getManufacturersFrontAxlesMassOverride = getManufacturersFrontAxlesMassOverride;
    self.setManufacturersFrontAxlesMassOverride = setManufacturersFrontAxlesMassOverride;
    self.getManufacturersRearAxlesMassOverride = getManufacturersRearAxlesMassOverride;
    self.setManufacturersRearAxlesMassOverride = setManufacturersRearAxlesMassOverride;
    self.getManufacturersGrossVehicleMassOverride = getManufacturersGrossVehicleMassOverride;
    self.setManufacturersGrossVehicleMassOverride = setManufacturersGrossVehicleMassOverride;
    self.getManufacturersGrossCombinationMassOverride = getManufacturersGrossCombinationMassOverride;
    self.setManufacturersGrossCombinationMassOverride = setManufacturersGrossCombinationMassOverride;
    self.getPermissibleRearOverride = getPermissibleRearOverride;
    self.setPermissibleRearOverride = setPermissibleRearOverride;
    self.getPermissibleFrontOverride = getPermissibleFrontOverride;
    self.setPermissibleFrontOverride = setPermissibleFrontOverride;
    self.getPermissibleTotalOverride = getPermissibleTotalOverride;
    self.setPermissibleTotalOverride = setPermissibleTotalOverride;
    self.getCrewFirstRowHorizontalCOGOverride = getCrewFirstRowHorizontalCOGOverride;
    self.setCrewFirstRowHorizontalCOGOverride = setCrewFirstRowHorizontalCOGOverride;
    self.getCrewSecondRow = getCrewSecondRow;
    self.setCrewSecondRow = setCrewSecondRow;
    self.getCrewSecondRowHorizontalCOGOverride = getCrewSecondRowHorizontalCOGOverride;
    self.setCrewSecondRowHorizontalCOGOverride = setCrewSecondRowHorizontalCOGOverride;
    
    self.getAccessories = getAccessories;
    self.getBody = getBody;
    self.addBody = addBody;
    self.addAccessory = addAccessory;
    self.getChassisHeightMax = getChassisHeightMax;
    self.hasAccessory = hasAccessory;
    self.getFrontAddition = getFrontAddition;
    self.getRearAddition = getRearAddition;
    self.getBodyFrontAxle = getBodyFrontAxle;
    self.getBodyRearAxle = getBodyRearAxle;
    self.getBodyTotalAxle = getBodyTotalAxle;
    self.getPayloadCOGToWheelbaseTheoreticalEnd = getPayloadCOGToWheelbaseTheoreticalEnd;
    self.getPayloadCOGToWheelbaseTheoreticalStart = getPayloadCOGToWheelbaseTheoreticalStart;
    self.getFrontalAreaHeight = getFrontalAreaHeight;
    self.getPayloadHolder = getPayloadHolder;
    self.getOverallHeight = getOverallHeight;
    self.getOverallHeightMeasuredFromFront = getOverallHeightMeasuredFromFront;
    self.getFrontOfVehicleToPayloadDeckStart = getFrontOfVehicleToPayloadDeckStart;
    self.getBottomOfVehicleToPayloadDeckStart = getBottomOfVehicleToPayloadDeckStart;
    self.deleteAccessory = deleteAccessory;
    self.getPortionOfOverallLengthOverhangingRear = getPortionOfOverallLengthOverhangingRear;
    self.getFuelTank2VerticalCOG = getFuelTank2VerticalCOG;
    self.getFuelTank3VerticalCOG = getFuelTank3VerticalCOG;
    self.getFuelTank4VerticalCOG = getFuelTank4VerticalCOG;
    self.getFuelTank5VerticalCOG = getFuelTank5VerticalCOG;
    self.getFuelTank6VerticalCOG = getFuelTank6VerticalCOG;
    self.getFuelTank7VerticalCOG = getFuelTank7VerticalCOG;
    self.getFuelTank8VerticalCOG = getFuelTank8VerticalCOG;
    self.getFuelTank2VolumeIncludedInTare = getFuelTank2VolumeIncludedInTare;
    self.getFuelTank3VolumeIncludedInTare = getFuelTank3VolumeIncludedInTare;
    self.getFuelTank4VolumeIncludedInTare = getFuelTank4VolumeIncludedInTare;
    self.getFuelTank5VolumeIncludedInTare = getFuelTank5VolumeIncludedInTare;
    self.getFuelTank6VolumeIncludedInTare = getFuelTank6VolumeIncludedInTare;
    self.getFuelTank7VolumeIncludedInTare = getFuelTank7VolumeIncludedInTare;
    self.getFuelTank8VolumeIncludedInTare = getFuelTank8VolumeIncludedInTare;
    self.getFuelTanks = getFuelTanks;

    self.getFuelTank1IncludeInCalculation = getFuelTank1IncludeInCalculation;
    self.getFuelTank1OfferedAs = getFuelTank1OfferedAs;
    self.getFuelTank1COGOverride = getFuelTank1COGOverride;
    self.getFuelTank1VerticalCOGOverride = getFuelTank1VerticalCOGOverride;
    self.getFuelTank2IncludeInCalculation = getFuelTank2IncludeInCalculation;
    self.getFuelTank2OfferedAs = getFuelTank2OfferedAs;
    self.getFuelTank2COGOverride = getFuelTank2COGOverride;
    self.getFuelTank2VerticalCOGOverride = getFuelTank2VerticalCOGOverride;
    self.getFuelTank3IncludeInCalculation = getFuelTank3IncludeInCalculation;
    self.getFuelTank3OfferedAs = getFuelTank3OfferedAs;
    self.getFuelTank3COGOverride = getFuelTank3COGOverride;
    self.getFuelTank3VerticalCOGOverride = getFuelTank3VerticalCOGOverride;
    self.getFuelTank4IncludeInCalculation = getFuelTank4IncludeInCalculation;
    self.getFuelTank4OfferedAs = getFuelTank4OfferedAs;
    self.getFuelTank4COGOverride = getFuelTank4COGOverride;
    self.getFuelTank4VerticalCOGOverride = getFuelTank4VerticalCOGOverride;
    self.getFuelTank5IncludeInCalculation = getFuelTank5IncludeInCalculation;
    self.getFuelTank5OfferedAs = getFuelTank5OfferedAs;
    self.getFuelTank5COGOverride = getFuelTank5COGOverride;
    self.getFuelTank5VerticalCOGOverride = getFuelTank5VerticalCOGOverride;
    self.getFuelTank6IncludeInCalculation = getFuelTank6IncludeInCalculation;
    self.getFuelTank6OfferedAs = getFuelTank6OfferedAs;
    self.getFuelTank6COGOverride = getFuelTank6COGOverride;
    self.getFuelTank6VerticalCOGOverride = getFuelTank6VerticalCOGOverride;
    self.getFuelTank7IncludeInCalculation = getFuelTank7IncludeInCalculation;
    self.getFuelTank7OfferedAs = getFuelTank7OfferedAs;
    self.getFuelTank7COGOverride = getFuelTank7COGOverride;
    self.getFuelTank7VerticalCOGOverride = getFuelTank7VerticalCOGOverride;
    self.getFuelTank8IncludeInCalculation = getFuelTank8IncludeInCalculation;
    self.getFuelTank8OfferedAs = getFuelTank8OfferedAs;
    self.getFuelTank8COGOverride = getFuelTank8COGOverride;
    self.getFuelTank8VerticalCOGOverride = getFuelTank8VerticalCOGOverride;

    self.setFuelTank1IncludeInCalculation = setFuelTank1IncludeInCalculation;
    self.setFuelTank1OfferedAs = setFuelTank1OfferedAs;
    self.setFuelTank1COGOverride = setFuelTank1COGOverride;
    self.setFuelTank1VerticalCOGOverride = setFuelTank1VerticalCOGOverride;
    self.setFuelTank2IncludeInCalculation = setFuelTank2IncludeInCalculation;
    self.setFuelTank2OfferedAs = setFuelTank2OfferedAs;
    self.setFuelTank2COGOverride = setFuelTank2COGOverride;
    self.setFuelTank2VerticalCOGOverride = setFuelTank2VerticalCOGOverride;
    self.setFuelTank3IncludeInCalculation = setFuelTank3IncludeInCalculation;
    self.setFuelTank3OfferedAs = setFuelTank3OfferedAs;
    self.setFuelTank3COGOverride = setFuelTank3COGOverride;
    self.setFuelTank3VerticalCOGOverride = setFuelTank3VerticalCOGOverride;
    self.setFuelTank4IncludeInCalculation = setFuelTank4IncludeInCalculation;
    self.setFuelTank4OfferedAs = setFuelTank4OfferedAs;
    self.setFuelTank4COGOverride = setFuelTank4COGOverride;
    self.setFuelTank4VerticalCOGOverride = setFuelTank4VerticalCOGOverride;
    self.setFuelTank5IncludeInCalculation = setFuelTank5IncludeInCalculation;
    self.setFuelTank5OfferedAs = setFuelTank5OfferedAs;
    self.setFuelTank5COGOverride = setFuelTank5COGOverride;
    self.setFuelTank5VerticalCOGOverride = setFuelTank5VerticalCOGOverride;
    self.setFuelTank6IncludeInCalculation = setFuelTank6IncludeInCalculation;
    self.setFuelTank6OfferedAs = setFuelTank6OfferedAs;
    self.setFuelTank6COGOverride = setFuelTank6COGOverride;
    self.setFuelTank6VerticalCOGOverride = setFuelTank6VerticalCOGOverride;
    self.setFuelTank7IncludeInCalculation = setFuelTank7IncludeInCalculation;
    self.setFuelTank7OfferedAs = setFuelTank7OfferedAs;
    self.setFuelTank7COGOverride = setFuelTank7COGOverride;
    self.setFuelTank7VerticalCOGOverride = setFuelTank7VerticalCOGOverride;
    self.setFuelTank8IncludeInCalculation = setFuelTank8IncludeInCalculation;
    self.setFuelTank8OfferedAs = setFuelTank8OfferedAs;
    self.setFuelTank8COGOverride = setFuelTank8COGOverride;
    self.setFuelTank8VerticalCOGOverride = setFuelTank8VerticalCOGOverride;

    self.setFuelTank1VolumeIncludedInTare = setFuelTank1VolumeIncludedInTare;
    self.setFuelTank2VolumeIncludedInTare = setFuelTank2VolumeIncludedInTare;
    self.setFuelTank3VolumeIncludedInTare = setFuelTank3VolumeIncludedInTare;
    self.setFuelTank4VolumeIncludedInTare = setFuelTank4VolumeIncludedInTare;

    self.getFuelIncludedInUnladen = getFuelIncludedInUnladen;
    self.getFuelExcludedInUnladen = getFuelExcludedInUnladen;

    self.addFuelTank = addFuelTank;
    self.deleteFuelTank = deleteFuelTank;
    self.saveFuelTanks = saveFuelTanks;
    self.getFuelTank1 = getFuelTank1;
    self.getFuelTank2 = getFuelTank2;
    self.getFuelTank3 = getFuelTank3;
    self.getFuelTank4 = getFuelTank4;
    self.getFuelTank5 = getFuelTank5;
    self.getFuelTank6 = getFuelTank6;
    self.getFuelTank7 = getFuelTank7;
    self.getFuelTank8 = getFuelTank8;
    //self.addAccessories = addAccessories;
    self.getFuelTankById = getFuelTankById;
    
    self.getBodyCOGToRearAxles = getBodyCOGToRearAxles;
    self.getAccessoryHolder = getAccessoryHolder;

    //self.getVehicleGroup1BridgeFormulaDistance = getVehicleGroup1BridgeFormulaDistance;
    self.getStartOfVehicle = getStartOfVehicle;
    self.getCalculatedCabTiltStartPoint = getCalculatedCabTiltStartPoint;
    self.getCalculatedCabTiltSweepAngle = getCalculatedCabTiltSweepAngle;
    self.getCalculatedCabTiltHingePoint = getCalculatedCabTiltHingePoint;
    self.getCalculatedCabTiltRadius = getCalculatedCabTiltRadius;

    self.getPortionOfOverallLengthOverhangingFront = getPortionOfOverallLengthOverhangingFront;
    self.setBodyWidthManufacturerMax = setBodyWidthManufacturerMax;
    self.getBodyWidthManufacturerMax = getBodyWidthManufacturerMax;
    self.getOverallWidthInclAccessories = getOverallWidthInclAccessories;

    self.getPayloadCOGType = getPayloadCOGType;
    self.setPayloadCOGType = setPayloadCOGType;
    self.getPayloadHorizontalCOG = getPayloadHorizontalCOG;
    self.setPayloadHorizontalCOG = setPayloadHorizontalCOG;
    self.getPayloadVerticalCOG = getPayloadVerticalCOG;
    self.setPayloadVerticalCOG = setPayloadVerticalCOG;
    self.getPayloadVerticalCOGOverride = getPayloadVerticalCOGOverride;
    self.setPayloadVerticalCOGOverride = setPayloadVerticalCOGOverride;

    self.clearFuelTanks = clearFuelTanks;
    self.getFuelTankByNumber = getFuelTankByNumber;

    self.getVehicleVerticalCOGConfirmed = getVehicleVerticalCOGConfirmed;
    self.getPayloadVerticalCOGConfirmed = getPayloadVerticalCOGConfirmed;
    self.getFuelTank1VerticalCOGConfirmed = getFuelTank1VerticalCOGConfirmed;
    self.getFuelTank2VerticalCOGConfirmed = getFuelTank2VerticalCOGConfirmed;
    self.getFuelTank3VerticalCOGConfirmed = getFuelTank3VerticalCOGConfirmed;
    self.getFuelTank4VerticalCOGConfirmed = getFuelTank4VerticalCOGConfirmed;
    self.getFuelTank5VerticalCOGConfirmed = getFuelTank5VerticalCOGConfirmed;
    self.getFuelTank6VerticalCOGConfirmed = getFuelTank6VerticalCOGConfirmed;
    self.getFuelTank7VerticalCOGConfirmed = getFuelTank7VerticalCOGConfirmed;
    self.getFuelTank8VerticalCOGConfirmed = getFuelTank8VerticalCOGConfirmed;
    self.getCrewFirstRowVerticalCOGConfirmed = getCrewFirstRowVerticalCOGConfirmed;
    self.getCrewSecondRowVerticalCOGConfirmed = getCrewSecondRowVerticalCOGConfirmed;

    self.getDistanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEnd;
    self.getDistanceFromWheelbaseTheoreticalStart = getDistanceFromWheelbaseTheoreticalStart;

    self.getTareDriverIncluded = getTareDriverIncluded;
    self.setTareDriverIncluded = setTareDriverIncluded;

    self.hasPusherOrTag = hasPusherOrTag;
    self.getPusherOrTagRearAxleGross = getPusherOrTagRearAxleGross;
    self.getRearRearAxlesGross = getRearRearAxlesGross;
    
    self.getFifthWheelOffsetMin = getFifthWheelOffsetMin;
    self.setFifthWheelOffsetMin = setFifthWheelOffsetMin;
    self.getFifthWheelOffsetMax = getFifthWheelOffsetMax;
    self.setFifthWheelOffsetMax = setFifthWheelOffsetMax;

    self.getAxlesHolder = getAxlesHolder;

    self.getWheelbaseTheoreticalEndToAxle4Rear = getWheelbaseTheoreticalEndToAxle4Rear;
    self.getBumperToBackOfRearMostEquipment = getBumperToBackOfRearMostEquipment;
    self.getStartOfVehicle = getStartOfVehicle;

    self.getRearMostAccessoryToAxles = getRearMostAccessoryToAxles;
    self.getFrontalArea = getFrontalArea;

    //2018.1
    self.getCabWidthOverride = getCabWidthOverride;
    self.setCabWidthOverride = setCabWidthOverride;
    self.getRearWidthOverride = getRearWidthOverride;
    self.setRearWidthOverride = setRearWidthOverride;
    self.getTrackWidthFrontOverride = getTrackWidthFrontOverride;
    self.setTrackWidthFrontOverride = setTrackWidthFrontOverride;
    self.getTrackWidthRearOverride = getTrackWidthRearOverride;
    self.setTrackWidthRearOverride = setTrackWidthRearOverride;
    self.getChassisWidthOverride = getChassisWidthOverride;
    self.setChassisWidthOverride = setChassisWidthOverride;
    self.getTurningAbilitySpecifiedAs = getTurningAbilitySpecifiedAs;
    self.setTurningAbilitySpecifiedAs = setTurningAbilitySpecifiedAs;
    self.getTurningRadiusCurbToCurbOverride = getTurningRadiusCurbToCurbOverride;
    self.setTurningRadiusCurbToCurbOverride = setTurningRadiusCurbToCurbOverride;
    self.setTurningRadiusCurbToCurb = setTurningRadiusCurbToCurb;
    self.getTurningRadiusWallToWallOverride = getTurningRadiusWallToWallOverride;
    self.setTurningRadiusWallToWallOverride = setTurningRadiusWallToWallOverride;
    self.setTurningRadiusWallToWall = setTurningRadiusWallToWall;
    self.getSteeringAngleInnerOverride = getSteeringAngleInnerOverride;
    self.setSteeringAngleInnerOverride = setSteeringAngleInnerOverride;
    self.setSteeringAngleInner = setSteeringAngleInner;
    self.getWheelbaseTurning = getWheelbaseTurning;
    self.getRearWidthOfVehicleOrBodyForTurningCircleCalculation = getRearWidthOfVehicleOrBodyForTurningCircleCalculation;
    self.allowTurningCircleCalculation = allowTurningCircleCalculation;
    self.getRearWidthOfBodyForTurningCircleCalculation = getRearWidthOfBodyForTurningCircleCalculation;
    self.getRearWidthOfVehicleForTurningCircleCalculation = getRearWidthOfVehicleForTurningCircleCalculation;
    self.getWheelbaseTurningToRearOfVehicleForTurningCircleCalculation = getWheelbaseTurningToRearOfVehicleForTurningCircleCalculation;
    self.getWheelbaseTheoreticalEndToRearMostAxle = getWheelbaseTheoreticalEndToRearMostAxle;
    self.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation = getWheelbaseTurningToRearOfBodyForTurningCircleCalculation;
    self.getSteeringAngleInnerCalculated = getSteeringAngleInnerCalculated;
    self.setSteeringAngleInnerCalculated = setSteeringAngleInnerCalculated;
    self.getTurningRadiusWallToWallCalculated = getTurningRadiusWallToWallCalculated;
    self.setTurningRadiusWallToWallCalculated = setTurningRadiusWallToWallCalculated;
    self.getTurningRadiusCurbToCurbCalculated = getTurningRadiusCurbToCurbCalculated;
    self.setTurningRadiusCurbToCurbCalculated = setTurningRadiusCurbToCurbCalculated;
    self.getSteeringAngleOuter = getSteeringAngleOuter;
    self.getBodyLengthForTurningCircleCalculation = getBodyLengthForTurningCircleCalculation;
    self.getCurrentTurningAbilityVal = getCurrentTurningAbilityVal;

    //2018.2
    self.getAxle1FrontToAxle2FrontOverride = getAxle1FrontToAxle2FrontOverride;
    self.setAxle1FrontToAxle2FrontOverride = setAxle1FrontToAxle2FrontOverride;
    self.getAxle2FrontToAxle1RearOverride = getAxle2FrontToAxle1RearOverride;
    self.setAxle2FrontToAxle1RearOverride = setAxle2FrontToAxle1RearOverride;
    self.getAxle1RearToAxle2RearOverride = getAxle1RearToAxle2RearOverride;
    self.setAxle1RearToAxle2RearOverride = setAxle1RearToAxle2RearOverride;
    self.getAxle2RearToAxle3RearOverride = getAxle2RearToAxle3RearOverride;
    self.setAxle2RearToAxle3RearOverride = setAxle2RearToAxle3RearOverride;
    self.getAxle3RearToAxle4RearOverride = getAxle3RearToAxle4RearOverride;
    self.setAxle3RearToAxle4RearOverride = setAxle3RearToAxle4RearOverride;

    //2018.3
    self.getOverallLengthInclAccessoriesAndPayload = getOverallLengthInclAccessoriesAndPayload;
    self.getRearAxleSpan = getRearAxleSpan;

    //2018.4
    self.getAxleLastToChassisEnd = getAxleLastToChassisEnd;
    self.getWheelbaseTheoreticalEnd = getWheelbaseTheoreticalEnd;
    self.getRearSwingClearanceRadius = getRearSwingClearanceRadius;

    //2018.7
    self.getAxle2FrontToAxle1RearText = getAxle2FrontToAxle1RearText;
    self.getRearOverhangText = getRearOverhangText;
    self.getDistanceToAxle1Rear = getDistanceToAxle1Rear;
    self.getTareFrontIgnoreOverride = getTareFrontIgnoreOverride;
    self.getTareRearIgnoreOverride = getTareRearIgnoreOverride;
    self.getBreakPointForAxle1FrontToAxle2FrontFromAxle1Front = getBreakPointForAxle1FrontToAxle2FrontFromAxle1Front;
    self.setBreakPointForAxle1FrontToAxle2FrontFromAxle1Front = setBreakPointForAxle1FrontToAxle2FrontFromAxle1Front;
    self.getBreakPointForAxle1RearToAxle2RearFromAxle1Rear = getBreakPointForAxle1RearToAxle2RearFromAxle1Rear;
    self.setBreakPointForAxle1RearToAxle2RearFromAxle1Rear = setBreakPointForAxle1RearToAxle2RearFromAxle1Rear;
    self.getBreakPointForAxle2RearToAxle3RearFromAxle2Rear = getBreakPointForAxle2RearToAxle3RearFromAxle2Rear;
    self.setBreakPointForAxle2RearToAxle3RearFromAxle2Rear = setBreakPointForAxle2RearToAxle3RearFromAxle2Rear;
    self.getBreakPointForAxle3RearToAxle4RearFromAxle3Rear = getBreakPointForAxle3RearToAxle4RearFromAxle3Rear;
    self.setBreakPointForAxle3RearToAxle4RearFromAxle3Rear = setBreakPointForAxle3RearToAxle4RearFromAxle3Rear;

    //2018.8
    self.getRearOverhangTotalInclAccessoriesAndPayload = getRearOverhangTotalInclAccessoriesAndPayload;

    //2018.9
    self.updateValues = updateValues;
    self.getDescription = getDescription;
    self.setDescription = setDescription;
    self.getVehicleTypeDistributionCode = getVehicleTypeDistributionCode;
    self.setVehicleTypeDistributionCode = setVehicleTypeDistributionCode;

    //2018.11
    self.getWheelbaseSpecified = getWheelbaseSpecified;
    self.setWheelbaseSpecified = setWheelbaseSpecified;
    self.getWheelbaseOverride = getWheelbaseOverride;
    self.setWheelbaseOverride = setWheelbaseOverride;
    self.getAxle1RearToCentreOfRearAxles = getAxle1RearToCentreOfRearAxles;
    self.getAxle1RearToCentreOfRearDrivenAxles = getAxle1RearToCentreOfRearDrivenAxles;
    self.getAxle1RearToFirstRearDrivenAxle = getAxle1RearToFirstRearDrivenAxle;
    self.getFrontAxleAverageTyreLife = getFrontAxleAverageTyreLife;
    self.getRearAxleAverageTyreLife = getRearAxleAverageTyreLife;
    self.getSecondsteerAverageTyreLife = getSecondsteerAverageTyreLife;
    self.getPusherAxleAverageTyreLife = getPusherAxleAverageTyreLife;
    self.getTagAxleAverageTyreLife = getTagAxleAverageTyreLife;
    self.getTrailerFrontAxleAverageTyreLife = getTrailerFrontAxleAverageTyreLife;
    self.getTrailerRearAxleAverageTyreLife = getTrailerRearAxleAverageTyreLife;

    self.getVehicleTopViewDrawing = getVehicleTopViewDrawing;
    self.setVehicleTopViewDrawing = setVehicleTopViewDrawing;
    self.getGraphicBlobByElevation = getGraphicBlobByElevation;

    self.getFuelTank1LateralCOG = getFuelTank1LateralCOG;
    self.setFuelTank1LateralCOG = setFuelTank1LateralCOG;
    self.getFuelTank1LateralCOGOverride = getFuelTank1LateralCOGOverride;
    self.setFuelTank1LateralCOGOverride = setFuelTank1LateralCOGOverride;
    self.getFuelTank1LateralCOGConfirmed = getFuelTank1LateralCOGConfirmed;
    self.setFuelTank1LateralCOGConfirmed = setFuelTank1LateralCOGConfirmed;
    self.getFuelTank2LateralCOG = getFuelTank2LateralCOG;
    self.setFuelTank2LateralCOG = setFuelTank2LateralCOG;
    self.getFuelTank2LateralCOGOverride = getFuelTank2LateralCOGOverride;
    self.setFuelTank2LateralCOGOverride = setFuelTank2LateralCOGOverride;
    self.getFuelTank2LateralCOGConfirmed = getFuelTank2LateralCOGConfirmed;
    self.setFuelTank2LateralCOGConfirmed = setFuelTank2LateralCOGConfirmed;
    self.getFuelTank3LateralCOG = getFuelTank3LateralCOG;
    self.setFuelTank3LateralCOG = setFuelTank3LateralCOG;
    self.getFuelTank3LateralCOGOverride = getFuelTank3LateralCOGOverride;
    self.setFuelTank3LateralCOGOverride = setFuelTank3LateralCOGOverride;
    self.getFuelTank3LateralCOGConfirmed = getFuelTank3LateralCOGConfirmed;
    self.setFuelTank3LateralCOGConfirmed = setFuelTank3LateralCOGConfirmed;
    self.getFuelTank4LateralCOG = getFuelTank4LateralCOG;
    self.setFuelTank4LateralCOG = setFuelTank4LateralCOG;
    self.getFuelTank4LateralCOGOverride = getFuelTank4LateralCOGOverride;
    self.setFuelTank4LateralCOGOverride = setFuelTank4LateralCOGOverride;
    self.getFuelTank4LateralCOGConfirmed = getFuelTank4LateralCOGConfirmed;
    self.setFuelTank4LateralCOGConfirmed = setFuelTank4LateralCOGConfirmed;
    self.getFuelTank5LateralCOG = getFuelTank5LateralCOG;
    self.setFuelTank5LateralCOG = setFuelTank5LateralCOG;
    self.getFuelTank5LateralCOGOverride = getFuelTank5LateralCOGOverride;
    self.setFuelTank5LateralCOGOverride = setFuelTank5LateralCOGOverride;
    self.getFuelTank5LateralCOGConfirmed = getFuelTank5LateralCOGConfirmed;
    self.setFuelTank5LateralCOGConfirmed = setFuelTank5LateralCOGConfirmed;
    self.getFuelTank6LateralCOG = getFuelTank6LateralCOG;
    self.setFuelTank6LateralCOG = setFuelTank6LateralCOG;
    self.getFuelTank6LateralCOGOverride = getFuelTank6LateralCOGOverride;
    self.setFuelTank6LateralCOGOverride = setFuelTank6LateralCOGOverride;
    self.getFuelTank6LateralCOGConfirmed = getFuelTank6LateralCOGConfirmed;
    self.setFuelTank6LateralCOGConfirmed = setFuelTank6LateralCOGConfirmed;
    self.getFuelTank7LateralCOG = getFuelTank7LateralCOG;
    self.setFuelTank7LateralCOG = setFuelTank7LateralCOG;
    self.getFuelTank7LateralCOGOverride = getFuelTank7LateralCOGOverride;
    self.setFuelTank7LateralCOGOverride = setFuelTank7LateralCOGOverride;
    self.getFuelTank7LateralCOGConfirmed = getFuelTank7LateralCOGConfirmed;
    self.setFuelTank7LateralCOGConfirmed = setFuelTank7LateralCOGConfirmed;
    self.getFuelTank8LateralCOG = getFuelTank8LateralCOG;
    self.setFuelTank8LateralCOG = setFuelTank8LateralCOG;
    self.getFuelTank8LateralCOGOverride = getFuelTank8LateralCOGOverride;
    self.setFuelTank8LateralCOGOverride = setFuelTank8LateralCOGOverride;
    self.getFuelTank8LateralCOGConfirmed = getFuelTank8LateralCOGConfirmed;
    self.setFuelTank8LateralCOGConfirmed = setFuelTank8LateralCOGConfirmed;
    self.getCrewFirstRowLateralCOG = getCrewFirstRowLateralCOG;
    self.setCrewFirstRowLateralCOG = setCrewFirstRowLateralCOG;
    self.getCrewFirstRowLateralCOGOverride = getCrewFirstRowLateralCOGOverride;
    self.setCrewFirstRowLateralCOGOverride = setCrewFirstRowLateralCOGOverride;
    self.getCrewFirstRowLateralCOGConfirmed = getCrewFirstRowLateralCOGConfirmed;
    self.setCrewFirstRowLateralCOGConfirmed = setCrewFirstRowLateralCOGConfirmed;
    self.getCrewSecondRowLateralCOG = getCrewSecondRowLateralCOG;
    self.setCrewSecondRowLateralCOG = setCrewSecondRowLateralCOG;
    self.getCrewSecondRowLateralCOGOverride = getCrewSecondRowLateralCOGOverride;
    self.setCrewSecondRowLateralCOGOverride = setCrewSecondRowLateralCOGOverride;
    self.getCrewSecondRowLateralCOGConfirmed = getCrewSecondRowLateralCOGConfirmed;
    self.setCrewSecondRowLateralCOGConfirmed = setCrewSecondRowLateralCOGConfirmed;
    self.getVehicleLateralCOG = getVehicleLateralCOG;
    self.setVehicleLateralCOG = setVehicleLateralCOG;
    self.getVehicleLateralCOGOverride = getVehicleLateralCOGOverride;
    self.setVehicleLateralCOGOverride = setVehicleLateralCOGOverride;
    self.getVehicleLateralCOGConfirmed = getVehicleLateralCOGConfirmed;
    self.setVehicleLateralCOGConfirmed = setVehicleLateralCOGConfirmed;
    self.getPayloadLateralCOG = getPayloadLateralCOG;
    self.setPayloadLateralCOG = setPayloadLateralCOG;
    self.getPayloadLateralCOGOverride = getPayloadLateralCOGOverride;
    self.setPayloadLateralCOGOverride = setPayloadLateralCOGOverride;
    self.getPayloadLateralCOGConfirmed = getPayloadLateralCOGConfirmed;
    self.setPayloadLateralCOGConfirmed = setPayloadLateralCOGConfirmed;

    self.getLeftOfVehicleToPayloadDeckStart = getLeftOfVehicleToPayloadDeckStart;
    self.getLoadCompartmentWidth = getLoadCompartmentWidth;
    self.setLoadCompartmentWidth = setLoadCompartmentWidth;
    self.getMaxAxleWidth = getMaxAxleWidth;
    self.getTyrePriceRemainingSets = getTyrePriceRemainingSets;
    self.getUpdateCounter = getUpdateCounter;
    self.setUpdateCounter = setUpdateCounter;

    self.setFuelTanks = setFuelTanks;

    //2019.1
    self.getAxle1RearToStartOfAxleSection = getAxle1RearToStartOfAxleSection;

    //2019.2
    //self.getAxle1ToWheelbaseEnd = getAxle1ToWheelbaseEnd;
    self.getFrontToWheelbaseTheoreticalEnd = getFrontToWheelbaseTheoreticalEnd;
    self.getFrontToPayloadDeckStart = getFrontToPayloadDeckStart;

    //2019.3
    self.getBottomToPayloadDeckStart = getBottomToPayloadDeckStart;
    self.getLeftToPayloadDeckStart = getLeftToPayloadDeckStart;

    //2019.5
    self.getAxle1FrontToBackOfCab = getAxle1FrontToBackOfCab;
    self.getCabToAxles = getCabToAxles;
    self.getCabToAxlesOverride = getCabToAxlesOverride;
    self.setCabToAxlesOverride = setCabToAxlesOverride;
    self.getNumberOfRearDrivenAxles = getNumberOfRearDrivenAxles;

    //2019.6
    
    self.getBodyAvailableChassisLength = getBodyAvailableChassisLength;
    
    self.setBullbarAdded = setBullbarAdded;

    //2020.1
    self.getRearDrivenAxleSpan = getRearDrivenAxleSpan;
    self.getDistanceFromAxle1RearToFirstDownAxle = getDistanceFromAxle1RearToFirstDownAxle;
    self.getDistanceFromAxle1RearToFirstDownDrivenAxle = getDistanceFromAxle1RearToFirstDownDrivenAxle;
    self.getAxleGroups = getAxleGroups;
    self.getAxleObjectsForMenu = getAxleObjectsForMenu;
    self.getVehicleBridgeFormulaPermissible = getVehicleBridgeFormulaPermissible;
    self.setVehicleBridgeFormulaPermissible = setVehicleBridgeFormulaPermissible;
    self.getVehicleBridgeFormulaPermissibleOverride = getVehicleBridgeFormulaPermissibleOverride;
    self.setVehicleBridgeFormulaPermissibleOverride = setVehicleBridgeFormulaPermissibleOverride;
    self.getCombinationBridgeFormulaPermissible = getCombinationBridgeFormulaPermissible;
    self.setCombinationBridgeFormulaPermissible = setCombinationBridgeFormulaPermissible;
    self.getCombinationBridgeFormulaPermissibleOverride = getCombinationBridgeFormulaPermissibleOverride;
    self.setCombinationBridgeFormulaPermissibleOverride = setCombinationBridgeFormulaPermissibleOverride;
    self.getVehicleTotalPermissible = getVehicleTotalPermissible;
    self.getCombinationTotalPermissible = getCombinationTotalPermissible;
    self.getPusherOrTagRearAxleDistribution = getPusherOrTagRearAxleDistribution;
    self.getRearRearAxlesDistribution = getRearRearAxlesDistribution;
    self.getRearMostDrivenAxleToChassisEnd = getRearMostDrivenAxleToChassisEnd;
    self.getWheelbaseTheoreticalEndToRearMostDrivenAxle = getWheelbaseTheoreticalEndToRearMostDrivenAxle;
    self.getTareRearRear = getTareRearRear;
    self.setTareRearRear = setTareRearRear;
    self.getBridgeFormulaRearPermissible = getBridgeFormulaRearPermissible;
    self.setBridgeFormulaRearPermissible = setBridgeFormulaRearPermissible;
    self.getBridgeFormulaRearPermissibleOverride = getBridgeFormulaRearPermissibleOverride;
    self.setBridgeFormulaRearPermissibleOverride = setBridgeFormulaRearPermissibleOverride;
    self.getRevisedUnladenRear = getRevisedUnladenRear;
    self.getRevisedUnladenFront = getRevisedUnladenFront;
    self.getRevisedUnladenTotal = getRevisedUnladenTotal;
    self.getRevisedGrossRear = getRevisedGrossRear;
    self.getRevisedGrossFront = getRevisedGrossFront;

    //2020.3
    self.getFuelSpecificGravityOverride = getFuelSpecificGravityOverride;
    self.setFuelSpecificGravityOverride = setFuelSpecificGravityOverride;

    //2020.4
    self.hasServiceBody = hasServiceBody;

    //2020.5
    self.getOverallHeightAtRear = getOverallHeightAtRear;

    //2021.1
    self.getBackOfBodyToRearOfVehicle = getBackOfBodyToRearOfVehicle;
    //2021.2
    self.getFrontOfVehicleToWheelbaseTheoreticalEnd = getFrontOfVehicleToWheelbaseTheoreticalEnd;

    //2021.3
    self.getBodyOverhang = getBodyOverhang;
    self.getRearProtrusionVehicleOnly = getRearProtrusionVehicleOnly;
    
    //2022.2
    self.getDecompressedVehicleDrawing = getDecompressedVehicleDrawing;
    self.setDecompressedVehicleDrawing = setDecompressedVehicleDrawing;
    self.getDecompressedVehicleTopViewDrawing = getDecompressedVehicleTopViewDrawing;
    self.setDecompressedVehicleTopViewDrawing = setDecompressedVehicleTopViewDrawing;
    self.getDecompressedGraphicBlobByElevation = getDecompressedGraphicBlobByElevation;
    
    //2023.3
    self.getVehicleRearMinus1ToFirstMainAxle = getVehicleRearMinus1ToFirstMainAxle;
    self.getVehiclePayloadCOGMM = getVehiclePayloadCOGMM;
    self.getVehiclePayloadCOGPercent = getVehiclePayloadCOGPercent;
    self.getVehicleEffectiveRearOverhang = getVehicleEffectiveRearOverhang;
    self.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning = getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning;

    //2023.4
    self.getLNGExpressedAs = getLNGExpressedAs;
    self.getCNGExpressedAs = getCNGExpressedAs;
    self.getHydrogenExpressedAs = getHydrogenExpressedAs;
    self.getCurrentFuelTypeExpressedAs = getCurrentFuelTypeExpressedAs;
    self.countFuelTankType = countFuelTankType;
    self.setExpressedAs = setExpressedAs;
    self.getExpressedAs = getExpressedAs;
    self.getWeightIncludedInFuelAndCrew = getWeightIncludedInFuelAndCrew;
    self.getSumBatteryCapacity = getSumBatteryCapacity;
    self.getFuelOnlyIncludedInChassis = getFuelOnlyIncludedInChassis;
    self.getFuelOnlyIncludedInExtras = getFuelOnlyIncludedInExtras;
    self.getFuelOnlyExcludedInUnladen = getFuelOnlyExcludedInUnladen;
    self.getAdBlueIncludedInChassis = getAdBlueIncludedInChassis;
    self.getAdBlueIncludedInExtras = getAdBlueIncludedInExtras;
    self.getAdBlueExcludedInUnladen = getAdBlueExcludedInUnladen;
    self.getSpecificGravityAdBlue = getSpecificGravityAdBlue;
    self.setSpecificGravityAdBlue = setSpecificGravityAdBlue;
    self.getSpecificGravityAdBlueOverride = getSpecificGravityAdBlueOverride;
    self.setSpecificGravityAdBlueOverride = setSpecificGravityAdBlueOverride;

    //2023.5
    self.getOverallWidthInclAccessoriesAndPayload = getOverallWidthInclAccessoriesAndPayload;
    self.getDevicesForIndirectVision = getDevicesForIndirectVision;
    self.setDevicesForIndirectVision = setDevicesForIndirectVision;
    self.getCommercialVehicleBrakeSystems = getCommercialVehicleBrakeSystems;
    self.setCommercialVehicleBrakeSystems = setCommercialVehicleBrakeSystems;
    self.getAdvancedEmergencyBraking = getAdvancedEmergencyBraking;
    self.setAdvancedEmergencyBraking = setAdvancedEmergencyBraking;
    self.getLaneDepartureWarning = getLaneDepartureWarning;
    self.setLaneDepartureWarning = setLaneDepartureWarning;
    self.getSideUnderrunProtection = getSideUnderrunProtection;
    self.setSideUnderrunProtection = setSideUnderrunProtection;
    self.getConspicuityMarkingsToTheRear = getConspicuityMarkingsToTheRear;
    self.setConspicuityMarkingsToTheRear = setConspicuityMarkingsToTheRear;
    self.getConspicuityMarkingsToBothSides = getConspicuityMarkingsToBothSides;
    self.setConspicuityMarkingsToBothSides = setConspicuityMarkingsToBothSides;
    self.getBlindSpotInformationSystems = getBlindSpotInformationSystems;
    self.setBlindSpotInformationSystems = setBlindSpotInformationSystems;
    self.getOverallLengthExclAccessoriesExceptRearBumper = getOverallLengthExclAccessoriesExceptRearBumper;

    //2024.2
    self.getEngineMaximumTorqueNm = getEngineMaximumTorqueNm; 
    self.setEngineMaximumTorqueNm = setEngineMaximumTorqueNm;
    self.getEngineMaximumTorqueLbFt = getEngineMaximumTorqueLbFt;
    self.setEngineMaximumTorqueLbFt = setEngineMaximumTorqueLbFt;
    self.getTransmissionFirstGearRatio = getTransmissionFirstGearRatio;
    self.setTransmissionFirstGearRatio = setTransmissionFirstGearRatio;
    self.getTransmissionReverseGearRatio = getTransmissionReverseGearRatio;
    self.setTransmissionReverseGearRatio = setTransmissionReverseGearRatio;
    self.getTransmissionTorqueConverterMultiplication = getTransmissionTorqueConverterMultiplication;
    self.setTransmissionTorqueConverterMultiplication = setTransmissionTorqueConverterMultiplication;
    self.getTransmissionParkPAWL = getTransmissionParkPAWL;
    self.setTransmissionParkPAWL = setTransmissionParkPAWL;

    //2024.3
    self.getSteeringAngleAxle1RearInnerCalculated = getSteeringAngleAxle1RearInnerCalculated;
    self.setSteeringAngleAxle1RearInnerCalculated = setSteeringAngleAxle1RearInnerCalculated;
    self.getSteeringAngleAxle2RearInnerCalculated = getSteeringAngleAxle2RearInnerCalculated;
    self.setSteeringAngleAxle2RearInnerCalculated = setSteeringAngleAxle2RearInnerCalculated;
    self.getSteeringAngleAxle3RearInnerCalculated = getSteeringAngleAxle3RearInnerCalculated;
    self.setSteeringAngleAxle3RearInnerCalculated = setSteeringAngleAxle3RearInnerCalculated;
    self.getSteeringAngleAxle4RearInnerCalculated = getSteeringAngleAxle4RearInnerCalculated;
    self.setSteeringAngleAxle4RearInnerCalculated = setSteeringAngleAxle4RearInnerCalculated;
    self.getOverallHeightExcludingPayload = getOverallHeightExcludingPayload;
    self.getEmissionsStandard = getEmissionsStandard;
    self.setEmissionsStandard = setEmissionsStandard;
};

ChassisObjectMixin.call(Configuration.prototype);

export default Configuration;

    
    
    /*

    Return ApplicationGlobals.TurningMomentsFront(TotalFuelTank1,
                                             WheelbaseTheoretical - (_FuelTanks(0).COG - Axle1FrontToWheelbaseTheoreticalStart),
                                             WheelbaseTheoretical)

    Public Shared Function TurningMomentsFront(ByVal mass As Double, _
                                               ByVal distanceFromWheelbaseTheoreticalEnd As Double, _
                                               ByVal wheelbaseTheoretical As Double) As Double

        If wheelbaseTheoretical <> 0 Then
            Return (mass * distanceFromWheelbaseTheoreticalEnd) / wheelbaseTheoretical
        Else
            Return 0
        End If

    End Function

    
    */
