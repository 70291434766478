<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox costing-tyre-detail-modal">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: getTranslationText('1169')">{{getTranslationText('1169')}}</span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <div id="tyre-detail-accordion-container" class="accordion">
                    <h3 class="modal-body-header">
                        <div class="fill-flex-div-row flex-space-between">
                            <div>
                                <span data-bind="text: getTranslationText('4219')">{{getTranslationText('4219')}}</span>
                            </div>
                            <div class="total-text">
                                <span data-bind="text: vehicleCostPerKmFormatted">{{vehicleCostPerKmFormatted}}</span>
                            </div>
                        </div>
                    </h3>
                    <div>
                        <div class="accordion-content-container">
                            <table>
                                <thead>
                                    <tr data-bind="template: { name: 'template-header-tyre-detail' }">
                                        <HeaderTyreDetail :costPerDistanceTextFromParent="costPerDistanceText"></HeaderTyreDetail>
                                    </tr>
                                </thead>
                                <tbody data-bind="foreach: vehicleAxleItems">
                                    <!-- <tr data-bind="template: { name: 'template-row-tyre-detail', data: $data }"></tr> -->
                                    <tr v-for="row in vehicleAxleItems" :key="row">
                                        <RowTyreDetail :rowFromParent="row"></RowTyreDetail>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <template v-if="trailer1AxleItems.length > 0">
                        <h3 class="modal-body-header">
                            <div class="fill-flex-div-row flex-space-between">
                                <div>
                                    <span data-bind="text: getTranslationText('2821')">{{getTranslationText('2821')}}</span>
                                </div>
                                <div class="total-text">
                                    <span data-bind="text: trailer1CostPerKmFormatted">{{trailer1CostPerKmFormatted}}</span>
                                </div>
                            </div>
                        </h3>
                        <div>
                            <div class="accordion-content-container">
                                <table>
                                    <thead>
                                        <tr data-bind="template: { name: 'template-header-tyre-detail' }">
                                            <HeaderTyreDetail :costPerDistanceTextFromParent="costPerDistanceText"></HeaderTyreDetail>
                                        </tr>
                                    </thead>
                                    <tbody data-bind="foreach: trailer1AxleItems">
                                        <tr v-for="row in trailer1AxleItems" :key="row">
                                            <RowTyreDetail :rowFromParent="row"></RowTyreDetail>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </template>
                    
                    <template v-if="trailer2AxleItems.length > 0">
                        <h3 class="modal-body-header">
                            <div class="fill-flex-div-row flex-space-between">
                                <div>
                                    <span data-bind="text: getTranslationText('2822')">{{getTranslationText('2822')}}</span>
                                </div>
                                <div class="total-text">
                                    <span data-bind="text: trailer2CostPerKmFormatted">{{trailer2CostPerKmFormatted}}</span>
                                </div>
                            </div>
                        </h3>
                        <div>
                            <div class="accordion-content-container">
                                <table>
                                    <thead>
                                        <tr data-bind="template: { name: 'template-header-tyre-detail' }">
                                            <HeaderTyreDetail :costPerDistanceTextFromParent="costPerDistanceText"></HeaderTyreDetail>
                                        </tr>
                                    </thead>
                                    <tbody data-bind="foreach: trailer2AxleItems">
                                        <tr v-for="row in trailer2AxleItems" :key="row">
                                            <RowTyreDetail :rowFromParent="row"></RowTyreDetail>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="tyre-detail-total-div">
                    <div class="total-column total-text">
                        <span data-bind="text: totalCostPerKmFormatted">{{totalCostPerKmFormatted}}</span>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience btn-primary" 
                    @click="ok"
                    data-bind="click: ok, touchstart: ok">
                    <span data-bind="text: getTranslationText('271')">{{getTranslationText('271')}}</span>
                </button>
                <button class="btn btn-default btn-truckscience"
                    @click="close()"
                    data-bind="click: close, touchstart: close">
                    <span data-bind="text: getTranslationText('65')">{{getTranslationText('65')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
    

    <!-- <div id="modal" class="messageBox costing-tyre-detail-modal">
        <div class="modal-header modal-header-text">
            <h4 class="modal-title"><span data-bind="text: getTranslationText('1169')"></span></h4>
        </div>
        <div class="modal-body modal-body-text">
            <div id="tyre-detail-accordion-container" class="accordion">
                <h3 class="modal-body-header">
                    <div class="fill-flex-div-row flex-space-between">
                        <div>
                            <span data-bind="text: getTranslationText('4219')"></span>
                        </div>
                        <div class="total-text">
                            <span data-bind="text: vehicleCostPerKmFormatted"></span>
                        </div>
                    </div>
                </h3>
                <div>
                    <div class="accordion-content-container">
                        <table>
                            <thead>
                                <tr data-bind="template: { name: 'template-header-tyre-detail' }"></tr>
                            </thead>
                            <tbody data-bind="foreach: vehicleAxleItems">
                                <tr data-bind="template: { name: 'template-row-tyre-detail', data: $data }"></tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                ko if: trailer1AxleItems.length > 0
                <h3 class="modal-body-header">
                    <div class="fill-flex-div-row flex-space-between">
                        <div>
                            <span data-bind="text: getTranslationText('2821')"></span>
                        </div>
                        <div class="total-text">
                            <span data-bind="text: trailer1CostPerKmFormatted"></span>
                        </div>
                    </div>
                </h3>
                <div>
                    <div class="accordion-content-container">
                        <table>
                            <thead>
                                <tr data-bind="template: { name: 'template-header-tyre-detail" }"></tr>
                            </thead>
                            <tbody data-bind="foreach: trailer1AxleItems">
                                <tr data-bind="template: { name: 'template-row-tyre-detail', data: $data }"></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                /ko
                ko if: trailer2AxleItems.length > 0
                <h3 class="modal-body-header">
                    <div class="fill-flex-div-row flex-space-between">
                        <div>
                            <span data-bind="text: getTranslationText('2822')"></span>
                        </div>
                        <div class="total-text">
                            <span data-bind="text: trailer2CostPerKmFormatted"></span>
                        </div>
                    </div>
                </h3>
                <div>
                    <div class="accordion-content-container">
                        <table>
                            <thead>
                                <tr data-bind="template: { name: 'template-header-tyre-detail' }"></tr>
                            </thead>
                            <tbody data-bind="foreach: trailer2AxleItems">
                                <tr data-bind="template: { name: 'template-row-tyre-detail', data: $data }"></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                /ko
            </div>
            <div class="tyre-detail-total-div">
                <div class="total-column total-text">
                    <span data-bind="text: totalCostPerKmFormatted"></span>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-default btn-truckscience btn-primary" data-bind="click: ok, touchstart: ok"><span data-bind="text: getTranslationText('271')"></span></button>
            <button class="btn btn-default btn-truckscience" data-bind="click: close, touchstart: close"><span data-bind="text: getTranslationText('65')"></span></button>
        </div>
    </div> -->
</template>

<script>
import { toRef, ref, shallowRef, onMounted, computed, onUpdated } from 'vue';
import config from '@/services/config';
import globals from '@/services/globals';
import offerManager from '@/services/offerManager';
import CustomMessageBox from '@/services/CustomMessageBox';

import PopupModal from '../PopupModal.vue';

import HeaderTyreDetail from '@/components/modals/costing/costingTyreDetail/HeaderTyreDetail.vue';
import RowTyreDetail from '@/components/modals/costing/costingTyreDetail/RowTyreDetail.vue';

const $ = window.$;
const noUiSlider = window.noUiSlider;

const vehicleAxleItems = shallowRef([]);
const trailer1AxleItems = shallowRef([]);
const trailer2AxleItems = shallowRef([]);

const vehicleCostPerKmFormatted = shallowRef(0);
const trailer1CostPerKmFormatted = shallowRef(0);
const trailer2CostPerKmFormatted = shallowRef(0);
const totalCostPerKmFormatted = shallowRef(0);

let activeOffer,
    activeMeasurementSystem,
    triggerSubscriptionUpdates = shallowRef(new Date().getTime()),
    _tyreRateMin = globals.costingTyreRateMin,
    _tyreRateMax = globals.costingTyreRateMax,
    _updatedTyreRate,
    startingValues,
    costPerDistanceText = globals.user.getActiveMeasurementSystem().id === config.MEASUREMENT_SYSTEM.METRIC ? config.getTranslationText('774') : config.getTranslationText('885');

// #region Getters and Setters
function getTyreRateMin() {
    return _tyreRateMin;
}
function getTyreRateMax() {
    return _tyreRateMax;
}
function getUpdatedTyreRate() {
    return _updatedTyreRate;
}
function setUpdatedTyreRate(newValue) {
    _updatedTyreRate = newValue;
}
// #endregion Getters and Setters

function updateTotalCostPerKmFormatted() {
    var vehicleValueToUse = 0,
        trailer1ValueToUse = 0,
        trailer2ValueToUse = 0,
        valueToUse = 0;

    vehicleAxleItems.value.forEach(function (axleItem) {
        vehicleValueToUse += axleItem.getCostPerKm();
    });

    vehicleCostPerKmFormatted.value = offerManager.getUIFormattedMinorCurrencyPerDistanceValue(vehicleValueToUse);

    trailer1AxleItems.value.forEach(function (axleItem) {
        trailer1ValueToUse += axleItem.getCostPerKm();
    });

    trailer2AxleItems.value.forEach(function (axleItem) {
        trailer2ValueToUse += axleItem.getCostPerKm();
    });

    trailer1CostPerKmFormatted.value = offerManager.getUIFormattedMinorCurrencyPerDistanceValue(trailer1ValueToUse);
    trailer2CostPerKmFormatted.value = offerManager.getUIFormattedMinorCurrencyPerDistanceValue(trailer2ValueToUse);
    setUpdatedTyreRate(vehicleValueToUse + trailer1ValueToUse + trailer2ValueToUse);
    totalCostPerKmFormatted.value = offerManager.getUIFormattedMinorCurrencyPerDistanceValue(getUpdatedTyreRate());
}

// #region Durandal Functions
function activate(data) {
    var trailer1 = activeOffer.getTrailer1(),
        trailer2 = activeOffer.getTrailer2();
    vehicleAxleItems.value = setupVehicleAxleItems(activeOffer.getConfiguration().getAxles());
    if (trailer1 !== null) {
        trailer1AxleItems.value = setupTrailer1AxleItems(trailer1.getAxlesHolder().getAxles());
    }
    if (trailer2 !== null) {
        trailer2AxleItems.value = setupTrailer2AxleItems(trailer2.getAxlesHolder().getAxles());
    }
    updateTotalCostPerKmFormatted();
    startingValues = getAxleValues();
    return true;
}
function compositionComplete() {
    $('#tyre-detail-accordion-container').accordion({
        heightStyle: "content",
        icons: {
            header: "accordion-icon accordion-icon-closed",
            activeHeader: "accordion-icon accordion-icon-open"
        }
    });
    createSelectmenusAndSliders();
}
// #endregion Durandal Functions

// #region Setup Functions
/**
 * Run the initialization code to create jQuery UI selectmenus and NoUiSliders
 */
function createSelectmenusAndSliders() {
    vehicleAxleItems.value.forEach(createAxleItem);

    if (trailer1AxleItems.value.length > 0) {
        trailer1AxleItems.value.forEach(createAxleItem);
    }

    if (trailer2AxleItems.value.length > 0) {
        trailer2AxleItems.value.forEach(createAxleItem);
    }

    function createAxleItem(axleItem) {
        axleItem.createJsElements();
    }
}

function setupVehicleAxleItems(vehicleAxles) {
    return setupAxleItems(vehicleAxles, 'vehicle');
}

function setupTrailer1AxleItems(trailer1Axles) {
    return setupAxleItems(trailer1Axles, 'trailer1');
}

function setupTrailer2AxleItems(trailer2Axles) {
    return setupAxleItems(trailer2Axles, 'trailer2');
}
// #endregion Setup Functions

// #region Format Functions
function formatCurrencyValue(value) {
    if (!isNaN(value)) {
        return offerManager.getUIFormattedCurrencyNoDecimalPlaceValue(value);
    }
}

function formatDistanceValue(value) {
    if (!isNaN(value)) {
        return offerManager.getUIFormattedDistanceNoDecimalValue(value);
    }
}

function convertDistanceFunction(value) {
    return globals.returnFloat(value) / activeMeasurementSystem.distanceConversion;
}
// #endregion Format Functions

function getAxleValues() {
    var axleValues = {};
    
    activeOffer.getRig().getChassisObjects().forEach(function (chassisObject) {
        if (axleValues[chassisObject.getChassisObjectName()] === undefined) {
            axleValues[chassisObject.getChassisObjectName()] = [];
        }

        chassisObject.getAxlesHolder().getAxles().forEach(function (axle) {
            axleValues[chassisObject.getChassisObjectName()].push({ noOfTyresOnAxleForCostingOverride: axle.getNoOfTyresOnAxleForCostingOverride(), noOfTyresOnAxleForCosting: axle.getNoOfTyresOnAxleForCosting(), averageCostOfTyre: axle.getAverageCostOfTyre(), averageLifeOfTyre: axle.getAverageLifeOfTyre() });
        });
    });
    return axleValues;
}

/**
 * Create axle item to be used in each row on the modal
 * @param {Object[]} axles - Array of axle objects (model/offer/configuration/model.axle.js)
 * @param {string} type - Type of chassis thast axle is attached to
 */
function setupAxleItems(axles, type) {
    var builtItems = [],
        optionsArray = offerManager.getNumberOfTyresOptions();

    axles.forEach(function (axle, index) {
        var idToUse = type + '-axle-' + index,
            noOfTyresSelectMenu = new SelectMenuBuilder(axle.getNoOfTyresOnAxleForCosting(), axle.getNoOfTyresOnAxleForCostingOverride(), optionsArray, idToUse + '-noOfTyres-selectmenu')
                .width(55)
                .build(),
            avgCostOfTyreSlider = new SliderOptionsBuilder(axle.getAverageCostOfTyre(), idToUse + '-avgCost-slider')
                .min(activeOffer.getCosting().getTyrePriceMin())
                .max(activeOffer.getCosting().getTyrePriceMax())
                .step(activeOffer.getCosting().getTyrePriceIncrement())
                .formatObject(
                    new SliderFormatObjectBuilder()
                    .sliderToFunction(function (value) {
                        return formatCurrencyValue(value);
                    })
                    .build()
                    )
                .formatFunction(formatCurrencyValue)
                .errorMessageText(config.getTranslationText('1171'))
                .blurFunctionMin(globals.costingAverageCostOfTyreMin)
                .blurFunctionMax(globals.costingAverageCostOfTyreMax)
                .build(),
            avgLifeOfTyreSlider = new SliderOptionsBuilder(axle.getAverageLifeOfTyre(), idToUse + '-avgLife-slider')
                .min(activeOffer.getCosting().getAverageTyreLifeMin())
                .max(activeOffer.getCosting().getAverageTyreLifeMax())
                .step(activeOffer.getCosting().getAverageTyreLifeIncrement())
                .formatObject(
                    new SliderFormatObjectBuilder()
                        .sliderToFunction(function (value) {
                            return formatDistanceValue(value);
                        })
                        .build()
                )
                .formatFunction(formatDistanceValue)
                .conversionFunction(convertDistanceFunction)
                .errorMessageText(config.getTranslationText('1172'))
                .blurFunctionMin(globals.costingAverageLifeOfTyreMin)
                .blurFunctionMax(globals.costingAverageLifeOfTyreMax)
                .build(),
            tyreDetailAxleItem = new TyreDetailAxleItemBuilder(axle)
                .label(axle.getNumber() + ' (' + axle.getTypeAsString() + ')')
                .addNoOfTyresSelectMenu(noOfTyresSelectMenu)
                .addAverageCostOfTyresSlider(avgCostOfTyreSlider)
                .addAverageLifeOfTyresSlider(avgLifeOfTyreSlider)
                .build();

        builtItems.push(new TyreDetailAxleItem(tyreDetailAxleItem));
    });

    return builtItems;
}

//#region Builder Functions
function TyreDetailAxleItemBuilder(originalAxleObject) {
    var self = this,
        newElement = {
            originalAxleObject: originalAxleObject
        };

    /**
     * 
     * @param {string} l - label of element
     */
    function label(l) {
        newElement.label = l;
        return self;
    }

    /**
     * 
     * @param {object} notsm
     */
    function addNoOfTyresSelectMenu(notsm) {
        newElement.noOfTyresSelectMenuObject = notsm;
        return self;
    }

    /**
     * 
     * @param {object} acots
     */
    function addAverageCostOfTyresSlider(acots) {
        newElement.averageCostOfTyresSliderObject = acots;
        return self;
    }

    /**
     * 
     * @param {object} alots
     */
    function addAverageLifeOfTyresSlider(alots) {
        newElement.averageLifeOfTyresSliderObject = alots;
        return self;
    }

    /**
     * Returns built SettingsBuilder object
     */
    function build() {
        return newElement;
    }

    this.label = label;
    this.addNoOfTyresSelectMenu = addNoOfTyresSelectMenu;
    this.addAverageCostOfTyresSlider = addAverageCostOfTyresSlider;
    this.addAverageLifeOfTyresSlider = addAverageLifeOfTyresSlider;
    this.build = build;
}

function SliderFormatObjectBuilder() {
    var self = this;
    var newElement = {
        to: function (value) {
            return value;
        },
        from: function (value) {
            return value;
        }
    }

    function sliderToFunction(stf) {
        newElement.to = stf;
        return self;
    }

    function sliderFromFunction(sff) {
        newElement.from = sff;
        return self;
    }

    function build() {
        return newElement;
    }

    this.sliderToFunction = sliderToFunction;
    this.sliderFromFunction = sliderFromFunction;
    this.build = build;
}

function SliderOptionsBuilder(value, id) {
    var self = this,
        newElement = {
            id: id,
            value: value,
            min: 0,
            max: 100,
            step: 1,
            direction: 'ltr',
            formatObject: null
        };

    function min(min) {
        newElement.min = min;
        return self;
    }

    function max(max) {
        newElement.max = max;
        return self;
    }

    function step(step) {
        newElement.step = step;
        return self;
    }

    function formatObject(f) {
        newElement.formatObject = f;
        return self;
    }

    function direction(d) {
        newElement.direction = d;
        return self;
    }

    function formatFunction(ff) {
        newElement.formatFunction = ff;
        return self;
    }

    function conversionFunction(cf) {
        newElement.conversionFunction = cf;
        return self;
    }

    function errorMessageText(emt) {
        newElement.errorMessageText = emt;
        return self;
    }

    function blurFunctionMin(bfm) {
        newElement.blurFunctionMin = bfm;
        return self;
    }

    function blurFunctionMax(bfm) {
        newElement.blurFunctionMax = bfm;
        return self;
    }

    function build() {
        return newElement;
    }

    this.id = id;
    this.min = min;
    this.max = max;
    this.step = step;
    this.formatObject = formatObject;
    this.direction = direction;
    this.formatFunction = formatFunction;
    this.conversionFunction = conversionFunction;
    this.errorMessageText = errorMessageText;
    this.blurFunctionMin = blurFunctionMin;
    this.blurFunctionMax = blurFunctionMax;
    this.build = build;
}

function SelectMenuBuilder(value, overrideValue, oa, id) {
    var self = this,
        newElement = {
            value: value,
            overrideValue: overrideValue,
            optionsArray: oa,
            id: id,
            width: 60
        };

    /**
     * 
     * @param {Number} w - width of the selectmenu
     */
    function width(w) {
        newElement.width = w;
        return self;
    }

    /**
     * 
     * @param {function} of - Function to be run when the selectmenu opens
     */
    function openFunction(of) {
        newElement.openFunction = of;
        return self;
    }

    /**
     * 
     * @param {function} cf - Function to be run when the selectmenu closes
     */
    function closeFunction(cf) {
        newElement.closeFunction = cf;
        return self;
    }

    function build() {
        return newElement;
    }

    this.width = width;
    this.openFunction = openFunction;
    this.closeFunction = closeFunction;
    this.build = build;
}
//#endregion Builder Functions

//#region Objects to be used on page
function TyreDetailInputBase(initObj) {
    var self = this,
        _value,
        _originalValue;

    construct(initObj);
    
    function construct(objToUse){
        self.id = objToUse.id.includes('#') ? objToUse.id.substr(1) : objToUse.id;
        self.type = objToUse.type;
        self.formatFunction = objToUse.formatFunction;

        setValue(objToUse.value);
        setOriginalValue(objToUse.value);
    }

    function getValue() {
        return _value;
    }

    function setValue(newValue) {                    
        _value = newValue;
    }

    function getOriginalValue() {
        return _originalValue;
    }

    function setOriginalValue(newValue) {
        _originalValue = newValue;
    }

    function getValueFormatted() {
        return typeof self.formatFunction === 'function' ? self.formatFunction(self.getValue()) : self.getValue();
    }

    self.getValue = getValue;
    self.setValue = setValue;
    self.getValueFormatted = getValueFormatted;
    self.getOriginalValue = getOriginalValue;
}

function TyreDetailSelectMenu(initSelectMenuObject) {
    TyreDetailInputBase.call(this, initSelectMenuObject);
    var self = this,
        _overrideValue,
        selectMenuOptionsObject = {
            select: selectFunction
        };

    construct(initSelectMenuObject);

    function construct(objToUse) {
        selectMenuOptionsObject.width = objToUse.width;
        self.optionsArray = objToUse.optionsArray;
        setOverrideValue(objToUse.overrideValue);
        self.valueForSelect = shallowRef(self.getValue());
        self.override = shallowRef(getOverrideValue());
        if (self.override.value === false) {
            selectMenuOptionsObject.disabled = true;
        }
    }

    function getOverrideValue() {
        return _overrideValue;
    }

    function setOverrideValue(newValue) {
        _overrideValue = newValue;
    }

    /**
     * Create selectmenu
     */
    self.createJsElement = function () {
        $('#' + self.id).selectmenu(selectMenuOptionsObject);
    }

    /**
     * Destroy the selectmenu
     */
    self.destroy = function () {
        $('#' + self.id).selectmenu('destroy');
    };

    self.toggleOverride = function () {
        if (self.override.value === true) {
            self.override.value = false;
            self.valueForSelect.value = self.getOriginalValue();
            $('#' + self.id).selectmenu('refresh');
            $('#' + self.id).selectmenu('disable');
        } else {
            self.override.value = true;
            $('#' + self.id).selectmenu('enable');
        }
    };

    function selectFunction(event, ui) {
        self.setValue(parseInt(ui.item.value));
        self.valueForSelect.value = self.getValue();
        triggerSubscriptionUpdates.value = new Date().getTime();
    }
}

function TyreDetailSlider(initSliderObject) {
    TyreDetailInputBase.call(this, initSliderObject);
    var self = this,
        sliderOptionsObject = {},
        slider;

    construct(initSliderObject);

    function construct(objToUse) {
        self.inputId = self.id + '-input';
        self.inputContainerId = self.id + '-input-container';
        self.min = objToUse.min;
        self.max = objToUse.max;
        sliderOptionsObject.min = objToUse.min;
        sliderOptionsObject.max = objToUse.max;
        sliderOptionsObject.step = objToUse.step;
        sliderOptionsObject.direction = objToUse.direction;
        sliderOptionsObject.formatObject = objToUse.formatObject;
        self.conversionFunction = objToUse.conversionFunction !== undefined ? objToUse.conversionFunction : function (value) { return value; };
        self.errorMessageText = objToUse.errorMessageText;
        self.blurFunctionMin = objToUse.blurFunctionMin;
        self.blurFunctionMax = objToUse.blurFunctionMax;

        self.errorMessage = shallowRef();
    }

    self.blurFunction = function () {
        var tempVal, updateValue,
            newValue = $('#' + self.inputId).val(),
            sliderElement = document.getElementById(self.id);
        if (newValue !== '') {
            tempVal = convertInputValueToMetric(newValue);
        } else {
            tempVal = -1;
        }

        if (isValueBetweenBlurFunctionMinAndMax(tempVal)) {
            sliderElement.noUiSlider.set(tempVal, true, true);
            self.setValue(tempVal);
            document.getElementById(self.inputId).value = self.getValueFormatted();
        } else {
            document.getElementById(self.inputId).value = self.getValueFormatted();
            self.errorMessage.value = config.getValidationMessageText(config.VALIDATION_MESSAGE_TYPE.BETWEEN, globals.user.getCultureCode(), self.errorMessageText, self.formatFunction(self.blurFunctionMin), self.formatFunction(self.blurFunctionMax));
            offerManager.displayError(self.inputContainerId, self.errorMessage.value);
        }
        triggerSubscriptionUpdates.value = new Date().getTime();
    }

    self.selectAllText = function () {
        globals.selectAllTextById(self.inputId);                    
    }

    /**
     * Create slider
     */
    self.createJsElement = function () {
        var sliderElement = document.getElementById(self.id);
        var sliderCreationObject = {
            start: self.getValue(),
            step: sliderOptionsObject.step,
            //connect: sliderOptionsObject.direction === 'rtl' ? 'upper' : 'lower',
            connect: sliderOptionsObject.direction === 'rtl' ? [false, true] : [true, false],
            tooltips: true,
            direction: sliderOptionsObject.direction,
            range: {
                'min': sliderOptionsObject.min,
                'max': sliderOptionsObject.max
            }
        }

        if (sliderOptionsObject.formatObject !== null) {
            sliderCreationObject.format = sliderOptionsObject.formatObject;
        }

        if (sliderElement !== null) {
            noUiSlider.create(sliderElement, sliderCreationObject);
            $('#' + self.id).find('.noUi-tooltip').hide();
            document.getElementById(self.inputId).value = self.getValueFormatted();

            sliderElement.noUiSlider.on('slide', function (values, handle) {
                $('#' + self.id).find('.noUi-tooltip').show();
            });

            sliderElement.noUiSlider.on('start', function (values, handle) {
                $('#' + self.id).find('.noUi-tooltip').show();
            });

            sliderElement.noUiSlider.on('set', function (values, handle) {
                $('#' + self.id).find('.noUi-tooltip').hide();
                var valueToUse = typeof values[handle] === 'string' ? self.conversionFunction(globals.returnFloat(globals.removeUnneccessaryCharactersFromGlobalizeNumberString(values[handle]))) : self.conversionFunction(values[handle]);
                self.setValue(valueToUse);
                document.getElementById(self.inputId).value = self.getValueFormatted();

                triggerSubscriptionUpdates.value = new Date().getTime();
            });
        }
    }

    /**
     * Destroy the slider
     */
    self.destroy = function () {
        $(self.id).destroy();
    };

    function convertInputValueToMetric(value) {
        var newValue = typeof value === 'string' ? globals.removeUnneccessaryCharactersFromGlobalizeNumberString(value) : value,
            measurementSystemToUse = activeMeasurementSystem;

        switch (self.incrementType) {
            case config.INCREMENT_TYPE.LENGTH:
                return globals.returnFloat(newValue) * measurementSystemToUse.lengthConversion;
            case config.INCREMENT_TYPE.MASS:
                return globals.returnFloat(newValue) / measurementSystemToUse.massConversion;
            default:
                return globals.returnFloat(newValue);
        }
    }

    function isValueBetweenMinAndMax(value) {
        return value >= self.min && value <= self.max;
    }

    function isValueBetweenBlurFunctionMinAndMax(value) {
        return value >= self.blurFunctionMin && value <= self.blurFunctionMax;
    }
}

function TyreDetailAxleItem(initAxleItem) {
    var self = this,
        _costPerKm;

    construct(initAxleItem);

    function construct(objToUse) {
        self.label = objToUse.label;
        self.originalAxleObject = objToUse.originalAxleObject;
        self.noOfTyresSelectMenu = new TyreDetailSelectMenu(objToUse.noOfTyresSelectMenuObject);
        self.averageCostOfTyresSlider = new TyreDetailSlider(objToUse.averageCostOfTyresSliderObject);
        self.averageLifeOfTyresSlider = new TyreDetailSlider(objToUse.averageLifeOfTyresSliderObject);
    }

    function getCostPerKm() {
        return _costPerKm;
    }

    function setCostPerKm(newValue) {
        _costPerKm = newValue;
    }

    self.getCostPerKm = getCostPerKm;

    self.costPerKmFormatted = computed(function () {
        var valueToUse = 0;
        triggerSubscriptionUpdates.value;
        //If Average Life per Tyre > 0
        //    = Average Cost per Tyre / Average Life per Tyre x No. of Tyres x 100
        //Else
        //    = 0
        //Endif
        if (self.averageLifeOfTyresSlider.getValue() > 0) {
            // valueToUse = (self.averageCostOfTyresSlider.getValue() / self.averageLifeOfTyresSlider.getValue()) * self.noOfTyresSelectMenu.getValue() * 100;
            valueToUse = (self.averageCostOfTyresSlider.getValue() / globals.getAverageLifeOfTyresValueForMeasurementSystem(self.averageLifeOfTyresSlider.getValue(), offerManager.getActiveOffer().getMeasurementSystem())) * self.noOfTyresSelectMenu.getValue() * 100;
        } else {
            valueToUse = 0;
        }
        setCostPerKm(valueToUse);
        updateTotalCostPerKmFormatted();

        return offerManager.getUIFormattedMinorCurrencyPerDistanceValue(getCostPerKm());
    });

    self.createJsElements = function () {
        self.noOfTyresSelectMenu.createJsElement();
        self.averageCostOfTyresSlider.createJsElement();
        self.averageLifeOfTyresSlider.createJsElement();
    };

    /**
     * Destroy created items, observables and computeds
     */
    self.destroy = function () {
        self.noOfTyresSelectMenu.destroy();
        self.averageCostOfTyresSlider.destroy();
        self.averageLifeOfTyresSlider.destroy();
    };
}
//#endregion Objects to be used on page

export default {
    name: 'CostingTyreDetailModal',
    props: {
        propsForModal: Object
    },
    components: { HeaderTyreDetail, RowTyreDetail, PopupModal },
    setup(props, context) {
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        
        let resolvePromise = undefined;

        activeOffer = offerManager.getActiveOffer();
        activeMeasurementSystem = activeOffer.getMeasurementSystem();

        onMounted(() => {
            activate();
            setTimeout(function(){ compositionComplete(); }, 100);
        });

        function show(opts = {}) {
           // Once we set our config, we tell the popup modal to open
            this.popup.open();
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function ok() {
            if (isTyreRateValid()) {
                vehicleAxleItems.value.forEach(updateOriginalAxleObjectIfNecessary);
                trailer1AxleItems.value.forEach(updateOriginalAxleObjectIfNecessary);
                trailer2AxleItems.value.forEach(updateOriginalAxleObjectIfNecessary);
                var finishingValues = getAxleValues();
                offerManager.getUndoHandler().newCompoundChangeValueOp(config.COSTING_DETAIL_MODAL_TYPES.TYRES, startingValues, finishingValues);
                close(getUpdatedTyreRate());
            } else {
                globals.showDialog(new CustomMessageBox(config.getValidationMessageText(config.VALIDATION_MESSAGE_TYPE.BETWEEN, globals.user.getCultureCode(), config.getTranslationText('2789'), offerManager.getUIFormattedMinorCurrencyPerDistanceValue(getTyreRateMin()), offerManager.getUIFormattedMinorCurrencyPerDistanceValue(getTyreRateMax())), config.applicationTitle, [config.getTranslationText('271')]));
            }
            
            function isTyreRateValid() {
                if (getUpdatedTyreRate() >= getTyreRateMin() && getUpdatedTyreRate() <= getTyreRateMax()) {
                    return true;
                }
                return false;
            }

            function updateOriginalAxleObjectIfNecessary(axleItem) {
                if (axleItem.noOfTyresSelectMenu.override.value !== axleItem.originalAxleObject.getNoOfTyresOnAxleForCostingOverride()) {
                    axleItem.originalAxleObject.setNoOfTyresOnAxleForCostingOverride(axleItem.noOfTyresSelectMenu.override.value);
                }

                if (axleItem.noOfTyresSelectMenu.getValue() !== axleItem.originalAxleObject.getNoOfTyresOnAxleForCosting()) {
                    axleItem.originalAxleObject.setNoOfTyresOnAxleForCosting(axleItem.noOfTyresSelectMenu.getValue());
                }

                if (axleItem.averageCostOfTyresSlider.getValue() !== axleItem.originalAxleObject.getAverageCostOfTyre()) {
                    axleItem.originalAxleObject.setAverageCostOfTyre(axleItem.averageCostOfTyresSlider.getValue());
                }

                if (axleItem.averageLifeOfTyresSlider.getValue() !== axleItem.originalAxleObject.getAverageLifeOfTyre()) {
                    axleItem.originalAxleObject.setAverageLifeOfTyre(axleItem.averageLifeOfTyresSlider.getValue());
                }
            }
        }

        function close(param) {
            globals.clearShallowRefArrayAndAddItems(vehicleAxleItems, []);
            globals.clearShallowRefArrayAndAddItems(trailer1AxleItems, []);
            globals.clearShallowRefArrayAndAddItems(trailer2AxleItems, []);
            popup.value.close();
            resolvePromise(param);
        }

        return {
            popup,
            show,
            // refs
            vehicleAxleItems,
            trailer1AxleItems,
            trailer2AxleItems,
            vehicleCostPerKmFormatted,
            trailer1CostPerKmFormatted,
            trailer2CostPerKmFormatted,
            totalCostPerKmFormatted,
            costPerDistanceText,
            // Functions
            ok,
            close,
            // Text
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>

</style>