import globals from '../services/globals';
import dataManager from '../services/dataManager';
import { createRouter, createWebHistory } from 'vue-router';
import bus from '../main';



const $ = window.$;
var TScMessenger = window.TScMessenger;
let versionNumber = process.env.VUE_APP_VERSION;

let isTruckScienceAPIUser = location.href.indexOf('truck-science') !== -1 ? true : false;
let optionalApiUserPathPrefix = '';
if(isTruckScienceAPIUser) {
    optionalApiUserPathPrefix = '/truck-science';
}

let ROUTES = {
    NOT_FOUND: {
        path: '/:pathMatch(.*)*',
        redirect: optionalApiUserPathPrefix + '/get_started'
        // redirect: to => {
        //     // the function receives the target route as the argument
        //     // we return a redirect path/location here.
            
        //     if (globals.user.getAppRefreshUnderway()/* || (globals.user.getEmailAddress() !== '' && globals.user.getEmailAddress() !== null && globals.user.getEmailAddress() !== undefined)*/) {
        //         console.log("In catchAll route, redirecting to selection from: " + to.path);
        //         return { path: '/selection' };
        //     }
        //     console.log("In catchAll route, redirecting to get_started from: " + to.path);
        //     return { path: '/get_started' };
        //   },
    },
  GET_STARTED: {
      name: 'getstarted',
      meta: {
          route: 'get_started(/:mode)',
          moduleId: 'viewmodels/get_started',
          title: 'Get Started',
          nav: false,
          id: 'get-started-id',
          hash: '/get_started',
          tooltip: '1066',
      },
      path: optionalApiUserPathPrefix + '/get_started',
      component: () => import('@/views/GetStartedView.vue'),
      beforeEnter: (to, from) => {
        globals.showSpinner();
        TScMessenger.writeDebugMessage('In get_started before enter... ' + versionNumber);
        //prevent navigation back to module when browser refresh or app refresh detected
        
        // if(versionNumber === '2023.1.16' && !localStorage.getItem('oldToNewUpdateHackApplied')) {
        //     localStorage.setItem('oldToNewUpdateHackApplied', 'YES');
        //     console.log('In get_started before enter... oldToNewUpdateHackApplied, returning selection... ' + versionNumber);
        //     // // console.log('In get_started before enter... setting app refresh underway... ' + versionNumber);
        //     // // globals.user.setAppRefreshUnderway('applying_update');
        //     // console.log('In get_started before enter... returning selection from version check code... ' + versionNumber);
        //     setTimeout(function () {
        //         console.log('In get_started before enter... emitting handleAppUpdate... ' + versionNumber);
        //         bus.emit('handleAppUpdate');
        //     }, 3000);
            
        //     return { name: ROUTES.SELECTION.name }
        // }
        if(localStorage.getItem('overrideRouterLogicAndGoToGetStarted')) {
        // if(globals.overrideRouterLogicAndGoToGetStarted) {
            globals.dataModelReady.value = true;
            globals.user.removeAppRefreshUnderway();
            return true;
        }else if (globals.user.getAppRefreshUnderway() || (( 
        (performance !== undefined && performance.navigation !== undefined && performance.navigation.type !== undefined && performance.navigation.type === 1) || 
        (globals.user.getEmailAddress() !== '' && globals.user.getEmailAddress() !== null && globals.user.getEmailAddress() !== undefined) || localStorage.getItem('authMethod')) && globals.getAlreadyAuthenticated() === 'YES')) {
            // TScMessenger.writeDebugMessage('In get_started before enter... returning selection... to.query: ' + to.query.mode  + ', from.query: ' + from.query.mode + ', version: ' + versionNumber);
            return { name: ROUTES.SELECTION.name }
        } else {
            // TScMessenger.writeDebugMessage('In get_started before enter... returning get_started... to.query: ' + to.query.mode  + ', from.query: ' + from.query.mode + ', version: ' + versionNumber);
            if(to.query.mode) {
                // to.query.mode = from.query.mode;
                localStorage.setItem('mode', to.query.mode);
            }
            // if(to.query.accessCode) {
            //     // to.query.accessCode = from.query.accessCode;
            //     localStorage.setItem('accessCode', to.query.accessCode);
            // }
            // to.query = from.query;
            TScMessenger.writeDebugMessage('globals.dataModelReady.value = true in router>getstarted>route to getstarted...');
            globals.dataModelReady.value = true;
            if(!(globals.user.getAccessCode() !== '' && globals.user.getAccessCode())) {
                globals.hideSpinner();
            }else  {
                globals.showSpinner();
                if(globals.preventHideSpinner) {
                    return false;
                }
            }
            //continue as normal
            return true;
        }
      }
  },
//   SIGN_UP: {
//     name: 'signup',
//     meta: {
//         route: 'signup',
//         moduleId: 'viewmodels/signup',
//         title: 'Sign Up',
//         nav: false,
//         id: 'signup-id',
//         hash: '/signup',
//         tooltip: '1066',
//     },
//     path: '/signup',
//     component: () => import('@/views/SignUpView.vue'),
//     beforeEnter: (to, from) => {
//     //   globals.showSpinner();
//       TScMessenger.writeDebugMessage('In sign up before enter... ' + versionNumber);
      

//       return true;
//     }
// },
  SELECTION: {
      name: 'selection',
      meta : {
          route: 'selection',
          moduleId: 'viewmodels/selection',
          title: 'Selection',
          nav: false,
          id: 'selection-id',
          hash: '/selection',
          tooltip: '1066',
      },
      path: optionalApiUserPathPrefix + '/selection',
      component: () => import('@/views/SelectionView.vue'),
      beforeEnter: (to, from) => {
        // globals.showSpinner();
        TScMessenger.writeDebugMessage('In selection before enter... ' + versionNumber);
        //prevent navigation back to module when browser refresh or app refresh detected
        
        if(localStorage.getItem('overrideRouterLogicAndGoToGetStarted')) {
        // if(globals.overrideRouterLogicAndGoToGetStarted) {
            globals.dataModelReady.value = true;
            globals.user.removeAppRefreshUnderway();
            return { name: ROUTES.GET_STARTED.name };
        }else if(globals.user.getAppRefreshUnderway() || 
            (((globals.user.getEmailAddress() !== '' && globals.user.getEmailAddress() !== null && globals.user.getEmailAddress() !== undefined) || localStorage.getItem('authMethod')) && globals.getAlreadyAuthenticated() === 'YES')) {
            TScMessenger.writeDebugMessage('In selection before enter, returning true... ');
            return true;
        }else {
            TScMessenger.writeDebugMessage('In selection before enter, returning false... ');
            return { name: ROUTES.GET_STARTED.name };
        }

        
      }
  },
  CONFIGURATION: {
      name: 'configuration',
      meta: {
          route: 'configuration(/:mode)',
          moduleId: 'viewmodels/offer/configuration/configuration',
          title: 'Configuration',
          nav: true,
          id: 'configuration-id',
          hash: '/configuration',
          dataAvailabilityCode: 'ActiveConfiguration',
          tooltip: '1061',
      },
      path: optionalApiUserPathPrefix + '/configuration',
      component: () => import('@/views/offer/ConfigurationView.vue'),
      beforeEnter: ((to, from) => {
        globals.sendModuleDataToIntercom(to, from, dataManager.sendInfoToIntercom);
        return true;
      })
  },
  PERFORMANCE: {
      name: 'performance',
      meta: {
          route: 'performance',
          moduleId: 'viewmodels/offer/performance',
          title: 'Performance',
          nav: true,
          id: 'performance-id',
          hash: '/performance',
          dataAvailabilityCode: 'ActivePerformance',
          tooltip: '1062',
      },
      path: optionalApiUserPathPrefix + '/performance',
      component: () => import('@/views/offer/PerformanceView.vue'),
      beforeEnter: ((to, from) => {
        globals.sendModuleDataToIntercom(to, from, dataManager.sendInfoToIntercom);
        return true;
      })
  },
  COSTING: {
      name: 'costing',
      meta: {
          route: 'costing',
          moduleId: 'viewmodels/offer/costing',
          title: 'Costing',
          nav: true,
          id: 'costing-id',
          hash: '/costing',
          dataAvailabilityCode: 'ActiveCosting',
          tooltip: '1063',
      },
      path: optionalApiUserPathPrefix + '/costing',
      component: () => import('@/views/offer/CostingView.vue'),
      beforeEnter: ((to, from) => {
        globals.sendModuleDataToIntercom(to, from, dataManager.sendInfoToIntercom);
        return true;
      })
  },
  SPECIFICATION: {
      name: 'specification',
      meta: {
          route: 'specification',
          moduleId: 'viewmodels/offer/specification',
          title: 'Specification',
          nav: true,
          id: 'specification-id',
          hash: '/specification',
          dataAvailabilityCode: 'ActiveSpecification',
          tooltip: '1064',
      },
      path: optionalApiUserPathPrefix + '/specification',
      component: () => import('@/views/offer/SpecificationView.vue'),
      beforeEnter: ((to, from) => {
        globals.sendModuleDataToIntercom(to, from, dataManager.sendInfoToIntercom);
        return true;
      })
  },
  SUMMARY: {
      name: 'summary',
      meta: {
          route: 'summary',
          moduleId: 'viewmodels/offer/summary',
          title: 'Summary',
          nav: true,
          id: 'summary-id',
          hash: '/summary',
          dataAvailabilityCode: 'ActiveSummary',
          tooltip: '1065',
          faClasses: 'fas fa-file-pdf',
      },
      path: optionalApiUserPathPrefix + '/summary',
      component: () => import('@/views/offer/SummaryView.vue'),
      beforeEnter: ((to, from) => {
        globals.sendModuleDataToIntercom(to, from, dataManager.sendInfoToIntercom);
        return true;
      })
  },
  EXTERNAL_ACCESS: {
      name: 'externalAccess',
      meta: {
          route: 'externalAccess(/:accessCode)',
          moduleId: 'viewmodels/externalAccess',
          title: 'Access Share',
          nav: false,
          id: 'externalAccess-id',
          hash: '/externalAccess',
          tooltip: '1066'
      },
      path: optionalApiUserPathPrefix + '/externalAccess',
      component: () => import('@/views/ExternalAccessView.vue'),
      beforeEnter: (to, from) => {
        TScMessenger.writeDebugMessage('In externalAccess beforeEnter...');
        if (to !== undefined && to.query && to.query.accessCode && to.query.accessCode !== '') {
            localStorage.setItem('accessCode', to.query.accessCode);
            globals.dataModelReady.value = true;
            return true;
        } else if (to !== undefined && to.query && to.query.accessFlag && (to.query.accessFlag === 'embed' || to.query.accessFlag === 'tab')) {
            localStorage.setItem('accessFlag', to.query.accessFlag);
            // globals.dataModelReady.value = true;
            globals.dataModelReady.value = true;
            return true;
        }  else if (to !== undefined && to.hash && to.hash.indexOf('accessFlag') !== -1 && (to.hash.indexOf('accessFlag=embed') !== -1 || to.hash.indexOf('accessFlag=tab') !== -1)) {
            TScMessenger.writeDebugMessage('In externalAccess beforeEnter, hash detected, routing to externalAccess...');
            localStorage.setItem('accessFlag', to.hash);
            // globals.dataModelReady.value = true;
            globals.dataModelReady.value = true;
            return true;
        }else {
            return { name: ROUTES.GET_STARTED.name };
        }
      }
  }
};
const startingRoutes = [ROUTES.GET_STARTED, /*ROUTES.SIGN_UP,*/ ROUTES.EXTERNAL_ACCESS, ROUTES.SELECTION, ROUTES.NOT_FOUND];
const router = createRouter({
  history: createWebHistory('/'),
  routes: startingRoutes
});
// router.beforeEach((to, from) => {
//     // next(vm => {
//     //     globals.sendModuleDataToIntercom(to, from, dataManager.sendInfoToIntercom);
//     // });
//     console.log("In beforeEach: to: " + to.name + ", from: " + from.name);
//     return true;
//   });

// router.beforeResolve((to, from) => {
//     globals.sendModuleDataToIntercom(to, from, dataManager.sendInfoToIntercom);
// });

// router.afterEach((to, from, failure) => {
//   // if(globals.displayBlackout !== undefined) {
//   //   globals.displayBlackout = false;
//   //   globals.displaySpinner = false;
//   // }
//   return true;
// })

export { router, ROUTES };
