import config from '../config';
import globals from '../globals';
import { nextTick } from 'vue';
import utils from '../utils';
// import { config, utils, globals } from '../../services';
// import $ from '../../assets/scripts/jquery-3.6.0.min';
// import router from '@/router';

const $ = window.$;


function OpEngine(offerManager) {

    function executeOp(op, undoRedoFlag) {

        // if (router.activeItem().title !== op.getModuleTitle()) {
        //     var hash = utils.getModuleHashFromTitle(op.getModuleTitle());
        //     if (hash !== null) {
        //         router.navigate(hash);
        //     }

        //     return false;
        // }
        var opAOG;
        switch (op.getType()) {
            case config.CALCULATION_OP_TYPES.CHANGE_VALUE:
                opAOG = offerManager.getAttributeGroup(op.getPath());
                if (undoRedoFlag === 'UNDO') {
                    opAOG.disableUndoOpCreation();
                    opAOG.formatted.value = getMeasurementSystemValue(op, op.getUndoValue(), 'TO');
                    //opAOG.formatted(op.getUndoValue());
                    opAOG.enableUndoOpCreation();
                    if (op.getLinkedOp()) {
                        executeOp(op.getLinkedOp(), 'UNDO');
                    }
                } else {
                    opAOG.disableUndoOpCreation();
                    if (op.getIsFromDragging()) {
                        if (op.getInitialMeasurementSystemId() === offerManager.activeMeasurementSystem.value.id) {
                            opAOG.formatted.value = op.getRedoValue();
                        } else if ((op.getInitialMeasurementSystemId() === config.MEASUREMENT_SYSTEM.IMPERIALUK || op.getInitialMeasurementSystemId() === config.MEASUREMENT_SYSTEM.IMPERIALUS) && offerManager.activeMeasurementSystem.value.id === config.MEASUREMENT_SYSTEM.METRIC) {
                            opAOG.formatted.value = getMeasurementSystemValue(op, op.getRedoValue(), 'FROM');
                        } else {
                            opAOG.formatted.value = getMeasurementSystemValue(op, op.getRedoValue(), 'TO');
                        }
                    } else {
                        opAOG.formatted.value = getMeasurementSystemValue(op, op.getRedoValue(), 'TO');
                    }
                    opAOG.enableUndoOpCreation();
                    if (op.getLinkedOp()) {
                        executeOp(op.getLinkedOp(), 'REDO');
                    }
                }
                if (op.getValueLinkedSliderId()) {
                    setTimeout(function () {
                        var event = document.createEvent('Event');
                        event.initEvent('blur', true, true);

                        var sliderElem = document.getElementById(op.getValueLinkedSliderId())

                        // Dispatch the event.
                        sliderElem.dispatchEvent(event);
                    }, 150);
                }
                break;
            case config.CALCULATION_OP_TYPES.CHANGE_SELECT:
                opAOG = offerManager.getAttributeGroup(op.getPath());
                offerManager.getUndoHandler().disableUndoOpCreation();
                if (undoRedoFlag === 'UNDO') {
                    var undoOption = opAOG.getSelectOptionById(op.getUndoValue());
                    if (undoOption !== null) {
                        opAOG.disableUndoOpCreation();
                        if (opAOG.getValidator() !== null) {
                            opAOG.optionProxy.value = undoOption;
                        } else {
                            opAOG.option.value = undoOption;
                            opAOG.optionId.value = undoOption.id;
                        }
                        opAOG.enableUndoOpCreation();
                    }
                } else {
                    var redoOption = opAOG.getSelectOptionById(op.getRedoValue());
                    if (redoOption !== null) {
                        opAOG.disableUndoOpCreation();
                        if (opAOG.getValidator() !== null) {
                            opAOG.optionProxy.value = redoOption;
                        } else {
                            opAOG.option.value = redoOption;
                            opAOG.optionId.value = redoOption.id;
                        }
                        nextTick(function (){
                            opAOG.enableUndoOpCreation();
                        });
                    }
                }
                if (opAOG.refreshObv) {
                    opAOG.refreshObv.value = new Date().getTime();
                }
                
                nextTick(function (){
                    offerManager.getUndoHandler().enableUndoOpCreation();
                });
                break;
            case config.CALCULATION_OP_TYPES.CHANGE_CHECKBOX:
                opAOG = offerManager.getAttributeGroup(op.getPath());
                if (undoRedoFlag === 'UNDO') {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    opAOG.disableUndoOpCreation();
                    if (op.getCheckboxType() === config.CHECKBOX_TYPE.OVERRIDE) {
                        opAOG.override.value = op.getUndoValue();
                    } else {
                        opAOG.value.value = op.getUndoValue();
                        if (op.getUndoValue() === false) {
                            if (op.getLinkedOp()) {
                                executeOp(op.getLinkedOp(), 'UNDO');
                            }
                        }
                    }
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                        opAOG.enableUndoOpCreation();
                    });
                    
                    
                } else {
                    opAOG.disableUndoOpCreation();
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    if (op.getCheckboxType() === config.CHECKBOX_TYPE.OVERRIDE) {
                        opAOG.override.value = op.getRedoValue();
                    } else {
                        opAOG.value.value = op.getRedoValue();
                    }
                    
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                        opAOG.enableUndoOpCreation();
                    });
                }
                break;
            case config.CALCULATION_OP_TYPES.ADD_RIG_OBJECT:
                if (undoRedoFlag === 'UNDO') {
                    //switch (op.getObjectAccessoryType()) {
                    //    case config.ACCESSORY_TYPES.BODY:
                    //        offerManager.getUndoHandler().disableUndoOpCreation();
                    //        offerManager.removeObjectFromRig(op.getPath());
                    //        offerManager.getUndoHandler().enableUndoOpCreation();
                    //        //offerManager.getBody(offerManager.getBodyStub(op.getObjectId(), op.getObjectSource(), op.getObjectAccessoryType(), op.getObjectAccessLevel()), op.getPath()).then(function (response) {
                    //        //    offerManager.addBodyToCurrentChassis(response.Body, op.getPath());
                    //        //    offerManager.invalidatePdfAndDrawing();
                    //        //});
                    //        break;
                    //}
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    offerManager.removeObjectFromRig(op.getPath(), op.getObjectInternalId());
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                } else {
                    switch (op.getObjectAccessoryType()) {
                        case config.ACCESSORY_TYPES.BODY:
                            offerManager.getUndoHandler().disableUndoOpCreation();
                            offerManager.getBody(offerManager.getBodyStub(op.getObjectId(), op.getObjectSource(), op.getObjectAccessoryType(), op.getObjectAccessLevel()), op.getPath()).then(function (response) {
                                offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), response.Body.getId(), op.getPath());
                                op.addObjectInternalId(response.Body.getId());
                                offerManager.getUndoHandler().disableUndoOpCreation();
                                offerManager.addBodyToCurrentChassis(response.Body, op.getPath());
                                nextTick(function (){
                                    offerManager.getUndoHandler().enableUndoOpCreation();
                                });
                                offerManager.invalidatePdfAndDrawing();
                            });
                            break;
                        case config.ACCESSORY_TYPES.TRAILER:
                            offerManager.getUndoHandler().disableUndoOpCreation();
                            offerManager.getTrailer(offerManager.getTrailerStub(op.getObjectId(), op.getObjectSource(), op.getObjectAccessoryType(), op.getObjectAccessLevel()), op.getPath()).then(function (response) {
                                offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), response.Trailer.getId(), op.getPath());
                                op.addObjectInternalId(response.Trailer.getId());
                                offerManager.getUndoHandler().disableUndoOpCreation();
                                offerManager.addTrailerToCurrentRig(response.Trailer, op.getPath());
                                nextTick(function (){
                                    offerManager.getUndoHandler().enableUndoOpCreation();
                                });
                                offerManager.invalidatePdfAndDrawing();
                            });
                            break;
                        case config.ACCESSORY_TYPES.OTHER:
                        case config.ACCESSORY_TYPES.CRANE:
                        case config.ACCESSORY_TYPES.FRIDGE:
                        case config.ACCESSORY_TYPES.TAILLIFT:
                        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                        case config.ACCESSORY_TYPES.HITCH:
                        case config.ACCESSORY_TYPES.HOOKLIFT:
                            offerManager.getUndoHandler().disableUndoOpCreation();
                            offerManager.getAccessory(offerManager.getAccessoryStub(op.getObjectId(), op.getObjectSource(), op.getObjectAccessoryType(), op.getObjectAccessLevel()), op.getPath()).then(function (response) {
                                offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), response.Accessory.getId(), op.getPath());
                                op.addObjectInternalId(response.Accessory.getId());
                                offerManager.getUndoHandler().disableUndoOpCreation();
                                //var pathToUse = op.getPath();
                                offerManager.addAccessoryToCurrentChassis(response.Accessory, op.getPath());
                                //offerManager.postUndoConfigurationCallback(op.getPath());
                                offerManager.notifyUndoRedoListeners(op, 'REDO');
                                nextTick(function (){
                                    offerManager.getUndoHandler().enableUndoOpCreation();
                                });
                                offerManager.invalidatePdfAndDrawing();
                            });
                            break;
                        case config.ACCESSORY_TYPES.PAYLOAD:
                            offerManager.getUndoHandler().disableUndoOpCreation();
                            offerManager.getPayload(offerManager.getPayloadStub(op.getObjectId(), op.getObjectSource(), op.getObjectAccessoryType(), op.getObjectAccessLevel()), op.getPath()).then(function (response) {
                                offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), response.Payload.getId(), op.getPath());
                                op.addObjectInternalId(response.Payload.getId());
                                offerManager.getUndoHandler().disableUndoOpCreation();
                                offerManager.addPayloadToCurrentChassis(response.Payload, op.getPath());
                                //offerManager.postUndoConfigurationCallback(op.getPath());
                                offerManager.notifyUndoRedoListeners(op, 'REDO');
                                nextTick(function (){
                                    offerManager.getUndoHandler().enableUndoOpCreation();
                                });
                                offerManager.invalidatePdfAndDrawing();
                            });
                            break;
                    }
                }
                break;
            case config.CALCULATION_OP_TYPES.REMOVE_RIG_OBJECT:
                var waypoint;
                if (undoRedoFlag === 'UNDO') {
                    switch (op.getObjectAccessoryType()) {
                        case config.ACCESSORY_TYPES.BODY:
                            offerManager.getUndoHandler().disableUndoOpCreation();
                            offerManager.getBody(offerManager.getBodyStub(op.getObjectId(), op.getObjectSource(), op.getObjectAccessoryType(), op.getObjectAccessLevel()), op.getPath()).then(function (response) {
                                offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), response.Body.getId(), op.getPath());
                                op.addObjectInternalId(response.Body.getId());
                                offerManager.getUndoHandler().disableUndoOpCreation();
                                offerManager.addBodyToCurrentChassis(response.Body, op.getPath());
                                handleObjectUndoStackIfPresent(op);
                                nextTick(function (){
                                    offerManager.getUndoHandler().enableUndoOpCreation();
                                });
                                offerManager.invalidatePdfAndDrawing();
                            });
                            
                            break;
                        case config.ACCESSORY_TYPES.TRAILER:
                            offerManager.getUndoHandler().disableUndoOpCreation();
                            offerManager.getTrailer(offerManager.getTrailerStub(op.getObjectId(), op.getObjectSource(), op.getObjectAccessoryType(), op.getObjectAccessLevel()), op.getPath()).then(function (response) {
                                offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), response.Trailer.getId(), op.getPath());
                                op.addObjectInternalId(response.Trailer.getId());
                                offerManager.getUndoHandler().disableUndoOpCreation();
                                offerManager.addTrailerToCurrentRig(response.Trailer, op.getPath());
                                handleObjectUndoStackIfPresent(op, 'REDO')
                                nextTick(function (){
                                    offerManager.getUndoHandler().enableUndoOpCreation();
                                });
                                offerManager.invalidatePdfAndDrawing();
                            });
                            break;
                        case config.ACCESSORY_TYPES.OTHER:
                        case config.ACCESSORY_TYPES.CRANE:
                        case config.ACCESSORY_TYPES.FRIDGE:
                        case config.ACCESSORY_TYPES.TAILLIFT:
                        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                        case config.ACCESSORY_TYPES.HITCH:
                        case config.ACCESSORY_TYPES.HOOKLIFT:
                            offerManager.getUndoHandler().disableUndoOpCreation();
                            offerManager.getAccessory(offerManager.getAccessoryStub(op.getObjectId(), op.getObjectSource(), op.getObjectAccessoryType(), op.getObjectAccessLevel()), op.getPath()).then(function (response) {
                                offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), response.Accessory.getId(), op.getPath());
                                op.addObjectInternalId(response.Accessory.getId());
                                offerManager.getUndoHandler().disableUndoOpCreation();
                                offerManager.addAccessoryToCurrentChassis(response.Accessory, op.getPath());
                                //offerManager.postUndoConfigurationCallback(op.getPath());
                                offerManager.notifyUndoRedoListeners(op, 'UNDO');
                                handleObjectUndoStackIfPresent(op, 'REDO')
                                nextTick(function (){
                                    offerManager.getUndoHandler().enableUndoOpCreation();
                                });
                                offerManager.invalidatePdfAndDrawing();
                            });
                            break;
                        case config.ACCESSORY_TYPES.PAYLOAD:
                            offerManager.getUndoHandler().disableUndoOpCreation();
                            offerManager.getPayload(offerManager.getPayloadStub(op.getObjectId(), op.getObjectSource(), op.getObjectAccessoryType(), op.getObjectAccessLevel()), op.getPath()).then(function (response) {
                                offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), response.Payload.getId(), op.getPath());
                                op.addObjectInternalId(response.Payload.getId());
                                offerManager.getUndoHandler().disableUndoOpCreation();
                                offerManager.addPayloadToCurrentChassis(response.Payload, op.getPath());
                                //offerManager.postUndoConfigurationCallback(op.getPath());
                                offerManager.notifyUndoRedoListeners(op, 'UNDO');
                                handleObjectUndoStackIfPresent(op, 'REDO')
                                nextTick(function (){
                                    offerManager.getUndoHandler().enableUndoOpCreation();
                                });
                                offerManager.invalidatePdfAndDrawing();
                            });
                            break;
                    }
                } else {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    offerManager.removeObjectFromRig(op.getPath(), op.getObjectInternalId());
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                    //switch (op.getObjectAccessoryType()) {
                    //    case config.ACCESSORY_TYPES.BODY:
                    //        offerManager.getUndoHandler().disableUndoOpCreation();
                    //        offerManager.removeObjectFromRig(op.getPath());
                    //        offerManager.getUndoHandler().enableUndoOpCreation();
                    //        //offerManager.getBody(offerManager.getBodyStub(op.getObjectId(), op.getObjectSource(), op.getObjectAccessoryType(), op.getObjectAccessLevel()), op.getPath()).then(function (response) {
                    //        //    offerManager.addBodyToCurrentChassis(response.Body, op.getPath());
                    //        //    offerManager.invalidatePdfAndDrawing();
                    //        //});
                    //        break;
                    //}
                }
                break;
            case config.CALCULATION_OP_TYPES.ADD_AXLE:
                if (undoRedoFlag === 'UNDO') {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    offerManager.removeObjectFromRig(op.getPath(), op.getObjectInternalId());
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                }else {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    offerManager.equipAxle(op.getPath(), op.getAxleType()).then(function (response) {
                        offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), response.Axle.getId(), op.getPath());
                        op.addObjectInternalId(response.Axle.getId());
                        
                        //offerManager.postUndoConfigurationCallback(op.getPath());
                        
                        nextTick(function (){
                            offerManager.getUndoHandler().enableUndoOpCreation();
                        });
                        offerManager.invalidatePdfAndDrawing();
                    });
                }
                break;
            case config.CALCULATION_OP_TYPES.REMOVE_AXLE:
                if (undoRedoFlag === 'UNDO') {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    offerManager.equipAxle(op.getPath(), op.getAxleType()).then(function (response) {
                        offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), response.Axle.getId(), op.getPath());
                        op.addObjectInternalId(response.Axle.getId());

                        //offerManager.postUndoConfigurationCallback(op.getPath());
                        handleObjectUndoStackIfPresent(op, 'REDO')
                        nextTick(function (){
                            offerManager.getUndoHandler().enableUndoOpCreation();
                        });
                        offerManager.invalidatePdfAndDrawing();
                    });
                } else {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    offerManager.removeObjectFromRig(op.getPath(), op.getObjectInternalId());
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                }
                break;
            case config.CALCULATION_OP_TYPES.ADD_WAYPOINT:
                if (undoRedoFlag === 'UNDO') {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    //offerManager.deleteObject(op.getPath(), op.getObjectInternalId());
                    offerManager.notifyUndoRedoListeners(op, 'UNDO');
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                } else {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    waypoint = op.getCreateMethod()(op.getWaypointDescription(), op.getWaypointLocation());
                    offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), waypoint.getInternalId(), op.getPath());
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                    
                }
                break;
            case config.CALCULATION_OP_TYPES.REMOVE_WAYPOINT:
                if (undoRedoFlag === 'UNDO') {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    waypoint = op.getCreateMethod()(op.getWaypointDescription(), op.getWaypointLocation());
                    offerManager.getUndoHandler().updateOpDefunctObjectInternalId(op.getObjectInternalId(), waypoint.getInternalId(), op.getPath());
                    handleObjectUndoStackIfPresent(op, 'REDO')
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                } else {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    //offerManager.deleteObject(op.getPath(), op.getObjectInternalId());
                    offerManager.notifyUndoRedoListeners(op, 'REDO');
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                    
                }
                break;
            case config.CALCULATION_OP_TYPES.CHANGE_WAYPOINT_ORDER:
                if (undoRedoFlag === 'UNDO') {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    offerManager.notifyUndoRedoListeners(op, 'UNDO');
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                } else {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    offerManager.notifyUndoRedoListeners(op, 'REDO');
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                }
                break;
            case config.CALCULATION_OP_TYPES.COMPOUND_CHANGE_VALUE:
                if (undoRedoFlag === 'UNDO') {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    offerManager.notifyUndoRedoListeners(op, 'UNDO');
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                } else {
                    offerManager.getUndoHandler().disableUndoOpCreation();
                    offerManager.notifyUndoRedoListeners(op, 'REDO');
                    nextTick(function (){
                        offerManager.getUndoHandler().enableUndoOpCreation();
                    });
                }
                break;
        }
        return true;
        function handleObjectUndoStackIfPresent(op, opContext) {
            if (op.getObjectUndoStack()) {
                //offerManager.getUndoHandler().blockOpCreationFlagChange();
                offerManager.showShellSpinner(true);
                var undoStack = op.getObjectUndoStack();
                var undoStackCounter = { value: 0 };
                handleNextOp(undoStackCounter, undoStack, opContext);
                
                offerManager.showShellSpinner(false);
                //op.getObjectUndoStack().forEach(function (op) {
                //    if (op.getType() === config.CALCULATION_OP_TYPES.ADD_RIG_OBJECT || op.getType() === config.CALCULATION_OP_TYPES.REMOVE_RIG_OBJECT) {

                //        executOpWithPromise().then();
                //        function executOpWithPromise() {
                //            undoStackDfd = $.Deferred();
                //            executeOp(op, opContext);
                //            return undoStackDfd.promise()
                //        }
                //    } else {
                //        executeOp(op, opContext);
                //    }
                    
                //});
                //offerManager.getUndoHandler().unblockOpCreationFlagChange();
            }
        }

        function handleNextOp(undoStackCounter, undoStack, opContext) {
            if (undoStackCounter.value === undoStack.length) {
                return;
            }
            var curOp = undoStack[undoStackCounter.value++];
            if (curOp.getType() === config.CALCULATION_OP_TYPES.ADD_RIG_OBJECT || curOp.getType() === config.CALCULATION_OP_TYPES.REMOVE_RIG_OBJECT) {

                executOpWithPromise(curOp, opContext).then(function () {
                    offerManager.getUndoHandler().setUndoStackDfd(null, null);
                    handleNextOp(undoStackCounter, undoStack, opContext);
                });
                
            } else {
                executeOp(curOp, opContext);
                handleNextOp(undoStackCounter, undoStack, opContext);
            }
        }
        function executOpWithPromise(curOp, opContext) {
            var newUndoStackDeferred = $.Deferred();
            offerManager.getUndoHandler().setUndoStackDfd(newUndoStackDeferred, curOp.getPath());
            executeOp(curOp, opContext);
            return newUndoStackDeferred.promise()
        }

        function getMeasurementSystemValue(op, newValue, conversionDirFlag) {

            //var newValue = op.getRedoValue();
            var unitType = op.getUnitType();
            var measurementSystem;
            if (conversionDirFlag === 'TO') {
                measurementSystem = offerManager.getActiveOffer() !== '' && offerManager.getActiveOffer() !== undefined ? offerManager.getActiveOffer().getMeasurementSystem() : globals.user.getActiveMeasurementSystem();
            } else {
                measurementSystem = globals.getMeasurementSystemUsingType(op.getInitialMeasurementSystemId());
            }

            var unitTypeForSwitch;

            if (op.getCustomFormattingRuleFunc() !== undefined) {
                if (op.getCustomFormattingRuleFunc()() === true) {
                    unitTypeForSwitch = op.getCustomUnitType();
                }
            }

            if (conversionDirFlag === 'TO') {
                switch (unitTypeForSwitch || unitType) {
                    case config.INCREMENT_TYPE.LENGTH:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().lengthConversion;
                        newValue = globals.returnFloat(newValue) / measurementSystem.lengthConversion;
                        break;
                    case config.INCREMENT_TYPE.MASS:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().massConversion;
                        newValue = globals.returnFloat(newValue) * measurementSystem.massConversion;
                        break;
                    case config.INCREMENT_TYPE.VOLUME:
                    case config.INCREMENT_TYPE.VOLUMEPER100KMS:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().volumeConversion;
                        newValue = globals.returnFloat(newValue) * measurementSystem.volumeConversion;
                        break;
                    case config.INCREMENT_TYPE.CURRENCYPERVOLUME:
                        //newValue = globals.returnFloat(newValue) * getOfferManager().getActiveOffer().getMeasurementSystem().volumeConversion;
                        newValue = globals.returnFloat(newValue) / measurementSystem.volumeConversion;
                        break;
                    case config.INCREMENT_TYPE.SPECIFICGRAVITY:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().densityConversion;
                        newValue = globals.returnFloat(newValue) * measurementSystem.densityConversion;
                        break;
                    case config.INCREMENT_TYPE.LINEARDENSITY:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().linearDensityConversion;
                        newValue = globals.returnFloat(newValue) * measurementSystem.linearDensityConversion;
                        break;
                    case config.INCREMENT_TYPE.PERCENTAGE:
                    case config.INCREMENT_TYPE.PERCENTAGEINCREMENT:
                    case config.INCREMENT_TYPE.NUMBER:
                        newValue = globals.returnFloat(newValue);
                        break;
                    case config.INCREMENT_TYPE.MONTHLYDISTANCE:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().distanceConversion;
                        newValue = globals.returnFloat(newValue) * measurementSystem.distanceConversion;
                        break;
                    case config.INCREMENT_TYPE.AREA:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().areaConversion;
                        newValue = globals.returnFloat(newValue) * measurementSystem.areaConversion;
                        break;
                    case config.INCREMENT_TYPE.SPEED:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().speedConversion;
                        newValue = globals.returnFloat(newValue) * measurementSystem.speedConversion;
                        break;
                    case config.INCREMENT_TYPE.FUELCONSUMPTION:
                        newValue = globals.returnFloat(newValue);
                        break;
                    default:
                        if (unitTypeForSwitch !== config.INCREMENT_TYPE.NONE && unitType !== config.INCREMENT_TYPE.NONE /*&& unitTypeForSwitch !== config.INCREMENT_TYPE.MONTHS && unitType !== config.INCREMENT_TYPE.MONTHS*/) {
                            newValue = globals.returnFloat(newValue);
                        }

                }
            } else {
                switch (unitTypeForSwitch || unitType) {
                    case config.INCREMENT_TYPE.LENGTH:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().lengthConversion;
                        newValue = globals.returnFloat(newValue) * measurementSystem.lengthConversion;
                        break;
                    case config.INCREMENT_TYPE.MASS:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().massConversion;
                        newValue = globals.returnFloat(newValue) / measurementSystem.massConversion;
                        break;
                    case config.INCREMENT_TYPE.VOLUME:
                    case config.INCREMENT_TYPE.VOLUMEPER100KMS:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().volumeConversion;
                        newValue = globals.returnFloat(newValue) / measurementSystem.volumeConversion;
                        break;
                    case config.INCREMENT_TYPE.CURRENCYPERVOLUME:
                        //newValue = globals.returnFloat(newValue) * getOfferManager().getActiveOffer().getMeasurementSystem().volumeConversion;
                        newValue = globals.returnFloat(newValue) * measurementSystem.volumeConversion;
                        break;
                    case config.INCREMENT_TYPE.SPECIFICGRAVITY:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().densityConversion;
                        newValue = globals.returnFloat(newValue) / measurementSystem.densityConversion;
                        break;
                    case config.INCREMENT_TYPE.LINEARDENSITY:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().linearDensityConversion;
                        newValue = globals.returnFloat(newValue) / measurementSystem.linearDensityConversion;
                        break;
                    case config.INCREMENT_TYPE.PERCENTAGE:
                    case config.INCREMENT_TYPE.PERCENTAGEINCREMENT:
                    case config.INCREMENT_TYPE.NUMBER:
                        newValue = globals.returnFloat(newValue);
                        break;
                    case config.INCREMENT_TYPE.MONTHLYDISTANCE:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().distanceConversion;
                        newValue = globals.returnFloat(newValue) / measurementSystem.distanceConversion;
                        break;
                    case config.INCREMENT_TYPE.AREA:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().areaConversion;
                        newValue = globals.returnFloat(newValue) / measurementSystem.areaConversion;
                        break;
                    case config.INCREMENT_TYPE.SPEED:
                        //newValue = globals.returnFloat(newValue) / getOfferManager().getActiveOffer().getMeasurementSystem().speedConversion;
                        newValue = globals.returnFloat(newValue) / measurementSystem.speedConversion;
                        break;
                    case config.INCREMENT_TYPE.FUELCONSUMPTION:
                        newValue = globals.returnFloat(newValue);
                        break;
                    default:
                        if (unitTypeForSwitch !== config.INCREMENT_TYPE.NONE && unitType !== config.INCREMENT_TYPE.NONE /*&& unitTypeForSwitch !== config.INCREMENT_TYPE.MONTHS && unitType !== config.INCREMENT_TYPE.MONTHS*/) {
                            newValue = globals.returnFloat(newValue);
                        }

                }
            }
            return newValue;
        }
    }

    this.executeOp = executeOp;
}

export default OpEngine;

