
import config from '../config';

const $ = window.$;

var defaultPlugins = [config.JSTREE_PLUGINS.TYPES],
    defaultTypesObj = {
    default: {
        icon: 'fas fa-folder',
        valid_children: ['default']
    }
};

/**
 * 
 * @param {object} folderTreeObj - JsTree jQuery object
 * @param {string} eventName - 
 * @param {function} callback - function to be called when event occurs. Params are e, data
 */
function addEvent(folderTreeObj, eventName, callback) {
    if (typeof folderTreeObj !== 'object') {
        throw 'folderTreeObj param is not an object';
    }
    if (typeof eventName !== 'string') {
        throw 'eventName param is not an object';
    }
    if (typeof callback !== 'function') {
        throw 'callback param is not an function';
    }
    folderTreeObj.on(eventName, callback);
}

/**
 * 
 * @param {string} id
 * @param {object[]} folderStructure
 * @param {object} types
 * @param {string[]} plugins
 * @returns {object}
 */
function createFolderStructure(id, folderStructure, types, plugins) {
    if (typeof id !== 'string') {
        throw 'is is required to create folder';
    }
    if (!Array.isArray(folderStructure)) {
        throw 'folderStructure must be an array';
    }
    var idToUse = id.includes('#') ? id : '#' + id,
        typesToUse = typeof types === 'object' && types !== null ? types : defaultTypesObj,
        pluginsToUse = Array.isArray(plugins) ? plugins : defaultPlugins;
    return $(idToUse).jstree({
        core: {
            data: folderStructure,
            check_callback: true
        },
        //unique: {
        //    duplicate: function (name, counter) {
        //        alert('duplicate node added: ' + name);
        //        return name; // This would just return the duplicate name to use as the node is created
        //    }
        //},
        types: typesToUse,
        plugins: pluginsToUse
    });
}

function cleanUpInstance(folderTree, setFunction) {
    var folderTreeObj = typeof folderTree === 'function' ? folderTree() : folderTree;
    folderTreeObj.jstree().destroy();
    folderTreeObj = null;
    if (typeof setFunction === 'function') {
        setFunction(null);
    }
}

function convertFolderDataForUseInFolderManager(folderObjectFromDb, selectedId) {
    var returnArray = [];

    folderObjectFromDb.isOpen = true;

    returnArray.push(createJsTreeObject(folderObjectFromDb));

    return returnArray;

    function createJsTreeObject(folderObj) {
        var returnObject = {
            text: folderObj.Name === 'ROOT' ? 'My Saved Calculations' : folderObj.Name,
            folderDatabaseId: folderObj.Id
        };

        if (folderObj.isOpen === true) {
            if (returnObject.state === undefined) {
                returnObject.state = {};
            }
            returnObject.state.opened = true;
        }

        if (folderObj.isSelected === true) {
            if (returnObject.state === undefined) {
                returnObject.state = {};
            }
            returnObject.state.selected = true;
        }

        if (typeof folderObj.type === 'string') {
            returnObject.type = folderObj.type;
        }

        if (typeof selectedId === 'number') {
            if (folderObj.Id === selectedId) {
                if (returnObject.state === undefined) {
                    returnObject.state = {};
                }
                returnObject.state.selected = true;
            }
        }

        if (folderObj.SubFolders.length > 0) {
            returnObject.children = [];

            folderObj.SubFolders.forEach(function (subfolder) {
                returnObject.children.push(createJsTreeObject(subfolder));
            });

            returnObject.children.sort(function (a, b) {
                if (a.text.toUpperCase() < b.text.toUpperCase()) {
                    return -1;
                }
                if (a.text.toUpperCase() > b.text.toUpperCase()) {
                    return 1;
                }
                return 0;
            });
        }

        return returnObject;
    }
}

function convertSelectedNodePathForUseInCloudServices(jsTreeObject) {
    var pathArray = [];
    var nodeString = jsTreeObject.get_selected();
    if (!nodeString.length) {
        return pathArray;
    }
    var node = jsTreeObject.get_node(nodeString);
    var pathToNode = jsTreeObject.get_path(node, null, true);
    pathToNode.forEach(function (pathElement) {
        var pathNode = jsTreeObject.get_node(pathElement);
        pathArray.push({
            Id: pathNode.original.folderDatabaseId,
            Name: jsTreeObject.get_text(pathElement)
        });
    });

    return pathArray;
}

function handleCreateFolderOnSelection(e, data) {
    var newFolderText = config.getTranslationText('2976')/* Folder Name */,
        createNewFolderObject = {
        "folderDatabaseId": -1,
        "text": config.getTranslationText('2975') /*'Create New Folder'*/,
        "type": "create_new_folder"
    };  
        
    data.instance.deselect_all(true);
    var node = data.instance.get_node(data.selected[data.selected.length - 1]);
    var nodeToDisable, sel;
    if (data.instance.get_type(node) === 'create_new_folder') {
        // Deselect all
        for (nodeToDisable in data.instance._model.data) {
            data.instance.disable_node(nodeToDisable);
        }
        // Select node
        data.instance.enable_node(node);
        data.instance.select_node(node, true);
        data.instance.set_type(node, 'new_folder');
        // Add new node - include create_new_folder
        sel = data.instance.get_selected();
        if (!sel.length) { return false; }
        sel = sel[0];
        // Add create new folder node
        data.instance.create_node(sel, createNewFolderObject);
        // Rename node
        if (sel) {
            data.instance.open_node(sel);
            data.instance.rename_node(sel, newFolderText);
            data.instance.edit(sel);
        }
    } else {
        data.instance.select_node(node, true);
        if (data.instance.get_type(node) === 'default') {
            // Remove any other create new folder nodes
            for (nodeToDisable in data.instance._model.data) {
                if (data.instance.get_type(nodeToDisable) === 'create_new_folder') {
                    data.instance.delete_node(nodeToDisable);
                }
            }
            // Add the create folder node
            // Add new node - include create_new_folder
            sel = data.instance.get_selected();
            if (!sel.length) { return false; }
            sel = sel[0];
            // Add create new folder node
            data.instance.create_node(sel, createNewFolderObject, 0);
            data.instance.open_node(sel);
        }
    }
}

var FolderManager = {
    addEvent: addEvent,
    createFolderStructure: createFolderStructure,
    cleanUpInstance: cleanUpInstance,
    convertFolderDataForUseInFolderManager: convertFolderDataForUseInFolderManager,
    convertSelectedNodePathForUseInCloudServices: convertSelectedNodePathForUseInCloudServices,
    handleCreateFolderOnSelection: handleCreateFolderOnSelection
};
export default FolderManager;