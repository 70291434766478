<template>
     <popup-modal ref="popup">
        <div id="modal" :class="['messageBox', 'buy-now-modal', modalModeCssClass ]">
            <div :class="['modal-body', 'buy-now-modal', editionsList.length === 1 ? 'buy-now-modal-1-option' : '' ]">
                <div class="buy-now-header" >
                    <h4 class="modal-title" v-if="headerMessage === ''">
                        <span v-text="getTranslationText('4439')"></span>
                    </h4>
                    <div class="buy-now-header-message" v-else>
                        <span class="exclamation-circle"><i class="fa fa-exclamation-circle"></i></span>
                        <span class="header-message" v-html="headerMessage"></span>
                    </div>
                </div>
                <div class="buy-now-selection-container">
                    <div class="buy-now-billing-cycle flex-space-between">
                        <div class="buy-now-billing-cycle-switch">
                            <div id="billingCycleOptionsMonthly" :class="['buy-now-billing-cycle-option', selectedBillingCycle === BILLING_CYCLE_OPTIONS.MONTHLY ? 'active' : '' ]" @click="changeBillingCycle">
                                <span v-text="getTranslationText('4440')"></span>
                            </div>
                            <div id="billingCycleOptionsAnnually" :class="['buy-now-billing-cycle-option', selectedBillingCycle === BILLING_CYCLE_OPTIONS.ANNUALLY ? 'active' : '' ]" @click="changeBillingCycle">
                                <span v-text="getTranslationText('4441')"></span>
                            </div>
                            <div class="annual-savings-text">
                                {{annualSavingsText}}
                            </div>
                        </div>
                    </div>

                    <div :class="['buy-now-editions-container', editionsList.length === 1 ? 'position-center' : '']">
                        <template v-for="edition in editionsList" v-bind:key="edition.shortDescription">
                            <div :class="['buy-now-message-div',  'buy-now-edition-card', edition.editionCardCss]">
                                <div class="dog-ear dog-ear-fold"></div>
                                <div class="dog-ear dog-ear-icon"><i class="fa fa-check"></i></div>
                                <div class="payment-container">
                                    <div class="header-and-message-container">
                                        <div class="header">
                                            <span v-text="edition.header"></span>
                                        </div>
                                        <div class="message">
                                            <span v-text="edition.shortDescription"></span>
                                        </div>
                                        <div class="message pricing">
                                            <div class="currency-and-price">
                                                <span class="currency" v-text="edition.currencySymbol"></span>
                                                <span class="price" v-text="edition.costPerUserPerMonth.value"></span>
                                                <!-- <span class="currency" v-text="edition.costPerUserPerMonthDecimal.value"></span> -->
                                                <span class="discounted-price price" v-text="edition.discountedCostPerUserPerMonth.value"></span>
                                                <!-- <span class="discounted-price currency" v-text="edition.discountedCostPerUserPerMonthDecimal"></span> -->
                                                <span class="currency" v-text="edition.appendedDecimalText"></span>
                                            </div>
                                            <div class="payment">
                                                <span v-text="edition.billingCostsText.value"></span>
                                            </div>
                                            <div class="payment">
                                                <span v-text="edition.billingCostsPerUserText.value"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="divider"><div></div></div>
                                    <div class="buy-now-features-list-container">
                                        <div>
                                            <div class="features-header-text">
                                                <span v-text="edition.featuresHeaderText"></span>
                                            </div>
                                            <ul class="fa-ul">
                                                <li v-for="feature in edition.featuresArray" v-bind:key="feature">
                                                    <span class="fa-li"><i class="fas fa-check"></i></span><span v-text="feature"></span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="button">
                                            <!--<button class="btn btn-default btn-truckscience" id="buy-now-button-14DayPass" href="javascript:void(0)" data-bind="click: buttonClickFunction, touchstart: buttonClickFunction, css: buttonCss"><span data-bind="text: buttonText"></span></button>-->
                                            <button :class="['btn', 'btn-default', 'btn-truckscience', edition.buttonCss]" href="javascript:void(0)" @click="edition.buttonClickFunction"  @touchstart="edition.buttonClickFunction" :id="edition.buttonId"><span v-text="edition.buttonText"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="buy-now-footer">
                    <div>
                        <div class="buy-now-subheader" v-if="subheaderMessage !== ''">
                            <img class="header-image" alt="Alternate Text" :src="headerImageSrc" />
                            <span class="subheader-message" v-html="subheaderMessage"></span>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-default btn-truckscience" @click="extendTrial" @touchstart="extendTrial" v-visible="trialHasExpired"><span v-text="getTranslationText('132')"></span></button>
                        <button class="btn btn-default btn-truckscience" @click="close" @touchstart="close"><span v-text="closeButtonText"></span></button>
                    </div>
                </div>
            </div>
            <div class="get-started-background"></div>
        </div>
    </popup-modal>
</template>

<script>

import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';
import CustomMessageBox from '@/services/CustomMessageBox';
import offerManager from '@/services/offerManager';
import { shallowRef, triggerRef, toRef, watch, ref, isRef, onBeforeMount, onMounted, nextTick } from 'vue';
import PopupModal from '@/components/modals/PopupModal.vue';
import Globalize from '@/assets/scripts/globalize/globalize';

const $ = window.$;
const TScMessenger = window.TScMessenger;
const Chargebee = window.Chargebee;

export default {
    name: 'BuyNowModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    },
    setup(props) {
         const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        
        let resolvePromise = undefined,
        rejectPromise = undefined;


        let cart,
            cbInstance,
            slider;

        let applyDiscountToModal = false;
        let billingDetailsForProduct = globals.user.getBillingDetails().UserBillingDetails.filter(function (item) {
            return item.ProductName === globals.user.getProduct();
        });

        let shell = opts.value.shell,
        sourceOfCall = opts.value.sourceOfCall,
        modalModeCssClass = shallowRef(''),
        headerImageSrc = shallowRef(''),
        planType = '',
        editionName = '',
        planAnalyticsLabel= '',
        planValue = '',
        getTranslationText = config.getTranslationText,
        BILLING_CYCLE_OPTIONS = config.BILLING_CYCLE_OPTIONS,
        isAccountCancelled = typeof opts.value.isAccountCancelled === 'boolean' ? opts.value.isAccountCancelled : false,
        numberOfUsers = shallowRef(1),
        trialHasExpired = shallowRef(),
        headerMessage = shallowRef(),
        subheaderMessage = shallowRef(),
        currencySymbol = shallowRef(),
        closeButtonText = shallowRef(),
        annualSavingsText = shallowRef();
        
        watch(numberOfUsers, function (newValue, oldValue) {
            TScMessenger.writeDebugMessage('');
            if (slider !== undefined && slider.noUiSlider !== undefined) {
                if (newValue !== Math.round(slider.noUiSlider.get())) {
                    slider.noUiSlider.set(newValue);
                    updateBillingCosts();
                }
            }
        });

        let essentialsBillingCostsText = shallowRef(),
        essentialsBillingCostsPerUserText = shallowRef(),
        essentialsBillingCostsPerUserPerMonthText = shallowRef(),
        essentialsBillingCostsPerUserPerMonthDecimalText = shallowRef(),
        essentialsBillingDiscountedCostsPerUserPerMonthText = shallowRef(),
        essentialsBillingDiscountedCostsPerUserPerMonthDecimalText = shallowRef(),
        professionalBillingCostsText = shallowRef(),
        professionalBillingCostsPerUserText = shallowRef(),
        professionalBillingCostsPerUserPerMonthText = shallowRef(),
        professionalBillingCostsPerUserPerMonthDecimalText = shallowRef(),
        professionalBillingDiscountedCostsPerUserPerMonthText = shallowRef(),
        professionalBillingDiscountedCostsPerUserPerMonthDecimalText = shallowRef(),
        enterpriseBillingCostsText = shallowRef(),
        enterpriseBillingCostsPerUserText = shallowRef(),
        enterpriseBillingCostsPerUserPerMonthText = shallowRef(),
        enterpriseBillingCostsPerUserPerMonthDecimalText = shallowRef(),
        enterpriseBillingDiscountedCostsPerUserPerMonthText = shallowRef(),
        enterpriseBillingDiscountedCostsPerUserPerMonthDecimalText = shallowRef(),

        selectedBillingCycle = shallowRef(config.BILLING_CYCLE_OPTIONS.ANNUALLY);
        //self.selectedBillingCycle.subscribe(function (newValue) {
        //    updateBillingCosts();
        //});
        let editionsList = shallowRef([]);

        onMounted(function () {
            activate();
            // compositionComplete();
            setTimeout(function () {
                compositionComplete();
            }, 100);
        });

        function show() {
            // Once we set our config, we tell the popup modal to open
            popup.value.open();
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                rejectPromise = reject
            })
        }


        function selectAllText(event, element) {
            globals.selectAllTextById(element.currentTarget.id);
        }
        /**
         * Function called when user clicks on billing cycle button
         * @param {HTMLElement} el - Button clicked by the user
         */
        function changeBillingCycle(el) {
            // If button is not already active
            if ($(el.currentTarget).hasClass('active') === false) {
                // Toggle button
                if (selectedBillingCycle.value === config.BILLING_CYCLE_OPTIONS.ANNUALLY) {
                    selectedBillingCycle.value = config.BILLING_CYCLE_OPTIONS.MONTHLY;
                } else {
                    selectedBillingCycle.value = config.BILLING_CYCLE_OPTIONS.ANNUALLY;
                }
                // Update billing cost details
                updateBillingCosts();
            }
        }

        


        
        function activate() {

            setUpText();
            setUpDefaultValues();
            setUpEditionsList();
        }

        function compositionComplete() {
            setUpChargebee();
            //setUpSlider();

            if ($('#ntea-message-link').length) {
                $('#ntea-message-link').click(function () {
                    //shell.openUserSettingsModal(config.SETTINGS_MODAL_TABS.INTEGRATION, setUpText);
                    // shell.openUserSettingsModal(config.SETTINGS_MODAL_TABS.INTEGRATION, closeDialog);
                    closeDialog();
                    shell.openUserSettingsModal(config.SETTINGS_MODAL_TABS.INTEGRATION);
                    var metaDataObject = {};
                    metaDataObject[config.INTERCOM_METADATA_ITEM.SOURCE] = config.CONSIDERED_INTEGRATION_SOURCE.BUY_NOW_MODAL;
                    dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CONSIDERED_NTEA_INTEGRATION, metaDataObject));
                    return false;
                });
                offerManager.registerOpenBuyNowDialog(shell.openBuyNowModalAndHideSpinner);
            }
        }

        function closeDialog() {
            offerManager.registerOpenBuyNowDialog(null);
            popup.value.close();
            resolvePromise();
        }

        function setUpText() {
            // Update relevant messages here
            //#important, trialHasExpired is hardcoded as false in relation to 3513 to bypass trialExpired functionality ... may be re-introduced at a later date 
            //self.trialHasExpired = false;//(globals.user.getUserType() === config.USER_TYPES.TRIAL_USER_FORMER || globals.user.getTrialCalculationsRemaining() === 0) && offerManager.offerIsOpen() === false;
            //self.trialHasExpired = (globals.user.getUserType() === config.USER_TYPES.TRIAL_USER_FORMER || globals.user.getTrialCalculationsRemaining() === 0) && offerManager.offerIsOpen() === false;
            trialHasExpired.value = globals.user.getUserType() === config.USER_TYPES.TRIAL_USER_FORMER && offerManager.offerIsOpen.value === false && globals.user.hasFullSupportPlan();
            headerMessage.value = opts.value.message !== undefined ? opts.value.message : "";
            subheaderMessage.value = opts.value.subheaderText !== undefined ? opts.value.subheaderText : '';
            currencySymbol.value = globals.user.getBillingDetails().BillingCurrency; //offerManager.currencySymbolMajor()
            closeButtonText.value = trialHasExpired.value === true ? config.getTranslationText('190') : config.getTranslationText('53');
            annualSavingsText.value = billingDetailsForProduct.length === 1 ? config.getTranslationText('4550') : config.getTranslationText('4549');
            
            if (subheaderMessage.value.includes(config.getTranslationText('1206'))) {
                modalModeCssClass.value = 'buy-now-modal-entitled-to-discount-mode';
            } else if (subheaderMessage.value.includes(config.getTranslationText('1205'))) {
                modalModeCssClass.value = 'buy-now-modal-discounted-mode';
                applyDiscountToModal = true;
            } else {
                modalModeCssClass.value = '';
            }
            headerImageSrc.value = modalModeCssClass.value === 'buy-now-modal-entitled-to-discount-mode' || modalModeCssClass.value === 'buy-now-modal-discounted-mode' ? config.cdnAddress + config.nteaLogoLocation : '';
            
        }

        /**
         * Code for setting up Chargebee and associated callbacks
         * */
         function setUpChargebee() {
            var chargebeeSetupObject = {
                site: config.chargebeeSiteName,
                isItemsModel: true,
            };

            cbInstance = Chargebee.init(chargebeeSetupObject);

            // https://www.chargebee.com/checkout-portal-docs/dropIn.html#overview
            // https://www.chargebee.com/checkout-portal-docs/dropIn.html#chargebee-instance-object
            cbInstance.setCheckoutCallbacks(function (cart) {
                // you can define a custom callbacks based on cart object
                return {
                    loaded: function () {
                        TScMessenger.writeDebugMessage("checkout opened");
                    },
                    close: function () {
                        TScMessenger.writeDebugMessage("checkout closed");
                    },
                    success: function (hostedPageId) {
                        cbInstance.closeAll();
                        handleSuccessfulSubscribe(hostedPageId, editionName);
                        
                        TScMessenger.writeDebugMessage("success fired")
                    },
                    error: function () {
                        TScMessenger.writeDebugMessage("error raised in Chargebee checkout callback");
                    },
                    step: function (value) {
                        // value -> which step in checkout
                        TScMessenger.writeDebugMessage(value);
                    }
                }
            });

            cart = cbInstance.getCart();

            //document.getElementById("subscribe").addEventListener("click", function () {
            //    var product = cbInstanceObject.initializeProduct('axle-weight-calculator-monthly-eur-v2');
            //    cart.replaceProduct(product);
            //    cart.proceedToCheckout();
            //});
        }
        
        /**
         * Set default values when opening the modal
         * */
        function setUpDefaultValues() {
            // Update number of users
            numberOfUsers.value = 1;
            updateBillingCosts();
        }

        /**
         * Function to create edition objects and insert them into self.editionsList array to be used on modal
         * */
        function setUpEditionsList() {
            var arrayToUse = [];

            // If there is only 1 billing detail onject then it's the Sales Tool
            if (billingDetailsForProduct.length === 1) {
                modalModeCssClass.value += ' buy-now-sales-tool';
                // 1 active card
                var salesToolFeaturesArray = [];
                

                var headerMessageCode = '', subHeaderMessageCode = '';
                if (billingDetailsForProduct[0].EditionName === config.APP_EDITION.OPERATOR) {
                    headerMessageCode = '4526';
                    subHeaderMessageCode = '4527';
                    if (config.getTranslationText('4528') !== '') {
                        salesToolFeaturesArray.push(config.getTranslationText('4528'));
                    }
                    if (config.getTranslationText('4529') !== '') {
                        salesToolFeaturesArray.push(config.getTranslationText('4529'));
                    }
                    if (config.getTranslationText('4530') !== '') {
                        salesToolFeaturesArray.push(config.getTranslationText('4530'));
                    }
                    if (config.getTranslationText('4531') !== '') {
                        salesToolFeaturesArray.push(config.getTranslationText('4531'));
                    }
                    if (config.getTranslationText('4532') !== '') {
                        salesToolFeaturesArray.push(config.getTranslationText('4532'));
                    }
                } else {
                    headerMessageCode = '4476';
                    subHeaderMessageCode = '4477';
                    if (config.getTranslationText('4478') !== '') {
                        salesToolFeaturesArray.push(config.getTranslationText('4478'));
                    }
                    if (config.getTranslationText('4479') !== '') {
                        salesToolFeaturesArray.push(config.getTranslationText('4479'));
                    }
                    if (config.getTranslationText('4480') !== '') {
                        salesToolFeaturesArray.push(config.getTranslationText('4480'));
                    }
                    if (config.getTranslationText('4481') !== '') {
                        salesToolFeaturesArray.push(config.getTranslationText('4481'));
                    }
                    if (config.getTranslationText('4533') !== '') {
                        salesToolFeaturesArray.push(config.getTranslationText('4533'));
                    }
                }

                arrayToUse.push(setupEditionObject(billingDetailsForProduct[0].EditionName,
                    config.getTranslationText(headerMessageCode),
                    config.getTranslationText(subHeaderMessageCode),
                    currencySymbol.value,
                    professionalBillingCostsPerUserPerMonthText,
                    professionalBillingCostsPerUserPerMonthDecimalText,
                    professionalBillingDiscountedCostsPerUserPerMonthText,
                    professionalBillingDiscountedCostsPerUserPerMonthDecimalText,
                    professionalBillingCostsText,
                    professionalBillingCostsPerUserText,
                    '',
                    salesToolFeaturesArray,
                    openChargebeeModal,
                    config.getTranslationText('4482'),
                    'monthly-payment active  buy-now-edition-card-professional',
                    'btn-primary'
                ));
            } else {
                var essentialsFeaturesArray = [],
                    professionalFeaturesArray = [],
                    enterpriseFeaturesArray = [];

                // Essentials
                if (config.getTranslationText('4448') !== '') {
                    essentialsFeaturesArray.push(config.getTranslationText('4448'));
                }
                if (config.getTranslationText('4449') !== '') {
                    essentialsFeaturesArray.push(config.getTranslationText('4449'));
                }
                if (config.getTranslationText('4450') !== '') {
                    essentialsFeaturesArray.push(config.getTranslationText('4450'));
                }
                if (config.getTranslationText('4451') !== '') {
                    essentialsFeaturesArray.push(config.getTranslationText('4451'));
                }
                if (config.getTranslationText('4452') !== '') {
                    essentialsFeaturesArray.push(config.getTranslationText('4452'));
                }
                if (config.getTranslationText('4642') !== '') {
                    essentialsFeaturesArray.push(config.getTranslationText('4642'));
                }

                arrayToUse.push(setupEditionObject(billingDetailsForProduct[0].EditionName,
                    config.getTranslationText('4444'),
                    config.getTranslationText('4445'),
                    currencySymbol.value,
                    essentialsBillingCostsPerUserPerMonthText,
                    essentialsBillingCostsPerUserPerMonthDecimalText,
                    essentialsBillingDiscountedCostsPerUserPerMonthText,
                    essentialsBillingDiscountedCostsPerUserPerMonthDecimalText,
                    essentialsBillingCostsText,
                    essentialsBillingCostsPerUserText,
                    '',
                    essentialsFeaturesArray,
                    openChargebeeModal,
                    config.getTranslationText('4453'),
                    'once-off-payment buy-now-edition-card buy-now-edition-card-essentials',
                    '',
                    config.getTranslationText('4544')
                ));

                // Professional
                if (config.getTranslationText('4459') !== '') {
                    professionalFeaturesArray.push(config.getTranslationText('4459'));
                }
                if (config.getTranslationText('4460') !== '') {
                    professionalFeaturesArray.push(config.getTranslationText('4460'));
                }
                if (config.getTranslationText('4461') !== '') {
                    professionalFeaturesArray.push(config.getTranslationText('4461'));
                }
                if (config.getTranslationText('4463') !== '') {
                    professionalFeaturesArray.push(config.getTranslationText('4463'));
                }
                if (config.getTranslationText('4464') !== '') {
                    professionalFeaturesArray.push(config.getTranslationText('4464'));
                }
                if (config.getTranslationText('4553') !== '') {
                    professionalFeaturesArray.push(config.getTranslationText('4553'));
                }
                if (config.getTranslationText('4643') !== '') {
                    professionalFeaturesArray.push(config.getTranslationText('4643'));
                }
                if (config.getTranslationText('4796') !== '') {
                    professionalFeaturesArray.push(config.getTranslationText('4796'));
                }
                
                arrayToUse.push(setupEditionObject(billingDetailsForProduct[1].EditionName,
                    config.getTranslationText('4454'),
                    config.getTranslationText('4455'),
                    currencySymbol.value,
                    professionalBillingCostsPerUserPerMonthText,
                    professionalBillingCostsPerUserPerMonthDecimalText,
                    professionalBillingDiscountedCostsPerUserPerMonthText,
                    professionalBillingDiscountedCostsPerUserPerMonthDecimalText,
                    professionalBillingCostsText,
                    professionalBillingCostsPerUserText,
                    config.getTranslationText('4458'),
                    professionalFeaturesArray,
                    openChargebeeModal,
                    config.getTranslationText('4465'),
                    'monthly-payment active buy-now-edition-card-professional',
                    'btn-primary',
                    config.getTranslationText('4545')
                ));

                // Enterprise
                if (config.getTranslationText('4462') !== '') {
                    enterpriseFeaturesArray.push(config.getTranslationText('4462'));
                }
                if (config.getTranslationText('4471') !== '') {
                    enterpriseFeaturesArray.push(config.getTranslationText('4471'));
                }
                if (config.getTranslationText('4472') !== '') {
                    enterpriseFeaturesArray.push(config.getTranslationText('4472'));
                }
                if (config.getTranslationText('4473') !== '') {
                    enterpriseFeaturesArray.push(config.getTranslationText('4473'));
                }
                if (config.getTranslationText('4474') !== '') {
                    enterpriseFeaturesArray.push(config.getTranslationText('4474'));
                }
                if (config.getTranslationText('4537') !== '') {
                    enterpriseFeaturesArray.push(config.getTranslationText('4537'));
                }

                arrayToUse.push(setupEditionObject(billingDetailsForProduct[2].EditionName,
                    config.getTranslationText('4466'),
                    config.getTranslationText('4467'),
                    currencySymbol.value,
                    enterpriseBillingCostsPerUserPerMonthText,
                    enterpriseBillingCostsPerUserPerMonthDecimalText,
                    enterpriseBillingDiscountedCostsPerUserPerMonthText,
                    enterpriseBillingDiscountedCostsPerUserPerMonthDecimalText,
                    enterpriseBillingCostsText,
                    enterpriseBillingCostsPerUserText,
                    config.getTranslationText('4470'),
                    enterpriseFeaturesArray,
                    openChargebeeModal,
                    config.getTranslationText('4475'),
                    'annual-payment buy-now-edition-card-enterprise',
                    '',
                    config.getTranslationText('4544')
                ));
            }

            editionsList.value = arrayToUse;
            triggerRef(editionsList);
        }

        /**
         * Create object to be used for edition card on UI
         * @param {string} editionText
         * @param {string} header - Header text of the edition card
         * @param {string} shortDescription - Description displayed under the 
         * @param {string} currencySymbol
         * @param {KnockoutObservable} costPerUserPerMonth
         * @param {KnockoutObservable} costPerUserPerMonthDecimal
         * @param {KnockoutObservable} discountedCostPerUserPerMonth
         * @param {KnockoutObservable} discountedCostPerUserPerMonthDecimal
         * @param {KnockoutObservable} billingCostsText
         * @param {KnockoutObservable} billingCostsPerUserText
         * @param {string} featuresHeaderText
         * @param {string[]} featuresArray
         * @param {Function} buttonClickFunction
         * @param {string} buttonText
         * @param {string} editionCardCss
         * @param {string} buttonCss
         * @param {string} appendedDecimalText
         * @returns {object}
         */
        function setupEditionObject(editionText, header, shortDescription, currencySymbol, costPerUserPerMonth, costPerUserPerMonthDecimal, discountedCostPerUserPerMonth, discountedCostPerUserPerMonthDecimal, billingCostsText, billingCostsPerUserText, featuresHeaderText, featuresArray, buttonClickFunction, buttonText, editionCardCss, buttonCss, appendedDecimalText) {
            var returnObject = {};

            // String values
            returnObject.buttonId = typeof editionText === 'string' ? 'buy-now-button-' + editionText : 'buy-now-button-stringMissing';
            returnObject.header = typeof header === 'string' ? header : 'header is not a string';
            returnObject.shortDescription = typeof shortDescription === 'string' ? shortDescription : 'shortDescription is not a string';
            returnObject.currencySymbol = typeof currencySymbol === 'string' ? currencySymbol : 'currencySymbol is not a string';
            returnObject.buttonText = typeof buttonText === 'string' ? buttonText : 'buttonText is not a string';
            returnObject.editionCardCss = typeof editionCardCss === 'string' ? editionCardCss : '';
            returnObject.buttonCss = typeof buttonCss === 'string' ? buttonCss : '';
            returnObject.featuresHeaderText = typeof featuresHeaderText === 'string' ? featuresHeaderText : '';
            returnObject.appendedDecimalText = typeof appendedDecimalText === 'string' ? appendedDecimalText : '';

            // Observables
            returnObject.costPerUserPerMonth = isRef(costPerUserPerMonth) === true ? costPerUserPerMonth : 'costPerUserPerMonth is not a ref';
            returnObject.costPerUserPerMonthDecimal = isRef(costPerUserPerMonthDecimal) === true ? costPerUserPerMonthDecimal : 'costPerUserPerMonthDecimal is not a ref';
            returnObject.discountedCostPerUserPerMonth = isRef(discountedCostPerUserPerMonth) === true ? discountedCostPerUserPerMonth : 'discountedCostPerUserPerMonth is not a ref';
            returnObject.discountedCostPerUserPerMonthDecimal = isRef(discountedCostPerUserPerMonthDecimal) === true ? discountedCostPerUserPerMonthDecimal : 'discountedCostPerUserPerMonthDecimal is not a ref';
            returnObject.billingCostsText = isRef(billingCostsText) === true ? billingCostsText : 'billingCostsText is not a ref';
            returnObject.billingCostsPerUserText = isRef(billingCostsPerUserText) === true ? billingCostsPerUserText : 'billingCostsPerUserText is not a ref';

            // Array
            returnObject.featuresArray = Array.isArray(featuresArray) === true ? featuresArray : 'featuresArray is not an array';

            // Function
            returnObject.buttonClickFunction = typeof buttonClickFunction === 'function' ? buttonClickFunction : 'buttonClickFunction is not a function';

            return returnObject;
        }

        // /**
        //  * Setup noUiSlider and update the numberOfUsers variable and tooltip when the user moves the handle
        //  * */
        // function setUpSlider() {
        //     slider = document.getElementById('number-of-users-slider');

        //     noUiSlider.create(slider, {
        //         start: [1],
        //         step: 1,
        //         connect: 'lower',
        //         range: {
        //             'min': 1,
        //             'max': 100
        //         },
        //         tooltips: true
        //     });

        //     /**
        //      * Callback to update numberOfUsers and tooltip
        //      */ 
        //     slider.noUiSlider.on('update', function (values, handle) {
        //         var val = Math.round(values[handle]);
        //         if (val !== self.numberOfUsers()) {
        //             self.numberOfUsers(val);
        //             updateBillingCosts();
        //             updateTooltip();
        //         }
        //     });

        //     updateTooltip();
        // }

        /**
         * Update the tooltip text depending on number of users selected
         * */
        function updateTooltip() {
            var message = '';
            if (numberOfUsers.value === 1) {
                message = config.getTranslationText('4442');
            } else {
                message = config.getTranslationText('4443', [numberOfUsers.value]);
            }
            $('.noUi-tooltip').text(message);
        }

        /**
         * 
         * */
        function updateBillingCosts() {
            var essentialsBillingDetailsObject,
                professionalBillingDetailsObject,
                enterpriseBillingDetailsObject;

            if (billingDetailsForProduct.length === 1) {
                professionalBillingDetailsObject = billingDetailsForProduct[0];
            } else {
                essentialsBillingDetailsObject = billingDetailsForProduct[0];
                professionalBillingDetailsObject = billingDetailsForProduct[1];
                enterpriseBillingDetailsObject = billingDetailsForProduct[2];
            }

            var numberOfAdditionalUsers,
                essentialsCost,
                essentialsCostPerUserPerMonth,
                essentialsCostPerUserPerMonthDecimal,
                essentialsDiscountedCost,
                essentialsDiscountedCostPerUserPerMonth,
                essentialsDiscountedCostPerUserPerMonthDecimal,
                professionalCost,
                professionalCostPerUserPerMonth,
                professionalCostPerUserPerMonthDecimal,
                professionalDiscountedCost,
                professionalDiscountedCostPerUserPerMonth,
                professionalDiscountedCostPerUserPerMonthDecimal,
                enterpriseCost,
                enterpriseCostPerUserPerMonth,
                enterpriseCostPerUserPerMonthDecimal,
                enterpriseDiscountedCost,
                enterpriseDiscountedCostPerUserPerMonth,
                enterpriseDiscountedCostPerUserPerMonthDecimal,
                decimalPointForCultureCode = Globalize.culture(globals.user.getCultureCode()).numberFormat.currency['.'];

            if (selectedBillingCycle.value === config.BILLING_CYCLE_OPTIONS.MONTHLY) {
                if (numberOfUsers.value > 1) {
                    numberOfAdditionalUsers = numberOfUsers.value - 1;
                    professionalCost = professionalBillingDetailsObject.MonthlyBillingMonthlyAmountFirst + (professionalBillingDetailsObject.MonthlyBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                    professionalDiscountedCost = professionalBillingDetailsObject.MonthlyBillingDiscountedMonthlyAmount + (professionalBillingDetailsObject.MonthlyBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                    if (billingDetailsForProduct.length > 1) {
                        essentialsCost = essentialsBillingDetailsObject.MonthlyBillingMonthlyAmountFirst + (essentialsBillingDetailsObject.MonthlyBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                        essentialsDiscountedCost = essentialsBillingDetailsObject.MonthlyBillingDiscountedMonthlyAmount + (essentialsBillingDetailsObject.MonthlyBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                        enterpriseCost = enterpriseBillingDetailsObject.MonthlyBillingMonthlyAmountFirst + (enterpriseBillingDetailsObject.MonthlyBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                        enterpriseDiscountedCost = enterpriseBillingDetailsObject.MonthlyBillingDiscountedMonthlyAmount + (enterpriseBillingDetailsObject.MonthlyBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                    }
                } else {
                    professionalCost = professionalBillingDetailsObject.MonthlyBillingMonthlyAmountFirst;
                    professionalDiscountedCost = professionalBillingDetailsObject.MonthlyBillingDiscountedMonthlyAmount;
                    if (billingDetailsForProduct.length > 1) {
                        essentialsCost = essentialsBillingDetailsObject.MonthlyBillingMonthlyAmountFirst;
                        essentialsDiscountedCost = essentialsBillingDetailsObject.MonthlyBillingDiscountedMonthlyAmount;
                        enterpriseCost = enterpriseBillingDetailsObject.MonthlyBillingMonthlyAmountFirst;
                        enterpriseDiscountedCost = enterpriseBillingDetailsObject.MonthlyBillingDiscountedMonthlyAmount;
                    }
                }

                if (applyDiscountToModal === true) {
                    professionalBillingCostsText.value = config.getTranslationText('4456', [currencySymbol.value, Globalize.format(professionalDiscountedCost, 'n0')]);
                } else {
                    //self.professionalBillingCostsText('per user per month, billed monthly at ' + self.currencySymbol + Globalize.format(professionalCost, 'n0'));
                    professionalBillingCostsText.value = config.getTranslationText('4456', [currencySymbol.value, Globalize.format(professionalCost, 'n0')]);
                }
                professionalBillingCostsPerUserText.value = config.getTranslationText('4540', [currencySymbol.value, Globalize.format(professionalBillingDetailsObject.MonthlyBillingMonthlyAmountSubsequent, 'n0')]);
                
                if (billingDetailsForProduct.length > 1) {
                    //self.essentialsBillingCostsText('per user per month, billed monthly at ' + self.currencySymbol + Globalize.format(essentialsCost, 'n0'));
                    essentialsBillingCostsText.value = config.getTranslationText('4446', [currencySymbol.value, Globalize.format(essentialsCost, 'n0')]);
                    essentialsBillingCostsPerUserText.value = config.getTranslationText('4538', [currencySymbol.value, Globalize.format(essentialsBillingDetailsObject.MonthlyBillingMonthlyAmountSubsequent, 'n0')]);

                    if (applyDiscountToModal === true) {
                        enterpriseBillingCostsText.value = config.getTranslationText('4468', [currencySymbol.value, Globalize.format(enterpriseDiscountedCost, 'n0')]);
                    } else {
                        //self.enterpriseBillingCostsText('per user per month, billed monthly at ' + self.currencySymbol + Globalize.format(enterpriseCost, 'n0'));
                        enterpriseBillingCostsText.value = config.getTranslationText('4468', [currencySymbol.value, Globalize.format(enterpriseCost, 'n0')]);
                    }
                    enterpriseBillingCostsPerUserText.value = config.getTranslationText('4542', [currencySymbol.value, Globalize.format(enterpriseBillingDetailsObject.MonthlyBillingMonthlyAmountSubsequent, 'n0')]);
                }
            } else {
                if (numberOfUsers.value > 1) {
                    numberOfAdditionalUsers = numberOfUsers.value - 1;
                    professionalCost = professionalBillingDetailsObject.AnnualBillingMonthlyAmountFirst + (professionalBillingDetailsObject.AnnualBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                    professionalDiscountedCost = professionalBillingDetailsObject.AnnualBillingMonthlyAmountFirst + (professionalBillingDetailsObject.AnnualBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                    if (billingDetailsForProduct.length > 1) {
                        essentialsCost = essentialsBillingDetailsObject.AnnualBillingMonthlyAmountFirst + (essentialsBillingDetailsObject.AnnualBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                        essentialsDiscountedCost = essentialsBillingDetailsObject.AnnualBillingDiscountedMonthlyAmount + (essentialsBillingDetailsObject.AnnualBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                        enterpriseCost = enterpriseBillingDetailsObject.AnnualBillingMonthlyAmountFirst + (enterpriseBillingDetailsObject.AnnualBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                        enterpriseDiscountedCost = enterpriseBillingDetailsObject.AnnualBillingDiscountedMonthlyAmount + (enterpriseBillingDetailsObject.AnnualBillingMonthlyAmountSubsequent * numberOfAdditionalUsers);
                    }
                } else {
                    professionalCost = professionalBillingDetailsObject.AnnualBillingMonthlyAmountFirst;
                    professionalDiscountedCost = professionalBillingDetailsObject.AnnualBillingDiscountedMonthlyAmount;
                    if (billingDetailsForProduct.length > 1) {
                        essentialsCost = essentialsBillingDetailsObject.AnnualBillingMonthlyAmountFirst;
                        essentialsDiscountedCost = essentialsBillingDetailsObject.AnnualBillingDiscountedMonthlyAmount;
                        enterpriseCost = enterpriseBillingDetailsObject.AnnualBillingMonthlyAmountFirst;
                        enterpriseDiscountedCost = enterpriseBillingDetailsObject.AnnualBillingDiscountedMonthlyAmount;
                    }
                }
                if (applyDiscountToModal === true) {
                    //self.professionalBillingCostsText('per user per month, billed annually at ' + self.currencySymbol + Globalize.format(professionalCost, 'n0'));
                    professionalBillingCostsText.value = config.getTranslationText('4457', [currencySymbol.value, Globalize.format(Math.round(professionalDiscountedCost * 12), 'n0')]);
                } else {
                    professionalBillingCostsText.value = config.getTranslationText('4457', [currencySymbol.value, Globalize.format(Math.round(professionalCost * 12), 'n0')]);
                }
                professionalBillingCostsPerUserText.value = config.getTranslationText('4541', [currencySymbol.value, Globalize.format(Math.round(professionalBillingDetailsObject.AnnualBillingMonthlyAmountSubsequent * 12), 'n0')]);

                professionalCost = (professionalCost).toFixed(2);
                professionalDiscountedCost = (professionalDiscountedCost).toFixed(2);

                if (billingDetailsForProduct.length > 1) {
                    essentialsBillingCostsPerUserText.value = config.getTranslationText('4539', [currencySymbol.value, Globalize.format(Math.round(essentialsBillingDetailsObject.AnnualBillingMonthlyAmountSubsequent * 12), 'n0')]);

                    if (applyDiscountToModal === true) {
                        //self.essentialsBillingCostsText('per user per month, billed annually at ' + self.currencySymbol + Globalize.format(essentialsCost, 'n0'));
                        /*self.essentialsBillingCostsText(config.getTranslationText('4447', [self.currencySymbol, Globalize.format(Math.round(essentialsCost * 12), 'n0')]));*/
                        essentialsBillingCostsText.value = config.getTranslationText('4447', [currencySymbol.value, Globalize.format(Math.round(essentialsBillingDetailsObject.AnnualBillingMonthlyAmountFirst * 12), 'n0')]);
                        
                        //self.enterpriseBillingCostsText('per user per month, billed annually at ' + self.currencySymbol + Globalize.format(enterpriseCost, 'n0'));
                        /*self.enterpriseBillingCostsText(config.getTranslationText('4469', [self.currencySymbol, Globalize.format(Math.round(enterpriseCost * 12), 'n0')]));*/
                        enterpriseBillingCostsText.value = config.getTranslationText('4447', [currencySymbol.value, Globalize.format(Math.round(enterpriseBillingDetailsObject.AnnualBillingDiscountedMonthlyAmount * 12), 'n0')]);
                        enterpriseBillingCostsPerUserText.value = config.getTranslationText('4543', [currencySymbol.value, Globalize.format(Math.round(enterpriseBillingDetailsObject.AnnualBillingMonthlyAmountSubsequent * 12), 'n0')]);
                    } else {
                        //self.essentialsBillingCostsText('per user per month, billed annually at ' + self.currencySymbol + Globalize.format(essentialsCost, 'n0'));
                        /*self.essentialsBillingCostsText(config.getTranslationText('4447', [self.currencySymbol, Globalize.format(Math.round(essentialsDiscountedCost * 12), 'n0')]));*/
                        essentialsBillingCostsText.value = config.getTranslationText('4447', [currencySymbol.value, Globalize.format(Math.round(essentialsBillingDetailsObject.AnnualBillingMonthlyAmountFirst * 12), 'n0')]);

                        //self.enterpriseBillingCostsText('per user per month, billed annually at ' + self.currencySymbol + Globalize.format(enterpriseCost, 'n0'));
                        /*self.enterpriseBillingCostsText(config.getTranslationText('4469', [self.currencySymbol, Globalize.format(Math.round(enterpriseDiscountedCost * 12), 'n0')]));*/
                        enterpriseBillingCostsText.value = config.getTranslationText('4469', [currencySymbol.value, Globalize.format(Math.round(enterpriseBillingDetailsObject.AnnualBillingMonthlyAmountFirst * 12), 'n0')]);
                        enterpriseBillingCostsPerUserText.value = config.getTranslationText('4543', [currencySymbol.value, Globalize.format(Math.round(enterpriseBillingDetailsObject.AnnualBillingMonthlyAmountSubsequent * 12), 'n0')]);

                    }
                    essentialsCost = (essentialsCost).toFixed(2);
                    enterpriseCost = (enterpriseCost).toFixed(2);
                    essentialsDiscountedCost = (essentialsDiscountedCost).toFixed(2);
                    enterpriseDiscountedCost = (enterpriseDiscountedCost).toFixed(2);
                }
            }

            let professionalCostPerUserPerMonthString = (professionalCost / numberOfUsers.value).toFixed(2).toString().split('.');
            professionalCostPerUserPerMonth = professionalCostPerUserPerMonthString[0];
            professionalCostPerUserPerMonthDecimal = decimalPointForCultureCode + professionalCostPerUserPerMonthString[1];
            let professionalCostPerUserPerMonthDecimalNumber = parseInt(professionalCostPerUserPerMonthString[1]);
            if(professionalCostPerUserPerMonthDecimalNumber >= 50) {
                professionalCostPerUserPerMonth = parseInt(professionalCostPerUserPerMonthString[0]) + 1;
            }
            professionalBillingCostsPerUserPerMonthText.value = professionalCostPerUserPerMonth;
            professionalBillingCostsPerUserPerMonthDecimalText.value = professionalCostPerUserPerMonthDecimal;

            // Discounted values
            let professionalDiscountedCostPerUserPerMonthString = (professionalDiscountedCost / numberOfUsers.value).toFixed(2).toString().split('.');
            professionalDiscountedCostPerUserPerMonth = professionalDiscountedCostPerUserPerMonthString[0];
            professionalDiscountedCostPerUserPerMonthDecimal = decimalPointForCultureCode + professionalDiscountedCostPerUserPerMonthString[1];
            let professionalDiscountedCostPerUserPerMonthDecimalNumber = parseInt(professionalDiscountedCostPerUserPerMonthString[1]);
            if(professionalDiscountedCostPerUserPerMonthDecimalNumber >= 50) {
                professionalDiscountedCostPerUserPerMonth = parseInt(professionalDiscountedCostPerUserPerMonthString[0]) + 1;
            }
            professionalBillingDiscountedCostsPerUserPerMonthText.value = professionalDiscountedCostPerUserPerMonth;
            professionalBillingDiscountedCostsPerUserPerMonthDecimalText.value = professionalDiscountedCostPerUserPerMonthDecimal;
            /*self.professionalBillingDiscountedCostsPerUserPerMonthDecimalText(config.getTranslationText('4546', [professionalDiscountedCostPerUserPerMonthDecimal]));*/

            if (billingDetailsForProduct.length > 1) {
                let essentialsCostPerUserPerMonthString = (essentialsCost / numberOfUsers.value).toFixed(2).toString().split('.');
                essentialsCostPerUserPerMonth = essentialsCostPerUserPerMonthString[0];
                essentialsCostPerUserPerMonthDecimal = decimalPointForCultureCode + essentialsCostPerUserPerMonthString[1];
                let essentialsCostPerUserPerMonthDecimalNumber = parseInt(essentialsCostPerUserPerMonthString[1]);
                if(essentialsCostPerUserPerMonthDecimalNumber >= 50) {
                    essentialsCostPerUserPerMonth = parseInt(essentialsCostPerUserPerMonthString[0]) + 1;
                }
                essentialsBillingCostsPerUserPerMonthText.value = essentialsCostPerUserPerMonth;
                essentialsBillingCostsPerUserPerMonthDecimalText.value = essentialsCostPerUserPerMonthDecimal;
                // Discounted Values
                let essentialsDiscountedCostPerUserPerMonthString = (essentialsCost / numberOfUsers.value).toFixed(2).toString().split('.');
                essentialsDiscountedCostPerUserPerMonth = essentialsDiscountedCostPerUserPerMonthString[0];
                essentialsDiscountedCostPerUserPerMonthDecimal = decimalPointForCultureCode + essentialsDiscountedCostPerUserPerMonthString[1];
                let essentialsDiscountedCostPerUserPerMonthDecimalNumber = parseInt(essentialsDiscountedCostPerUserPerMonthString[1]);
                if(essentialsDiscountedCostPerUserPerMonthDecimalNumber >= 50) {
                    essentialsDiscountedCostPerUserPerMonth = parseInt(essentialsDiscountedCostPerUserPerMonthString[0]) + 1;
                }
                essentialsBillingDiscountedCostsPerUserPerMonthText.value = essentialsDiscountedCostPerUserPerMonth;
                essentialsBillingDiscountedCostsPerUserPerMonthDecimalText.value = essentialsDiscountedCostPerUserPerMonthDecimal;

                let enterpriseCostPerUserPerMonthString = (enterpriseCost / numberOfUsers.value).toFixed(2).toString().split('.');
                enterpriseCostPerUserPerMonth = enterpriseCostPerUserPerMonthString[0];
                enterpriseCostPerUserPerMonthDecimal = decimalPointForCultureCode + enterpriseCostPerUserPerMonthString[1];
                let enterpriseCostPerUserPerMonthDecimalNumber = parseInt(enterpriseCostPerUserPerMonthString[1]);
                if(enterpriseCostPerUserPerMonthDecimalNumber >= 50) {
                    enterpriseCostPerUserPerMonth = parseInt(enterpriseCostPerUserPerMonthString[0]) + 1;
                }
                enterpriseBillingCostsPerUserPerMonthText.value = enterpriseCostPerUserPerMonth;
                enterpriseBillingCostsPerUserPerMonthDecimalText.value = enterpriseCostPerUserPerMonthDecimal;
                // Discounted Values
                let enterpriseDiscountedCostPerUserPerMonthString = (enterpriseDiscountedCost / numberOfUsers.value).toFixed(2).toString().split('.');
                enterpriseDiscountedCostPerUserPerMonth = enterpriseDiscountedCostPerUserPerMonthString[0];
                enterpriseDiscountedCostPerUserPerMonthDecimal = decimalPointForCultureCode + enterpriseDiscountedCostPerUserPerMonthString[1];
                let enterpriseDiscountedCostPerUserPerMonthDecimalNumber = parseInt(enterpriseDiscountedCostPerUserPerMonthString[1]);
                if(enterpriseDiscountedCostPerUserPerMonthDecimalNumber >= 50) {
                    enterpriseDiscountedCostPerUserPerMonth = parseInt(enterpriseDiscountedCostPerUserPerMonthString[0]) + 1;
                }
                enterpriseBillingDiscountedCostsPerUserPerMonthText.value = enterpriseDiscountedCostPerUserPerMonth;
                enterpriseBillingDiscountedCostsPerUserPerMonthDecimalText.value = enterpriseDiscountedCostPerUserPerMonthDecimal;
                /*self.enterpriseBillingDiscountedCostsPerUserPerMonthDecimalText(config.getTranslationText('4546', [enterpriseDiscountedCostPerUserPerMonthDecimal]));*/
            }
            
            //if (essentialsCostPerUserPerMonth % 1 === 0) {
            //    essentialsCostPerUserPerMonth = Math.round(essentialsCostPerUserPerMonth);
            //}
            //if (professionalCostPerUserPerMonth % 1 === 0) {
            //    professionalCostPerUserPerMonth = Math.round(professionalCostPerUserPerMonth);
            //}
            //if (enterpriseCostPerUserPerMonth % 1 === 0) {
            //    enterpriseCostPerUserPerMonth = Math.round(enterpriseCostPerUserPerMonth);
            //}

        }

        /**
         * Open the Chargebee modal depending on button clicked by the user
         * @param {HTMLElement} element - Button clicked by user
         */
        function openChargebeeModal(element) {
            var billingDetails = globals.user.getBillingDetails(),
                product,
                planPriceId,
                planPriceQuantity = 1,
                couponId;
            var elementId = element.currentTarget.id,
                splitElementId = elementId.split('-'),
                editionButtonClicked = splitElementId[splitElementId.length - 1],
                editionObject = billingDetailsForProduct.filter(function (obj) {
                    return obj.EditionName === editionButtonClicked;
                });

            if (editionObject.length > 0) {
                if (selectedBillingCycle.value === config.BILLING_CYCLE_OPTIONS.MONTHLY) {
                    planPriceId = editionObject[0].MonthlyBillingChargebeePlanId;
                    if (applyDiscountToModal === true && editionObject[0].EditionName.toUpperCase() !== 'ESSENTIALS') {
                        couponId = editionObject[0].MonthlyBillingDefaultCouponCode;
                    }
                } else {
                    planPriceId = editionObject[0].AnnualBillingChargebeePlanId;
                    if (applyDiscountToModal === true && editionObject[0].EditionName.toUpperCase() !== 'ESSENTIALS') {
                        couponId = editionObject[0].AnnualBillingDefaultCouponCode;
                    }
                }
                planPriceQuantity = numberOfUsers.value;
                editionName = editionObject[0].EditionName;
                
                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.SELECTED_PLAN, getIntercomMetadata(selectedBillingCycle.value, editionName)));
                if (globals.isGoogleAnalyticsKeyAvailable()) {
                    let paramObj = {};
                    paramObj[config.GOOGLE_ANALYTICS_EVENT_PARAMETERS.BILLING_PERIOD] = selectedBillingCycle.value;
                    paramObj[config.GOOGLE_ANALYTICS_EVENT_PARAMETERS.EDITION] = editionName;
                    dataManager.triggerEventInGoogleAnalytics(config.GOOGLE_ANALYTICS_EVENTS.SELECTED_PLAN, paramObj);
                }

                product = cbInstance.initializeProduct(planPriceId, planPriceQuantity);

                product.setCustomData({ cf_application_type: config.applicationIdentityType });
                product.setCustomData({ cf_source: "APPLICATION" });

                if (typeof couponId === 'string' && couponId.length > 1) {
                    product.addCoupon(couponId);
                }

                cart.replaceProduct(product);

                // Check this link https://www.chargebee.com/checkout-portal-docs/drop-in-tutorial.html#advanced-configurations
                // Gives details for adding names, billing address etc
                cart.setCustomer(createCustomerObjectForChargebee());

                // https://www.chargebee.com/docs/plans.html
                cart.proceedToCheckout();
            } else {
                TScMessenger.writeDebugMessage('Error: edition not found in openChargebeeModal');
            }


            function createCustomerObjectForChargebee() {
                var customer = createPersonObjectForChargebee();
                // https://www.chargebee.com/checkout-portal-docs/dropIn.html#cart-object
                customer.email = globals.user.getEmailAddress();
                customer.billing_address = createAddressDetailsObjectForChargebee();
                
                return customer;
            }

            function createAddressDetailsObjectForChargebee() {
                var address = createPersonObjectForChargebee();
                // https://www.chargebee.com/checkout-portal-docs/dropIn.html#setshippingaddress
                //address.line1;
                //address.line2;
                //address.city;
                //address.state;
                //address.state_code;
                //address.country = globals.user.getCountryName();
                address.country = globals.user.getCountryAbbreviation();


                return address;
            }

            function createPersonObjectForChargebee() {
                var person = {};
                person.first_name = globals.user.getFirstName();
                person.last_name = globals.user.getLastName();
                //person.company = globals.user.getCompanyName();
                person.phone = globals.user.getDirectNumber();
                person.email = globals.user.getEmailAddress();
                return person;
            }

            /**
             * Create object containing metadata for SELECTED_PLAN event on Intercom
             * @param {string} billingCycle - MONTHLY/ANNUALLY. From config.BILLING_CYCLE_OPTIONS enum
             * @param {string} editionName - Name of edition from database. Essentials/Professional/Enterprise
             * @returns {object} Object to be sent to Intercom
             */
            function getIntercomMetadata(billingCycle, editionName) {
                var returnObject = {};
                returnObject[config.INTERCOM_METADATA_ITEM.PLAN_TYPE] = billingCycle;
                returnObject[config.INTERCOM_METADATA_ITEM.EDITION_NAME] = editionName;
                return returnObject;
            }
        }

        function extendTrial() {
            closeDialog();
            nextTick(function () {
                globals.showDialog({
                    type: 'RequestTrialExtensionModal',
                    callback: function (dialogResult) {
                        if (dialogResult !== undefined) {
                            globals.spinnerText.value = '';
                            globals.showSpinner();

                            dataManager.requestTrialExtension(dialogResult.Company, dialogResult.PhoneNumber)
                                .then(function (transactionResult) {
                                    globals.hideSpinner();
                                    shell.showTrialExtensionRequestSuccessfulMessage();
                                })
                                .fail(function (error) {
                                    globals.hideSpinner();
                                    shell.showTrialExtensionRequestSuccessfulMessage();
                                });
                        }
                    }
                });
            });
            
            // closeDialog();

            // RequestTrialExtensionModal.show(function (dialogResult) {
            //     if (dialogResult !== undefined) {
            //         shell.spinnerText('');
            //         shell.showSpinner();

            //         dataManager.requestTrialExtension(dialogResult.Company, dialogResult.PhoneNumber)
            //             .then(function (transactionResult) {
            //                 shell.hideSpinner();
            //                 shell.showTrialExtensionRequestSuccessfulMessage();
            //             })
            //             .fail(function (error) {
            //                 shell.hideSpinner();
            //                 shell.showTrialExtensionRequestSuccessfulMessage();
            //             });
            //     }
            // });
        }
        
        function handleSuccessfulSubscribe(hostedPageId, boughtEdition) {
            globals.showSpinner();
            dataManager.updateUserAfterCheckout(hostedPageId, boughtEdition)
                .then(function (success) {
                    if (success.ReturnCode === 1) {
                        if (sourceOfCall !== undefined && sourceOfCall === config.BUY_NOW_SOURCE.FROM_LOGIN) {
                            shell.doStepsAfterSuccessfulSubcribeExternal()
                                .then(function () {
                                    dataManager.log("Successful trial conversion from login screen", null, 'shell', config.LOG_MESSAGE_TYPE.INFO);
                                    doCommonSuccessSteps();
                                });
                        } else {
                            dataManager.log("Successful trial conversion while in app", null, 'shell', config.LOG_MESSAGE_TYPE.INFO);
                            doCommonSuccessSteps();
                        }
                    } else {
                        // popup.value.close();
                        closeDialog();
                        globals.hideSpinner();
                        dataManager.log("buyNowModal.js, handleSuccessfulSubscribe, dataManager.updateUserAfterCheckout, success, ReturnCode=" + success.ReturnCode + ", 4512", success.Message, 'buyNowModal', config.LOG_MESSAGE_TYPE.ERROR);
                        nextTick(function () {
                            globals.showDialog(new CustomMessageBox(config.getMessageText('4512', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4512')));
                        });
                        
                    }
                    function doCommonSuccessSteps() {


                        // if (globals.user.allowGoogleAnalyticsCall()) {
                        //     dataManager.sendDataToGoogleAnalytics(config.GOOGLE_ANALYTICS.CATEGORY.TRIAL_USERS, config.GOOGLE_ANALYTICS.ACTION.BOUGHT, planAnalyticsLabel, planValue);
                        // }

                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.BOUGHT_PLAN));
                        if (globals.isGoogleAnalyticsKeyAvailable()) {
                            dataManager.triggerEventInGoogleAnalytics(config.GOOGLE_ANALYTICS_EVENTS.BOUGHT_PLAN);
                        }
                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomUserData, createUserObject());
                        offerManager.validPdfOnLocalStorage.value = false;
                        offerManager.setConfigDrawing(null);

                        displayMessage(boughtEdition).then(function () {
                            shell.forceDataRefresh(config.APP_DATA_REFRESH_SCENARIO.FORCED_REFRESH_AFTER_BUY);
                        });

                        

                        function createUserObject() {
                            var returnObject = {};
                            returnObject[config.INTERCOM_USER.LICENCE] = config.USER_TYPES.FULL.toUpperCase();
                            returnObject[config.INTERCOM_USER.PLAN] = planType.toUpperCase();
                            return returnObject;
                        }

                        function displayMessage(boughtEdition) {
                            var dfd = $.Deferred();
                            // popup.value.close();
                            closeDialog();
                            //cbInstanceObject.closeAll();
                            globals.hideSpinner();
                            nextTick(function () {
                                    shell.showSubscriptionSuccessfulMessage(boughtEdition, opts.value.displayMode).then(function () {
                                    dfd.resolve();
                                });
                            });
                            

                            return dfd.promise();
                        }
                    }
                    
                    //dataManager.adjustLoggedInTrialUserAfterSuccessfulSubscribe(self.planType)
                    //    .then(function () {
                    //        if (sourceOfCall !== undefined && sourceOfCall === config.BUY_NOW_SOURCE.FROM_LOGIN) {
                    //            shell.doStepsAfterSuccessfulSubcribeExternal()
                    //                .then(function () {
                    //                    dataManager.log("Successful trial conversion from login screen", null, system.getModuleId(shell), config.LOG_MESSAGE_TYPE.INFO);
                    //                    doCommonSuccessSteps();
                    //                });
                    //        } else {
                    //            dataManager.log("Successful trial conversion while in app", null, system.getModuleId(shell), config.LOG_MESSAGE_TYPE.INFO);
                    //            doCommonSuccessSteps();
                    //        }

                    //        function doCommonSuccessSteps() {
                    //            globals.drawTrialWatermark(false);
                    //            shell.paymentMenuStatus(config.PAYMENT_MENU_OPTIONS.DO_NOT_DISPLAY);

                    //            if (globals.user.allowGoogleAnalyticsCall()) {
                    //                dataManager.sendDataToGoogleAnalytics(config.GOOGLE_ANALYTICS.CATEGORY.TRIAL_USERS, config.GOOGLE_ANALYTICS.ACTION.BOUGHT, self.planAnalyticsLabel, self.planValue);
                    //            }

                    //            dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.BOUGHT_PLAN));
                    //            dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomUserData, createUserObject());
                    //            offerManager.validPdfOnLocalStorage(false);
                    //            offerManager.setConfigDrawing(null);
                    //            shell.allowUserMenuAccessToChargebeePortal(globals.user.allowUserAccessToChargebeePortal());
                    //            dataManager.remove(OPERATION.ConfigDrawing, offerManager.vehicleId() + "_configurationDrawing");
                    //            if (shell.summaryScreenActive() === true) {
                    //                $.when(offerManager.getCreatePdfWithPromiseCallback()(false)).done(function () {
                    //                    displayMessage();
                    //                });
                    //            } else {
                    //                displayMessage();
                    //            }

                    //            function createUserObject() {
                    //                var returnObject = {};
                    //                returnObject[config.INTERCOM_USER.LICENCE] = config.USER_TYPES.FULL.toUpperCase();
                    //                returnObject[config.INTERCOM_USER.PLAN] = self.planType.toUpperCase();
                    //                return returnObject;
                    //            }

                    //            function displayMessage() {
                    //                dialog.close(self);
                    //                //cbInstanceObject.closeAll();
                    //                shell.hideSpinner();
                    //                if (isAlreadySubscribed !== undefined && isAlreadySubscribed === true) {
                    //                    shell.showAlreadySubscribedMessage();
                    //                } else {
                    //                    shell.showSubscriptionSuccessfulMessage();
                    //                }
                    //            }
                    //        }

                    //    })
                    //    .fail(function () {
                    //        if (globals.user.allowGoogleAnalyticsCall()) {
                    //            dataManager.sendDataToGoogleAnalytics(config.GOOGLE_ANALYTICS.CATEGORY.TRIAL_USERS, config.GOOGLE_ANALYTICS.ACTION.BOUGHT, self.planAnalyticsLabel, self.planValue);
                    //        }
                    //        shell.hideSpinner();
                    //        var refreshScenario;
                    //        if (isAlreadySubscribed !== undefined && isAlreadySubscribed === true) {
                    //            refreshScenario = config.APP_DATA_REFRESH_SCENARIO.ERROR_UPGRADING_USER_ALREADY_SUBSCRIBED;
                    //        } else {
                    //            refreshScenario = config.APP_DATA_REFRESH_SCENARIO.ERROR_UPGRADING_USER_AFTER_SUCCESSFUL_SUBSCRIBE;
                    //        }
                    //        shell.forceDataRefresh(refreshScenario);
                    //        //shell.paymentMenuStatus(config.PAYMENT_MENU_OPTIONS.BUY_NOW);
                    //        //app.showMessage(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')]);
                    //    });
                })
                .fail(function (error) {
                    if (globals.user.allowGoogleAnalyticsCall()) {
                        dataManager.sendDataToGoogleAnalytics(config.GOOGLE_ANALYTICS.CATEGORY.TRIAL_USERS, config.GOOGLE_ANALYTICS.ACTION.BOUGHT, planAnalyticsLabel, planValue);
                    }
                    // popup.value.close();
                    closeDialog();
                    globals.hideSpinner();
                    dataManager.log("buyNowModal.js, handleSuccessfulSubscribe, dataManager.updateUserAfterCheckout, fail, 4515", error, 'buyNowModal', config.LOG_MESSAGE_TYPE.ERROR);
                    nextTick(function () {
                        globals.showDialog(new CustomMessageBox(config.getMessageText('4515', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('4515')));
                    });
                    
                });
            
        }

        function close() {
            closeDialog();
        }

        return {
            popup,
            show,
            modalModeCssClass,
            editionsList,
            headerMessage,
            headerImageSrc,
            getTranslationText,
            subheaderMessage,
            extendTrial,
            close,
            trialHasExpired,
            BILLING_CYCLE_OPTIONS,
            closeButtonText,
            selectedBillingCycle,
            changeBillingCycle,
            annualSavingsText
        }
    },
}
</script>