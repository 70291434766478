<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: modalTitle">{{getTranslationText('1104')}}</span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <form id="file-load-form" 
                    enctype="multipart/form-data" 
                    action="" 
                    method="post" 
                    role="form" 
                    @submit.prevent="handleFileSelect"
                    data-bind="submit: handleFileSelect">
                    <div class="fill-display-flex center-vertical">
                        <div class="file-input-div">
                            <input id="file-select" 
                                name="csvFile" 
                                type="file" 
                                accept=".csv" />
                        </div>
                        <button class="multiselect-div-clear" 
                            type="submit"
                            data-bind="click: function() { $('#file-load-form').submit(); }">
                            <i class="fas fa-file-download fa-lg"></i> <span data-bind="text: sendButtonText">{{getTranslationText('1105')}}</span>
                        </button>
                    </div>
                </form>

                <div v-if="showBodyText === true || showErrors === true" class="messages-container">
                    <div v-if="showBodyText === true" class="message-paragraph">
                        <p class="message-paragraph" 
                            v-html="bodyText"
                            data-bind="html: bodyText">
                        </p>
                    </div>
                    <div v-if="showErrors === true" class="message-paragraph">
                        <p class="message-paragraph" 
                            v-html="errorText"
                            data-bind="html: errorText">
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience" 
                    v-visible="showCancel === true"
                    @click="cancel"
                    data-bind="visible: showCancel, click: function () { close(getTranslationText('2709'))}, touchstart: function () { close(getTranslationText('2709'))}">
                    <span data-bind="text: getTranslationText('2709')">{{getTranslationText('2709')}}</span>
                </button>
                <button class="btn btn-default btn-truckscience btn-primary" 
                    v-visible="showRefreshNowLater === true"
                    @click="refreshNow"
                    data-bind="visible: showRefreshNowLater, click: function () { close(getTranslationText('1106'))}, touchstart: function () { close(getTranslationText('1106'))}">
                    <span data-bind="text: getTranslationText('1106')">{{getTranslationText('1106')}}</span>
                </button>
                <button class="btn btn-default btn-truckscience" 
                    v-visible="showRefreshNowLater === true"
                    @click="refreshLater"
                    data-bind="visible: showRefreshNowLater, click: function () { close(getTranslationText('1107'))}, touchstart: function () { close(getTranslationText('1107'))}">
                    <span data-bind="text: getTranslationText('1107')">{{getTranslationText('1107')}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';
import PopupModal from './PopupModal.vue';
import CustomMessageBox from '@/services/CustomMessageBox';
import { ref, onMounted, toRef, nextTick } from 'vue';

const $ = window.$;

export default {
    name: 'UploadFileModal',
    components: { PopupModal },
    props: {
        propsForModal: Object
    }, 
    setup(props) {
        const opts = toRef(props, "propsForModal");
        const popup = ref(null);
        const showRefreshNowLater = ref(false);
        const showCancel = ref(true);
        const showBodyText = ref(false);
        const showErrors = ref(false);

        const bodyText = ref('');
        const errorText = ref([]);

        let resolvePromise;
        
        onMounted(() => {
            setTimeout(function() { compositionComplete(); }, 10);
            // nextTick(compositionComplete);
        })

        function compositionComplete() {
            document.getElementById('file-select').addEventListener('click', function (evt) { handleClick() }, false);
        }

        function handleClick() {
            showBodyText.value = false;
            bodyText.value = '';
            showErrors.value = false;
            errorText.value = '';
            showRefreshNowLater.value = false;
            showCancel.value = true;
        }

        function sendDocument() {
            $('#file-load-form').submit(function(e) {
                e.preventDefault();
            });
        }

        function handleFileSelect() {
            var form = document.getElementById('file-form');
            var fileSelect = document.getElementById('file-select');

            var files = fileSelect.files;
            var formData = new FormData();

            for (var i = 0; i < files.length; i++) {
                var file = files[i];

                // Add the file to the request.
                formData.append('csvFile', file, file.name);
            }
            globals.showSpinner();
            dataManager.postCSVFileData(formData)
                .then(function (data) {
                    globals.hideSpinner();
                    if (data.Result.ReturnCode === 1) {
                        //success message and enable refresh now and refresh later buttons
                        showErrors.value = false;
                        errorText.value = '';
                        bodyText.value = config.getMessageText('1672', globals.user.getCultureCode());
                        showBodyText.value = true;
                        showRefreshNowLater.value = true;
                        showCancel.value = false;
                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CSV_UPLOADED, {}));
                    } else if (data.Result.ReturnCode === 2) {
                        //file successfully processed but not committed due to errors message
                        bodyText.value = config.getMessageText('1673', globals.user.getCultureCode()) + ':</br>';
                        errorText.value = getErrorText(data);
                        showBodyText.value = true;
                        showErrors.value = true;
                        showRefreshNowLater.value = false;
                        showCancel.value = true;
                    } else if (data.Result.ReturnCode === -1) {
                        //unsupported operation message 
                        bodyText.value = config.getMessageText('1674', globals.user.getCultureCode());
                        showErrors.value = false;
                        errorText.value = '';
                        showBodyText.value = true;
                        showRefreshNowLater.value = false;
                        showCancel.value = true;
                    } else if (data.Result.ReturnCode === -2) {
                        //unexpected error message
                        bodyText.value = config.getMessageText('1675', globals.user.getCultureCode());
                        showErrors.value = false;
                        errorText.value = '';
                        showBodyText.value = true;
                        showRefreshNowLater.value = false;
                        showCancel.value = true;
                    } else if (data.Result.ReturnCode === -3) {
                        //could not allocate to requester message
                        bodyText.value = config.getMessageText('1676', globals.user.getCultureCode());
                        showErrors.value = false;
                        errorText.value = '';
                        showBodyText.value = true;
                        showRefreshNowLater.value = false;
                        showCancel.value = true;
                    }

                    function getErrorText(data) {
                        var errorText = '';

                        if (data.HighLevelErrors.length > 0) {
                            errorText += config.getTranslationText('1108') + ':';
                            errorText += '<ul>';
                            data.HighLevelErrors.forEach(function (highLevelError) {
                                errorText += '<li>' + highLevelError + '</li>';
                            });
                            errorText += '</ul>';
                        }
                        if (data.UploadObjects.length > 0) {
                            if (uploadObjectErrors(data)) {
                                errorText += config.getTranslationText('1109') + ':';
                                data.UploadObjects.forEach(function (uploadObject) {
                                    errorText += '<ul>';
                                    errorText += uploadObject.Name !== '' ? '<li>' + uploadObject.Name + '</li>' : '<li>' + 'Vehicle X' + '</li>' ;
                                    if (uploadObject.Errors.length > 0) {
                                        errorText += '<ul>';
                                        uploadObject.Errors.forEach(function (vehicleLevelError) {
                                            errorText += '<li>' + vehicleLevelError.Message + '</li>';
                                        });
                                        errorText += '</ul>';
                                    } else {
                                        errorText += '<li>' + config.getTranslationText('1110') + '</li>';
                                    }
                                    errorText += '</ul>';
                                });
                            }
                        }

                        return errorText;
                    }

                    function uploadObjectErrors(data) {
                        var uploadObjectHasErrors = false;

                        data.UploadObjects.forEach(function (uploadObject) {
                            if (uploadObject.Errors.length > 0) {
                                uploadObjectHasErrors = true;
                            }
                        });

                        return uploadObjectHasErrors;
                    }

                }).
                fail(function (error) {
                    globals.hideSpinner();
                    if (error.errorMessage === 'Offline') {
                        // app.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')));
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')))
                    } else {
                        // dataManager.log("handleFileSelect, dataManager.postCSVFileData, fail, 1571", error, system.getModuleId(UploadFileModal), config.LOG_MESSAGE_TYPE.ERROR);
                        // app.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')))
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')))
                    }
                });
        }

        function show(opts = {}) {
            // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function cancel() {
            close(config.getTranslationText('2709'));
        }

        function refreshNow() {
            close(config.getTranslationText('1106'));
        }

        function refreshLater() {
            close(config.getTranslationText('1107'));
        }

        function close(text) {
            popup.value.close();
            resolvePromise(text);
        }

        return {
            show,
            popup,
            sendDocument,
            handleFileSelect,
            cancel,
            showCancel,
            showRefreshNowLater,
            showErrors,
            errorText,
            bodyText,
            showBodyText,
            refreshNow,
            refreshLater,
            getTranslationText: config.getTranslationText
        }
    },
}
</script>
