<template>
    <div>
        <div class="dropdown display-overflow">
            <a
                href="#"
                class="dropdown-toggle nav-buttons"
                aria-expanded="false"
                id="user-dropdown-control"
                data-bs-toggle="dropdown"
                data-toggle="dropdown"
                @click.prevent="toggleDropdown"
            >
                <i class="fa fa-user-circle fa-lg custom-fa-size"></i>
            </a>
            <ul
                class="dropdown-menu"
                role="menu"
                aria-labelledby="user-dropdown-control"
                id="dropdownUserMenu"
            >
                <li role="presentation">
                    <div class="dropdownUserMenu-user-section">
                        <div class="icon-div">
                            <i class="far fa-user-circle fa-lg custom-fa-size"></i>
                        </div>
                        <div class="user-section">
                            <div class="user-name">
                                <span data-bind="text: displayUserName">{{displayUserName}}</span>
                            </div>
                            <div class="user-details email-address">
                                <span data-bind="text: displayEmailAddress">{{displayEmailAddress}}</span>
                            </div>
                            <div class="user-details product-details">
                                <span data-bind="text: productNameObv">{{productNameObv}}</span>
                            </div>
                            <div class="user-details product-details">
                                <span data-bind="text: editionAndVersionObv">{{editionAndVersionObv}}</span>
                            </div>
                        </div>
                    </div>
                </li>
                <li role="presentation" class="divider"></li>
                <template v-if="cdgArray.length > 1">
                    <li role="presentation" 
                        class="open li-container-cdg">
                        <ul
                            class="dropdown-menu"
                            role="menu"
                            aria-labelledby="dropdownUserMenuTest"
                            id="dropdownUserMenuTest"
                        >
                            <li v-for="cdg in cdgArray"
                                :key="cdg"
                                @click="selectLanguage(cdg)"
                                role="presentation">
                                <a role="menuitem" 
                                    tabindex="-1" 
                                    href="#">
                                    <div class="dropdown-text-div-container">
                                        <div class="dropdown-text-div-icon">
                                            <span>
                                                <i :class="{ 'fa-check-square': cdg.Default === true, 'fa-square': cdg.Default === false }"
                                                    class="far"></i>
                                            </span>
                                        </div>
                                        <div class="dropdown-text-div-text" :id="'cdg-display-id-' + cdg.Id">
                                            <span data-bind="text: DisplayName">{{cdg.DisplayName}}</span>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li role="presentation" class="divider"></li>
                </template>
                
                <li v-if="isGoogleUser === false" 
                    role="presentation">
                    <a role="menuitem" 
                        tabindex="-1" 
                        href="#" 
                        @click="openChangePasswordModal"
                        data-bind="click: openChangePasswordModal, touchstart: openChangePasswordModal">
                        <span data-bind="text: navBarTextObservable().changePasswordText">{{getTranslationText('2352')}}</span>
                    </a>
                </li>
                <li v-if="displayEmailValidation === true"
                    role="presentation">
                    <a role="menuitem" 
                        tabindex="-1" 
                        href="#" 
                        @click="showEmailVerificationModal"
                        data-bind="click: offerManager.showEmailVerificationModal, touchstart: offerManager.showEmailVerificationModal, visible: showVerifyMenuButton">
                        <span data-bind="text: navBarTextObservable.verifyEmailText">{{getTranslationText('2353')}}</span>
                    </a>
                </li>
                <li v-if="displayReferralMenuOption === true"
                    role="presentation">
                    <a role="menuitem" 
                        tabindex="-1" 
                        href="#" 
                        @click="openReferralModal"
                        data-bind="click: openReferralModal, touchstart: openReferralModal">
                        <span data-bind="text: navBarTextObservable().referAFriendText">{{getTranslationText('2383')}}</span>
                    </a>
                </li>
                <li role="presentation">
                    <a role="menuitem" 
                        tabindex="-1" 
                        href="#" 
                        @click="startUserDrivenDataRefresh"
                        data-bind="click: startUserDrivenDataRefresh, touchstart: startUserDrivenDataRefresh">
                        <span data-bind="text: navBarTextObservable().refreshDataText">{{getTranslationText('2384')}}</span>
                    </a>
                </li>
                <li v-if="displayUploadVehicleDataMenuOption === true"
                    role="presentation">
                    <a role="menuitem" 
                        tabindex="-1" 
                        href="#" 
                        @click="openUploadFileModal"
                        data-bind="click: openUploadFileModal, touchstart: openUploadFileModal">
                        <span data-bind="text: navBarTextObservable().uploadVehicleDataText">{{getTranslationText('2708')}}</span>
                    </a>
                </li>
                <li v-if="allowOpenTsvFile === true"
                    role="presentation">
                    <a role="menuitem" 
                        tabindex="-1" 
                        href="#" 
                        @click="openTsvFileModalFunction"
                        data-bind="click: openTsvFileModalFunction, touchstart: openTsvFileModalFunction">
                        <span data-bind="text: navBarTextObservable().openTsvFileText">{{getTranslationText('4081')}}</span>
                    </a>
                </li>
                <template v-if="allowUserAccessToChargebeePortal === true">
                    <li role="presentation" class="divider"></li>
                    <li role="presentation">
                        <a role="menuitem" 
                            tabindex="-1" 
                            href="#" 
                            @click="openChangebeePortalHomePage"
                            data-bind="click: openChangebeePortalHomePage, touchstart: openChangebeePortalHomePage">
                            <span data-bind="text: navBarTextObservable().manageAccountText">{{getTranslationText('1210')}}</span>
                        </a>
                    </li>
                </template>
                <li role="presentation" class="divider"></li>
                <li role="presentation">
                    <a @click="openHelpModal"
                        role="menuitem" 
                        tabindex="-1" 
                        href="#">
                        <span>{{getTranslationText('2385')}}</span>
                    </a>
                </li>
                <li role="presentation" class="divider"></li>
                <li role="presentation">
                    <a role="menuitem" tabindex="-1" href="#" @click="logout">
                        <div class="dropdown-text-div-container logout-text-div">
                            <div
                                class="dropdown-text-div-text"
                                style="-ms-word-wrap: break-word; word-wrap: break-word; white-space: normal;"
                            >
                                <span>{{getTranslationText('2386')}}</span>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import offerManager from '@/services/offerManager';
import bus from '@/main';
// import { shallowRef } from 'vue';

const $ = window.$;

function logout() {
    bus.emit('logout', {});
}

function openHelpModal() {
    bus.emit('openHelpModalOnClick', {});
}

function openUploadFileModal() {
    bus.emit('openUploadFileModalOnClick', {});
}

function openChangePasswordModal() {
    bus.emit('openChangePasswordModalEvent', {});
}

function selectLanguage(selectedLanguage) {
    bus.emit('selectLanguageEvent', selectedLanguage);
}

function startUserDrivenDataRefresh() {
    bus.emit('startUserDrivenDataRefreshEvent');
}

function openTsvFileModalFunction() {
    bus.emit('openTsvFileModalFunctionEvent');
}

function openChangebeePortalHomePage() {
    bus.emit('openChangebeePortalHomePageEvent');
}

function openReferralModal() {
    bus.emit('openReferralModalEvent');
}

export default {
    name: 'UserDropdownMenu',
    setup() {
        // const cdgArray = shallowRef([]);
        // globals.clearShallowRefArrayAndAddItems(cdgArray, globals.user.cdgArray.value);

        function toggleDropdown() {
            $('#user-dropdown-control').dropdown();
        }

        return {
            // Functions
            logout,
            openHelpModal,
            openUploadFileModal,
            toggleDropdown,
            openChangePasswordModal,
            selectLanguage,
            showEmailVerificationModal: offerManager.showEmailVerificationModal,
            startUserDrivenDataRefresh,
            openTsvFileModalFunction,
            openChangebeePortalHomePage,
            openReferralModal,
            // Permissions
            displayUserName: globals.user.displayNameObv,
            displayEmailAddress: globals.user.displayEmailAddressObv,
            isGoogleUser: globals.user.isGoogleUserObv,
            allowOpenTsvFile: globals.user.allowOpenTsvFileObv,
            allowUserAccessToChargebeePortal: globals.user.allowUserAccessToChargebeePortalObv,
            displayUploadVehicleDataMenuOption: globals.user.allowDataUploadObv,
            displayEmailValidation: globals.user.displayEmailVerificationMenuOptionObv,
            displayReferralMenuOption: globals.user.allowReferrals(),
            // Customer Distribution Groups
            cdgArray: globals.user.cdgArray,
            // cdgArray: cdgArray,
            // Text
            productNameObv: globals.user.productNameObv,
            editionAndVersionObv: globals.user.editionAndVersionObv,
            getTranslationText: config.getTranslationText
        }
    }
}
</script>

<style lang="scss" scoped>
</style>