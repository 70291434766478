import * as msalAuth from '../msal/auth';
import { onUnmounted, ref, shallowRef, watch, nextTick } from 'vue'; 
import bus from '@/main';

const $ = window.$;
var TScMessenger = window.TScMessenger;
const CustomTruckScienceRequirements = window.CustomTruckScienceRequirements;

var LoginSignUpObject = function(logger, dataManager, config, globals, shell, forgotPasswordModal, termsAndConditionsModal, CustomMessageBox, initialSignInMode) {

    var self = this;

    // #region Variables
    //var dfd;
    var selectedCountryAbbreviation = "";
    var currentBrowser = CustomTruckScienceRequirements.getBrowser();
    var countryCheckComplete = false;
    var microsoftImageUrl = config.cdnAddress + "img/app/ms-logo.png";
    let initialSignInModeToUse = initialSignInMode && initialSignInMode.mode ? initialSignInMode.mode : config.GET_STARTED_MODES.SIGN_IN;
    //var accessCode;
    // #endregion Variables

    // #region Observables
    var loginEmail = shallowRef(),
        loginPassword = shallowRef(),
        loginButtonText = shallowRef(),
        signUpButtonText = shallowRef(),
        signUpFirstName = shallowRef(),
        signUpLastName = shallowRef(),
        signUpEmail = shallowRef(),
        signUpPassword = shallowRef(),
        signUpCountry = shallowRef(),
        showSalesBlurb = shallowRef(false),
        countryList = shallowRef(config.availableCountriesList),
        signUpPasswordTooltipMessage = config.getTooltipText('1707', globals.user.getCultureCode()),
        getStartedDisplayMode = ref(initialSignInModeToUse),
        showSocialSignUpSection = shallowRef(false),
        showCountriesDropdown = shallowRef(false),
        emailFieldReadOnly = shallowRef(false),
        actionButtonCallback = null;

    // #endregion Observables

    watch(getStartedDisplayMode, function (newValue) {
        if (newValue === config.GET_STARTED_MODES.SIGN_UP) {
            localStorage.setItem('GET_STARTED_MODE', config.GET_STARTED_MODES.SIGN_UP);
            //localStorage.setItem('GET_STARTED_COUNTRY', signUpCountry());
        } else {
            localStorage.removeItem('GET_STARTED_MODE');
            //localStorage.removeItem('GET_STARTED_COUNTRY');
        }
    });

    watch(signUpCountry, function (newValue) {
        if (getStartedDisplayMode.value === config.GET_STARTED_MODES.SIGN_UP) {
            localStorage.setItem('GET_STARTED_COUNTRY', signUpCountry.value);
        } else {
            localStorage.removeItem('GET_STARTED_COUNTRY');
        }

    });

    // function checkIsOnline() {
    //     $.when(dataManager.checkIsOnline()).done(function (result) {
    //         if (!result) {
    //             goToOfflinePage()
    //         }
    //     }).fail(function () {
    //         goToOfflinePage();
    //     });

    //     function goToOfflinePage() {
    //         if (globals.alreadyAuthenticated.value === false) {
    //             //not sure this funtion used
    //         }
    //     }
    // }

    // function getCountries() {
    //     $.when(dataManager.getCountries()).done(function () {

    //     })
    //     .fail(function () {
    //         if (globals.alreadyAuthenticated.value === false) {
    //             var url = CustomTruckScienceRequirements.getMetaTagContent('get-started-error-url');
    //             window.location = url;
    //         }
    //     });
    // }

    function getUserCountry() {
        function success(data) {
            
            doActionsAfterCountryLookup(data);
        }
        function error(error) {
            
            doActionsAfterCountryLookup({
                country_code: "US"
            });
        }
        //https://ipapi.com/documentation
        let key = config.ipapiKey;
        $.ajax({
            dataType: "json",
            url: 'https://api.ipapi.com/api/check?access_key=' + key,
            success: success,
            timeout: 5000,
            error: error
        });
    }

    function doActionsAfterCountryLookup(data) {
        //var country = data !== undefined ? data.country : "";
        var defaultCountry = "US";
        var country = data !== undefined && data.country_code ? data.country_code : defaultCountry;
        signUpCountry.value = country;
        countryCheckComplete = true;
        if (getStartedDisplayMode.value === config.GET_STARTED_MODES.SIGN_UP) {
            setUpCountrySelectMenu();
        }
    }

    function setUpCountrySelectMenu() {
        $('#div-to-hide').remove();
        $('#countries-div').addClass('input-x2');
        $('#get-started-country-select').val(signUpCountry.value);
        $('#get-started-country-select').selectmenu({
            width: 380,
            open: function () {
                var container = $('#get-started-country-select-menu'),
                scrollTo = $('.ui-menu-item.ui-state-focus');
                if (container.scrollTop() === 0) {
                    var scrollToPosition = parseInt(scrollTo.offset().top - container.offset().top + scrollTo.scrollTop() - container.height() / 2) + 'px';
                    container.animate({
                        scrollTop: scrollToPosition
                    }, 1);
                }
            },
            change: function (event, data) {
                signUpCountry.value = data.item.value;
            }
        });
        $('.ui-selectmenu-menu').addClass('get-started-dropdown');
        if (countryList.value.length === 1) {
            $('.ui-selectmenu-menu').addClass('get-started-dropdown-1-row');
        }
    }

    // #region Durandal functions
    function onBeforeMount(parameters) {
        countryCheckComplete = false;
        globals.user.updateUser({ lastUrlHash: window.location.pathname });

        if (initialSignInMode && initialSignInMode.mode && initialSignInMode.mode === config.GET_STARTED_MODES.SIGN_UP) {
            
            getStartedDisplayMode.value = config.GET_STARTED_MODES.SIGN_UP;
            getUserCountry();
            // Send data to Google Analytics
            //if (globals.isGoogleAnalyticsKeyAvailable()) {
            //    dataManager.sendDataToGoogleAnalytics(config.GOOGLE_ANALYTICS.CATEGORY.TRIAL_USERS, config.GOOGLE_ANALYTICS.ACTION.GET_STARTED_FOR_SHARE);
            //}
        } else if (parameters !== undefined) {
            if (parameters.mode !== undefined && parameters.mode === config.GET_STARTED_MODES.SIGN_UP) {
                getStartedDisplayMode.value = config.GET_STARTED_MODES.SIGN_UP;
                getUserCountry();
                // Send data to Google Analytics
                // if (globals.isGoogleAnalyticsKeyAvailable()) {
                //     dataManager.sendDataToGoogleAnalytics(config.GOOGLE_ANALYTICS.CATEGORY.TRIAL_USERS, config.GOOGLE_ANALYTICS.ACTION.GET_STARTED_CLICKED);
                // }
            } else if (parameters.accessCode !== undefined) {
                getStartedDisplayMode.value = config.GET_STARTED_MODES.SIGN_IN;
                globals.user.updateUser({ accessCode: parameters.accessCode });

                //if (globals.isGoogleAnalyticsKeyAvailable()) {
                //    if (parameters.userType === config.USER_TYPES.FULL) {
                //        dataManager.sendDataToGoogleAnalytics(config.GOOGLE_ANALYTICS.CATEGORY.FULL_USERS, config.GOOGLE_ANALYTICS.ACTION.REDIRECTED_TO_LOGIN_FOR_SHARE);
                //    } else {
                //        dataManager.sendDataToGoogleAnalytics(config.GOOGLE_ANALYTICS.CATEGORY.TRIAL_USERS, config.GOOGLE_ANALYTICS.ACTION.REDIRECTED_TO_LOGIN_FOR_SHARE);
                //    }
                //}
            } else {
                getStartedDisplayMode.value = config.GET_STARTED_MODES.SIGN_IN;
            }
        } else {
            // if (globals.isGoogleAnalyticsKeyAvailable()) {
            //     dataManager.sendDataToGoogleAnalytics(config.GOOGLE_ANALYTICS.CATEGORY.FULL_USERS, config.GOOGLE_ANALYTICS.ACTION.LOGIN_CLICKED);
            // }
            getStartedDisplayMode.value = config.GET_STARTED_MODES.SIGN_IN;
        }
        globals.loginScreenActive.value = true;
        logger.log('Get Started View Activated', null, 'get_started', config.showDebugToast);
        loginButtonText.value = config.getTranslationText('2358');
        signUpButtonText.value = config.getTranslationText('2375');
        if (globals.getRegisterUserUsingMicrosoft()) {
            getStartedDisplayMode.value = config.GET_STARTED_MODES.SIGN_UP;
            var cachedUser = globals.getAuthContext().getCachedUser(),
                countryToUse = localStorage.getItem('GET_STARTED_COUNTRY');
            //registerNewUser()
            registerNewUser(cachedUser.profile.given_name, cachedUser.profile.family_name, cachedUser.userName, globals.user.getPassword(), countryToUse, globals.user.getAuthenticationMethod());
        }
        //checkIsOnline();
        dataManager.checkIsOnlineHeadVersion()
            .then(function () {
                globals.isOnline.value = true;
            })
            .fail(function () {
                // globals.isOnline.value = false;
                globals.isOnline.value = true;
            });

        setUpInputDefaults();

        return true;
    }

    function onMounted() {

        // $(document).ready(function () {
            // shell.displayGetStartedIcon.value = true;
            // shell.hideSpinner();
            startApp();
            
            signUpInputBehaviour();
            loginInputBehaviour();
            setupMicrosoftButtons();

            if (globals.getBrowser() === config.BROWSER_NAME.INTERNETEXPLORER) {
                window.initPlaceholder();
            }

            setTimeout(function(){
                globals.hideSpinner();
                $('#get-started-section').removeClass('do-not-display');
            }, 250);
    }
    onUnmounted(() => {
        globals.loginScreenActive.value = false;
    })
    function deactivate() {
        setUpInputDefaults();
        $('#get-started-section').addClass('do-not-display');
        globals.loginScreenActive.value = false;
        // shell.displayGetStartedIcon(false);
        return true;
    }
    function canActivate() {
        
        //shell.showSpinner();
        ////dodgy hack to prevent login screen from flashing in Safari
        //if (dataManager.securityTokenIsValid(true)) {
        //    if (globals.user.hasPermission(PERMISSIONS.OVERVIEW.Code)) {
        //        if (globals.hasRoute('#/overview')) {
        //            return { redirect: '#/overview' };
        //        } else {
        //            shell.hideSpinner();
        //            //continue as normal
        //            return true;
        //        }
        //    } else {
        //        if (globals.hasRoute('#/selection')) {
        //            return { redirect: '#/selection' };
        //        } else {
        //            shell.hideSpinner();
        //            //continue as normal
        //            return true;
        //        }
        //    }
        //} else {
        //    shell.hideSpinner();
        //    //continue as normal
        //    return true;
        //}
        return true;
    }
    // #endregion Durandal functions

    function signUpInputBehaviour() {
        $('#sign-up-password input').on('blur', function () {
            $('#sign-up-password-input').tooltip('destroy');
            if (signUpPassword.value !== undefined && signUpPassword.value.length > 0 && !globals.isValidPassword(signUpPassword.value)) {
                $('#sign-up-password').addClass('error');
                $('#sign-up-password-input').focus().addClass('error');
            } else {
                $('#sign-up-password').removeClass('error');
                $('#sign-up-password-input').removeClass('error');
                return true;
            }
        });
        $('#sign-up-password input').on('focus', function () {
            $('#sign-up-password-input').tooltip({ trigger: 'manual', html: true }).tooltip('show');
        });

        $('#sign-up-email-input').on('focus', function () {
            if ($('#sign-up-email').hasClass('error')) {
                $('#sign-up-email-input').tooltip({ trigger: 'manual' }).tooltip('show');
            }
        });
        $('#sign-up-email-input').on('blur', function () {
            $('#sign-up-email-input').tooltip('destroy');
            if (signUpEmail.value !== undefined && signUpEmail.value.length > 0 && !globals.isValidEmailAddress(signUpEmail.value)) {
                $('#sign-up-email').addClass('error');
                $('#sign-up-email-input').focus().addClass('error');
            } else {
                $('#sign-up-email').removeClass('error');
                $('#sign-up-email-input').removeClass('error');
                return true;
            }
        });

        //$('#msRegisterBtn').on('click', function () {
        //    globals.getAuthContext().loginPopup();
        //});
    }

    function loginInputBehaviour() {
        $('#login-email-input').on('focus', function () {
            if ($('#login-email').hasClass('error')) {
                $('#login-email-input').tooltip({ trigger: 'manual' }).tooltip('show');
            }
        });
        $('#login-email-input').on('blur', function () {
            $('#login-email-input').tooltip('destroy');
            if (loginEmail.value !== undefined && loginEmail.value.length > 0 && !globals.isValidEmailAddress(loginEmail.value)) {
                $('#login-email').addClass('error');
                $('#login-email-input').focus().addClass('error');
            } else {
                $('#login-email').removeClass('error');
                $('#login-email-input').removeClass('error');
                return true;
            }
        });

        //$('#msLoginBtn').on('click', function () {
        //    globals.getAuthContext().loginPopup().then(
        //        function (token) {
        //            TScMessenger.writeDebugMessage('Success');
        //            var user = globals.getAuthContext().getUser();
        //            if (getStartedDisplayMode() === config.GET_STARTED_MODES.SIGN_UP) {
        //                var fullName = user.name,
        //                    nameSplit = fullName.split(' '),
        //                    firstName = nameSplit[0],
        //                    lastName = nameSplit.length > 1 ? nameSplit[1] : '';

        //                registerNewUser(firstName,
        //                    lastName,
        //                    user.displayableId,
        //                    token,
        //                    signUpCountry(),
        //                    config.AUTHENTICATION_METHOD.MICROSOFT);
        //            } else {
        //                shell.login(user.displayableId, token, system.getModuleId(vm), loginButtonText, function () {
        //                    selection.selectedBodyType('');
        //                    selection.selectedBodyManufacturer('');
        //                }, false, config.AUTHENTICATION_METHOD.MICROSOFT);
        //            }
        //        },
        //        function (error) {
        //            TScMessenger.writeDebugMessage('Fail');
        //        });
        //        //globals.getAuthContext().openWindow();
        //        //globals.getAuthContext().loginRedirect();
        //        //globals.getAuthContext().acquireTokenPopup();
        //        //globals.getAuthContext().acquireTokenSilent().then(
        //        //    function (token) {
        //        //        TScMessenger.writeDebugMessage('Login with token');
        //        //    },
        //        //    function (error) {
        //        //        if (error) {
        //        //            globals.getAuthContext().acquireTokenRedirect();
        //        //        }
        //        //    }
        //        //);
        //});
    }

    function setupMicrosoftButtons() {
        $('.btn-microsoft').unbind('click');
        $('.btn-microsoft').on('click', function () {
            if (globals.isOnline.value === true) {
                let loginRequest = {
                    scopes: ["User.Read"]
                }
                msalAuth.setupAndInitialiseMsalPublicClientApplication()
                    .then(resp => {
                            if (getStartedDisplayMode.value === config.GET_STARTED_MODES.SIGN_UP) {
                                var fullName = resp.fullName,
                                    firstName,
                                    lastName;
                                if (fullName !== undefined) {
                                    var nameSplit = fullName.split(' ');
                                    firstName = nameSplit.length > 0 ? nameSplit[0] : config.getTranslationText('1029');
                                    lastName = nameSplit.length > 1 ? nameSplit[1] : config.getTranslationText('1030');
                                } else {
                                    firstName = config.getTranslationText('1029');
                                    lastName = config.getTranslationText('1030');
                                }
                                registerNewUser(firstName,
                                    lastName,
                                    resp.email,
                                    resp.accessToken,
                                    signUpCountry.value,
                                    config.AUTHENTICATION_METHOD.MICROSOFT);
                            } else {
                                shell.login(resp.email, resp.accessToken, 'currentViewModel', loginButtonText, function () {}, false, config.AUTHENTICATION_METHOD.MICROSOFT, undefined, undefined, loginSignUpInterface);
                            }
                        })
                        .fail(error => {
                            TScMessenger.writeDebugMessage('Fail');
                        });
            } else {
                globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')));
            }

        });
    }

    function setUpInputDefaults() {
        loginEmail.value = '';
        loginPassword.value = '';
        signUpFirstName.value = '';
        signUpLastName.value = '';
        signUpEmail.value = '';
        signUpPassword.value = '';
        signUpCountry.value = '';
    }

    function submitLogin() {
        var passedValidation = true,
            errorFocusId = undefined;
        $('.error').removeClass('error');
        $('#login-email-input').tooltip('destroy').removeClass('error');

        if (!globals.isValidEmailAddress(loginEmail.value)) {
            $('#login-email').addClass('error');
            $('#login-email-input').addClass('error');
            setErrorFocusId('#login-email-input');
            passedValidation = false;
        }

        if (loginPassword.value === undefined || loginPassword.value.length === 0) {
            $('#login-password').addClass('error');
            $('#login-password-input').addClass('error');
            setErrorFocusId('#login-password-input');
            passedValidation = false;
        }

        if (passedValidation === true) {
            if (globals.isOnline.value === true) {
                if (globals.user.getAccessCode() === '') {
                    
                    shell.login(loginEmail, loginPassword, 'currentViewModel', loginButtonText, function () { }, undefined, undefined, undefined, undefined, loginSignUpInterface);
                } else {
                    var decodedAccessCode = globals.decodeAccessCode(globals.user.getAccessCode());
                    var accessCodeParts = decodedAccessCode.split('_');
                    var allowDistributorToOpenLink = false;
                    if (accessCodeParts.length > 6 && accessCodeParts[6] === config.allowOpenOfferByDistributorFlagString) {
                        allowDistributorToOpenLink = true;
                    }
                    if (globals.user.getEmailAddress() === loginEmail.value || loginEmail.value === accessCodeParts[5] || (allowDistributorToOpenLink === true && accessCodeParts[5] === config.SUPPORT_EMAIL_ADDRESSES.TRUCKSCIENCE)) {
                        shell.login(loginEmail, loginPassword, 'currentViewModel', loginButtonText, function () {
                            shell.offerManager.handleDeepLinkAccessCode(globals.user.getAccessCode());
                            globals.user.updateUser({ accessCode: '' });
                        }, false, config.AUTHENTICATION_METHOD.TRUCKSCIENCE, true, undefined, loginSignUpInterface);
                    } else {
                        
                        shell.login(loginEmail, loginPassword, 'currentViewModel', loginButtonText, function () {
                            globals.user.updateUser({ accessCode: '' });
                        }, undefined, undefined, undefined, undefined, loginSignUpInterface);
                    }
                }
            } else {
                globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')));
            }
        } else {
            $(errorFocusId).focus();
        }

        function setErrorFocusId(value) {
            errorFocusId = errorFocusId === undefined ? value : errorFocusId;
        }
    }

    function submitSignUp() {
        var passedValidation = true,
            errorFocusId = undefined;

        $('.error').removeClass('error');

        if (signUpFirstName.value === undefined || signUpFirstName.value.length === 0) {
            $('#sign-up-name').addClass('error');
            $('#sign-up-first-name').addClass('error');
            setErrorFocusId('#sign-up-first-name');
            passedValidation = false;
        }

        if (signUpLastName.value === undefined || signUpLastName.value.length === 0) {
            $('#sign-up-name').addClass('error');
            $('#sign-up-last-name').addClass('error');
            setErrorFocusId('#sign-up-last-name');
            passedValidation = false;
        }

        if (globals.isValidEmailAddress(signUpEmail.value) === false) {
            $('#sign-up-email').addClass('error');
            setErrorFocusId('#sign-up-email-input');
            passedValidation = false;
        }

        if (globals.isValidPassword(signUpPassword.value) === false) {
            $('#sign-up-password').addClass('error');
            setErrorFocusId('#sign-up-password-input');
            passedValidation = false;
        }

        if (passedValidation === true) {
            if (globals.isOnline.value === true) {
                if (globals.user.getFirstName() === config.shareeTempName) {
                    updateShareeUserDetails(signUpFirstName.value, signUpLastName.value, signUpPassword.value, config.AUTHENTICATION_METHOD.TRUCKSCIENCE);
                } else {
                    registerNewUser(signUpFirstName.value, signUpLastName.value, signUpEmail.value, signUpPassword.value, signUpCountry.value, config.AUTHENTICATION_METHOD.TRUCKSCIENCE);
                }
                
            } else {
                globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')));
            }
        } else {
            // set focus
            $(errorFocusId).focus();
        }

        function setErrorFocusId(value) {
            errorFocusId = errorFocusId === undefined ? value : errorFocusId;
        }
    }
    /*
    '#######################################################################################
        'possible return codes
        '  1    Company and contact created successfully
        '  2    Duplicate trial registration (same day)
        '  3    Duplicate trial registration (past)
        ' -2    Invalid API key provided
        ' -3    Application Type must be either DESKTOP or SILVERLIGHT or HTML5
        ' -4    Invalid email address provided
        ' -5    Country not found
        ' -7    Password must be provided where Application Type is HTML5
        ' -8    Google Client ID is invalid
        ' -9    Microsoft Client ID is invalid
        ' -99   Unknown error
        '#######################################################################################
    */

    function registerNewUser(firstName, lastName, email, password, country, authenticationMethod) {
        globals.showSpinner();
        dataManager.signUp(firstName, lastName, email, password, country, authenticationMethod)
            .then(function (result) {
                if (result.ReturnCode === 1) {
                    var sendVerifiedEmailInfoToIntercom = false;
                    if (globals.isGoogleAnalyticsKeyAvailable()) {
                        let paramObj = {};
                        paramObj[config.GOOGLE_ANALYTICS_EVENT_PARAMETERS.AUTHENTICATION_MODE] = authenticationMethod;
                        dataManager.triggerEventInGoogleAnalytics(config.GOOGLE_ANALYTICS_EVENTS.TRIAL_REGISTERED, paramObj);
                    }
                    if (authenticationMethod === config.AUTHENTICATION_METHOD.GOOGLE || authenticationMethod === config.AUTHENTICATION_METHOD.MICROSOFT) {
                        //dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.VERIFIED_EMAIL, { VerifiedFrom: config.EMAIL_VERIFIED_FROM_OPTIONS.EXTERNAL }));
                        sendVerifiedEmailInfoToIntercom = true;
                    }

                    shell.login(email, password, 'currentViewModel', signUpButtonText, function () { }, true, authenticationMethod, false, sendVerifiedEmailInfoToIntercom);
                } else {
                    globals.hideSpinner();
                    var messageCode = '1571';
                    let msgButtons = [config.getTranslationText('271')];
                    switch (result.ReturnCode) {
                        case 2:
                        case 3:
                            messageCode = '1559';
                            break;
                    }
                    if(messageCode === '1559') {
                        msgButtons.unshift(config.getTranslationText('4805'));
                    }
                    globals.showDialog(new CustomMessageBox(config.getMessageText(messageCode, globals.user.getCultureCode()), config.applicationTitle, msgButtons, config.getMessageReference(messageCode)))
                    .addCallback(function(dialogResult){
                        if(dialogResult === config.getTranslationText('4805')) {
                            loginEmail.value = signUpEmail.value;
                            toggleGetStartedDisplayMode();
                        }
                    });
                }
            })
            .fail(function (error) {
                globals.hideSpinner();
                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));
            });

    }

    function updateShareeUserDetails(signUpFirstName, signUpLastName, signUpPassword, authenticationMethod) {
        globals.showSpinner();
        dataManager.updateShareeUserDetails(signUpFirstName, signUpLastName, signUpPassword, authenticationMethod)
            .then(function (result) {
                globals.hideSpinner();
                if (result.ReturnCode === 1) {
                    globals.user.updateUser({ firstName: signUpFirstName, lastName: signUpLastName, password: signUpPassword, authenticationMethod: authenticationMethod, accessCode: '' }); 
                    globals.user.displayNameObv.value = globals.user.getDisplayName();
                    globals.user.displayEmailAddressObv.value = globals.user.getEmailAddress();
                    bus.emit('setupIntercomUserSettings');
                    actionButtonCallback();
                } else {
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));
                }
            })
            .fail(function (error) {
                globals.hideSpinner();
                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));
            });
    }

    function openForgotPasswordModal() {
        globals.showDialog({
            type: 'ForgotPasswordModal'
        });
        // forgotPasswordModal.show(shell).then(function (response) {

        // });
        // return false;
    }
    
    
    

    function handleRegisterNowClick(optionalUserDetails) {
        signUpEmail.value = optionalUserDetails && optionalUserDetails.email ? optionalUserDetails.email : loginEmail.value;
        signUpPassword.value = optionalUserDetails && optionalUserDetails.password ? optionalUserDetails.password : loginPassword.value;
        toggleGetStartedDisplayMode();
    }

    let loginSignUpInterface = {
        openForgotPasswordModal: openForgotPasswordModal,
        handleRegisterNowClick: handleRegisterNowClick
    }

    function showTermsAndConditions() {
        // termsAndConditionsModal.show().then(function (response) { });
        bus.emit('openTermsAndConditionsModalOnClick', false);
    }

    function showPrivacyPolicy() {
        // termsAndConditionsModal.show(true).then(function (response) { });
        bus.emit('openTermsAndConditionsModalOnClick', false);
    }

    function toggleGetStartedDisplayMode(event, obj) {
        if (getStartedDisplayMode.value === config.GET_STARTED_MODES.SIGN_UP) {
            getStartedDisplayMode.value = config.GET_STARTED_MODES.SIGN_IN;
            loginInputBehaviour();
        } else {
            getStartedDisplayMode.value = config.GET_STARTED_MODES.SIGN_UP;
            if (countryCheckComplete) {
                setUpCountrySelectMenu();
            } else {
                getUserCountry();
            }

            // signUpInputBehaviour();
        }
        if (globals.getBrowser() === config.BROWSER_NAME.INTERNETEXPLORER) {
            window.initPlaceholder();
        }
        
        nextTick(function() { 
            signUpInputBehaviour();

            if (getStartedDisplayMode.value === config.GET_STARTED_MODES.SIGN_IN) {
                loginInputBehaviour();
            } else {
                if (countryCheckComplete) {
                    setUpCountrySelectMenu();
                } else {
                    getUserCountry();
                }
            }
            setupMicrosoftButtons();
            startApp();
        });
    }

    

    function onGoogleSignInSuccess(googleUser) {
        var profile = googleUser.getBasicProfile();
    }
    function onGoogleSignInFailure(error) {
        console.log(error);
    }

    function renderButton() {
        //gapi.load('auth2', function () {
        //    gapi.auth2.init();
        //});

        // window.gapi.signin2.render('g-signin2', {
        //     'scope': 'profile email',
        //     'width': 240,
        //     'height': 50,
        //     'longtitle': true,
        //     'theme': 'dark',
        //     'onsuccess': onGoogleSignInSuccess,
        //     'onfailure': onGoogleSignInFailure
        // });

        // https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.renderButton
        const buttonText = getStartedDisplayMode.value === config.GET_STARTED_MODES.SIGN_UP ? "signup_with" : "signin_with";
        window.google.accounts.id.renderButton(
            document.getElementById("customBtn"),
            { 
                theme: "outline",
                type: "standard",
                text: buttonText,
                logo_alignment: "center",
                width: 340
             }  // customization attributes
        );
    }

    function startApp() {
        if (window.google && typeof window.google.accounts !== 'undefined' && window.google.accounts !== null) {
            attachSignin(document.getElementById('customBtn'));
        }
    }

    function attachSignin(element) {
        if (element !== null) {
            
            // window.auth2.attachClickHandler(element.id, {},
            //     function (googleUser) {
            //         var profile = googleUser.getBasicProfile(),
            //             givenName = profile.getGivenName() !== undefined ? profile.getGivenName() : config.getTranslationText('2381'),
            //             familyName = profile.getFamilyName() !== undefined ? profile.getFamilyName() : config.getTranslationText('2382');
            //         if (getStartedDisplayMode.value === config.GET_STARTED_MODES.SIGN_UP) {
            //             registerNewUser(givenName,
            //                 familyName,
            //                 profile.getEmail(),
            //                 googleUser.getAuthResponse().id_token,
            //                 signUpCountry.value,
            //                 config.AUTHENTICATION_METHOD.GOOGLE)
            //         } else {
            //             shell.login(profile.getEmail(), googleUser.getAuthResponse().id_token, 'currentViewModel', loginButtonText, function () {}, false, config.AUTHENTICATION_METHOD.GOOGLE);
            //         }
            //     }, function (error) {
            //         TScMessenger.writeErrorMessage(JSON.stringify(error, undefined, 2));
            //     });
            window.google.accounts.id.initialize({
                client_id: process.env.VUE_APP_GoogleLoginClientID,
                callback: handleCredentialResponse
            });
            renderButton();
        }
    }

    function handleCredentialResponse(response) {
        TScMessenger.writeDebugMessage("Encoded JWT ID token: " + response.credential);
        const decodedCredentials = parseJwt(response.credential);
        TScMessenger.writeDebugMessage('Test');
        var givenName = typeof decodedCredentials.given_name === 'string' ? decodedCredentials.given_name : config.getTranslationText('2381'),
            familyName = typeof decodedCredentials.family_name === 'string' ? decodedCredentials.family_name : config.getTranslationText('2382');
        if (getStartedDisplayMode.value === config.GET_STARTED_MODES.SIGN_UP) {
            registerNewUser(givenName,
                familyName,
                decodedCredentials.email,
                response.credential,
                signUpCountry.value,
                config.AUTHENTICATION_METHOD.GOOGLE)
        } else {
            shell.login(decodedCredentials.email, response.credential, 'currentViewModel', loginButtonText, function () {}, false, config.AUTHENTICATION_METHOD.GOOGLE, undefined, undefined, loginSignUpInterface);
        }
    }

    function parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
       var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
       }).join(''));
     
       return JSON.parse(jsonPayload);
     }

    function setShowSalesBlurb(newValue) {
        self.showSalesBlurb.value = newValue;
    }

    function setShowSocialSignUpSection(newValue) {
        self.showSocialSignUpSection.value = newValue;
    }

    function setShowCountriesDropdown(newValue) {
        self.showCountriesDropdown.value = newValue;
    }

    function setSignUpEmail(newValue) {
        signUpEmail.value = newValue;
    }

    function setEmailFieldReadOnly(newValue) {
        self.emailFieldReadOnly.value = newValue;
    }

    function setActionButtonCallback(callback) {
        actionButtonCallback = callback;
    }

    this.onBeforeMount = onBeforeMount;
    this.canActivate = canActivate;
    this.onMounted = onMounted;
    this.deactivate = deactivate;
    this.config = config;
    this.submitLogin = submitLogin;
    this.submitSignUp = submitSignUp;
    this.loginEmail = loginEmail;
    this.loginPassword = loginPassword;
    this.loginButtonText = loginButtonText;
    this.signUpFirstName = signUpFirstName;
    this.signUpLastName = signUpLastName;
    this.signUpEmail = signUpEmail;
    this.signUpPassword = signUpPassword;
    this.signUpCountry = signUpCountry;
    this.signUpButtonText = signUpButtonText;
    this.openForgotPasswordModal = openForgotPasswordModal;
    this.handleRegisterNowClick = handleRegisterNowClick;
    this.countryList = countryList;
    this.showTermsAndConditions = showTermsAndConditions;
    this.showPrivacyPolicy = showPrivacyPolicy;
    //showWhyCountryMessage: showWhyCountryMessage,
    this.currentBrowser = currentBrowser;
    this.signUpPasswordTooltipMessage = signUpPasswordTooltipMessage;
    this.getStartedDisplayMode = getStartedDisplayMode;
    this.toggleGetStartedDisplayMode = toggleGetStartedDisplayMode;
    this.moduleTitle = config.MODULE_TITLE.GET_STARTED;
    this.renderButton = renderButton;
    this.isInternetExplorer = globals.isInternetExplorer;
    this.microsoftImageUrl = microsoftImageUrl;
    this.showSalesBlurb = showSalesBlurb;
    this.showSocialSignUpSection = showSocialSignUpSection;
    this.setShowSalesBlurb = setShowSalesBlurb;
    this.setShowSocialSignUpSection = setShowSocialSignUpSection;
    this.showCountriesDropdown = showCountriesDropdown;
    this.setShowCountriesDropdown = setShowCountriesDropdown;
    this.setSignUpEmail = setSignUpEmail;
    this.emailFieldReadOnly = emailFieldReadOnly;
    this.setEmailFieldReadOnly = setEmailFieldReadOnly;
    this.setActionButtonCallback = setActionButtonCallback;
    // return {
    //     onBeforeMount: onBeforeMount,
    //     canActivate: canActivate,
    //     compositionComplete: compositionComplete,
    //     deactivate: deactivate,
    //     config: config,
    //     submitLogin: submitLogin,
    //     submitSignUp: submitSignUp,
    //     loginEmail: loginEmail,
    //     loginPassword: loginPassword,
    //     loginButtonText: loginButtonText,
    //     signUpFirstName: signUpFirstName,
    //     signUpLastName: signUpLastName,
    //     signUpEmail: signUpEmail,
    //     signUpPassword: signUpPassword,
    //     signUpCountry: signUpCountry,
    //     signUpButtonText: signUpButtonText,
    //     openForgotPasswordModal: openForgotPasswordModal,
    //     countryList: countryList,
    //     showTermsAndConditions: showTermsAndConditions,
    //     showPrivacyPolicy: showPrivacyPolicy,
    //     //showWhyCountryMessage: showWhyCountryMessage,
    //     currentBrowser: currentBrowser,
    //     signUpPasswordTooltipMessage: signUpPasswordTooltipMessage,
    //     getStartedDisplayMode: getStartedDisplayMode,
    //     toggleGetStartedDisplayMode: toggleGetStartedDisplayMode,
    //     moduleTitle: config.MODULE_TITLE.GET_STARTED,
    //     renderButton: renderButton,
    //     isInternetExplorer: globals.isInternetExplorer,
    //     microsoftImageUrl: microsoftImageUrl,
    //     showSalesBlurb: showSalesBlurb,
    //     showSocialSignUpSection: showSocialSignUpSection,
    //     setShowSalesBlurb: setShowSalesBlurb,
    //     setShowSocialSignUpSection: setShowSocialSignUpSection,
    //     showCountriesDropdown: showCountriesDropdown,
    //     setShowCountriesDropdown: setShowCountriesDropdown,
    //     setSignUpEmail: setSignUpEmail,
    //     emailFieldReadOnly: emailFieldReadOnly,
    //     setEmailFieldReadOnly: setEmailFieldReadOnly,
    //     setActionButtonCallback: setActionButtonCallback
    // }

}

export default LoginSignUpObject;
    
